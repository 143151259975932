import {Grid, styled} from "@mui/material";
import {prefixClasses} from "../common/Theme/utils";

const classes = {
    root: "root",
    sideLine: "sideLine",
    sectionWrapper: "sectionWrapper",
    sectionContent: "sectionContent",
    sectionHeading: "sectionHeading",
    sectionBoldText: "sectionBoldText",
    sectionSmallText: "sectionSmallText",
    sectionGrid: "sectionGrid",
    gridElementsAll: "gridElementsAll",
    gridElementText: "gridElementText",
    gridElementGradient: "gridElementGradient",
    elementFirst: "elementFirst",
    elementSecond: "elementSecond",
    elementThird: "elementThird",
    elementFourth: "elementFourth",
    elementFifth: "elementFifth",
    elementSixth: "elementSixth",
    elementSeventh: "elementSeventh",
    elementEighth: "elementEighth",
    elementNinth: "elementNinth",
    hoverPopUp: "hoverPopUp",
    gridElementDivider: "gridElementDivider",
    gridElementRecommendationLink: "gridElementRecommendationLink",
};

prefixClasses("PartTwoPNC", classes);

export const StyledPartTwoPNC = styled(Grid)(({ theme }) => ({
    [`&.${classes.root}`]: {
        padding: '48px 0',
        width: '100%',
        backgroundImage: "url('/circles-background.jpg')",
        backgroundPositionX: 'left',
        backgroundPositionY: 'top',
        backgroundSize: '100%',
    },
    [`& .${classes.sideLine}`]: {
        height: '2px',
        position: 'relative',
        width: '100%',
        bottom: '40px',
        right: 'calc(100% + 60px)',
        backgroundColor: '#008bc8',
        [theme.breakpoints.down('mobileXL')]: {
            display: 'none',
        },
    },
    [`& .${classes.sectionWrapper}`]: {
        padding: '0px 0px',
        margin: '0 auto',
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.down('xl')]: {
            maxWidth: '1140px',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '960px',
        },
        [theme.breakpoints.down('mobileXL')]: {
            maxWidth: '720px',
        },
        [theme.breakpoints.down('mobileLG')]: {
            maxWidth: '540px',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '450px',
        },
        [theme.breakpoints.down('mobileMD')]: {
            maxWidth: '360px',
        },
        [theme.breakpoints.down('mobileSM')]: {
            maxWidth: '300px',
        },
    },
    [`& .${classes.sectionContent}`]: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: '83.33%',
        marginLeft: '8.33%',
        padding: '0 7.5px',
        [theme.breakpoints.down('mobileXL')]: {
            flex: 'auto',
            marginLeft: '0',
            padding: '0 0',
        },
    },
    [`& .${classes.sectionHeading}`]: {
        color: '#d2e0e6',
        fontSize: '1.875rem',
        textTransform: 'uppercase',
        marginBottom: '24px',
        fontWeight: '900',
    },
    [`& .${classes.sectionBoldText}`]: {
        fontSize: '1.875rem',
        [theme.breakpoints.down('mobileMD')]: {
            fontSize: '1.5rem',
        },
        marginBottom: '48px',
    },
    [`& .${classes.sectionSmallText}`]: {
        fontSize: '1rem',
        marginBottom: '32px',
    },
    [`& .${classes.sectionGrid}`]: {
        gap: '15px',
    },
    [`& .${classes.gridElementsAll}`]: {
        height: '260px',
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: '32%',
        [theme.breakpoints.down('md')]: {
            flexBasis: '48%',
        },
        [theme.breakpoints.down('mobileXL')]: {
            flexBasis: '47%',
        },
        [theme.breakpoints.down('mobileLG')]: {
            flexBasis: '100%',
            height: '230px',
        },
        [theme.breakpoints.down('mobileMD')]: {
            height: '200px',
        },
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        backgroundSize: 'cover',
        overflow: 'hidden',
        borderRadius: '5px',
        padding: '0px',
        cursor: 'pointer',
    },
    [`& .${classes.gridElementText}`]: {
        fontSize: '1.125rem',
        margin: 0,
    },
    [`& .${classes.gridElementGradient}`]: {
        width: '100%',
        height: '100%',
        background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%, #1e252f 90%)',
        padding: '15px',
        color: '#fff',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column-reverse',
        "&:hover": {
            background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%, #008bc8 80%)',
        },
    },
    [`& .${classes.elementFirst}`]: {
        backgroundImage: "url('/partTwoGrid1.jpeg')",
    },
    [`& .${classes.elementSecond}`]: {
        backgroundImage: "url('/partTwoGrid2.jpeg')",
    },
    [`& .${classes.elementThird}`]: {
        backgroundImage: "url('/partTwoGrid3.jpeg')",
    },
    [`& .${classes.elementFourth}`]: {
        backgroundImage: "url('/partTwoGrid4.jpg')",
    },
    [`& .${classes.elementFifth}`]: {
        backgroundImage: "url('/partTwoGrid5.jpeg')",
    },
    [`& .${classes.elementSixth}`]: {
        backgroundImage: "url('/partTwoGrid6.jpeg')",
    },
    [`& .${classes.elementSeventh}`]: {
        backgroundImage: "url('/partTwoGrid7.jpeg')",
    },
    [`& .${classes.elementEighth}`]: {
        backgroundImage: "url('/partTwoGrid8.jpeg')",
    },
    [`& .${classes.elementNinth}`]: {
        backgroundImage: "url('/partTwoGrid9.jpg')",
    },
    [`& .${classes.hoverPopUp}`]: {
        height: '38px',
        width: '100%',
        margin: 0,
        padding: 0,
    },
    [`& .${classes.gridElementDivider}`]: {
        width: '20%',
        borderBottomWidth: 1,
        backgroundColor: '#fff',
        marginTop: '5px',
    },
    [`& .${classes.gridElementRecommendationLink}`]: {
        color: '#fff',
        fontSize: '0.925rem',
        position: 'relative',
        top: '15px',
        textAlign: 'center',
    },
}));

export const partTwoPNCClasses = classes;