import { IDocument } from "../IDocument";
import { documentClasses as classes, StyledDocument } from "../StyledDocuments";
import {Typography, Grid, List, ListItem, CardMedia} from "@mui/material";

const TemplateThree = ({ document }: { document: IDocument }) => {
  return (
    <StyledDocument className={classes.root}>
      <CardMedia
        component="img"
        alt={document.imgAlt}
        image={document.img}
        className={classes.image}
      />
      <Grid>
        <Typography fontWeight={"bold"} className={classes.titleMarginSmall}>
          {document.title}
        </Typography>
        <Typography fontWeight={"bold"}>{document.date}</Typography>
      </Grid>
      <Grid className={classes.titleMarginLargeTop}>
        <Typography>{document.recommendationText}</Typography>
      </Grid>
      {document.summary &&
          <Grid className={classes.titleMarginLargeBoth}>
              <Typography fontWeight={"bold"} className={classes.titleMarginSmall}>
                  {document.summary}
              </Typography>
          </Grid>
      }
      {document.keyfindings &&
          <Grid>
              <Typography fontWeight={"bold"}>
                  {document.keyfindings?.title}
              </Typography>
              <List className={classes.bulletList}>
                  {document.keyfindings?.content.map((remark) => (
                      <ListItem className={classes.bulletListItem}>
                          <span>{remark}</span>
                      </ListItem>
                  ))}
              </List>
          </Grid>
      }
      <Grid className={classes.outcomes}>
        {document.evidence?.map((element, index) => (
          <Grid item>
            <Typography
              fontWeight={"bold"}
              className={classes.titleMarginMedium}
            >
              {element.title}
            </Typography>
            <Typography>{element.content}</Typography>
          </Grid>
        ))}
      </Grid>
      <Grid marginTop={"32px"}>
        <Typography fontWeight={"bold"} className={classes.titleMarginSmall}>
          {document.abstract?.title}
        </Typography>
        {document.abstract?.content.map((paragraph) => (
          <Typography className={classes.titleMarginSmall}>
            {paragraph}
          </Typography>
        ))}
      </Grid>
    </StyledDocument>
  );
};

export default TemplateThree;
