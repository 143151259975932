import { documents } from "./documents-data";
import { IDocument } from "./IDocument";
import { useParams } from "react-router-dom";
import TemplateTwo from "./Templates/TemplateTwo";
import TemplateOne from "./Templates/TemplateOne";
import TemplateThree from "./Templates/TemplateThree";

export const Document = () => {
  const { documentId } = useParams();
  const document = documents.find(
    (innerDocument: IDocument) => innerDocument.id === documentId
  ) as IDocument;

  if (document.template === "template-two") {
    return <TemplateTwo document={document} />;
  } else if (document.template === "template-three") {
    return <TemplateThree document={document} />;
  }
  return <TemplateOne document={document} />;
};
