import React, { useState, useEffect } from "react";
import { partThreeClasses as classes, StyledPartThree } from "./StyledPartThree";
import { Container, Typography, Box } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

export const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

const swiperElements = [
    {
        href: 'https://www.who.int/publications/i/item/guidelines-for-malaria',
        img: '/elementPhoto-22.jpg',
        text: 'WHO Guidelines for malaria (2022)'
    },
    {
        href: 'https://www.who.int/publications/i/item/9789240041608',
        img: '/elementPhoto-23.jpg',
        text: 'WHO Guideline on elimination and control of schistosomiasis (2022)'
    },
    {
        href: 'https://www.who.int/publications/i/item/9789240058262',
        img: '/elementPhoto-24.jpg',
        text: 'WHO recommendations for the care of the preterm or low-birth-weight infant (2022)'
    },
    {
        href: 'https://www.who.int/publications/i/item/9789240057296',
        img: '/elementPhoto-25.jpg',
        text: 'WHO recommendations on antenatal corticosteroids for improving preterm birth outcomes (2022):'
    },
    {
        href: 'https://apps.who.int/iris/handle/10665/333922?locale-attribute=en&',
        img: '/elementPhoto-1.png',
        text: 'Integrating stakeholder and community engagement in quality of care initiatives for maternal, newborn and child health (2020)',
    },
    {
        href: 'https://www.who.int/publications/i/item/978-92-4-000270-8',
        img: '/elementPhoto-2.png',
        text: 'Prevention of mother to child transmission of hepatitis B virus (2020)',
    },
    {
        href: 'https://www.who.int/publications/i/item/9789240008793',
        img: '/elementPhoto-4.png',
        text: 'WHO recommendations on drug treatment for non-severe hypertension in pregnancy (2020)',
    },
    {
        href: 'https://www.who.int/teams/health-care-readiness/covid-19',
        img: '/elementPhoto-5.png',
        text: 'Clinical management of COVID-19 (2020)',
    },
    {
        href: 'https://www.who.int/publications/i/item/who-consolidated-guidelines-on-tuberculosis-module-1-prevention-tuberculosis-preventive-treatment',
        img: '/elementPhoto-7.png',
        text: 'WHO consolidated guidelines on tuberculosis (2020)',
    },
    {
        href: 'https://www.who.int/publications/i/item/978-92-4-155058-1',
        img: '/elementPhoto-8.png',
        text: 'Consolidated guidelines on HIV testing services (2019)',
    },
    {
        href: 'https://www.who.int/publications/i/item/978-92-4-155058-1',
        img: '/elementPhoto-21.jpg',
        text: 'WHO Consolidated guidelines on HIV testing services (2019)'
    },
    {
        href: 'https://www.who.int/publications/m/item/who-unicef-guidance-note---ensuring-sustained-protection-against-diphtheria-replacing-tt-with-td-vaccine',
        img: '/elementPhoto-3.png',
        text: 'Ensuring sustained protection against diphtheria: replacing TT with Td vaccine (2018)',
    },
    {
        href: 'http://apps.who.int/iris/handle/10665/274277',
        img: '/elementPhoto-6.png',
        text: 'WHO recommendations on home-based records for maternal, newborn and child health (2018)',
    },
    {
        href: 'https://apps.who.int/iris/handle/10665/275846',
        img: '/elementPhoto-9.png',
        text: 'Standard protocol to assess the prevalence of gonorrhoea and chlamydia among pregnant women in ANC clinics (2018)',
    },
    {
        href: 'https://apps.who.int/iris/handle/10665/260178',
        img: '/elementPhoto-10.png',
        text: 'WHO recommendations Intrapartum care for a positive childbirth experience (2018)',
    },
    {
        href: 'https://apps.who.int/iris/handle/10665/259003',
        img: '/elementPhoto-11.png',
        text: 'WHO guideline on syphilis screening and treatment for pregnant women (2017)',
    },
    {
        href: 'https://apps.who.int/iris/handle/10665/254621',
        img: '/elementPhoto-13.png',
        text: 'Guidelines on Hepatitis B and C testing (2017)',
    },
    {
        href: 'https://apps.who.int/iris/handle/10665/255760',
        img: '/elementPhoto-14.png',
        text: 'Managing complications in pregnancy and childbirth: A guide (2017)',
    },
    {
        href: 'https://apps.who.int/iris/handle/10665/258983',
        img: '/elementPhoto-16.png',
        text: 'Guideline: Preventive chemotherapy to control soil-transmited helminth infections (2017)',
    },
    {
        href: 'https://apps.who.int/iris/handle/10665/254885',
        img: '/elementPhoto-17.png',
        text: 'Consolidated guideline on sexual & reproductive health & rights of women living with HIV (2017)',
    },
    {
        href: 'https://apps.who.int/iris/handle/10665/181041',
        img: '/elementPhoto-12.jpg',
        text: 'Health worker roles in providing safe abortion care and post-abortion contraception (2015)',
    },
    {
        href: 'https://apps.who.int/iris/handle/10665/186275',
        img: '/elementPhoto-15.png',
        text: 'Guideline on when to start ART & on pre-exposure prophylaxis for HIV (2015)',
    },
    {
        href: 'https://apps.who.int/iris/handle/10665/162441',
        img: '/elementPhoto-18.png',
        text: 'Guidelines for the treatment of malaria (2015)',
    },
    {
        href: 'https://apps.who.int/iris/handle/10665/249580',
        img: '/elementPhoto-19.png',
        text: 'Pregnancy, childbirth, postpartum and newborn care: A guide (2015)',
    },
    {
        href: 'https://apps.who.int/iris/handle/10665/172427',
        img: '/elementPhoto-20.png',
        text: 'WHO recommendations on health promotion interventions for maternal & newborn health (2015)',
    },
]

const mobileLGBreakpoint = 768;
const mobileXLBreakpoint = 900;

export const PartThree = () => {
    const [columnsNumber, setColumnsNumber] = useState(3);

    useEffect(() => {
        const handleWindowResize = () => {
            if(window.innerWidth<mobileLGBreakpoint) {setColumnsNumber(1)}
            else if(window.innerWidth>mobileXLBreakpoint) {setColumnsNumber(3)}
            else {setColumnsNumber(2)}
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    return <StyledPartThree className={classes.root} container>
        <Container id='Guidelines' className={classes.sectionWrapper}>
            <Container className={classes.sectionContent}>
                <Container className={classes.sectionText}>
                    <Typography className={classes.textHeading} variant="h1">Part 3</Typography>
                    <Typography variant="h2" className={classes.textBold}>
                        Links to the other relevant WHO guidance <br/>
                        related to maternal and newborn health
                        <Box className={classes.sideLine}/>
                    </Typography>
                    <Typography variant="h2" className={classes.textSmall}>ANC and PNC relevant recommendations from
                        previous and current guidance produced by WHO Departments are available here for the purpose of
                        providing a comprehensive repository for end-users.</Typography>
                </Container>
                <Swiper slidesPerView={columnsNumber} slidesPerGroup={columnsNumber} grid={{fill: 'row', rows: 3}} pagination={{clickable: true, type: 'bullets',}} modules={[Grid, Pagination]} className={classes.sectionSwiper}>
                    {swiperElements.map( (element, i) => (
                        <SwiperSlide key={i} className={classes.swiperElementWrapper}>
                            <Container onClick={() => openInNewTab(element.href)} className={classes.swiperElement}>
                                <Container className={classes.elementImageWrapper}>
                                    <Box component="img" src={element.img} className={classes.elementImage}/>
                                </Container>
                                <Typography className={classes.elementCaption} variant="h2">{element.text}</Typography>
                            </Container>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Container>
        </Container>
    </StyledPartThree>;
};