import React from "react";
import { footerClasses as classes, StyledFooter } from "./StyledFooter";
import { Grid, Box, Link, Divider, Typography } from "@mui/material";

const options = [
    {value: 'REVIEWS', pageId: '#Reviews' },
    {value: 'GUIDELINES', pageId: '#Guidelines' },
    {value: 'TOOLS', pageId: '#Tools' },
    {value: 'RESOURCES', pageId: '#Resources' },
    {value: 'CONTACT', pageId: 'https://www.who.int/teams/sexual-and-reproductive-health-and-research-(srh)/about-us' },
];

export const Footer = () => {
    return <StyledFooter className={classes.root}>
        <Grid className={classes.footer}>
            <Grid item className={classes.footerWrapper}>
                <Grid item className={classes.footerContent}>
                    <Grid>
                        <Box className={classes.footerLogo} component="img" src={'/logo.png'}/>
                    </Grid>
                    <Grid item className={classes.footerGridWrapper}>
                        {options.map((option) => (
                            <Grid item key={option.pageId}>
                                <Link className={classes.footerItem} href={option.pageId} underline="none" color="#000">{option.value}</Link>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item className={classes.footerContentLinks}>
                    <Grid item className={classes.footerLinkGroup}>
                        <Grid item>
                            <Typography>You can follow WHO on Twitter for all the latest updates.</Typography>
                        </Grid>
                        <Grid container className={classes.firstLinks}>
                            <Grid item>
                                <Link href="https://twitter.com/WHO" target="_blank" underline="none" color="#000">WHO</Link>
                            </Grid>
                            <Grid item>
                                <Link href="https://twitter.com/HRPresearch" target="_blank" underline="none" color="#000">HRP</Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.footerLinkGroup}>
                        <Grid item>
                            <Typography>Links to WHO webpages can be found here.</Typography>
                        </Grid>
                        <Grid container className={classes.secondLinks}>
                            <Grid item>
                                <Link href="https://www.who.int/teams/maternal-newborn-child-adolescent-health-and-ageing/research" target="_blank" underline="none" color="#000">MCA</Link>
                            </Grid>
                            <Grid item>
                                <Link href="https://www.who.int/teams/sexual-and-reproductive-health-and-research-(srh)/human-reproduction-programme" target="_blank" underline="none" color="#000">HRP</Link>
                            </Grid>
                            <Grid item>
                                <Link href="https://www.qualityofcarenetwork.org/" target="_blank" underline="none" color="#000">QCN</Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider />
            <Grid item className={classes.footerCopyright}>
                <Link underline="none" href="https://www.who.int/">WHO Copyright 2023</Link>
            </Grid>
        </Grid>
    </StyledFooter>;
};
