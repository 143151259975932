import { IDocument } from "./IDocument";
import { Grid, Link, List, ListItem, Typography } from "@mui/material";
import img71 from "./img/document7-1.jpg";
import img72 from "./img/document7-2.jpg";
import img73 from "./img/document7-3.jpg";
import img74 from "./img/document7-4.jpg";
import img75 from "./img/document7-5.jpg";
import img76 from "./img/document7-6.jpg";
import img77 from "./img/document7-7.png";

export const documentsSeven: IDocument[] = [
  {
    id: "19",
    img: img71,
    imgAlt: "a pregnant woman doing chores",
    title:
      "WHO recommendation on birth preparedness and complication readiness",
    date: "31 May 2015",
    recommendation: {
      title: "Recommendation",
      content:
        "Birth preparedness and complication readiness (BPCR) interventions are recommended to increase the use of skilled care at birth and to increase the timely use of facility care for obstetric and newborn complications.",
    },
    recommendationItalic:
      " (Strong recommendation, very low quality of evidence)",
    publicationHistory: {
      firstPublished: "May 2015",
      updated: "No update planned",
      assessedAsUpToDate: "May 2015",
    },
    remarks: {
      title: "Remarks",
      content: ["Additional research is required"],
    },
    background: {
      title: "Background",
      content: [
        "In 2003 the World Health Organization (WHO) published “Working with individuals, families and communities to improve maternal and newborn health”, (1) the IFC framework that promotes integrating the health promotion approach set out in the Ottawa Charter (2) into national maternal and newborn health (MNH) strategies.",
        "The IFC Framework was developed in response to analysis and global statements indicating that as well as strengthening services, MNH strategies need to improve the capacity of individuals, families and communities to provide appropriate care for pregnant women, mothers, and newborns in the home. It also addresses the reasons – over and above what happens in clinical services – why women do not reach good quality skilled care during pregnancy, childbirth and after birth. The Ottawa Charter’s health promotion components(2) were translated into MNH programme language and 12 promising interventions – identified through reviews of country experiences and the literature – were categorized into four priority areas. Community and intersectoral participation was recommended to guide implementation. Exact interventions to be adapted by country programmes were to be identified through local assessment; however, the framework highlighted the need for interventions to address all four priority areas at the same time. All six WHO Regions integrated this guidance into the regional maternal mortality reduction strategies.",
        "Birth Preparedness and Complication Readiness (BPCR) is an intervention included by WHO as an essential element of the antenatal care package. (3) It is often delivered to the pregnant woman by the health care provider in antenatal care or initiated or followed up through a visit to the home of the pregnant woman by a community health worker. In addition to working with an individual pregnant woman, programmes often address efforts to her family and to the broader community to increase awareness on BPCR or to improve health workers’ skills to provide BPCR as part of ANC. Programmes often provide education materials or other visual aids with BPCR information, or may implement mass media campaigns with BPCR messages.",
        "A birth and complications preparedness plan contains the following elements: the desired place of birth; the preferred birth attendant; the location of the closest facility for birth and in case of a complication; funds for any expenses related to birth and in case of complications; supplies and materials necessary to bring to the facility; an identified labour and birth companion; an identified support to look after the home and other children while the woman is away; transport to a facility for birth or in the case of a complication; and the identification of compatible blood donors in case of emergency.(4)",
        "To be able to be prepared for birth and possible complications, women, families and communities need to know about signs of onset of labour as well as danger signs during pregnancy and after birth for the woman and newborn. BPCR interventions have evolved and while originally programmes focused largely on careseeking for the woman, in recent years, programmes have recognized the value of discussing care-seeking for newborn complications.",
        "This recommendation was published within the 2015 WHO guideline “WHO Recommendations on Health Promotion Interventions for Maternal and Newborn Health.”(5)",
      ],
    },
    methods: {
      title: "Methods",
      content: [
        "The recommendation was developed using standardized operating procedures in accordance with the process described in the “WHO handbook for guideline development”, guided by the GRADE approach.(6) Outcomes used for this recommendation were aligned with the prioritized outcomes from the WHO recommendations on health promotion interventions for maternal and newborn health (2015).(5)",
        "A systematic review was conducted on birth and complications readiness.(7) In the review, randomized controlled trials relevant to the key question were screened by review authors, and data on relevant outcomes and comparisons were extracted. Evidence profiles (in the form of GRADE tables) were prepared for comparisons of interest, including the assessment and judgments for each outcome, and the estimated risks.",
        "WHO convened a Guideline Development Group (GDG) meeting on recommendations on health promotion interventions for maternal and newborn health where this recommendation was developed. The GDG comprised of a group of independent experts, who used the evidence profiles to assess evidence on effects on the pre-specified outcomes. GDG members discussed the balance between desirable and undesirable effects, overall quality of supporting evidence, values and preferences of stakeholders, resource requirements, cost-effectiveness, acceptability, feasibility and equity, to formulate the recommendation. Remarks were added to clarify the recommendation, and aid implementation.",
        <span>
          Further information on procedures for developing this recommendation
          are available{" "}
          <Link href="https://web.archive.org/web/20201105052025/http:/apps.who.int/iris/bitstream/handle/10665/172427/9789241508742_report_eng.pdf;jsessionid=0888E6D3E74DA6A9DE077D118B6EF1DB?sequence=1">
            here.
          </Link>
        </span>,
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following question:",
      content: [
        "What interventions used to implement BPCR are effective for increasing use of skilled birth attendants and for improving other maternal and newborn health outcomes?",
      ],
    },
    evidenceSummary: {
      title: "Evidence summary",
      content: [
        "Evidence on birth and complications readiness was extracted from a systematic review conducted by Solnes Miltenburg et al. (7) of 33 studies which summarized the findings from 21 different programmes. The study designs reported in the studies included one RCT, three cluster RCTs, seven pre and post comparative studies with a control group, three pre and post studies, seven one group before and after evaluations of which two had a qualitative component, and one qualitative study. The 21 programmes were implemented in Bangladesh, Burkina Faso, Cambodia, Eritrea, Guatemala, India, Indonesia, Kenya, Nepal, Pakistan and Tanzania. The BPCR interventions largely focused on promoting birth with a skilled birth attendant (SBA), with the exception of seven, which were primarily aimed to increase use of skilled care for obstetric complications.(8-14)\n" +
          "The programmes implemented different strategies including house visits by volunteers who provided education on BPCR, training of health workers in facilities to provide BPCR as part of ANC, provision of education materials or other visual aids with BPCR information, community mobilization activities to increase awareness on BPCR and mass media campaigns with BPCR messages. There was not sufficient evidence to determine which of these strategies or which combination of strategies were most effective.\n",
      ],
    },
    maternalOutcomes: {
      title: "",
      content: "",
    },
    fetalAndNeonatalOutcomes: [
      {
        title:
          "For the outcome of birth with a skilled birth attendant or facility birth, the quality of the evidence ranged from very low to low.",
        content: (
          <Grid>
            <Typography gutterBottom>
              - One RCT (Mullany, Becker and Hindin, 2007) of a single BPCR
              intervention using facility education sessions with poor women and
              husbands in a maternity hospital in urban Nepal reported a
              non-significant increase in the use of a skilled birth attendant
              at birth in both intervention groups (the husband and wife or wife
              alone received health education) compared with control (the wife
              receives no education). Low-quality evidence.(15)
            </Typography>
            <Typography gutterBottom>
              - 13 studies reported on this outcome, including four
              quasi-experimental studies (FCI Kenya, 2007; FCI Tanzania, 2007;
              Hounton et al., 2008; Turan, Tesfagiorghis and Polan, 2011) and
              one pre and post study with a control group (Sood et al., 2004
              Indonesia); seven were one group before and after evaluations
              (Fonseca-Becker and Schenck-Yglesias, 2004; Hodgins et al., 2010;
              McPherson et al., 2006; Moran et al., 2006; Mushi, Mpembeni and
              Jahn, 2010; Sinha, 2008; Sood et al., 2004 Nepal); and one
              qualitative feasibility study (Skinner and Rathavy, 2009).(16-25)
              Three quasi-experimental studies (FCI Tanzania, 2007; Hounton et
              al., 2008; Turan, Tesfagiorghis and Polan, 2011) and one pre and
              post study with a control group (Sood et al., 2004 Indonesia)
              report significant improvements in the primary outcome in the
              intervention area (SBA or facility births); the other
              quasi-experimental study reports a higher increase in SBA in the
              control area (FCI Kenya, 2007). Four of the one group before and
              after studies report significant improvements in SBA or facility
              birth (Fonseca-Becker and Schenck-Yglesias, 2004; Moran et al.,
              2006; Mushi, Mpembeni and Jahn, 2010; Sinha, 2008); the other
              three report slight improvements from baseline (Hodgins et al.,
              2010; McPherson et al., 2006) and greater improvement in SBA in
              the unexposed group at endline (Sood et al., 2004 Nepal). The
              qualitative feasibility study which includes pre- and
              post-facility data indicates an increase in the number of women
              giving birth with a midwife, but this is based on data from all
              villages linked to 10 health centres and not just the villages
              where the intervention occurred (Skinner and Rathavy, 2009). Very
              low-quality evidence.(26)
            </Typography>
            <Typography gutterBottom>
              - For those studies whose focus was{" "}
              <b>increasing access to skilled care for complications:</b>
            </Typography>
            <List>
              <ListItem>
                • Two cluster RCTs (Darmstadt et al., 2010; Midhet and Becker,
                2010) report the percentage of women giving birth at a facility
                was significantly higher in the intervention arms compared to
                the control. In another cluster RCT (Kumar et al., 2010),
                facility births were higher and more women gave birth with a
                qualified attendant in the intervention arm, but these were not
                significant differences. Very low-quality evidence.(11, 13)
              </ListItem>
              <ListItem>
                • A quasi-experimental study (Hossain and Ross, 2006) reports
                significant increases in facility birth in the intervention and
                the comparison areas baseline to follow-up, but not in the
                control area. Another quasi-experimental study (Baqui et al.,
                2008) reports significant improvement in use of skilled birth
                attendants in a health facility or at home from baseline to
                endline in the intervention district. In the pre-post study
                (Ahluwalia et al., 2003), there was a reduction in births
                assisted by a health provider. Very low-quality evidence.(8, 10,
                12)
              </ListItem>
            </List>
          </Grid>
        ),
      },
      {
        title:
          "For the outcome of care with a skilled birth attendant or in a facility in case of complications/illness in women and newborns, the quality of the evidence was rated as very low.",
        content: (
          <Grid>
            <Typography gutterBottom>
              - Four studies report on this outcome. The before and after
              studies report more women seeking skilled care for complications
              between baseline and followup (Fonseca-Becker and
              Schenck-Yglesias, 2004; McPherson et al., 2006) and increases
              between baseline and endline in percentage of respondents seeking
              care following recognitions of danger signs in newborns, in
              pregnancy and at delivery (Hodgins et al., 2010). One
              quasi-experimental pre and post study with a control group (FCI
              Tanzania, 2007) reports a significant increase in women with
              complications seeking treatment at a facility in the intervention
              group. Very low-quality evidence.(19-21)
            </Typography>
            <Typography gutterBottom>
              - For those studies whose focus was increasing access to skilled
              care for complications:
            </Typography>
            <List>
              <ListItem>
                • Three cluster RCTs report on this outcome. One cluster RCT
                (Midhet and Becker, 2010) reports significant increases in women
                with complications seeking care in pregnancy and after birth,
                but no significant difference between study arms for
                care-seeking for birth complications. Another cluster RCT (Kumar
                et al., 2012) reports that significantly fewer women with
                complications went to unqualified practitioners; and the other
                RCT (Darmstadt et al., 2010) reports that careseeking from a
                qualified provider for neonates with complications increased
                significantly more in the intervention arm than comparison. Very
                low-quality evidence.(11, 13, 14)
              </ListItem>
              <ListItem>
                • A quasi-experimental study (Hossain and Ross, 2006) reports a
                significant increase in women seeking care for complications in
                the intervention and comparison groups but not control. Both the
                pre and post study (Ahluwalia, 2003) and the follow-up
                evaluation (Ahluwalia, 2010) report increases in number of women
                with complications seeking hospital care but only the follow-up
                study (Ahluwalia, 2010) estimates what percentage this
                represents (based on old surveillance data). There is no
                comparable data from a control group of women who did not
                receive the intervention. Very low-quality evidence.(8, 9, 12)
              </ListItem>
            </List>
          </Grid>
        ),
      },
      {
        title:
          "For the outcome of maternal mortality, the quality of the evidence ranged from very low to moderate.\n",
        content: (
          <Grid>
            <Typography gutterBottom>
              - One quasi-experimental study (Hounton et al., 2008) reports a
              lower mortality risk in the intervention group and a decline over
              time but this is not significantly different to the
              non-intervention area or control area. Very low-quality
              evidence.(16)
            </Typography>
            <Typography gutterBottom>
              - For those studies whose focus was{" "}
              <b>increasing access to skilled care for complications:</b>
            </Typography>
            <List>
              <ListItem>
                • One RCT (Kumar et al., 2012) reports a non-significant
                downward trend in maternal mortality ratio (MMR) in the
                intervention arm compared to control. Low-quality evidence.(13)
              </ListItem>
            </List>
          </Grid>
        ),
      },
      {
        title:
          "For the outcome of neonatal mortality, the quality of the evidence was rated as very low to low.",
        content: (
          <Grid>
            <Typography gutterBottom>
              - The pre and post evaluation (Hodgins et al., 2010) showed fewer
              neonatal deaths over time but there was no separate comparison
              group. Low-quality evidence.(20)
            </Typography>
            <Typography gutterBottom>
              - For those studies whose focus was increasing access to skilled
              care for complications:
            </Typography>
            <List>
              <ListItem>
                • One cluster RCT (Kumar et al., 2012) reports significantly
                lower neonatal mortality in the BP intervention group. The other
                two cluster RCTs (Darmstadt et al., 2010; Midhet and Becker,
                2010) report no significant difference in neonatal mortality by
                study arm. Very low-quality evidence.(11, 13, 14)
              </ListItem>
              <ListItem>
                • The quasi-experimental study (Baqui et al., 2008) showed no
                difference in neonatal mortality rates between the intervention
                and comparison groups or from the baseline and endline.
                Low-quality evidence.(10)
              </ListItem>
            </List>
          </Grid>
        ),
      },
      {
        title:
          "Further information and considerations related to this recommendation can be found in the WHO guidelines, available at:",
        content: (
          <Link href="http://apps.who.int/iris/bitstream/10665/172427/2/9789241508742_tables_eng.pdf?ua=1">
            http://apps.who.int/iris/bitstream/10665/172427/2/9789241508742_tables_eng.pdf?ua=1
          </Link>
        ),
      },
    ],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "Implementation of BPCR should include preparedness for birth and complications for mother and newborn; as opposed to focusing either only on planning for birth, only on planning for complications or only on the mother.",
        "Use of a skilled birth attendant during childbirth or facility birth increased primarily under circumstances where BPCR interventions were part of a multiple package of interventions. Co-interventions which seemed to have a positive impact include community participation, the involvement of male partner and of other household decision-makers in discussions (with the woman’s consent) and concurrent efforts to improve the quality of service delivery.",
        "Factors that limited the impact of the interventions include health system barriers such as shortage of health professionals, lack of resources and poor quality of care; cultural factors that affected the use of care, including perceptions of what skilled care is; and high costs for seeking care which relate to out-ofpocket expenditures.",
        "In settings with extremely low use of SBA for birth and where facility birth is not feasible, BPCR should include the following actions: choosing an SBA to attend the birth in the home; preparing the place of birth at home; preparing for clean birth with essential materials and supplies such as a birth kit; planning for emergency transportation; essential newborn care preparedness (delayed first bathing, drying of newborn before the delivery of the placenta, initiation of breastfeeding within one hour after birth, safe cord care); and a companion who will stay with the woman for at least 24 hours after birth.",
      ],
    },
    additionalLists: [],
    researchImplications: {
      title: "Research implications",
      text: "The GDG identified that further research on the following high-priority questions is needed:",
      content: [
        "Agreement on priority BPCR actions and/or which combination of essential actions should be further tested.",
        "Whether BPCR interventions that include careseeking for the newborn in case of complications have had an impact on this outcome.",
        "Studies that better measure the effect on careseeking outcomes for pregnant women and newborns of including men and other key decision-makers at the household level in the discussions on preparations and in the corresponding decisions.",
        "Both quantitative and qualitative enquiries are needed.",
      ],
    },
    relatedLinks: {
      title: "Related links",
      text: (
        <Typography>
          WHO recommendations on antenatal care for a positive pregnancy
          experience (2015) -{" "}
          <Link href="https://web.archive.org/web/20200915144755/https://apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1">
            full document
          </Link>{" "}
          and{" "}
          <Link href="https://web.archive.org/web/20201219124651/https://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            evidence table
          </Link>
        </Typography>
      ),
      links: [
        {
          href: "https://web.archive.org/web/20201031220559/https://apps.who.int/iris/bitstream/handle/10665/255760/9789241565493-eng.pdf?sequence=1",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors",
        },
        {
          href: "https://web.archive.org/web/20201030093023/https://apps.who.int/iris/bitstream/handle/10665/249580/9789241549356-eng.pdf;jsessionid=7B8E18468A424E0301261B2CA6BCB324?sequence=1",
          text: "Pregnancy, Childbirth, Postpartum and Newborn Care: A guide for essential practice",
        },
        {
          href: "https://web.archive.org/web/20201103090439/https://www.who.int/reproductivehealth/en/",
          text: "WHO Programmes: Sexual and Reproductive health",
        },
        {
          href: "https://web.archive.org/web/20201111195601/https://www.who.int/health-topics/maternal-health#tab=tab_1",
          text: "Maternal Health",
        },
        {
          href: "https://web.archive.org/web/20201020052824/https://www.who.int/health-topics/newborn-health/#tab=tab_1",
          text: "Newborn Health",
        },
      ],
    },
    additionalLinks: (
      <Grid>
        <Typography>
          Supporting systematic reviews:{" "}
          <Link href="https://web.archive.org/web/20210214002721/https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0143382">
            Solnes Miltenburg A, Roggeveen Y, Shields L, van Elteren M, van
            Roosmalen J, Stekelenburg J, et al. Impact of Birth Preparedness and
            Complication Readiness Interventions on Birth with a Skilled
            Attendant: A Systematic Review. PloS one. 2015;10(11):e0143382.
          </Link>
        </Typography>
      </Grid>
    ),
    references: {
      title: "References",
      content: [
        "Working with individuals, families, and communities to improve maternal and newborn health. Geneva: World Health Organization; 2010. [26 November 2014] http://wwwwhoint/maternal_child_adolescent/documents/who_fch_rhr_0311/en/.",
        "The Ottawa Charter for Health Promotion [website]. Geneva: World Health Organization; 1986. [30 March 2014] http://wwwwhoint/healthpromotion/conferences/previous/ottawa/en/.",
        "Carroli G, Villar J, Piaggio G, Khan-Neelofur D, Gulmezoglu M, Mugford M, et al. WHO systematic review of randomised controlled trials of routine antenatal care. Lancet (London, England). 2001;357(9268):1565-70.",
        "Counselling for maternal and newborn health care: a handbook for building skills. Geneva: World Health Organization; 2013. [8 January 2015] http://appswhoint/iris/bitstream/10665/44016/1/9789241547628_engpdf?ua=1 [PubMed].",
        "Organization WH. WHO recommendations on health promotion interventions for maternal and newborn health 2015: World Health Organization; 2015.",
        "WHO Handbook for Guideline Development - 2nd edition. Geneva: World Health Organization; 2014. World Health Organization. 2014.",
        "Solnes Miltenburg A, Roggeveen Y, Shields L, van Elteren M, van Roosmalen J, Stekelenburg J, et al. Impact of Birth Preparedness and Complication Readiness Interventions on Birth with a Skilled Attendant: A Systematic Review. PloS one. 2015;10(11):e0143382.",
        "Ahluwalia IB, Schmid T, Kouletio M, Kanenda O. An evaluation of a community-based approach to safe motherhood in northwestern Tanzania. International journal of gynaecology and obstetrics: the official organ of the International Federation of Gynaecology and Obstetrics. 2003;82(2):231-40.",
        "Ahluwalia IB, Robinson D, Vallely L, Gieseker KE, Kabakama A. Sustainability of community-capacity to promote safer motherhood in northwestern Tanzania: what remains? Global health promotion. 2010;17(1):39-49.",
        "Baqui AH, Williams EK, Rosecrans AM, Agrawal PK, Ahmed S, Darmstadt GL, et al. Impact of an integrated nutrition and health programme on neonatal mortality in rural northern India. Bulletin of the World Health Organization. 2008;86(10):796-804.",
        "Darmstadt GL, Choi Y, Arifeen SE, Bari S, Rahman SM, Mannan I, et al. Evaluation of a cluster-randomized controlled trial of a package of community-based maternal and newborn interventions in Mirzapur, Bangladesh. PloS one. 2010;5(3):e9696.",
        "Hossain J, Ross SR. The effect of addressing demand for as well as supply of emergency obstetric care in Dinajpur, Bangladesh. International journal of gynaecology and obstetrics: the official organ of the International Federation of Gynaecology and Obstetrics. 2006;92(3):320-8.",
        "Kumar V, Mohanty S, Kumar A, Misra RP, Santosham M, Awasthi S, et al. Effect of community-based behaviour change management on neonatal mortality in Shivgarh, Uttar Pradesh, India: a cluster-randomised controlled trial. Lancet (London, England). 2008;372(9644):1151-62.",
        "Midhet F, Becker S. Impact of community-based interventions on maternal and neonatal health indicators: Results from a community randomized trial in rural Balochistan, Pakistan. Reproductive Health. 2010;7:30.",
        "Mullany LC, Lee TJ, Yone L, Lee CI, Teela KC, Paw P, et al. Impact of community-based maternal health workers on coverage of essential maternal health interventions among internally displaced communities in eastern Burma: the MOM project. PLoS medicine. 2010;7(8):e1000317.",
        "Hounton S, Menten J, Ouedraogo M, Dubourg D, Meda N, Ronsmans C, et al. Effects of a Skilled Care Initiative on pregnancy-related mortality in rural Burkina Faso. Tropical medicine & international health : TM & IH. 2008;13 Suppl 1:53-60.",
        "Turan JM, Tesfagiorghis M, Polan ML. Evaluation of a community intervention for promotion of safe motherhood in Eritrea. Journal of midwifery & women's health. 2011;56(1):8-17.",
        "Sood S, Chandra U, Palmer A, Molyneux I. Measuring the effects of the SIAGA behavior change campaign in Indonesia with population-based survey results. 2004",
        "Schenck-Yglesias. F-BFa. Measuring the effects of behavior change and service delivery interventions in Guatemala with population-based survey results. Baltimore: JHPIEGO;. 2004.",
        "Hodgins S, McPherson R, Suvedi BK, Shrestha RB, Silwal RC, Ban B, et al. Testing a scalable community-based approach to improve maternal and neonatal health in rural Nepal. Journal of Perinatology. 2010;30(6):388-95.",
        "McPherson RA, Khadka N, Moore JM, Sharma M. Are birth-preparedness programmes effective? Results from a field trial in Siraha district, Nepal. Journal of health, population, and nutrition. 2006;24(4):479-88.",
        "Moran AC, Sangli G, Dineen R, Rawlins B, Yameogo M, Baya B. Birth-preparedness for maternal health: findings from Koupela District, Burkina Faso. Journal of health, population, and nutrition. 2006;24(4):489-97.",
        "Mushi D, Mpembeni R, Jahn A. Effectiveness of community based Safe Motherhood promoters in improving the utilization of obstetric care. The case of Mtwara Rural District in Tanzania. BMC pregnancy and childbirth. 2010;10:14.",
        "Sinha D. Empowering communities to make pregnancy safer: an intervention in rural Andhra Pradesh: Population Council; 2008.",
        "Sood S, Chandra U, Mishra P, Neupane S. Measuring the effects of behavior change interventions in Nepal with population-based survey results. 2004.",
        "Skinner J, Rathavy T. Design and evaluation of a community participatory, birth preparedness project in Cambodia. Midwifery. 2009;25(6):738-43.",
      ],
    },
  },
  {
    id: "20",
    img: img72,
    imgAlt: "a man and a woman swimming",
    title:
      "WHO recommendation on male involvement interventions for maternal and neonatal health",
    date: "31 May 2015",
    recommendation: {
      title: "Recommendation",
      content:
        "Interventions to promote the involvement of men during pregnancy, childbirth and after birth are recommended to facilitate and support improved self-care of the woman, improved home care practices for the woman and newborn, and improved use of skilled care during pregnancy, childbirth and the postnatal period for women and newborns.",
    },
    recommendationItalic:
      "(Strong recommendation, very low quality of evidence)",
    publicationHistory: {
      firstPublished: "May 2015",
      updated: "No update planned",
      assessedAsUpToDate: "May 2015",
    },
    remarks: {
      title: "Remarks",
      content: [
        "These interventions are recommended provided that they are implemented in a way that respects, promotes and facilitates women’s choices and their autonomy in decision-making and supports women in taking care of themselves and their newborns. In order to ensure this, rigorous monitoring and evaluation of implementation is recommended.",
        "Additional research is required.",
      ],
    },
    background: {
      title: "Background",
      content: [
        "In 2003 the World Health Organization (WHO) published “Working with individuals, families and communities to improve maternal and newborn health”, (1) the IFC framework that promotes integrating the health promotion approach set out in the Ottawa Charter (2) into national maternal and newborn health (MNH) strategies.",
        "The IFC Framework was developed in response to analysis and global statements indicating that as well as strengthening services, MNH strategies need to improve the capacity of individuals, families and communities to provide appropriate care for pregnant women, mothers, and newborns in the home. It also addresses the reasons – over and above what happens in clinical services – why women do not reach good quality skilled care during pregnancy, childbirth and after birth. The Ottawa Charter’s health promotion components(2) were translated into MNH programme language and 12 promising interventions – identified through reviews of country experiences and the literature – were categorized into four priority areas. Community and intersectoral participation was recommended to guide implementation. Exact interventions to be adapted by country programmes were to be identified through local assessment; however, the framework highlighted the need for interventions to address all four priority areas at the same time. All six WHO Regions integrated this guidance into the regional maternal mortality reduction strategies.",
        "There has been increased recognition of the need to include men in MCH programmes since the mid-1990s, given the important role men have as partners/ husbands, fathers and community members and as a way of promoting egalitarian decisions about reproductive and maternal health.(3) Chapter IV Section C of the ICPD Programme of Action calls for an understanding of the joint responsibilities of men and women so that they become equal partners in public and private lives and to encourage and enable men to take responsibility for their sexual and reproductive behaviour.",
        "Different programmes have directed efforts to harness the support and active involvement of men for improved MNH outcomes. There are different models and rationales for seeking to involve men, including a view of men as gatekeepers and decision-makers for prompt access to MNH services both at the household and community levels; men as responsible partners of women and as an important sub-population within the community; the need to address men’s own health needs; and men’s preference to be involved as fathers/partners. Strategies often include mass media campaigns, community and workplace-based outreach and education for men only or for men and women together, home visits, facility-based counselling for couples, groups or men only.",
        "This recommendation was published within the 2015 WHO guideline “WHO Recommendations on Health Promotion Interventions for Maternal and Newborn Health.”(4)",
      ],
    },
    methods: {
      title: "Methods",
      content: [
        "The recommendation was developed using standardized operating procedures in accordance with the process described in the “WHO handbook for guideline development”, guided by the GRADE approach.(5) Outcomes used for this recommendation were aligned with the prioritized outcomes from the WHO recommendations on health promotion interventions for maternal and newborn health (2015).(4)",
        "A systematic review was conducted on interventions for male involvement in pregnancy, childbirth and the postnatal period.(6) In the review, randomized controlled trials relevant to the key question were screened by review authors, and data on relevant outcomes and comparisons were extracted. Evidence profiles (in the form of GRADE tables) were prepared for comparisons of interest, including the assessment and judgments for each outcome, and the estimated risks.",
        "WHO convened a Guideline Development Group (GDG) meeting on recommendations on health promotion interventions for maternal and newborn health where this recommendation was developed. The GDG comprised of a group of independent experts, who used the evidence profiles to assess evidence on effects on the pre-specified outcomes. GDG members discussed the balance between desirable and undesirable effects, overall quality of supporting evidence, values and preferences of stakeholders, resource requirements, cost-effectiveness, acceptability, feasibility and equity, to formulate the recommendation. Remarks were added to clarify the recommendation, and aid implementation.",
        <span>
          Further information on procedures for developing this recommendation
          are available{" "}
          <Link href="https://web.archive.org/web/20200715022626/http:/apps.who.int/iris/bitstream/handle/10665/172427/9789241508742_report_eng.pdf;jsessionid=0888E6D3E74DA6A9DE077D118B6EF1DB?sequence=1">
            here.
          </Link>
        </span>,
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following question:",
      content: [
        "What interventions used to increase male involvement have been effective in increasing care-seeking behaviour during pregnancy, for childbirth and after birth for women and newborns and in improving key maternal and newborn health outcomes?",
      ],
    },
    evidenceSummary: {
      title: "Evidence summary",
      content: [
        "Evidence on interventions for male involvement in pregnancy, childbirth and the postnatal period was extracted from a systematic review conducted by Tokhi et al. (forthcoming)(6) of 13 studies, including one RCT, three cluster RCTs, one cohort analytic study, four pre-post designs, three repeat cross-sectional and one programme evaluation using data from the health information system. Three of these studies report qualitative findings. The 13 studies were conducted in Bangladesh, Eritrea, India, Indonesia, Nepal, Pakistan, South Africa, Tanzania and Turkey.",
        "Male involvement strategies are employed as a means to support women to access care, address the influence of gender inequality on MNH and promote men’s positive involvement as partners and fathers.",
        "Four studies did not measure the critical outcome of birth with a skilled birth attendant or facility birth; three studies were primarily aimed to increase use of skilled care for obstetric complications.(7-9)",
        "The modes of interventions in the 13 studies included mass media campaigns, community-based outreach and education for men only or for men and women together, home visits, facility-based counselling for couples or for groups or for men only and workplace-based educationfor men. There was not sufficient evidence to determine which of these strategies or which combination of strategies were most effective.",
      ],
    },
    maternalOutcomes: {
      title: "",
      content: "",
    },
    fetalAndNeonatalOutcomes: [
      {
        title:
          "For the outcome of birth with a skilled birth attendant or facility birth, the quality of the evidence ranged from very low to low.",
        content: (
          <List>
            <ListItem>
              • In one RCT (Mullany, Becker and Hindin, 2007) the impact of the
              intervention on SBA and facility birth is unclear. Low-quality
              evidence.(10)
            </ListItem>
            <ListItem>
              • Six observational studies (Mushi, Mpembeni and Jahn, 2010;
              Purdin, Khan and Saucier, 2009; Sinha, 2008; Sood et al., 2004
              Indonesia; Sood et al., 2004 Nepal; Turan, Tesfagiorghis and
              Polan, 2011) reported some benefit for male involvement either for
              the presence of an SBA/facility birth or both. In one before and
              after study (Mushi, Mpembeni and Jahn, 2010) there was a
              statistically significant increase in presence of an SBA for the
              intervention group and most of these births were at facilities.
              Two studies using a pre and post intervention design reported a
              statistically significant increase in facility birth for the
              intervention group (Sinha 2008, Turan Tesfagiorghis and Polan,
              2011); no SBA data were reported. The programme evaluation study
              using a health information system (Purdin, Khan and Saucier, 2009)
              observed an increase in births in an EmOC facility among refugee
              women. In a repeat cross-sectional study (Sood et al., 2004
              Indonesia) women (and husbands) reported a statistically
              significant increase of use of SBA and facility birth for the
              exposed group. In the final study (Sood et al., 2004 Nepal), women
              not exposed to the intervention were more likely than the exposed
              group to report giving birth at a hospital and reported being
              assisted by a doctor. This contrasts with data from husbands; a
              higher percentage of those exposed than not exposed to the
              intervention reported their wives had given birth in hospital and
              gave birth assisted by a doctor. Very low-quality evidence.(11-16)
            </ListItem>
            <ListItem>
              • For those studies whose focus was increasing access to skilled
              care for complications:
            </ListItem>
            <List>
              <ListItem>
                o The cluster RCT (Midhet and Becker, 2010) showed a
                statistically significant increase in facility birth at the
                district hospital and a non-significant increase in birth with
                an SBA or trained TBA for the intervention groups.
                Moderate-quality evidence.(9)
              </ListItem>
              <ListItem>
                o In one quasi-experimental study (Hossain and Ross, 2006)
                facility birth increased statistically in the intervention
                group. Low-quality evidence.(8)
              </ListItem>
            </List>
          </List>
        ),
      },
      {
        title:
          "For the outcome of care with a skilled birth attendant or in a facility in case of complications/illness in women and newborns, the quality of the evidence ranged from low to moderate.",
        content: (
          <List>
            <ListItem>
              • One non-equivalent control group study (Varkey et al., 2004)
              reports an increase in visiting the dispensary in the intervention
              group, but not for attending hospital during presence of danger
              signs. Low-quality evidence.(17
            </ListItem>
            <ListItem>
              • For those studies whose focus was increasing access to skilled
              care for complications:
            </ListItem>
            <List>
              <ListItem>
                o One cluster RCT (Midhet and Becker, 2010) showed a
                statistically significant increase in women accessing hospital
                for treatment of problems during pregnancy but unclear impact
                during delivery, immediately after delivery or during the
                postpartum period. Moderate-quality evidence.
              </ListItem>
            </List>
          </List>
        ),
      },
      {
        title:
          "For the outcome of use of antenatal care (one or four visits), the quality of the evidence ranged from very low to low.",
        content: (
          <List>
            <ListItem>
              • One RCT (Mullany, Becker and Hindin, 2007) reported no
              difference between the study groups in use of more than three ANC
              visits. Low-quality evidence.(10)
            </ListItem>
            <ListItem>
              • Six observational studies (Mushi, Mpembeni and Jahn, 2010;
              Purdin, Khan and Saucier, 2009; Sinha, 2008; Sood et al., 2004
              Indonesia; Sood et al., 2004 Nepal; Turan, Tesfagiorghis and
              Polan, 2011) reported data on antenatal visits. One study using a
              pre and post intervention design (Sinha, 2008) and one programme
              evaluation using a health information system (Purdin, Khan and
              Saucier, 2009) showed more women made three or more ANC visits
              between baseline and follow-up. Another pre and post study (Mushi,
              Mpembeni and Jahn, 2010) showed a non-significant increase in four
              or more ANC visits. A one group before-and-after evaluation (Sood
              et al., 2004 Nepal) found no significant differences between
              exposed (post-intervention) and unexposed (pre-intervention)
              groups in four or more ANC visits. Two pre and post intervention
              studies with control groups (Sood et al., 2004 Indonesia; Turan,
              Tesfagiorghis and Polan, 2011) showed women and husbands exposed
              to the intervention were significantly more likely to report four
              or more ANC visits than those unexposed, but no baseline data are
              provided (Sood et al., 2004 Indonesia) and a statistically
              significant increase in more than one and more than four visits
              (Turan, Tesfagiorghis and Polan, 2011). Very low-quality evidence.
              (11-16)
            </ListItem>
            <ListItem>
              • For those studies whose focus was increasing access to skilled
              care for complications:
            </ListItem>
            <ListItem>
              o One cluster RCT (Midhet and Becker, 2010) showed significantly
              more pregnant women in the intervention arms in comparison to the
              control arm received adequate prenatal care (visits to qualified
              health care provider solely for the purpose of routine medical
              check-ups during first or second trimester of pregnancy) but the
              differences between intervention arms were not significant.
              Moderate-quality evidence.(9)
            </ListItem>
          </List>
        ),
      },
      {
        title:
          "For the outcome of breastfeeding, the quality of the evidence was assessed as very low to low.",
        content: (
          <List>
            <ListItem>
              • One cluster RCT (Kunene et al., 2004) shows that the percentage
              of women commencing mixed feeding at six months was higher in the
              intervention group than the control group. The results were not
              statistically significant. Low-quality evidence.
            </ListItem>
            <ListItem>
              • In one non-equivalent control group study design in which three
              dispensaries provided the intervention while three others
              functioned as control sites (Varkey et al., 2004), significantly
              more women in the control group continued exclusively
              breastfeeding for six months in comparison to the intervention
              group. Very low-quality evidence.(17)
            </ListItem>
            <ListItem>
              • In one cohort analytic study (Sahip and Turin, 2007), men in the
              intervention group reported a significant increase in their wives
              breastfeeding at three months. Very low-quality evidence.(18)
            </ListItem>
            <ListItem>
              • One repeat cross-sectional study (Fullerton, Killian, and Gass,
              2005) found a significant increase in women who breastfed within
              one hour of birth following the male involvement intervention.
              Very low-quality evidence.(7)
            </ListItem>
          </List>
        ),
      },
      {
        title:
          "For the outcome of postpartum care visits for women, the quality of the evidence ranged from low to very low.",
        content: (
          <List>
            <ListItem>
              • One randomized controlled trial (Mullany, Becker and Hindin,
              2007) showed women assigned to the couples group were
              significantly more likely to attend the postpartum visit than
              those assigned to the control group or women-alone group.
              Low-quality evidence.(10)
            </ListItem>
            <ListItem>
              • Two observational studies report on postpartum check-ups for
              mothers. In one cohort analytic study (Sahip and Turan, 2007),
              there was no significant difference between men in the control and
              intervention groups reporting whether their wife had a postpartum
              check-up. A programme evaluation (Purdin, Khan and Saucier, 2009)
              showed an increase in postpartum care within 72 hours of birth
              post intervention. Very low-quality evidence.(12, 18)
            </ListItem>
            <ListItem>
              • Additional outcomes not identified as critical and important
              were reported by some of the studiesincluding birth preparedness
              and complications readiness, male partners accompanying women to
              antenatal care, increased support for women and interaction
              between couples. In addition, information was available from those
              studies that reported on maternal nutrition, maternal death,
              stillbirths, perinatal mortality and neonatal mortality.(6) These
              were discussed in the meeting and considered by the GDG in its
              decision on the strength of the recommendation.
            </ListItem>
          </List>
        ),
      },
      {
        title:
          "Further information and considerations related to this recommendation can be found in the WHO guidelines, available at:",
        content: (
          <Link href="http://apps.who.int/iris/bitstream/10665/172427/2/9789241508742_tables_eng.pdf?ua=1">
            http://apps.who.int/iris/bitstream/10665/172427/2/9789241508742_tables_eng.pdf?ua=1
          </Link>
        ),
      },
    ],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "Male involvement strategies for MNH should primarily be targeted to support women’s careseeking and decision-making for their own health and the health of their children. Their implementation should not reduce women’s autonomy (in careseeking and decision-making in relation to their own health and the health of their children). It is necessary to avoid reinforcing gendered stereotypes of men as the decision-makers.",
        "Additionally, male involvement strategies should be linked to other efforts to implement gender transformative programming (e.g., programmes that promote egalitarian gender norms and women’s empowerment) and should promote the positive role that men can play as partners and fathers.",
        "Reflecting on the balance of benefits versus harms, the balance depends on the strategy to be employed and the context. In contexts where intimate partner violence is high, male involvement through facilitybased male involvement strategies need to be implemented with caution with due attention to not compromising women’s safety and confidentiality.",
        "Harms/risks can be mitigated through implementation approaches that train health providers and programme staff in gender-sensitive programming that promotes egalitarian decisionmaking between couples and respects women’s rights and autonomy along with close monitoring and evaluation for adverse impacts on women’s rights and autonomy.",
        "It is important to recognise the diversity in women’s values and preferences. Programmes should be designed having undertaken qualitative research and dialogue with women.",
        "When considering interventions such as couples counselling or facility-based interventions where the male partner is invited to accompany the woman for antenatal care, it is extremely important to obtain woman’s autonomous consent and in discuss in detail the aspects in which she wants him to be involved. Tailored and nuanced care is essential. There will be some women who want their male partners involved and they should be supported. There will be other women who do not want their male partners involved and this should be respected. If the woman does not wish to involve her male partner or is not able to engage with him, his involvement should not be conditional for providing services. Perhaps the most important implementation consideration noted was the need to ensure women’s permission, consent and perspective on male involvement before inviting men to be involved.",
        "The diversity of pregnant women’s partnership and family arrangements, including women without partners, needs to be considered in promoting male involvement interventions.",
        "Male involvement in clinical care around the time of pregnancy, childbirth and after birth should be contingent on the approval or request of women. Women should be consulted, in private, as to which aspects of care they would like to be confidential. This is particularly relevant to potentially sensitive clinical services, such as postpartum family planning.",
        "Health facilities should be male-friendly and health systems should be oriented towards dealing with men as well as women around the time of pregnancy, childbirth and after birth. However, access to quality care for women and newborns must not be contingent on men’s attendance or involvement.",
        "Many health services are not set up for men to accompany their partners. Physical infrastructure and the capacity of health providers to work with men and couples through gender-sensitive approaches need to be addressed.",
      ],
    },
    additionalLists: [],
    researchImplications: {
      title: "Research implications",
      text: "The GDG identified that further research on the following high-priority questions is needed:",
      content: [
        "Studies that are designed and powered to measure the effect of including men together with women in discussions and decision-making about MNH. Studies that measure the separate effects of including men and other key household decision-makers are also required.",
        "Studies of male involvement interventions that systematically record qualitative as well as quantitative information about the values and preferences of women and men relating to changes in men’s behaviours. In particular, qualitative information that relates to women’s bodily autonomy and autonomy in decision-making, genderstereotypes and power dynamics within relationships or households should be recorded. Existing studies that report on men’s or couple’s behaviours without reporting on women’s and men’s values and preferences relating to these behaviours risk obscuring differences between harmful and positive gender outcomes.",
        "Context-specific investigations of how interventions to promote male involvement influence intrahousehold dynamics, including relationships between mothers-in-law and daughters-in-law, and between grandparents and newborns/infants.",
        "The cost implications of making and sustaining health system changes that support the involvement of men around the time of pregnancy, childbirth and after birth, such as developing male-friendly health facilities and training health workers to respond to men and couples as well as women. Research addressing this gap should also consider the quality of health services.",
        "Assessments of the influence of male involvement interventions implemented at the level of local government on priority setting and resource allocation at the community level.",
        "Research on male involvement in MNH that integrates lessons extrapolated from the larger body of literature that exists on working with men and boys on gender equality and for other sexual and reproductive health topics.",
      ],
    },
    relatedLinks: {
      title: "Related links",
      text: (
        <Typography>
          WHO recommendations on antenatal care for a positive pregnancy
          experience (2015) -{" "}
          <Link href="https://web.archive.org/web/20200915144755/https://apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1">
            full document
          </Link>{" "}
          and{" "}
          <Link href="https://web.archive.org/web/20201219124651/https://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            evidence table
          </Link>
        </Typography>
      ),
      links: [
        {
          href: "https://web.archive.org/web/20201031220559/https://apps.who.int/iris/bitstream/handle/10665/255760/9789241565493-eng.pdf?sequence=1",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors",
        },
        {
          href: "https://web.archive.org/web/20201030093023/https://apps.who.int/iris/bitstream/handle/10665/249580/9789241549356-eng.pdf;jsessionid=7B8E18468A424E0301261B2CA6BCB324?sequence=1",
          text: "Pregnancy, Childbirth, Postpartum and Newborn Care: A guide for essential practice",
        },
        {
          href: "https://web.archive.org/web/20201103090439/https://www.who.int/reproductivehealth/en/",
          text: "WHO Programmes: Sexual and Reproductive health",
        },
        {
          href: "https://web.archive.org/web/20201111195601/https://www.who.int/health-topics/maternal-health#tab=tab_1",
          text: "Maternal Health",
        },
        {
          href: "https://web.archive.org/web/20201020052824/https://www.who.int/health-topics/newborn-health/#tab=tab_1",
          text: "Newborn Health",
        },
      ],
    },
    additionalLinks: (
      <Grid>
        <Typography>
          Supporting systematic reviews:{" "}
          <Link href="https://web.archive.org/web/20200715022626/https:/www.ncbi.nlm.nih.gov/pubmed/29370258">
            Tokhi M, Comrie-Thomson L, Davis J, Portela A, Chersich M, Luchters
            S. Involving men to improve maternal and newborn health: A
            systematic review of the effectiveness of interventions. PloS one.
            2018;13(1):e0191620.
          </Link>
        </Typography>
      </Grid>
    ),
    references: {
      title: "References",
      content: [
        "Working with individuals, families, and communities to improve maternal and newborn health. Geneva: World Health Organization; 2010. [26 November 2014] http://wwwwhoint/maternal_child_adolescent/documents/who_fch_rhr_0311/en/",
        "The Ottawa Charter for Health Promotion [website]. Geneva: World Health Organization; 1986. [30 March 2014] http://wwwwhoint/healthpromotion/conferences/previous/ottawa/en/.",
        "Davis J, Luchters S, Holmes W. Men and maternal and newborn health: benefits, harms, challenges and potential strategies for engaging men. Melbourne, Australia: Compass: Women's and Children's Health Knowledge Hub. 2012.",
        "WHO recommendations on health promotion interventions for maternal and newborn health 2015: World Health Organization; 2015.",
        "WHO Handbook for Guideline Development - 2nd edition. Geneva: World Health Organization; 2014. World Health Organization. 2014.",
        "Tokhi M, Comrie-Thomson L, Davis J, Portela A, Chersich M, Luchters S. Involving men to improve maternal and newborn health: A systematic review of the effectiveness of interventions. PloS one. 2018;13(1):e0191620.",
        "Fullerton JT, Killian R, Gass PM. Outcomes of a Community-and Home-Based Intervention for Safe Motherhood and Newborn Care. Health Care for Women International. 2005;26(7):561-76.",
        "Hossain J, Ross SR. The effect of addressing demand for as well as supply of emergency obstetric care in Dinajpur, Bangladesh. International journal of gynaecology and obstetrics: the official organ of the International Federation of Gynaecology and Obstetrics. 2006;92(3):320-8.",
        "Midhet F, Becker S. Impact of community-based interventions on maternal and neonatal health indicators: Results from a community randomized trial in rural Balochistan, Pakistan. Reproductive Health. 2010;7:30.",
        "Mullany LC, Lee TJ, Yone L, Lee CI, Teela KC, Paw P, et al. Impact of community-based maternal health workers on coverage of essential maternal health interventions among internally displaced communities in eastern Burma: the MOM project. PLoS medicine. 2010;7(8):e1000317.",
        "Mushi D, Mpembeni R, Jahn A. Effectiveness of community based Safe Motherhood promoters in improving the utilization of obstetric care. The case of Mtwara Rural District in Tanzania. BMC pregnancy and childbirth. 2010;10:14.",
        "Purdin S, Khan T, Saucier R. Reducing maternal mortality among Afghan refugees in Pakistan. International journal of gynaecology and obstetrics: the official organ of the International Federation of Gynaecology and Obstetrics. 2009;105(1):82-5.",
        "Sinha D. Empowering communities to make pregnancy safer: an intervention in rural Andhra Pradesh: Population Council; 2008.",
        "Sood S, Chandra U, Mishra P, Neupane S. Measuring the effects of behavior change interventions in Nepal with population-based survey results. 2004.",
        "Sood S, Chandra U, Palmer A, Molyneux I. Measuring the effects of the SIAGA behavior change campaign in Indonesia with population-based survey results. 2004.",
        "Turan JM, Tesfagiorghis M, Polan ML. Evaluation of a community intervention for promotion of safe motherhood in Eritrea. Journal of midwifery & women's health. 2011;56(1):8-17.",
        "Varkey LC, Mishra A, Das A, Ottolenghi E, Huntington D, Adamchak S, et al. Involving men in maternity care in India. New Delhi: Population Council. 2004:62.",
        "Sahip Y, Turan JM. Education for expectant fathers in workplaces in Turkey. Journal of biosocial science. 2007;39(6):843-60.",
      ],
    },
  },
  {
    id: "21",
    img: img73,
    imgAlt: "a pregnant woman getting examined",
    title:
      "WHO recommendation on partnership with Traditional Birth Attendants (TBAs)",
    date: "31 May 2015",
    recommendation: {
      title: "Recommendation",
      content:
        "Where TBAs remain the main providers of care at birth, dialogue with TBAs, women, families, communities and service providers is recommended in order to define and agree on alternative roles for TBAs, recognizing the important role they can play in supporting the health of women and newborns.",
    },
    recommendationItalic: "(Strong recommendation, very low-quality evidence)",
    publicationHistory: {
      firstPublished: "May 2015",
      updated: "No update planned",
      assessedAsUpToDate: "May 2015",
    },
    remarks: {
      title: "Remarks",
      content: [
        "Additional research is required.",
        "The GDG also endorsed the recommendations from the existing WHO guideline WHO OptimizeMNH. (1)",
        "The use of lay health workers including trained TBAs is recommended for promoting the uptake of a number of maternal and newborn-related health care behaviours and services, providing continuous social support during labour in the presence of a skilled birth attendant and administering misoprostol to prevent postpartum haemorrhage.",
        "The use of lay health workers including trained TBAs to deliver the following interventions is recommended, with targeted monitoring and evaluation: distribution of some oral supplement-type interventions to pregnant women (calcium supplementation for women living in areas with known low levels of calcium intake, routine iron and folate supplementation for pregnant women, intermittent presumptive therapy for malaria for pregnant women living in endemic areas and vitamin A supplementation for pregnant women living in areas where severe vitamin A deficiency is a serious public health problem); and initiation and maintenance of injectable contraceptives using a standard syringe.",
      ],
    },
    background: {
      title: "Background",
      content: [
        'In 2003 the World Health Organization (WHO) published "Working with individuals, families and communities to improve maternal and newborn health", (2) the IFC framework that promotes integrating the health promotion approach set out in the Ottawa Charter (3) into national maternal and newborn health (MNH) strategies.',
        "The IFC Framework was developed in response to analysis and global statements indicating that as well as strengthening services, MNH strategies need to improve the capacity of individuals, families and communities to provide appropriate care for pregnant women, mothers, and newborns in the home. It also addresses the reasons - over and above what happens in clinical services - why women do not reach good quality skilled care during pregnancy, childbirth and after birth. The Ottawa Charter's health promotion components(3) were translated into MNH programme language and 12 promising interventions - identified through reviews of country experiences and the literature - were categorized into four priority areas. Community and intersectoral participation was recommended to guide implementation. Exact interventions to be adapted by country programmes were to be identified through local assessment; however, the framework highlighted the need for interventions to address all four priority areas at the same time. All six WHO Regions integrated this guidance into the regional maternal mortality reduction strategies.",
        "As stated in the original IFC framework concept and strategy paper,(2) while WHO and partners move ahead in the promotion of skilled birth attendants and skilled care for childbirth, the responsibility of TBAs in MNH in those countries and areas where they currently are providers of childbirth care must be specified. Due to their cultural and social acceptability, knowledge and experience, TBAs can be considered an important ally for health education and social support and a positive link between women, families, communities and the formal health care system. (4)",
        "Recent WHO guidance WHO Optimize MNH(1) recognized that a more rational distribution of tasks and responsibilities among the cadre of health workers may improve access to maternal and newborn care. In reviewing the evidence on which tasks could be assumed by lay health workers, trained TBAs were considered and defined as a person who assists the mother during childbirth and who initially acquired their skills by attending births themselves or through an apprenticeship to other TBAs. Trained TBAs have received some level of biomedical training in pregnancy and childbirth care.",
      ],
    },
    methods: {
      title: "Methods",
      content: [
        'The recommendation was developed using standardized operating procedures in accordance with the process described in the "WHO handbook for guideline development", guided by the GRADE approach.(5) Outcomes used for this recommendation were aligned with the prioritized outcomes from the WHO recommendations on health promotion interventions for maternal and newborn health (2015).(6)',
        "A systematic review was conducted on interventions to find new roles for TBAs with in the formal health system. In the review, randomized controlled trials relevant to the key question were screened by review authors, and data on relevant outcomes and comparisons were extracted. Evidence profiles (in the form of GRADE tables) were prepared for comparisons of interest, including the assessment and judgments for each outcome, and the estimated risks.",
        "WHO convened a Guideline Development Group (GDG) meeting on recommendations on health promotion interventions for maternal and newborn health where this recommendation was developed. ",
        "The GDG comprised of a group of independent experts, who used the evidence profiles to assess evidence on effects on the pre-specified outcomes. GDG members discussed the balance between desirable and undesirable effects, overall quality of supporting evidence, values and preferences of stakeholders, resource requirements, cost-effectiveness, acceptability, feasibility and equity, to formulate the recommendation. Remarks were added to clarify the recommendation, and aid implementation.",
        <span>
          Further information on procedures for developing this recommendation
          are available{" "}
          <Link href="https://web.archive.org/web/20201105052139/http:/apps.who.int/iris/bitstream/handle/10665/172427/9789241508742_report_eng.pdf;jsessionid=0888E6D3E74DA6A9DE077D118B6EF1DB?sequence=1">
            here.
          </Link>
        </span>,
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following question:",
      content: [
        "What new roles for TBAs within the formal health system are effective for increasing childbirth with a skilled birth attendant/institutional birth and for improving other key maternal and newborn health outcomes?",
      ],
    },
    evidenceSummary: {
      title: "Evidence summary",
      content: [
        "Evidence on interventions to find new roles for TBAs with in the formal health system was extracted from a systematic review, conducted by Chersich, et al. Two existing systematic reviews were identified in the literature.(7, 8) These existing reviews were assessed and supplemented with additional literature identified through MASCOT/MH-SAR mapping. A total of six studies were identified.",
        "Different strategies were employed to develop collaborative relationships with TBAs. There was not sufficient evidence to determine which of these strategies or which combination of strategies were most effective.",
      ],
    },
    maternalOutcomes: {
      title: "",
      content: "",
    },
    fetalAndNeonatalOutcomes: [
      {
        title:
          "For the outcome of birth with a skilled birth attendant or facility birth, the quality of the evidence was rated as very low.",
        content:
          "Six observational studies reported on this outcome in low and middle-income countries; two studies were of refugee/internally displaced women only. The interventions varied and were multiple and complex. It is difficult to distinguish the effect of individual component(s) from the overall programmes or programme from existing safe motherhood initiative activities. Overall, the studies report improvements in use of SBA following implementation of the interventions compared with data in the period before the interventions were put in place. Two studies show a trend towards more women reporting SBA over time: one in Indonesia shows a trend towards substantial improvement over time (Frankenberg et al., 2009) and the other study from Myanmar shows more women reported being assisted by an SBA from baseline to follow-up (Mullany et al., 2010). Two other studies, one in Peru and the other in Indonesia (Gabrysch et al., 2009; Ronsmans et al., 2001), indicate an increase in SBA over time, one significantly (Ronsmans et al., 2001). One exception is a study in Bangladesh (Fauveau et al., 1991), where out of the 15% of registered women who requested a midwife to be present, only 9% actually had a midwife assist the birth. Hospital births also increased over time in a study in Pakistan where EmOC provision was part of the intervention in a refugee population (Purdin, Khan and Saucier, 2009) and in the culturally adapted birth model (Gabrysch et al., 2009). Very low-quality evidence.(9-14)",
      },
      {
        title:
          "For the outcome of ANC use (one to four visits), the quality of the evidence was rated as very low.",
        content:
          "Three observational studies reported on this outcome. A longitudinal panel survey (Frankenberg et al., 2009) showed use of ANC increased over time following the implementation of a TBA intervention. A retrospective pre and post analysis (Purdin et al., 2009) reported complete antenatal care coverage (three or more visits) increased baseline to endline; and a pre and post intervention study (Mullany et al., 2010) reported coverage of four or more visits increased significantly from baseline to endline. There were larger relative improvements in ANC use over time where TBA interventions were implemented in settings with Afghan refugee women in Pakistan (Purdin, Khan and Saucier, 2009) and internally displaced women in Eastern Myanmar (Mullany et al., 2010). Very low-quality evidence.(9, 10, 14)",
      },
      {
        title:
          "For the outcome of postnatal visits for woman and baby, the quality of the evidence was rated as very low.",
        content:
          "Four observational studies reported on this outcome. Only one study (Mullany et al., 2010) specified that the postnatal visit was for the mother and baby. All four studies showed that postpartum visits to women increased over time following the implementation of a TBA intervention (Fauveau et al., 1991; Ronsmans et al., 2001; Purdin, Khan and Saucier, 2009; Mullany et al., 2010). There were larger relative improvements in women receiving postpartum visits within 72 hours after birth where TBA interventions were implemented in settings with Afghan refugee women in Pakistan (Purdin, Khan and Saucier, 2009) and significant improvements in internally displaced women in Eastern Myanmar (Mullany, et al., 2010). Very low-quality evidence.(10, 12-14)",
      },
      {
        title:
          "Further information and considerations related to this recommendation can be found in the WHO guidelines, available at:",
        content: (
          <Link href="http://apps.who.int/iris/bitstream/10665/172427/2/9789241508742_tables_eng.pdf?ua=1">
            http://apps.who.int/iris/bitstream/10665/172427/2/9789241508742_tables_eng.pdf?ua=1
          </Link>
        ),
      },
    ],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "Interventions to support countries to transition from birth with a TBA to birth with an SBA are particularly important in light of priority strategies to increase SBAs and the human resource challenges in achieving this goal. Recent task shifting work has focused on the roles lay health workers including TBAs can assume. As noted above, interventions of interest for purposes of this review were those where TBAs were providers of childbirth care and efforts were made to increase childbirth with an SBA. This included interventions where TBAs were linked with formal health services to raise SBA/facility births; interventions to increase partnerships or teamwork with TBAs; or to find new roles for TBAs within the formal health system. Most of the strategies implemented focused on establishing partnership with TBAs.",
        "Exact costs are not available. We could infer from the different studies only limited information about costs incurred. These were dependent on the intervention type, including provision of payments to TBAs to take on new roles such as referring pregnant women, accompanying them to the facility, reimbursing transport costs or other costs associated with assuming a new role.",
        "A multifaceted approach is recommended to prepare TBAs and others for new roles, including the training of TBAs to strengthen their knowledge and skills to enable them to be able to assume new roles, the sensitization of health providers, communities, women and their families and TBAs to ensure mutually respectful dialogue and to establish trusting relationships, and the integration of other stakeholders to contribute to a support system for the transition period.",
        "It is clear that there may be settings such as in remote areas, humanitarian situations or overloaded health centres where TBAs attending births are considered necessary. However, this should be done under supervision and alongside steps which facilitate the groundwork for TBA transition.",
      ],
    },
    additionalLists: [],
    researchImplications: {
      title: "Research implications",
      text: "The GDG identified that further research on the following high-priority questions is needed:",
      content: [
        "Optimal study design and monitoring of important processes of implementation in order to be able to understand the effect of the different modes of delivering the intervention.",
        "Standardization of some outcome measures allowing for the possibility of local contextualization.",
        "Clear and common indicators for monitoring and evaluation and standardized measures of these with agreement at international level on priority actions and/or which combination of essential actions should be further tested.",
        "The need for studies to explore and record the preferences articulated by those providing and receiving care in more detailed and rigorous ways.",
        "The need for more detailed data on the transition phase as new roles for TBAs are introduced and implemented.",
        "Better reporting of intervention details and outcomes so that other programmes/countries can decide to replicate/implement.",
        "The need for systematic monitoring of interventions over time.",
        "Case studies of countries who have implemented policies to ban TBAs and the effect of these policies as well as those countries who have experience in implementing strategies but which may not yet be documented in the literature, such as in Latin America.",
        "Discussion of innovative study designs including different disciplines and methods in order to improve the breadth of the evidence base. Potential of TBAs in MDSR.",
        "Design of programmes which can better capture and evaluate individual components in multiple interventions.",
      ],
    },
    relatedLinks: {
      title: "Related links",
      text: (
        <Typography>
          WHO recommendations on antenatal care for a positive pregnancy
          experience (2015) -{" "}
          <Link href="https://web.archive.org/web/20200915144755/https://apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1">
            full document
          </Link>{" "}
          and{" "}
          <Link href="https://web.archive.org/web/20201219124651/https://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            evidence table
          </Link>
        </Typography>
      ),
      links: [
        {
          href: "https://web.archive.org/web/20201031220559/https://apps.who.int/iris/bitstream/handle/10665/255760/9789241565493-eng.pdf?sequence=1",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors",
        },
        {
          href: "https://web.archive.org/web/20201030093023/https://apps.who.int/iris/bitstream/handle/10665/249580/9789241549356-eng.pdf;jsessionid=7B8E18468A424E0301261B2CA6BCB324?sequence=1",
          text: "Pregnancy, Childbirth, Postpartum and Newborn Care: A guide for essential practice",
        },
        {
          href: "https://web.archive.org/web/20201103090439/https://www.who.int/reproductivehealth/en/",
          text: "WHO Programmes: Sexual and Reproductive health",
        },
        {
          href: "https://web.archive.org/web/20201111195601/https://www.who.int/health-topics/maternal-health#tab=tab_1",
          text: "Maternal Health",
        },
        {
          href: "https://web.archive.org/web/20201020052824/https://www.who.int/health-topics/newborn-health/#tab=tab_1",
          text: "Newborn Health",
        },
      ],
    },
    additionalLinks: (
      <Grid>
        <Typography fontWeight={"bold"} gutterBottom>
          Supporting systematic reviews:{" "}
        </Typography>
        <Typography gutterBottom>
          Systematic review: Chersich M, Dhana A, Moore L, Miller T
        </Typography>
        <Typography gutterBottom>
          Existing systematic reviews: Byrne A and Morgan A. How the integration
          of traditional birth attendants with formal health systems can
          increase skilled birth attendance. International Journal of
          Gynaecology and Obstetrics. 2011;115(2):127-34. doi:
          10.1016/j.ijgo.2011.06.019.{" "}
        </Typography>
        <Typography gutterBottom>
          Vieira C, Portela A, Miller T, Coast E, Leone T and Marston C.
          Increasing the use of skilled health personnel where traditional birth
          attendants were providers of childbirth care: a systematic review.
          PLoS ONE. 2012; 7(10):e47946.doi:47910.41371/journal.pone.0047946.
        </Typography>
        <Typography gutterBottom>
          Existing WHO guideline: WHO recommendations: Optimizing health worker
          roles to improve access to key maternal and newborn health
          interventions through task shifting. Geneva: World Health
          Organization; 2012 (http://apps.who.int/
          iris/bitstream/10665/77764/1/9789241504843_eng.pdf, accessed 12
          January 2015). Context and conditions paper: Tina Miller.
          Oxford-Brookes University, unpublished, (2014).
        </Typography>
      </Grid>
    ),
    references: {
      title: "References",
      content: [
        "WHO recommendations OPTIMIZE MNH Optimizing health worker roles to improve access to key maternal and newborn health interventions through task shifting. Geneva; 2012.",
        "Working with individuals, families, and communities to improve maternal and newborn health. Geneva: World Health Organization; 2010. [26 November 2014] http://wwwwhoint/maternal_child_adolescent/documents/who_fch_rhr_0311/en/.",
        "The Ottawa Charter for Health Promotion [website]. Geneva: World Health Organization; 1986. [30 March 2014] http://wwwwhoint/healthpromotion/conferences/previous/ottawa/en/.",
        "Making pregnancy safer: the critical role of the skilled attendant: a joint statement by WHO, ICM and FIGO. 2004.",
        "WHO Handbook for Guideline Development - 2nd edition. Geneva: World Health Organization; 2014. World Health Organization. 2014.",
        "WHO recommendations on health promotion interventions for maternal and newborn health 2015: World Health Organization; 2015.",
        "Byrne A, Morgan A. How the integration of traditional birth attendants with formal health systems can increase skilled birth attendance. International journal of gynaecology and obstetrics: the official organ of the International Federation of Gynaecology and Obstetrics. 2011;115(2):127-34.",
        "Vieira C, Portela A, Miller T, Coast E, Leone T, Marston C. Increasing the use of skilled health personnel where traditional birth attendants were providers of childbirth care: a systematic review. PloS one. 2012;7(10):e47946.",
        "Frankenberg E, Buttenheim A, Sikoki B, Suriastini W. Do Women Increase Their Use of Reproductive Health Care When It Becomes More Available? Evidence from Indonesia. Studies in family planning. 2009;40(1):27-38.",
        "Mullany LC, Lee TJ, Yone L, Lee CI, Teela KC, Paw P, et al. Impact of community-based maternal health workers on coverage of essential maternal health interventions among internally displaced communities in eastern Burma: the MOM project. PLoS medicine. 2010;7(8):e1000317.",
        "Gabrysch S, Lema C, Bedriñana E, Bautista MA, Malca R, Campbell OMR, et al. Cultural adaptation of birthing services in rural Ayacucho, Peru. Bulletin of the World Health Organization. 2009;87(9):724-9.",
        "Ronsmans C, Endang A, Gunawan S, Zazri A, McDermott J, Koblinsky M, et al. Evaluation of a comprehensive home-based midwifery programme in South Kalimantan, Indonesia. Tropical medicine & international health : TM & IH. 2001;6(10):799-810.",
        "Fauveau V, Stewart K, Khan SA, Chakraborty J. Effect on mortality of community-based maternity-care programme in rural Bangladesh. Lancet (London, England). 1991;338(8776):1183-6.",
        "Purdin S, Khan T, Saucier R. Reducing maternal mortality among Afghan refugees in Pakistan. International journal of gynaecology and obstetrics: the official organ of the International Federation of Gynaecology and Obstetrics. 2009;105(1):82-5.",
      ],
    },
  },
  {
    id: "22",
    img: img74,
    imgAlt: "a woman giving a speech",
    title:
      "WHO recommendation on providing culturally appropriate skilled maternity care",
    date: "31 May 2015",
    recommendation: {
      title: "Recommendation",
      content:
        "Ongoing dialogue with communities is recommended as an essential component in defining the characteristics of culturally appropriate, quality maternity care services that address the needs of women and newborns and incorporate their cultural preferences.\n" +
        "Mechanisms that ensure women's voices are meaningfully included in these dialogues are also recommended.\n",
    },
    recommendationItalic: "(Strong recommendation, very low-quality evidence)",
    publicationHistory: {
      firstPublished: "May 2015",
      updated: "No update planned",
      assessedAsUpToDate: "May 2015",
    },
    remarks: {
      title: "Remarks",
      content: ["Additional research is required."],
    },
    background: {
      title: "Background",
      content: [
        'In 2003 the World Health Organization (WHO) published "Working with individuals, families and communities to improve maternal and newborn health", (1) the IFC framework that promotes integrating the health promotion approach set out in the Ottawa Charter (2) into national maternal and newborn health (MNH) strategies.',
        "The IFC Framework was developed in response to analysis and global statements indicating that as well as strengthening services, MNH strategies need to improve the capacity of individuals, families and communities to provide appropriate care for pregnant women, mothers, and newborns in the home. It also addresses the reasons - over and above what happens in clinical services - why women do not reach good quality skilled care during pregnancy, childbirth and after birth. The Ottawa Charter's health promotion components(2) were translated into MNH programme language and 12 promising interventions - identified through reviews of country experiences and the literature - were categorized into four priority areas. Community and intersectoral participation was recommended to guide implementation. Exact interventions to be adapted by country programmes were to be identified through local assessment; however, the framework highlighted the need for interventions to address all four priority areas at the same time. All six WHO Regions integrated this guidance into the regional maternal mortality reduction strategies.",
        "The need for culturally appropriate health facilities is core to WHO's mandate on Health For All(3) and cultural competencies of providers and services were identified as a key area of intervention in the WHO IFC Framework.(1) A recent qualitative systematic review confirms the importance of cultural factors in the decisions of women and families to use skilled care at birth.(4) Different programmes have adapted models of service delivery or service practices to incorporate acceptable and respectful care, trained service providers, employed mediators and interpreters and used participatory approaches to engage in dialogue with communities in order to address cultural factors that affect use of care.",
      ],
    },
    methods: {
      title: "Methods",
      content: [
        'The recommendation was developed using standardized operating procedures in accordance with the process described in the "WHO handbook for guideline development", guided by the GRADE approach.(5) Outcomes used for this recommendation were aligned with the prioritized outcomes from the WHO recommendations on health promotion interventions for maternal and newborn health (2015).(6)',
        "A systematic review was conducted on interventions to provide culturally appropriate maternity care.(7) In the review, randomized controlled trials relevant to the key question were screened by review authors, and data on relevant outcomes and comparisons were extracted. Evidence profiles (in the form of GRADE tables) were prepared for comparisons of interest, including the assessment and judgments for each outcome, and the estimated risks.",
        "WHO convened a Guideline Development Group (GDG) meeting on recommendations on health promotion interventions for maternal and newborn health where this recommendation was developed. The GDG comprised of a group of independent experts, who used the evidence profiles to assess evidence on effects on the pre-specified outcomes. GDG members discussed the balance between desirable and undesirable effects, overall quality of supporting evidence, values and preferences of stakeholders, resource requirements, cost-effectiveness, acceptability, feasibility and equity, to formulate the recommendation. Remarks were added to clarify the recommendation, and aid implementation.",
        <span>
          Further information on procedures for developing this recommendation
          are available{" "}
          <Link href="https://web.archive.org/web/20201105052129/http:/apps.who.int/iris/bitstream/handle/10665/172427/9789241508742_report_eng.pdf;jsessionid=0888E6D3E74DA6A9DE077D118B6EF1DB?sequence=1">
            here.
          </Link>
        </span>,
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following question:",
      content: [
        "What strategies to provide culturally appropriate skilled maternity care lead to an increase in use of skilled maternity care before, during and after birth?",
      ],
    },
    evidenceSummary: {
      title: "Evidence Summary",
      content: [
        "Evidence on interventions to provide culturally appropriate maternity care was extracted from a systematic review conducted by Coast, Jones and Lattof (forthcoming).(7) The review was based on literature identified through a systematic mapping conducted by Coast et al.(8) and also through the MASCOT/ MH-SAR mapping. This literature was supplemented with hand-searches of the reference lists of relevant reviews and items included in the mapping, as well as further recommendations from experts. The review sought interventions designed primarily and explicitly to provide culturally appropriate skilled maternity care for defined ethno-linguistic or religious groups. Examples of interventions considered include those that adapt models of service delivery (e.g., the service setting, practices, materials and/or language) to provide culturally appropriate or acceptable care, interventions to provide staff training and interventions that employ service providers or mediators who share cultural characteristics with the relevant population. As suggested by the GDG, in this case we also searched for studies from high-income countries as it was felt that lessons could be learned from experiences with different ethnic groups in these settings.",
        "A total of 14 studies were identified as meeting our inclusion criteria. The studies were conducted mostly in high-income countries, where they focused on sub-populations in Australia, Canada, Israel, the United Kingdom and the United States of America (USA). One study was conducted in Peru.",
        "Interventions included selecting and/or changing a service provider to match the cultural characteristics of the study population, changing the service social setting, introducing and/or changing service practices, changing the language of services, changing the location of service delivery, using a participatory model in designing the intervention, providing staff training and changing the physical setting of the service. There was not sufficient evidence to determine which of these strategies or which combination of strategies were most effective.",
      ],
    },
    maternalOutcomes: {
      title: "",
      content: "",
    },
    fetalAndNeonatalOutcomes: [
      {
        title:
          "For the outcome of birth with a skilled birth attendant or facility birth, the quality of the evidence was rated as very low.",
        content:
          "Two observational studies reported on this outcome: one was a prospective cohort study conducted with an Indigenous population in Australia (Panaretto et al., 2005); the other was a pre and post comparative study conducted with poor indigenous Quechua communities in Peru (Gabrysch et al., 2009). The new childbirth care model in Peru reports increases in the percentage of births with an SBA and births in the health centre over time. A new ANC model in Australia showed that more women attending the programme gave birth at the hospital. Very low-quality evidence.(9, 10)",
      },
      {
        title:
          "For the outcome of care with a skilled birth attendant or in a facility in case of complications/illness in women, the quality of the evidence was rated as very low.",
        content:
          "Two retrospective studies with control groups reported on this outcome. Parsons and Day (1992) measured the effect of health advocates on careseeking among Turkish and Asian women in the UK, while Thompson et al. (1998) examined the effect of nursing case management and home visits on care-seeking among high-risk low-income Mexican- American women in the USA. Both studies report on antenatal admissions and length of stay but provide no data on use of a skilled birth attendant at birth. In Parsons and Day (1992), length of stay was significantly lower in the health advocates' intervention group, but there was no difference between the groups in the Thompson et al. (1998) study. There were too few antenatal admissions to calculate statistical differences in the Thompson et al. (1998) study but the study found more inpatient admissions and emergency room visits in the intervention group. In the Parsons and Day study (1992), antenatal admissions remained the same with the health advocates intervention and increased in the control group. Very low-quality evidence.(11, 12)",
      },
      {
        title:
          "For the outcome of use of ANC, the quality of the evidence was rated as very low.",
        content:
          "Eleven observational studies reported on this outcome. All studies were conducted in high income countries with ethnic minority women: five in Australia with Indigenous Aboriginal and Torres Strait Islander populations (Jan et al., 2004; Kildea et al., 2012; Nel and Pashen, 2003; Panaretto et al., 2005; Panaretto et al., 2007;); three in the US with minority groups including pregnant adolescents at high risk of poor outcomes (Julnes, 1994; Jewell and Russell, 2000; Thompson, Curry and Burton, 1998); two in the United Kingdom with Asian and Turkish women (Parsons and Day, 1992; Mason, 1990); and one in Israel with Bedouin women (Bilenko, Hammel and Belmaker, 2007). Interventions included health advocacy, liaison, linkage or brokerage for women; the employment of Indigenous health staff; group or individual support; home or clinic-based visits; and transport services. Overall results indicate a positive effect of culturally appropriate interventions on ANC use. Eight studies showed improvement on various measures of antenatal care utilization, including indexes of the adequacy of ANC, increases in the number of visits, or increases in women having at least six ANC visits (Bilenko, Hammel and Belmaker, 2007; Jan et al., 2004; Jewell and Russell, 2000; Julnes, 1994; Kildea et al., 2012, Nel and Pashen, 2003; Panaretto et al., 2005; Panaretto et al., 2007). Three report no difference in ANC use between those receiving the intervention and controls (Mason, 1990; Parsons and Day, 1992; Thompson, Curry and Burton, 1998). Very low-quality evidence.(9, 11-20)",
      },
      {
        title:
          "For the outcome of postpartum care visits for women, the quality of the evidence was rated as low.",
        content:
          "One RCT study (Marsiglia, Bermudez-Parsai and Coonrod, 2010) reported on this outcome in low-income Latina/Hispanic pregnant women in the USA. Results show a benefit of the cultural broker: attendance at the postpartum visit was 2.5 times more likely in the intervention group and women who met with the prenatal partners (cultural brokers) more often were more likely to attend the postpartum visit. Low-quality evidence.(21)",
      },
    ],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "The GDG requested that, despite the wording of the recommendation, the implementation of this intervention should not be limited to dialogue (communication) but should also offer the opportunity to participate and to transform the way services are offered and community-health services relations.",
        "Different interventions were employed in the 14 identified studies to provide culturally appropriate maternity care services. Most studies implied that these adjustments lead to increased satisfaction with services. These interventions might include one or a combination of the following elements: establishing, enhancing and evaluating dialogue between communities, policymakers, institutions and service providers; using cultural brokers as mediators; integrating culturally matched health staff (lay and skilled); adapting service practices to the cultural context where appropriate and feasible; and using and making respectful approaches central to dialogues between and within communities, institutions, service providers and policies.",
        "Only three studies reported on costs and the same were very context specific.",
        "Given that cultural beliefs and behaviour are impossible to isolate from the social and economic context in which they occur, interventions are likely to be most sustainable when they employ a participatory approach. Measures should be taken to support women, community members and groups, providers and institutions to establish and maintain respectful dialogue.",
        "For service providers, the promotion of dialogue with communities should be embedded in their training and supported and evaluated over their career. Policies at the national and local level should be in place to establish an enabling environment and support dialogue with communities.",
        "Culture is not static, and its dynamism needs to be recognized, anticipated and incorporated into maternity care services. The potentially harmful consequences of cultural stereotyping need to be avoided. Services designed for specific populations should take into account the potential harm of associated stigma. Efforts should be made to understand the cultural factors affecting use of care in the relevant context through prior studies and/or community participation in the design of the intervention.",
        <span>
          In establishing respectful dialogue with communities, the following
          considerations were highlighted by the GDG:
          <List>
            <ListItem>o Recognize and address power dynamics</ListItem>
            <ListItem>o Make links to respectful maternity care</ListItem>
            <ListItem>o Vulnerated populations are not vulnerable</ListItem>
            <ListItem>o Recognize gender hierarchies in institutions</ListItem>
            <ListItem>
              o Pre-service and in-service training of providers needs to take
              into account cultural competencies and clinical training sites
              should model these practices
            </ListItem>
            <ListItem>
              o Language is an important part of cultural considerations
            </ListItem>
          </List>
        </span>,
        "Support government health services in strengthening skills as a mediator in bringing together a broad array of actors for dialogue with communities",
      ],
    },
    additionalLists: [],
    researchImplications: {
      title: "Research implications",
      text: "The GDG identified that further research on the following high-priority questions is needed:",
      content: [
        "Studies should aim for optimal design and should document important process issues for implementation research to understand the effect of the different modalities of delivering the intervention.",
        "Studies should standardize some of the outcome measures, allowing for the possibility of local contextualization and for additional measures beyond the traditional health outcomes, including women's satisfaction with services, strengthening of services and community relations.",
        "Studies should include clear and common indicators for monitoring and evaluation and standardized measures of these and should use standard methods for capturing the opinions of women, the community and health staff on health services and quality.",
        "Agreement is needed on priority actions and/or which combinations of essential actions should be further tested.",
        "Intervention studies that provide strong(er) evidence of impact are needed.",
        "Where interventions are complex, these should be supplemented with qualitative and/or other studies that may isolate which components of the intervention are responsible for the outcomes.",
        "Studies should examine and present information on resource use where possible.",
        "Studies in low and middle-income countries should be prioritized.",
        "Where an intervention is designed explicitly to address cultural factors, sufficient detail should be provided in reporting for the audience to understand how the cultural factors were addressed and how the intervention was implemented.",
        "When writing up studies, authors should provide more detailed information on the content of the interventions and how the interventions were implemented.",
        "Progress has been made in some regions (e.g., LAC) to develop and include policies and standards of care with an intercultural approach. Case studies of these programmes should be developed to extract lessons learned.",
        "A review of curricula and training programmes for health professionals should be undertaken to determine the extent to which cultural sensitivity and competencies have been included. An expert group can establish core cultural competencies that can be adapted at the national and local levels and incorporated into training or curricula for health professionals.",
        "The intercultural dialogue approach can be used as a base to promote collaborative research towards simultaneous studies and/or multi-site studies, with guidance to funders.",
      ],
    },
    relatedLinks: {
      title: "Related links",
      text: (
        <Typography>
          WHO recommendations on antenatal care for a positive pregnancy
          experience (2015) -{" "}
          <Link href="https://web.archive.org/web/20200915144755/https://apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1">
            full document
          </Link>{" "}
          and{" "}
          <Link href="https://web.archive.org/web/20201219124651/https://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            evidence table
          </Link>
        </Typography>
      ),
      links: [
        {
          href: "https://web.archive.org/web/20201031220559/https://apps.who.int/iris/bitstream/handle/10665/255760/9789241565493-eng.pdf?sequence=1",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors",
        },
        {
          href: "https://web.archive.org/web/20201030093023/https://apps.who.int/iris/bitstream/handle/10665/249580/9789241549356-eng.pdf;jsessionid=7B8E18468A424E0301261B2CA6BCB324?sequence=1",
          text: "Pregnancy, Childbirth, Postpartum and Newborn Care: A guide for essential practice",
        },
        {
          href: "https://web.archive.org/web/20201103090439/https://www.who.int/reproductivehealth/en/",
          text: "WHO Programmes: Sexual and Reproductive health",
        },
        {
          href: "https://web.archive.org/web/20201111195601/https://www.who.int/health-topics/maternal-health#tab=tab_1",
          text: "Maternal Health",
        },
        {
          href: "https://web.archive.org/web/20201020052824/https://www.who.int/health-topics/newborn-health/#tab=tab_1",
          text: "Newborn Health",
        },
      ],
    },
    additionalLinks: (
      <Grid>
        <Typography fontWeight={"bold"} gutterBottom>
          Supporting systematic reviews:{" "}
        </Typography>
        <Typography gutterBottom>
          Coast E, Jones E, Lattof SR, Portela A. Effectiveness of interventions
          to provide culturally appropriate maternity care in increasing uptake
          of skilled maternity care: a systematic review. Health policy and
          planning. 2016;31(10):1479-91.
        </Typography>
      </Grid>
    ),
    references: {
      title: "References",
      content: [
        "The Ottawa Charter for Health Promotion [website]. Geneva: World Health Organization; 1986. [30 March 2014] http://wwwwhoint/healthpromotion/conferences/previous/ottawa/en/.",
        "Maternal mortality in 2005. Estimates developed by WHO, UNICEF, UNFPA, and the World Bank. 2005.",
        "Bohren MA, Hunter EC, Munthe-Kaas HM, Souza JP, Vogel JP, Gulmezoglu AM. Facilitators and barriers to facility-based delivery in low- and middle-income countries: a qualitative evidence synthesis. Reproductive Health. 2014;11(1):71.",
        "WHO Handbook for Guideline Development - 2nd edition. Geneva: World Health Organization; 2014. World Health Organization. 2014.",
        "WHO recommendations on health promotion interventions for maternal and newborn health 2015: World Health Organization; 2015.",
        "Coast E, Jones E, Lattof SR, Portela A. Effectiveness of interventions to provide culturally appropriate maternity care in increasing uptake of skilled maternity care: a systematic review. Health policy and planning. 2016;31(10):1479-91.",
        "Coast E, Jones E, Portela A, Lattof SR. Maternity Care Services and Culture: A Systematic Global Mapping of Interventions. PloS one. 2014;9(9).",
        "Panaretto KS, Lee HM, Mitchell MR, Larkins SL, Manessis V, Buettner PG, et al. Impact of a collaborative shared antenatal care program for urban Indigenous women: a prospective cohort study. The Medical journal of Australia. 2005;182(10):514-9.",
        "Gabrysch S, Lema C, Bedriñana E, Bautista MA, Malca R, Campbell OMR, et al. Cultural adaptation of birthing services in rural Ayacucho, Peru. Bulletin of the World Health Organization. 2009;87(9):724-9.",
        "Parsons L, Day S. Improving obstetric outcomes in ethnic minorities: an evaluation of health advocacy in Hackney. Journal of public health medicine. 1992;14(2):183-91.",
        "Thompson M, Curry MA, Burton D. The effects of nursing case management on the utilization of prenatal care by Mexican-Americans in rural Oregon. Public health nursing (Boston, Mass). 1998;15(2):82-90.",
        "Jan S, Conaty S, Hecker R, Bartlett M, Delaney S, Capon T. An holistic economic evaluation of an Aboriginal community-controlled midwifery programme in Western Sydney. Journal of health services research & policy. 2004;9(1):14-21.",
        "Kildea S, Stapleton H, Murphy R, Low NB, Gibbons K. The Murri clinic: a comparative retrospective study of an antenatal clinic developed for Aboriginal and Torres Strait Islander women. BMC pregnancy and childbirth. 2012;12:159.",
        "Nel P, Pashen D. Shared antenatal care for indigenous patients in a rural and remote community. Australian family physician. 2003;32(3):127-31.",
        "Panaretto KS, Mitchell MR, Anderson L, Larkins SL, Manessis V, Buettner PG, et al. Sustainable antenatal care services in an urban Indigenous community: the Townsville experience. The Medical journal of Australia. 2007;187(1):18-22.",
        "Julnes G, Konefal M, Pindur W, Kim P. Community-based perinatal care for disadvantaged adolescents: evaluation of The Resource Mothers Program. Journal of community health. 1994;19(1):41-53.",
        "Jewell NA, Russell KM. Increasing access to prenatal care: an evaluation of minority health coalitions' early pregnancy project. Journal of community health nursing. 2000;17(2):93-105.",
        "Mason ES. The Asian Mother and Baby Campaign (the Leicestershire experience). Journal of the Royal Society of Health. 1990;110(1):1-4, 9.",
        "Bilenko N, Hammel R, Belmaker I. Utilization of antenatal care services by a semi-nomadic Bedouin Arab population: evaluation of the impact of a local maternal and child health clinic. Maternal and Child Health Journal. 2007;11(5):425-30.",
        "Marsiglia FF, Bermudez-Parsai M, Coonrod D. Familias Sanas: an intervention designed to increase rates of postpartum visits among Latinas. Journal of health care for the poor and underserved. 2010;21(3 Suppl):119-31.",
        "Working with individuals, families, and communities to improve maternal and newborn health. Geneva: World Health Organization; 2010. [26 November 2014] http://wwwwhoint/maternal_child_adolescent/documents/who_fch_rhr_0311/en/.",
      ],
    },
  },
  {
    id: "23",
    img: img75,
    imgAlt: "two people making a podcast",
    title:
      "WHO recommendation on community participation in quality-improvement processes",
    date: "31 May 2015",
    recommendation: {
      title: "Recommendation",
      content:
        "Community participation in quality-improvement processes for maternity care services is recommended to improve quality of care from women's, communities' and health care providers' perspectives.\n" +
        "Communities should be involved in jointly defining and assessing quality. Mechanisms that ensure women's voices are meaningfully included are also recommended.\n",
    },
    recommendationItalic: "(Strong recommendation, low-quality evidence)",
    publicationHistory: {
      firstPublished: "2015",
      updated: "No update planned",
      assessedAsUpToDate: "2015",
    },
    remarks: {
      title: "Remarks",
      content: ["Additional research is required"],
    },
    background: {
      title: "Background",
      content: [
        'In 2003 the World Health Organization (WHO) published "Working with individuals, families and communities to improve maternal and newborn health", (1) the IFC framework that promotes integrating the health promotion approach set out in the Ottawa Charter (2) into national maternal and newborn health (MNH) strategies.',
        "The IFC Framework was developed in response to analysis and global statements indicating that as well as strengthening services, MNH strategies need to improve the capacity of individuals, families and communities to provide appropriate care for pregnant women, mothers, and newborns in the home. It also addresses the reasons - over and above what happens in clinical services - why women do not reach good quality skilled care during pregnancy, childbirth and after birth. The Ottawa Charter's health promotion components(2) were translated into MNH programme language and 12 promising interventions - identified through reviews of country experiences and the literature - were categorized into four priority areas. Community and intersectoral participation was recommended to guide implementation. Exact interventions to be adapted by country programmes were to be identified through local assessment; however, the framework highlighted the need for interventions to address all four priority areas at the same time. All six WHO Regions integrated this guidance into the regional maternal mortality reduction strategies.",
        "As indicated in the IFC Framework concept and strategy paper,(1) it is recognized that in order to assume a role in improving MNH, communities need information regarding local maternal and newborn health needs. There are different methodologies and tools designed for health systems to gather information regarding maternal and newborn death and morbidity. Several of these recognize the value of the community as a source of information.",
        "In recent years, particularly under the UN Secretary- General's Global Strategy for Women's and Children's Health(3) and the Commission on Information and Accountability, increased attention has been given to Maternal and Perinatal Death Surveillance and Responseas it contributes to better information for action by promoting routine identification and timely notification of maternal deaths, review of maternal deaths, and implementation and monitoring of steps to prevent similar deaths in the future.",
        "Community participation in this process may help provide more accurate information on the number of deaths and where and why the women died. Community participation in analysing information and in identifying possible solutions may help address social determinants, meet community needs, and incorporate a range of actors in the response. Members of the community may participate as family informants for maternal (and perinatal) death inquiries or in presentations of summary data to identify ways to improve health outcomes. Levels of participation can vary, e.g., providing views versus full decision-making. Delivery mechanisms can include involving community representatives in the MDSR coordinating group, or holding community group meetings to discuss maternal deaths, their causes and possible solutions.",
      ],
    },
    methods: {
      title: "Methods",
      content: [
        'The recommendation was developed using standardized operating procedures in accordance with the process described in the "WHO handbook for guideline development", guided by the GRADE approach.(4) Outcomes used for this recommendation were aligned with the prioritized outcomes from the WHO recommendations on health promotion interventions for maternal and newborn health (2015).(5)',
        "A systematic review was conducted on interventions to involve communities in the analysis and dissemination of information from maternal and perinatal death.(6) In the review, randomized controlled trials relevant to the key question were screened by review authors, and data on relevant outcomes and comparisons were extracted. Evidence profiles (in the form of GRADE tables) were prepared for comparisons of interest, including the assessment and judgments for each outcome, and the estimated risks.",
        "WHO convened a Guideline Development Group (GDG) meeting on recommendations on health promotion interventions for maternal and newborn health where this recommendation was developed. The GDG comprised of a group of independent experts, who used the evidence profiles to assess evidence on effects on the pre-specified outcomes. GDG members discussed the balance between desirable and undesirable effects, overall quality of supporting evidence, values and preferences of stakeholders, resource requirements, cost-effectiveness, acceptability, feasibility and equity, to formulate the recommendation. Remarks were added to clarify the recommendation, and aid implementation.",
        <span>
          Further information on procedures for developing this recommendation
          are available{" "}
          <Link href="https://web.archive.org/web/20201105052133/http:/apps.who.int/iris/bitstream/handle/10665/172427/9789241508742_report_eng.pdf;jsessionid=0888E6D3E74DA6A9DE077D118B6EF1DB?sequence=1">
            here.
          </Link>
        </span>,
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following question:",
      content: [
        "What interventions to involve communities in quality improvement processes for maternity care services are effective in increasing birth with a skilled birth attendant/ institutional birth and improving other key maternal and newborn health outcomes?",
      ],
    },
    evidenceSummary: {
      title: "Evidence summary",
      content: [
        "Evidence on interventions to involve communities in quality-improvement processes was extracted from a systematic review conducted by Marston et al. (forthcoming).(7) The review was based on literature identified through the MASCOT/MH-SAR mapping and through an additional systematic review conducted by Marston et al. (2013).(8) Five studies were included. One study was primarily aimed at increasing use of skilled care for obstetric complications.(9)",
        "Modified from the International Association for Public Participation, 2004",
        "Participation processes and mechanisms varied between studies. There was not sufficient evidence to determine which of the strategies or which combination of strategies were most effective. Table 4 summarizes the characteristic of participation within each included study.",
        "Weak evidence was reported for the key outcomes, although qualitative evidence points to some positive effects of the intervention. Some studies examine the effect of packages including some evidence of community participation in quality improvement but do not necessarily examine participation effects on their own.",
      ],
    },
    maternalOutcomes: {
      title: "",
      content: "",
    },
    fetalAndNeonatalOutcomes: [
      {
        title:
          "For the outcome of birth with a skilled birth attendant or facility birth, the quality of the evidence ranged from very low to moderate.",
        content:
          "One cluster RCT (Bjorkman and Svensson, 2009) conducted in Uganda reports a significant increase in facility births. Moderate-quality evidence.(10)\n" +
          "Four observational studies also report increases in facility births. In three studies the increase is significant: a pre and post study in Peru (Gabrysch et al., 2009) reports an increase in births in the health centre between baseline and follow-up and an increase in SBA; a controlled before and after study in Kenya (Kaseje et al., 2010) reports significant increases in facility births at intervention sites; and a pre and post study in India (Sinha, 2008) reports significant increases in births at primary health centres and government hospitals. A before and after study (Purdin, Khan and Saucier, 2009) reports the proportion of births in an EmOC facility increased following the intervention in a refugee population in Pakistan. Very low-quality evidence.(11-15)\n",
      },
      {
        title:
          "For those studies whose focus was increasing access to skilled care for complications:",
        content:
          "A study in rural Bangladesh (Hossain and Ross, 2006) reports significant increases in the intervention and comparison groups but not in the control group. The study design is not clearly stated, but it seems to be a quasi-experimental pre and post study with a control group. Very low-quality evidence.(9)",
      },
      {
        title:
          "For the outcome of care with a skilled birth attendant or in a facility in case of maternal complications, the quality of the evidence was rated as very low.",
        content:
          "One study conducted in rural Bangladesh (Hossain and Ross, 2006) reports significant increases in the intervention and comparison groups; the control group also shows a very small increase. As described above the design is not clearly stated. (9)",
      },
      {
        title:
          "For the outcome of ANC attendance, the quality of the evidence ranged from very low to moderate.",
        content:
          "One cluster RCT (Bjorkman and Svensson, 2009) conducted in Uganda reports a non-significant increase in ANC use. Moderate-quality evidence.(10)\n" +
          "Three observational studies report increases in ANC visits: a controlled before and after study in Kenya (Kaseje et al., 2010) reports increases in four or more visits but no significant differences between control and intervention sites, a pre and post study in India (Sinha, 2008) where the difference in the number of women who made at least one and more than three visits is significant between baseline and endline, and a retrospective before and after study reports complete ANC coverage (three or more visits) increased from baseline to endline in Afghan refugee women in Pakistan (Purdin, Khan and Saucier, 2009). Very low-quality evidence.(13-15)\n",
      },
      {
        title:
          "For the outcome postpartum visits for women, the quality of the evidence was rated as very low.",
        content:
          "Purdin, Khan and Saucier (2009) showed that coverage of postpartum care within 72 hours of birth increased over time following the intervention in a setting with Afghan refugee women in Pakistan. Very low-quality evidence.In addition to the critical and important outcomes, the GDG had requested information on the effect of the intervention on woman's satisfaction with services. Only one study (Gabrysch et al., 2009) reported on women's satisfaction using qualitative interviews.(12, 15)",
      },
    ],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "Collaboration among community members and service providers to jointly define and improve quality can be an effective approach when a supportive dialogue process is facilitated well and involves and takes into account the perspectives of all diverse participants.",
        "It is important to ensure that pregnant women have a voice and that there is adequate diversity in perspectives of both community members and service providers in quality-improvement processes.",
        "Quality-improvement committees can be an effective structure to support ongoing collaboration among communities and service providers when all members understand and are committed to the purpose of the group, roles and responsibilities are clear and acceptable to all members and group governance practices support participation.",
        "Joint assessment of health services and care is helpful to support informed decision-making. Ongoing monitoring of data helps to inform adaptation of strategies as necessary for continual improvement and accountability.",
        "Health facility leadership that supports collaboration with communities is necessary for this approach to be effective.",
        "Discussion at national and local levels is required for adaptation to context and to ensure a meaningful degree of decentralization so that an appropriate level of budget and resources are assigned to support the process.",
        "This intervention should be seen as a dynamic process. It takes time to build trust between the different actors and the capacity of the different actors to plan together and to work together will develop over time.",
        "Data must be presented in ways that can be understood by all participants, taking care to communicate health issues effectively to those within and outside of the health sector with varying levels of education and experience.",
        "While NGOs may have experience in facilitating and organizing community involvement, the work should be linked to government services and embedded in ongoing processes, when and where they exist.",
        "It is important to include voices and perspectives from diverse groups in the community.",
      ],
    },
    additionalLists: [],
    researchImplications: {
      title: "Research implications",
      text: "The GDG suggested it would be useful to have more information about:",
      content: [
        "Dialogue between community and health services and the dynamics of the community-service interface in various settings (decentralized, centralized, conflict/fragile states).",
        "Community/provider action planning processes, such as how strategies are agreed upon and how they change over time.",
        "Data for decision-making to support joint qualityimprovement efforts and accessible ways of presenting data to highlight progress and trends.",
        "Community feedback on services and the role of communities in holding services accountable for quality care. Similarly, mechanisms for providers to provide feedback to communities on aspects within individual, family and community control to improve quality care.",
        "Advocacy and its role in improving the quality of services (either the community alone or jointly with service providers to obtain resources, change management practices, etc.).",
        "The effects of participation in quality improvement on community dynamics, self-efficacy, identity, power relations, etc.",
        "The role of evolving technologies to support community participation in improving qualityof care (improved access to information, better communication through mobile devices, data collection possibilities, etc.).",
        "How to ensure confidentiality when sharing data for decision-making.",
        "Assessing the impact of experiences in high-income countries and identifying the lessons learned.",
      ],
    },
    relatedLinks: {
      title: "Related links",
      text: (
        <Typography>
          WHO recommendations on antenatal care for a positive pregnancy
          experience (2015) -{" "}
          <Link href="https://web.archive.org/web/20201105052133/http:/apps.who.int/iris/bitstream/handle/10665/172427/9789241508742_report_eng.pdf;jsessionid=D2FE71B72516ADEFBC5B03B8D22113F9?sequence=1">
            full document
          </Link>{" "}
          and{" "}
          <Link href="https://web.archive.org/web/20201105052133/http:/apps.who.int/iris/bitstream/10665/172427/2/9789241508742_tables_eng.pdf?ua=1">
            evidence table
          </Link>
        </Typography>
      ),
      links: [
        {
          href: "https://web.archive.org/web/20201031220559/https://apps.who.int/iris/bitstream/handle/10665/255760/9789241565493-eng.pdf?sequence=1",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors",
        },
        {
          href: "https://web.archive.org/web/20201030093023/https://apps.who.int/iris/bitstream/handle/10665/249580/9789241549356-eng.pdf;jsessionid=7B8E18468A424E0301261B2CA6BCB324?sequence=1",
          text: "Pregnancy, Childbirth, Postpartum and Newborn Care: A guide for essential practice",
        },
        {
          href: "https://web.archive.org/web/20201103090439/https://www.who.int/reproductivehealth/en/",
          text: "WHO Programmes: Sexual and Reproductive health",
        },
        {
          href: "https://web.archive.org/web/20201111195601/https://www.who.int/health-topics/maternal-health#tab=tab_1",
          text: "Maternal Health",
        },
        {
          href: "https://web.archive.org/web/20201020052824/https://www.who.int/health-topics/newborn-health/#tab=tab_1",
          text: "Newborn Health",
        },
      ],
    },
    additionalLinks: (
      <Grid>
        <Typography fontWeight={"bold"} gutterBottom>
          Supporting systematic reviews:{" "}
        </Typography>
        <Typography gutterBottom>
          Marston C, Sequeira M, Portela A, Cavallaro F, CR M. Systematic
          Reviews on community participation and maternity care seeking for
          maternal and newborn health.
        </Typography>
      </Grid>
    ),
    references: {
      title: "References",
      content: [
        "Working with individuals, families, and communities to improve maternal and newborn health. Geneva: World Health Organization; 2010. [26 November 2014] http://wwwwhoint/maternal_child_adolescent/documents/who_fch_rhr_0311/en/.",
        "The Ottawa Charter for Health Promotion [website]. Geneva: World Health Organization; 1986. [30 March 2014] http://wwwwhoint/healthpromotion/conferences/previous/ottawa/en/.",
        "The prevention and elimination of disrespect and abuse during facility-based childbirth: WHO statement. 2014.",
        "Bohren MA, Hunter EC, Munthe-Kaas HM, Souza JP, Vogel JP, Gulmezoglu AM. Facilitators and barriers to facility-based delivery in low- and middle-income countries: a qualitative evidence synthesis. Reproductive Health. 2014;11(1):71.",
        "WHO Handbook for Guideline Development - 2nd edition. Geneva: World Health Organization; 2014. World Health Organization. 2014.",
        "WHO recommendations on health promotion interventions for maternal and newborn health 2015: World Health Organization; 2015.",
        "Marston C, Sequeira M, Portela A, Cavallaro F, CR M. Systematic Reviews on community participation and maternity care seeking for maternal and newborn health. (forthcoming).",
        "Marston C, Renedo A, McGowan CR, Portela A. Effects of community participation on improving uptake of skilled care for maternal and newborn health: a systematic review. PloS one. 2013;8(2):e55012.",
        "Hossain J, Ross SR. The effect of addressing demand for as well as supply of emergency obstetric care in Dinajpur, Bangladesh. International journal of gynaecology and obstetrics: the official organ of the International Federation of Gynaecology and Obstetrics. 2006;92(3):320-8.",
        "Björkman M, Svensson J. Power to the people: evidence from a randomized field experiment on community-based monitoring in Uganda. The Quarterly Journal of Economics. 2009;124(2):735-69.",
        "Iap2 public participation spectrum. Wollongong, Australia: International Association for Public Participation; [23 January 2015]. year https://wwwiap2orgau/documents/item/84.",
        "Gabrysch S, Lema C, Bedriñana E, Bautista MA, Malca R, Campbell OMR, et al. Cultural adaptation of birthing services in rural Ayacucho, Peru. Bulletin of the World Health Organization. 2009;87(9):724-9.",
        "Kaseje D, Olayo R, Musita C, Oindo CO, Wafula C, Muga R. Evidence-based dialogue with communities for district health systems' performance improvement. Global public health. 2010;5(6):595-610.",
        "Sinha D. Empowering communities to make pregnancy safer: an intervention in rural Andhra Pradesh: Population Council; 2008.",
        "Purdin S, Khan T, Saucier R. Reducing maternal mortality among Afghan refugees in Pakistan. International journal of gynaecology and obstetrics: the official organ of the International Federation of Gynaecology and Obstetrics. 2009;105(1):82-5.",
      ],
    },
  },
  {
    id: "24",
    img: img76,
    imgAlt: "women on a demonstration",
    title:
      "WHO recommendation on community participation in programme planning and implementation",
    date: "31 May 2015",
    recommendation: {
      title: "Recommendation",
      content:
        "Community participation in programme planning, implementation and monitoring is recommended to improve use of skilled care during pregnancy, childbirth and the postnatal period for women and newborns, increase the timely use of facility care for obstetric and newborn complications and improve maternal and newborn health.\n" +
        "Mechanisms that ensure women's voices are meaningfully included are also recommended.\n",
    },
    recommendationItalic:
      "(Strong recommendation, very low quality of evidence)",
    publicationHistory: {
      firstPublished: "May 2015",
      updated: "No update planned",
      assessedAsUpToDate: "May 2015",
    },
    remarks: {
      title: "Remarks",
      content: ["Additional research is required"],
    },
    background: {
      title: "Background",
      content: [
        'In 2003 the World Health Organization (WHO) published "Working with individuals, families and communities to improve maternal and newborn health", (1) the IFC framework that promotes integrating the health promotion approach set out in the Ottawa Charter (2) into national maternal and newborn health (MNH) strategies.',
        "The IFC Framework was developed in response to analysis and global statements indicating that as well as strengthening services, MNH strategies need to improve the capacity of individuals, families and communities to provide appropriate care for pregnant women, mothers, and newborns in the home. It also addresses the reasons - over and above what happens in clinical services - why women do not reach good quality skilled care during pregnancy, childbirth and after birth. The Ottawa Charter's health promotion components(2) were translated into MNH programme language and 12 promising interventions - identified through reviews of country experiences and the literature - were categorized into four priority areas. Community and intersectoral participation was recommended to guide implementation. Exact interventions to be adapted by country programmes were to be identified through local assessment; however, the framework highlighted the need for interventions to address all four priority areas at the same time. All six WHO Regions integrated this guidance into the regional maternal mortality reduction strategies.",
        "Community participation is recognized in a number of legal instruments and key WHO policy documents and is considered within the IFC Framework and other WHO strategies to be a fundamental component of MNH strategies. Yet evidence is limited about the effect of community participation, here broadly defined as members of a community involved in planning, designing, implementing and monitoring strategies and interventions. Levels of participation can vary, e.g., providing views versus full decision-making. Interventions can also vary, e.g., consultations with communities, community representation on health facility management committees and meetings between community representatives, local authorities and health service managers.",
      ],
    },
    methods: {
      title: "Methods",
      content: [
        'The recommendation was developed using standardized operating procedures in accordance with the process described in the "WHO handbook for guideline development", guided by the GRADE approach.(3) Outcomes used for this recommendation were aligned with the prioritized outcomes from the WHO recommendations on health promotion interventions for maternal and newborn health (2015).(4)',
        "A systematic review was conducted on interventions to involve communities in MNH programme planning and implementation.(5) In the review, randomized controlled trials relevant to the key question were screened by review authors, and data on relevant outcomes and comparisons were extracted. Evidence profiles (in the form of GRADE tables) were prepared for comparisons of interest, including the assessment and judgments for each outcome, and the estimated risks.",
        "WHO convened a Guideline Development Group (GDG) meeting on recommendations on health promotion interventions for maternal and newborn health where this recommendation was developed. The GDG comprised of a group of independent experts, who used the evidence profiles to assess evidence on effects on the pre-specified outcomes. GDG members discussed the balance between desirable and undesirable effects, overall quality of supporting evidence, values and preferences of stakeholders, resource requirements, cost-effectiveness, acceptability, feasibility and equity, to formulate the recommendation. Remarks were added to clarify the recommendation, and aid implementation.",
        <span>
          Further information on procedures for developing this recommendation
          are available{" "}
          <Link href="https://web.archive.org/web/20201105052124/http:/apps.who.int/iris/bitstream/handle/10665/172427/9789241508742_report_eng.pdf;jsessionid=0888E6D3E74DA6A9DE077D118B6EF1DB?sequence=1">
            here.
          </Link>
        </span>,
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following question:",
      content: [
        "What interventions to involve communities in MNH programme planning are effective in increasing birth with a skilled birth attendant/institutional birth and improving other key maternal and newborn health outcomes?",
      ],
    },
    evidenceSummary: {
      title: "Evidence summary",
      content: [
        "Evidence on interventions to involve communities in MNH programme planning and implementation was based on literature identified through the MASCOT/ MH-SAR mapping, through a systematic review conducted by Marston et al. (2013)(5) and through an additional supplementary search conducted by Marston et al. (2014) to capture newborn health programmes. 12 studies were included. One programme was primarily aimed at increasing use of skilled care for obstetric complications.(6, 7)",
        "Participation processes and mechanisms varied between studies. There was not sufficient evidence to determine which of the strategies or which combination of strategies were most effective. On the scale of types of participation, most of the studies fell into the level of involving communities. Two others worked at sharing leadership between the community and the services.",
        "In summary, mixed quantitative evidence was found, often from studies with weak designs. Participation in planning takes many different forms, and it is difficult to compare the different strategies. Some interventions involved a package of measures including participation, and in these cases, the specific effects of participation cannot be separated out. However, the GDG noted that while the evidence was assessed as very low quality and inconsistent, there is positive reporting from different programmes in different contexts and on different outcomes, which provides an indication that there is potential for participation in planning to have an impact on care-seeking outcomes.",
      ],
    },
    maternalOutcomes: {
      title: "",
      content: "",
    },
    fetalAndNeonatalOutcomes: [
      {
        title:
          "For the outcome of birth with a skilled birth attendant or facility birth, the quality of the evidence was rated as very low.",
        content: (
          <Grid>
            <Typography gutterBottom>
              Two cluster RCTs (Bjorkman and Svensson, 2009; Bhutta et al.,
              2011) and one quasi-experimental study (reported in Malhotra et
              al., 2005) reported on this outcome. Both cluster RCTs report
              increased facility births, statistically significant in rural
              communities of Uganda (Bjorkman and Svensson, 2009) and not
              statistically significant in rural communities in Pakistan (Bhutta
              et al., 2011). The quasi-experimental study conducted with
              adolescents in Nepal (Malhotra et al., 2005) reports an increase
              in facility births at both sites but a greater increase at the
              control site than the study site. Very low-quality evidence.(8-11)
            </Typography>
            <Typography gutterBottom>
              Five observational studies also report on this outcome. Two
              controlled before and after studies report significant increases
              in facility births in Kenya and China (Kaseje et al., 2010
              Kaufman, Liu and Fang, 2012); the before and after studies report
              increased facility births following the intervention in Peru
              (Harkins et al., 2008); and in Pakistan, births in an EMoC
              facility increased in a refugee population (Purdin, Khan and
              Saucier, 2009). In Indonesia (Sood et al., 2004), study findings
              indicate facility births were significantly more likely in the
              group exposed to the intervention. Harkins et al. (2008) also
              reports an increase in use of skilled attendants at last birth in
              Honduras following a participation intervention. The community
              participation interventions are diverse and the level of
              participation in planning, implementing and evaluating programmes
              varies across the studies. Very low-quality evidence.(12-16)
            </Typography>
            <Typography gutterBottom>
              For those studies where the focus was increasing access to skilled
              care for complications:
            </Typography>
            <Typography gutterBottom>
              The pre and post intervention follow-up study in rural, poor
              communities in Tanzania (Ahluwalia et al., 2010) reports
              significant increases in births assisted by a trained person and
              occurring at a health facility over the three-year follow-up
              period. Very low-quality evidence.(7)
            </Typography>
          </Grid>
        ),
      },
      {
        title:
          "For the outcome of care with a skilled birth attendant or facility in case of maternal complications, the quality of the evidence was rated as very low.",
        content:
          "Only the pre and post intervention study in Tanzania (Ahluwalia et al., 2003) reported on this outcome. In this programme, community mobilization was a small component of a larger more complex programme focused on training TBAs, preparing women for birth with a TBA, and mobilizing communities to understand danger signs. The study reports an increase in the number of pregnant women attending the district hospital treated for obstetric complications. Very low-quality evidence.(6)",
      },
      {
        title:
          "For the outcome of ANC use, the quality of the evidence was rated as very low.",
        content: (
          <Grid>
            <Typography gutterBottom>
              Two cluster RCTs (Bhutta et al., 2011; Bjorkman and Svensson,
              2009) and one quasi-experimental study (Malhotra et al., 2005)
              reported on this outcome. The two cluster RCTs report
              non-significant increases in four or more visits in Pakistan
              (Bhutta et al., 2011) and number of ANC visits at the facility per
              month in Uganda (Bjorkman and Svensson, 2009). The
              quasiexperimental study of adolescents in Nepal (Malhotra et al.,
              2005) reports a decrease in mean percentage of women receiving ANC
              in the intervention group. Very low-quality evidence.(9-11)
            </Typography>
            <Typography gutterBottom>
              Seven observational studies also reported on this outcome. The
              controlled before and after studies report increases but no
              significant differences between control and intervention sites
              (Kaseje et al., 2010; Kaufman, Liu and Fang, 2012). The before and
              after studies all report increased ANC use following the
              intervention (Ahluwalia et al., 2010; Paxman et al., 2005; Sood et
              al., 2004; Harkins et al., Peru, 2008; Purdin, Khan and Saucier,
              2009). Studies were conducted in a range of countries from low
              income (Tanzania, Kenya) to middle income (Pakistan, Indonesia,
              China, India, Peru) and with varying populations including rural
              poor communities (Ahluwalia et al., 2010; Kaseje et al., 2010;
              Kaufman, Liu and Fang, 2012; Paxman et al., 2005; Sood et al.,
              2004), peri-urban migrant poor (Harkins et al., Peru 2008) and
              Afghan refugee women (Purdin, Khan and Saucier, 2009). The
              community participation interventions are diverse and the level of
              participation in planning, implementing and evaluating programmes
              varies across the studies. In some studies community participation
              is one small component of larger safe motherhood or maternal and
              newborn health programmes (Ahluwalia et al., 2010; Harkins et al.,
              2008; Paxman et al., 2005; Purdin, Khan and Saucier, 2008; Sood et
              al., 2004), while in others community participation was the main
              focus of the intervention, specifically communitybased monitoring
              via report cards (Bjorkman and Svensson, 2009), community dialogue
              (Kaseje et al., 2010) or more general participatory approaches to
              improve services for specific groups (Kaufman, Liu and Fang,
              2012). All studies report ANC use but use different measures. Some
              report number of women reporting four or more visits (Harkins et
              al., 2009; Kaseje et al., 2010; Sood et al., 2004), others report
              complete ANC (defined as three or more visits) (Paxman et al.,
              2005; Purdin, Khan and Saucier, 2009), one reports prenatal care
              before 20 weeks (Ahluwalia et al., 2010) and other descriptive
              measures (Kaufman, Liu and Fang, 2012). Very low-quality
              evidence.(7, 12-14, 16-18)
            </Typography>
          </Grid>
        ),
      },
      {
        title:
          "For the outcome of postpartum visits for women, the quality of the evidence was rated as very low.",
        content: (
          <Grid>
            <Typography gutterBottom>
              Purdin, Khan and Saucier (2009) showed that coverage of postpartum
              care within 72 hours of birth increased over time following the
              intervention in a setting with Afghan refugee women in Pakistan.
              Very low-quality of evidence.(14)
            </Typography>
            <Typography gutterBottom>
              In addition to the critical and important outcomes, the GDG had
              requested information on the effect of the intervention on woman's
              satisfaction with services. Theree studies implied improved
              satisfaction but one assessed satisfaction and determined it could
              not be attributed to the intervention.
            </Typography>
          </Grid>
        ),
      },
      {
        title:
          "Further information and considerations related to this recommendation can be found in the WHO guidelines, available at:",
        content: (
          <Link href="http://apps.who.int/iris/bitstream/10665/172427/2/9789241508742_tables_eng.pdf?ua=1">
            http://apps.who.int/iris/bitstream/10665/172427/2/9789241508742_tables_eng.pdf?ua=1
          </Link>
        ),
      },
    ],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "Participation in programme planning should ideally be implemented through either existing or adapted structures and platforms that enable planning at the local level (reflecting the local reality/needs) and input to planning at higher levels, with monitoring and evaluation and ongoing planning/replanning.",
        "Discussion at the national and local levels is required for adaptation to context and to ensure a meaningful degree of decentralization and the appropriate level of budget and resources assigned to support the process.",
        "Community participation in planning and implementation of MNH programmes is a dynamic process that can strengthen community capacity in many ways such as helping communities to effectively identify their assets, needs, and problems; plan together as a group; obtain and manage resources; problem-solve; use data to monitor progress and make decisions; and resolve or manage conflict. It takes time to build trust and for community members to develop the skills and processes necessary to plan and implement effectively as a group. However, once relationships and trust have been established and skills have been learned, they can be applied to address other community priorities. When engaging in these types of processes, those in supporting roles need to be aware of the changing context and adjust their support accordingly.",
        "Strategies that have been pre-determined by programmes that then mobilize communities to implement them risk not being owned by communities and may be abandoned in the future. This approach also limits opportunities for communities to learn how to identify emerging priority challenges over time and plan together to address them to improve MNH and other health issues.",
        "It is essential to have linkages between the different levels of the health system and between the different institutions involved in planning, implementation, monitoring and evaluation.",
        "An enabling/supportive environment is considered a key facilitator of implementation where the public sector/local government is involved in the multiorganization partnership.",
        "NGOs, which often have experience facilitating and organizing community involvement, can facilitate the process and provide technical support to develop communities' capacity to plan and implement interventions. The work should be linked to government services and embedded in ongoing processes, as appropriate to the local context. Community health workers can also play a vital role in linking communities and health services.",
        "Ensuring women's participation is extremely important as women are key stakeholders and directly affected by the issues discussed. Innovative mechanisms may be needed to ensure inclusion of women and other key stakeholders who may otherwise be excluded. This may require reflection on existing power dynamics to find appropriate and effective ways to address any power imbalances that would prevent their voices being elicited or heard.",
        "Facilitation of the process to ensure the discussion and interaction between the different actors and stakeholders is important. Programme implementation should include training facilitators in key MNH topics, data interpretation, communication, conflict resolution and management.",
        "Data presentation must be comprehensible and the communication of health issues should be adapted for the audience, who may have no previous health sector experience. For instance, culturally appropriate materials available in a local language suitable for individuals with low literacy/numeracy skills should be developed where needed.",
        "Health committees exist in many countries and can prove effective for monitoring progress, identifying and solving problems and re-planning as necessary. The purpose of the committee should be clear to all members and basic good group processes should be in place. It is important to review existing committees to see how they can become more effective, taking into account the extent to which community members are involved and how they can strengthen their planning and monitoring processes.",
        "Formal written action plans should be developed, setting out clearly assigned roles and responsibilities to clarify agreements and hold communities and services accountable for their activities.",
      ],
    },
    additionalLists: [],
    researchImplications: {
      title: "Research implications",
      text: "The GDG identified several areas for future research:",
      content: [
        "Community participation is often one intervention in a complex package of interventions. While RCT design may be helpful to determine effectiveness and outcomes, alternative designs are needed to look at processes and to understand change.",
        "Determining the most effective ways to share data that are accessible to those with low literacy and numeracy skills.",
        "Establishing the gender considerations that are necessary in planning processes, such as when and how do men and women participate and under what circumstances and in what contexts it might be useful to have single sex versus mixed sex groups. How cultural beliefs and practices influence the planning processes (e.g., how issues such as causality are perceived).",
        "How and when to share strategies that have been found to be effective in other settings with community planning groups.",
        "How and when to include community participation at the programme design stage.",
        "Determining the social effects of programmes where household decision-makers participate at a higher level while the pregnant women's level of participation is only to receive key messages.",
        "Establishing the values and preferences of community members who participate in the planning and implementation of MNH programmes.",
        "Assessing the impact of experiences in high-income countries and identifying the lessons learned.",
      ],
    },
    relatedLinks: {
      title: "Related links",
      text: (
        <Typography>
          WHO recommendations on antenatal care for a positive pregnancy
          experience (2015) -{" "}
          <Link href="https://web.archive.org/web/20201105052133/http:/apps.who.int/iris/bitstream/handle/10665/172427/9789241508742_report_eng.pdf;jsessionid=D2FE71B72516ADEFBC5B03B8D22113F9?sequence=1">
            full document
          </Link>{" "}
          and{" "}
          <Link href="https://web.archive.org/web/20201105052133/http:/apps.who.int/iris/bitstream/10665/172427/2/9789241508742_tables_eng.pdf?ua=1">
            evidence table
          </Link>
        </Typography>
      ),
      links: [
        {
          href: "https://web.archive.org/web/20201031220559/https://apps.who.int/iris/bitstream/handle/10665/255760/9789241565493-eng.pdf?sequence=1",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors",
        },
        {
          href: "https://web.archive.org/web/20201030093023/https://apps.who.int/iris/bitstream/handle/10665/249580/9789241549356-eng.pdf;jsessionid=7B8E18468A424E0301261B2CA6BCB324?sequence=1",
          text: "Pregnancy, Childbirth, Postpartum and Newborn Care: A guide for essential practice",
        },
        {
          href: "https://web.archive.org/web/20201103090439/https://www.who.int/reproductivehealth/en/",
          text: "WHO Programmes: Sexual and Reproductive health",
        },
        {
          href: "https://web.archive.org/web/20201111195601/https://www.who.int/health-topics/maternal-health#tab=tab_1",
          text: "Maternal Health",
        },
        {
          href: "https://web.archive.org/web/20201020052824/https://www.who.int/health-topics/newborn-health/#tab=tab_1",
          text: "Newborn Health",
        },
      ],
    },
    additionalLinks: (
      <Grid>
        <Typography fontWeight={"bold"} gutterBottom>
          Supporting systematic reviews:{" "}
        </Typography>
        <Typography gutterBottom>
          Marston C, Sequeira M, Portela A, Cavallaro F, CR M. Systematic
          Reviews on community participation and maternity care seeking for
          maternal and newborn health.
        </Typography>
      </Grid>
    ),
    references: {
      title: "References",
      content: [
        "Working with individuals, families, and communities to improve maternal and newborn health. Geneva: World Health Organization; 2010. [26 November 2014] http://wwwwhoint/maternal_child_adolescent/documents/who_fch_rhr_0311/en/.",
        "The Ottawa Charter for Health Promotion [website]. Geneva: World Health Organization; 1986. [30 March 2014] http://wwwwhoint/healthpromotion/conferences/previous/ottawa/en/.",
        "WHO Handbook for Guideline Development - 2nd edition. Geneva: World Health Organization; 2014. World Health Organization. 2014.",
        "WHO recommendations on health promotion interventions for maternal and newborn health 2015: World Health Organization; 2015.",
        "Marston C, Renedo A, McGowan CR, Portela A. Effects of community participation on improving uptake of skilled care for maternal and newborn health: a systematic review. PloS one. 2013;8(2):e55012.",
        "Ahluwalia IB, Schmid T, Kouletio M, Kanenda O. An evaluation of a community-based approach to safe motherhood in northwestern Tanzania. International journal of gynaecology and obstetrics: the official organ of the International Federation of Gynaecology and Obstetrics. 2003;82(2):231-40.",
        "Ahluwalia IB, Robinson D, Vallely L, Gieseker KE, Kabakama A. Sustainability of community-capacity to promote safer motherhood in northwestern Tanzania: what remains? Global health promotion. 2010;17(1):39-49.",
        "Iap2 public participation spectrum. Wollongong, Australia: International Association for Public Participation; [23 January 2015]. year https://wwwiap2orgau/documents/item/84.",
        "Björkman M, Svensson J. Power to the people: evidence from a randomized field experiment on community-based monitoring in Uganda. The Quarterly Journal of Economics. 2009;124(2):735-69.",
        "Bhutta ZA, Soofi S, Cousens S, Mohammad S, Memon ZA, Ali I, et al. Improvement of perinatal and newborn care in rural Pakistan through community-based strategies: a cluster-randomised effectiveness trial. Lancet (London, England). 2011;377(9763):403-12.",
        "Malhotra A, Mathur S, Pande R, E. R. Nepal: the distributional impact of participatory approaches on reproductive health for disadvantaged youths. Washington, DC: The World Bank; 2005 [7 January 2015] https://openknowledgeworldbankorg/bitstream/handle/10986/13687/324190HNP.... 2005.",
        "Kaseje D, Olayo R, Musita C, Oindo CO, Wafula C, Muga R. Evidence-based dialogue with communities for district health systems' performance improvement. Global public health. 2010;5(6):595-610.",
        "Kaufman J, Liu Y, Fang J. Improving reproductive health in rural China through participatory planning. Global public health. 2012;7(8):856-68.",
        "Purdin S, Khan T, Saucier R. Reducing maternal mortality among Afghan refugees in Pakistan. International journal of gynaecology and obstetrics: the official organ of the International Federation of Gynaecology and Obstetrics. 2009;105(1):82-",
        "Sood S, Chandra U, Mishra P, Neupane S. Measuring the effects of behavior change interventions in Nepal with population-based survey results. 2004.",
        "Harkins T, Drasbek C, Arroyo J, McQuestion M. The health benefits of social mobilization: experiences with community-based Integrated Management of Childhood Illness in Chao, Peru and San Luis, Honduras. Promotion & education. 2008;15(2):15-20.",
        "Sood S, Chandra U, Palmer A, Molyneux I. Measuring the effects of the SIAGA behavior change campaign in Indonesia with population-based survey results. 2004.",
        "Paxman JM, Sayeed A, Buxbaum A, Huber SC, Stover C. The India Local Initiatives Program: a model for expanding reproductive and child health services. Studies in family planning. 2005;36(3):203-20.",
      ],
    },
  },
  {
    id: "41",
    img: img77,
    imgAlt: "a woman talking to a group of other women",
    title:
      "WHO recommendation on interventions to promote awareness of human, sexual and reproductive rights and the right to access quality skilled care",
    date: "29 August 2021",
    recommendation: {
      title: "Recommendation",
      content: [
        "Because of the paucity of evidence available, additional research is recommended. ",
      ],
    },
    recommendationItalic: "(Recommended)",
    publicationHistory: {
      firstPublished: "May 2015",
      updated: "No update planned",
      assessedAsUpToDate: "May 2015",
    },
    remarks: {
      title: "Remarks",
      content: [
        "The GDG affirms as a matter of principle the importance for MNH programmes to inform women about their right to health and to access quality skilled care and to continue to empower them to access such care.",
      ],
    },
    background: {
      title: "Background",
      content: [
        "In 2003 the World Health Organization (WHO) published “Working with individuals, families and communities to improve maternal and newborn health”, (1) the IFC framework that promotes integrating the health promotion approach set out in the Ottawa Charter (2) into national maternal and newborn health (MNH) strategies.",
        "The IFC Framework was developed in response to analysis and global statements indicating that as well as strengthening services, MNH strategies need to improve the capacity of individuals, families and communities to provide appropriate care for pregnant women, mothers, and newborns in the home. It also addresses the reasons – over and above what happens in clinical services – why women do not reach good quality skilled care during pregnancy, childbirth and after birth. The Ottawa Charter’s health promotion components(2) were translated into MNH programme language and 12 promising interventions – identified through reviews of country experiences and the literature – were categorized into four priority areas. Community and intersectoral participation was recommended to guide implementation. Exact interventions to be adapted by country programmes were to be identified through local assessment; however, the framework highlighted the need for interventions to address all four priority areas at the same time. All six WHO Regions integrated this guidance into the regional maternal mortality reduction strategies.",
        "Human rights are considered as a guiding principle of the IFC Framework and within WHO strategies as a fundamental component of maternal and newborn health. Sexual and reproductive health (SRH) programmes and MNH programmes support the principle that women who are aware of their sexual and reproductive rights are in a better position to exercise their reproductive choices and determine how they negotiate family and community dynamics, how they are able to access health care and how they are treated by health services.(3)",
        "Families, communities, health providers and other stakeholders who know and respect human rights, in particular sexual and reproductive health and rights, will support women in better taking care of themselves and their children. Therefore, in addition to working with an individual pregnant woman, programmes often address her family, the broader community, service providers, managers and other health systems stakeholders to increase awareness of the right to health or to skilled care. Programmatic inputs include education materials or other visual aids, mass media campaigns and work with groups or public meetings and often focus content on what should be improved to ensure quality services.",
        "Respecting women’s human rights, their sexual and reproductive health and rights and their rights to access quality care are part of human rights-based approaches to health that are affirmed and recommended by national governments and international consensus agreements, including those endorsed by WHO and the United Nations more broadly, such as the Programme of Action of the International Conference on Population and Development (1994) and the Beijing Declaration and Platform of Action (1995). Considering the Office of the High Commissioner for Human Rights’ Technical guidance on a human rights-based approach to maternal mortality and morbidity(4) as well as the UN Commission on Information and Accountability, the importance of considering interventions that promote awareness of rights for women as a fundamental part of maternal newborn health is timely.",
      ],
    },
    methods: {
      title: "Methods",
      content: [
        "The recommendation was developed using standardized operating procedures in accordance with the process described in the “WHO handbook for guideline development”, guided by the GRADE approach.(5) Outcomes used for this recommendation were aligned with the prioritized outcomes from the WHO recommendations on health promotion interventions for maternal and newborn health (2015).(6)",
        "A systematic review was conducted on interventions to promote awareness of human rights, sexual reproductive rights and/or the right to quality skilled care.(7) In the review, randomized controlled trials relevant to the key question were screened by review authors, and data on relevant outcomes and comparisons were extracted. Evidence profiles (in the form of GRADE tables) were prepared for comparisons of interest, including the assessment and judgments for each outcome, and the estimated risks.",
        "WHO convened a Guideline Development Group (GDG) meeting on recommendations on health promotion interventions for maternal and newborn health where this recommendation was developed. The GDG comprised of a group of independent experts, who used the evidence profiles to assess evidence on effects on the pre-specified outcomes. GDG members discussed the balance between desirable and undesirable effects, overall quality of supporting evidence, values and preferences of stakeholders, resource requirements, cost-effectiveness, acceptability, feasibility and equity, to formulate the recommendation. Remarks were added to clarify the recommendation, and aid implementation.",
        <Typography>
          {" "}
          Further information on procedures for developing this recommendation
          are available{" "}
          <Link
            href="http://web.archive.org/web/20230207083015/http://apps.who.int/iris/bitstream/handle/10665/172427/9789241508742_report_eng.pdf;jsessionid=0888E6D3E74DA6A9DE077D118B6EF1DB?sequence=1"
            target="_blank"
          >
            here.
          </Link>
        </Typography>,
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following question:",
      content: [
        "Should alternative models of ANC with four visits (FANC) be recommended instead of standard care of eight visits for the ANC of healthy pregnant women?",
      ],
    },
    evidenceSummary: {
      title: "Evidence summery",
      content: [
        "Evidence on interventions to promote awareness of human rights, sexual reproductive rights and/or the right to quality skilled care was extracted from a systematic review conducted by George, Branchini and Portela (forthcoming).(7) In the literature many studies were found mentioning rights or discussing a rights-based approach. Most references discussed the importance of rights or the violation of rights or interventions but few documented promoting awareness of rights. Documents that did detail promoting awareness of rights largely did so without any explicit methodology or any tracking of effects on health outcomes. Three studies were found to have health outcome data to evaluate the effects of interventions to promote awareness of human rights and/or sexual reproductive rights and/or the right to quality skilled care. These include two cluster RCTs and one before and after study. Two of these studies report qualitative findings. There was not sufficient evidence to determine which of the modes of delivery of the intervention were most effective. Outcome measures for two of the studies included birth in a facility and all studies reported on use of antenatal care.",
        "For the outcome of birth in a facility, the quality of the evidence ranged from very low to moderate.",
        "One cluster RCT (Bjorkman and Svensson, 2009) conducted in Uganda reports a significant increase in facility births, reported as additional births at the facility on average per month. Moderate-quality evidence.(8)",
        "One pre-post programme evaluation (Sinha, 2008) reported on a significant increase in the number of women giving birth in primary health centres and government hospitals, with a significant decrease in births at private clinics. Very low-quality evidence.(9)",
        "For the outcome of use of antenatal care, the quality of evidence was rated as very low.",
        "Two cluster RCTs had varied results. One trial in Uganda (Bjorkman and Svensson, 2009) reports a non-significant increase in additional ANC visits per month on average. The other in India (Pandey et al., 2007) reports a significant increase in prenatal examinations at year 1. Very low-quality evidence.(8)",
        "One pre-post programme evaluation (Sinha, 2008) reports improved ANC care-seeking with significant differences between baseline and endline in women who made at least one and women who made more than three visits. Very low-quality evidence.(9)",
        <Typography>
          {" "}
          Further information and considerations related to this recommendation
          can be found in the WHO guidelines, available at:{" "}
          <Link
            href="http://apps.who.int/iris/bitstream/10665/172427/2/9789241508742_tables_eng.pdf?ua=1"
            target="_blank"
          >
            here.
          </Link>
        </Typography>,
      ],
    },
    maternalOutcomes: {
      title: "",
      content: "",
    },
    fetalAndNeonatalOutcomes: [],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "Promotion of awareness of rights may be better documented for some areas of SRH including family planning, post-abortion care, FGM and the right to have children for women living with HIV. Lessons learned could be reviewed from these areas and applied to MNH programmes.",
        "Awareness-raising on human rights should be an ongoing process involving women, families and communities through the life course and not limited only to the period of pregnancy, childbirth and after birth.",
        "Information regarding human rights should be provided in language and formats accessible to them and should target women both at the facilities and at the community level. Learning how to put rights awareness into practice is necessary, i.e., either through peer-counselling and/or practising to gain skills in negotiation, etc.",
        "Materials used to raise awareness of rights do not necessarily raise awareness by themselves. They are effective when they act as tools to foster interactive learning and dialogue within a specific context.",
        "Rights awareness-raising efforts may need the flexibility to embed themselves strategically within existing broader approaches that may already be facilitating community demand for services.",
        "To link the promotion of awareness of rights to realizing change in terms of access to maternal and newborn health services, it is vital for initiatives to be grounded in concrete actions and operational plans, with adequate follow-up and monitoring and evaluation to ensure that they realize goals and are not solely aspirational. Monitoring and accountability mechanisms oriented to rights promotion should be available within the health systems to review and respond to changes as needed.",
        "Awareness of rights is not just about stakeholders being more informed, but is also about supporting a critical consciousness that builds individual and collective capacity to support actions that realize rights to more accessible and responsive care. Initiatives therefore need to support the capacity of both rights holders and rights bearers.",
        "Creating partnerships and negotiating strategic alliances are neither easy nor predictable processes: they require fostering a common language and clarifying the rules of engagement to counter power imbalances. Nonetheless, those efforts that pursue multi-level, stakeholder and sectoral pathways to underpin the promotion of rights awareness are likely to build synergies that sustain and transform awareness into critical consciousness and action that supports improved access to quality services and better health outcomes.",
        "Training and capacity-building of health care providers on human rights is essential to ensure promotion and protection of the human rights ofwomen to ensure women's access to quality of care. Health teams need to understand the meaning of the right to quality of care and the right to health. A situation analysis to understand the context in which providers work in is essential, as the lack of time, lack of structural inputs and large patient loads can inhibit provider motivation and ability to support promotion of rights awareness.",
        "It is important to motivate health care providers to integrate a rights perspective in their own practice as a means of promoting the right to health for the population but also to improve the work environment.",
      ],
    },
    additionalLists: [],
    researchImplications: {
      title: "Research implications",
      text: "The GDG identified that further research on the following high-priority questions is needed:",
      content: [
        "Further consideration of the opportunity to explicitly integrate promoting awareness of rights and compliance with rights into current health promotion efforts is warranted. Unless one is not using the explicit language of rights due to strategic reasons, many projects that build individual and community capacity to improve demand for and access to quality maternal health services can lend themselves to also promoting awareness of rights and measure its effect as part of this package. The mapping of potential intervention combinations that need to be further explored and evaluated is recommended. Based on this mapping, prioritization of a research agenda and operational steps to fulfil it are required.",
        "Currently the evidence base for how this intervention supports improved health outcomes is limited to rural populations: primarily India and one study in Uganda. We need research evaluating experiences from other regions of the world, in different settings (e.g., urban) and with varied populations that may face particular forms of discrimination and oppression (migrants, nomadic groups, young people, institutionalized populations, people with disabilities, etc.) in order to better understand how to reach these populations.",
        "Qualitative research on values and preferences regarding interventions that promote awareness of rights, particularly on how these rights are understood, how they are adapted or not and applied to settings where rights may not be understood or rights may have different meanings is needed. Further examination of harms, benefits, unintended consequences and ethical issues that arise when promoting awareness of rights that contest existing power relations is required.",
        "Existing interventions that promote awareness of rights need to be better described. Projects must improve documentation of how they promoted awareness of rights, with whom and in what contexts. Numerous projects stated they were promoting awareness of rights but had very little description of how this was done. Guidance on supporting process evaluations and case studies for this type of interventions would be helpful. This would include the application of theories of change informed by on the ground experience and theoretical frameworks that help us to better understand the pathways, inputs and adaptations required for this kind of intervention.",
        "Funding and partnerships need to facilitate more and better quality evaluations of interventions that promote awareness of rights. Many examples of promoting awareness of rights for quality maternal health services exist but they are not always evaluated or, if evaluated, not in ways that support quality evidence generation. Standardizing measures used for the monitoring and evaluation of interventions that promote rights awareness, specifying the range of health and social outcomes that are of interest is critical so that evidence is comparable across studies. Improved capacity-building and support for the development of research methodologies more suited to these types of interventions that tracks change longitudinally, in participatory ways, that understands complexity and takes into consideration equity concerns is necessary.",
      ],
    },
    relatedLinks: {
      title: "Related links",
      text: (
        <Typography>
          <Link href="http://web.archive.org/web/20230207083015/https://extranet.who.int/rhl/guidelines/who-recommendations-health-promotion-interventions-maternal-and-newborn-health-2015#overlay=node/118073/edit">
            WHO recommendations on health promotion interventions for maternal
            and newborn health (2015)
          </Link>
          <Link href="https://web.archive.org/web/20200915144755/https://apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1">
            full document
          </Link>{" "}
          and{" "}
          <Link href="https://web.archive.org/web/20201219124651/https://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            evidence table
          </Link>
        </Typography>
      ),
      links: [
        {
          href: "https://web.archive.org/web/20201031220559/https://apps.who.int/iris/bitstream/handle/10665/255760/9789241565493-eng.pdf?sequence=1",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors",
        },
        {
          href: "https://web.archive.org/web/20201030093023/https://apps.who.int/iris/bitstream/handle/10665/249580/9789241549356-eng.pdf;jsessionid=7B8E18468A424E0301261B2CA6BCB324?sequence=1",
          text: "Pregnancy, Childbirth, Postpartum and Newborn Care: A guide for essential practice",
        },
        {
          href: "http://web.archive.org/web/20230123213043/https://www.who.int/reproductivehealth/en/",
          text: "WHO Programmes: Sexual and Reproductive health",
        },
        {
          href: "http://web.archive.org/web/20230507050243/https://www.who.int/maternal-health/en/",
          text: "Maternal Health",
        },
        {
          href: "http://web.archive.org/web/20211023133702/https://www.who.int/maternal_child_adolescent/newborns/en/",
          text: "Newborn Health",
        },
      ],
    },
    references: {
      title: "References",
      content: [
        "Working with individuals, families, and communities to improve maternal and newborn health. Geneva: World Health Organization; 2010. [26 November 2014] http://wwwwhoint/maternal_child_adolescent/documents/who_fch_rhr_0311/en/.",
        "The Ottawa Charter for Health Promotion [website]. Geneva: World Health Organization; 1986. [30 March 2014] http://wwwwhoint/healthpromotion/conferences/previous/ottawa/en/.",
        "Mirsky J. Birth rights: new approaches to safe motherhood: Panos Institute; 2001.",
        "Technical guidance on the application of a human-rights based approach to the implementation of policies and programmes to reduce preventable maternal morbidity and mortality. New York: United Nations Human Rights Council; 2012. [26 November 2014] http://www2ohchrorg/english/issues/women/docs/AHRC2122_enpdf.",
        "WHO Handbook for Guideline Development - 2nd edition. Geneva: World Health Organization; 2014. World Health Organization. 2014.",
        "WHO recommendations on health promotion interventions for maternal and newborn health 2015: World Health Organization; 2015.",
        "George A, Branchini C, A. P. Interventions that promote awareness of human rights, sexual and reproductive rights and/or rights to access quality care as a means to increase demand for or use of health care to improve maternal and newborn health outcomes: A systematic review. (Unpublished).",
        "Björkman M, Svensson J. Power to the people: evidence from a randomized field experiment on community-based monitoring in Uganda. The Quarterly Journal of Economics. 2009;124(2):735-69.",
        " Sinha D. Empowering communities to make pregnancy safer: an intervention in rural Andhra Pradesh: Population Council; 2008.",
      ],
    },
  },
];
