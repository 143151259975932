import {Grid, styled} from "@mui/material";
import {prefixClasses} from "../common/Theme/utils";

const classes = {
    root: "root",
    sideLine: "sideLine",
    sectionWrapper: "sectionWrapper",
    sectionFirstContent: "sectionFirstContent",
    sectionFirstText: "sectionFirstText",
    textHeading: "textHeading",
    textBold: "textBold",
    textSmall: "textSmall",
    swiperCardGradient: "swiperCardGradient",
    swiperCardHeading: "swiperCardHeading",
    swiperCardImage: "swiperCardImage",
    swiperCardButtons: "swiperCardButtons",
    cardButton: "cardButton",
    cardButtonDownload: "cardButtonDownload",
    cardButtonIcon: "cardButtonIcon",
    swiperOne: "swiperOne",
    swiperOneCard: "swiperOneCard",
    swiperOneCardText: "swiperOneCardText",
    swiperOneElementFirst: "swiperOneElementFirst",
    swiperOneElementSecond: "swiperOneElementSecond",
    swiperOneElementThird: "swiperOneElementThird",
    swiperOneElementFourth: "swiperOneElementFourth",
    swiperOneElementFifth: "swiperOneElementFifth",
    sectionSecondWrapper: "sectionSecondWrapper",
    sectionSecondContent: "sectionSecondContent",
    sectionSecondHeading: "textSecondHeading",
    sectionSecondText: "sectionSecondText",
    textSecondHeading: "textSecondHeading",
    swiperTwoCard: "swiperTwoCard",
    swiperTwoCardText: "swiperTwoCardText",
    swiperSecondElementFirst: "swiperSecondElementFirst",
    swiperSecondElementSecond: "swiperSecondElementSecond",
    swiperSecondElementThird: "swiperSecondElementThird",
    swiperSecondElementFourth: "swiperSecondElementFourth",
    swiperSecondElementFifth: "swiperSecondElementFifth",
    linkFont: "linkFont",
    sectionThirdWrapper: "sectionThirdWrapper",
    swiperCardImageOne: "swiperCardImageOne",
    swiperCardImageTwo: "swiperCardImageTwo",
    swiperCardImageThree: "swiperCardImageThree",
    swiperCardImageFour: "swiperCardImageFour",
};

prefixClasses("PartFourNew", classes);

export const StyledPartFourNew = styled(Grid)(({ theme }) => ({
    [`&.${classes.root}`]: {
        padding: '48px 0 0 0',
        width: '100%',
        backgroundImage: "url('/partThree-bg.jpg')",
        backgroundSize: '100%',
        backgroundPositionX: 'left',
        backgroundPositionY: 'top',
        backgroundRepeat: 'no-repeat',
    },
    [`& .${classes.sideLine}`]: {
        height: '2.5px',
        position: 'relative',
        width: '100%',
        bottom: '18px',
        right: 'calc(100% + 60px)',
        backgroundColor: '#008bc8',
        [theme.breakpoints.down('mobileXL')]: {
            display: 'none',
        },
    },
    [`& .${classes.sectionWrapper}`]: {
        padding: '0 0px',
        margin: '0 auto',
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.down('xl')]: {
            maxWidth: '1140px',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '960px',
        },
        [theme.breakpoints.down('mobileXL')]: {
            maxWidth: '720px',
        },
        [theme.breakpoints.down('mobileLG')]: {
            maxWidth: '540px',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '450px',
        },
        [theme.breakpoints.down('mobileMD')]: {
            maxWidth: '360px',
        },
        [theme.breakpoints.down('mobileSM')]: {
            maxWidth: '300px',
        },
    },
    [`& .${classes.sectionFirstContent}`]: {
        flexGrow: 0,
        flexShrink: 0,
        padding: '0px 7.5px',
        flexBasis: '83.33%',
        maxWidth: '83.33%',
        marginLeft: '8.33%',
        [theme.breakpoints.down('mobileXL')]: {
            flex: 'auto',
            maxWidth: '100%',
            marginLeft: '0',
        },
    },
    [`& .${classes.sectionFirstText}`]: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 0px',
    },
    [`& .${classes.textHeading}`]: {
        color: '#d2e0e6',
        fontSize: '1.875rem',
        textTransform: 'uppercase',
        marginBottom: '24px',
        fontWeight: '700',
    },
    [`& .${classes.textBold}`]: {
        color: '#1d242e',
        fontSize: '1.875rem',
        [theme.breakpoints.down('mobileMD')]: {
            fontSize: '1.5rem',
        },
        marginBottom: '48px',
    },
    [`& .${classes.textSmall}`]: {
        color: '#1d242e',
        fontSize: '1rem',
        marginBottom: '32px',
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
    },
    [`& .${classes.swiperCardGradient}`]: {
        width: '100%',
        height: '100%',
        background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%, #1e252f 90%)',
        padding: '20px',
        margin: '0px',
        color: '#fff',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        "&:hover": {
            background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%, #008bc8 80%)',
        },
    },
    [`& .${classes.swiperCardHeading}`]: {
        fontSize: '1.875rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.5rem',
        },
        fontFamily: 'LatoBold',
        lineHeight: '1.2',
        textAlign: 'center',
        fontWeight: '500',
        marginBottom: '8px',
    },
    [`& .${classes.swiperCardImage}`]: {
        backgroundSize: 'cover',
        backgroundPositionX: 'center',
        height: '260px',
        width: '100%',
        padding: '0px',
        borderRadius: '5px',
    },
    [`& .${classes.swiperCardButtons}`]: {
        justifyContent: 'center',
        marginTop: '8px',
        gap: '5px',
    },
    [`& .${classes.cardButton}`]: {
        padding: '14px 20px',
        fontSize: '0.75rem',
        fontFamily: 'LatoHeavy',
        [theme.breakpoints.down('mobileSM')]: {
            padding: '7px 10px',
            fontSize: '0.5rem',
        },
        lineHeight: '1',
        color: '#212529',
        backgroundColor: '#fff',
        borderColor: '#fff',
        opacity: '0.8',
        textTransform: 'uppercase',
        '&:hover': {
            backgroundColor: '#d2e0e6',
            borderColor: '#fff',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#d2e0e6',
            borderColor: '#fff',
        },
    },
    [`& .${classes.cardButtonIcon}`]: {
        marginRight: '8px',
        fontSize: '0.9rem',
    },
    [`& .${classes.cardButtonDownload}`]: {
        width: '100%',
        height: '100%',
    },
    [`& .${classes.swiperOne}`]: {
        marginBottom: '24px',
    },
    [`& .${classes.swiperOneCard}`]: {
        height: '260px',
        overflow: 'hidden',
        width: '39%',
        [theme.breakpoints.down('mobileXL')]: {
            width: '66%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    [`& .${classes.swiperOneCardText}`]: {
        textAlign: 'center',
        marginTop: '48px',
        lineHeight: '1.5',
        fontSize: '1rem',
        fontFamily: 'LatoRegular',
        [theme.breakpoints.down('sm')]: {
            width: '400px',
            marginTop: '36px',
            marginRight: 'auto',
            marginLeft: 'auto',
        },
        [theme.breakpoints.down('mobileMD')]: {
            width: '330px',
        },
    },
    [`& .${classes.swiperCardImageOne}`]: {
        backgroundImage: "url('/swiperCardImageOne.png')",
        backgroundPositionY: '25%',
    },
    [`& .${classes.swiperCardImageTwo}`]: {
        backgroundImage: "url('/swiperCardImageTwo.png')",
        backgroundPositionY: '50%',
    },
    [`& .${classes.swiperCardImageThree}`]: {
        backgroundImage: "url('/swiperCardImageThree.png')",
        backgroundPositionY: '40%',
    },
    [`& .${classes.swiperCardImageFour}`]: {
        backgroundImage: "url('/swiperCardImageFour.png')",
        backgroundPositionY: '25%',
    },
    [`& .${classes.swiperOneElementFirst}`]: {
        backgroundImage: "url('/swiperFirstPhoto-1.png')",
        backgroundPositionY: '25%',
    },
    [`& .${classes.swiperOneElementSecond}`]: {
        backgroundImage: "url('/swiperFirstPhoto-2.png')",
        backgroundPositionY: '40%',
    },
    [`& .${classes.swiperOneElementThird}`]: {
        backgroundImage: "url('/swiperFirstPhoto-3.png')",
        backgroundPositionY: '40%',
    },
    [`& .${classes.swiperOneElementFourth}`]: {
        backgroundImage: "url('/swiperFirstPhoto-4.png')",
    },
    [`& .${classes.swiperOneElementFifth}`]: {
        backgroundImage: "url('/swiperFirstPhoto-5.png')",
        backgroundPositionY: '50%',
    },
    [`& #swiperFirst .swiper-button-prev`]: {
        top: '356px',
        left: 'calc((61vw - 200px)/2)',
        [theme.breakpoints.down('lg')]: {
            top: '366px',
        },
        [theme.breakpoints.down('mobileXL')]: {
            top: '356px',
            left: 'calc((34vw - 120px)/2)',
        },
        [theme.breakpoints.down('mobileLG')]: {
            top: '376px',
        },
        [theme.breakpoints.down('sm')]: {
            top: '366px',
            left: 'calc((34vw - 140px)/2)',
        },
        [theme.breakpoints.down('mobileMD')]: {
            top: '376px',
            left: 'calc((34vw - 120px)/2)',
        },
        [theme.breakpoints.down('mobileSM')]: {
            display: 'none',
        },
        color: '#000',
    },
    [`& #swiperFirst .swiper-button-next`]: {
        top: '356px',
        right: 'calc((61vw - 200px)/2)',
        [theme.breakpoints.down('lg')]: {
            top: '366px',
        },
        [theme.breakpoints.down('mobileXL')]: {
            top: '356px',
            right: 'calc((34vw - 120px)/2)',
        },
        [theme.breakpoints.down('mobileLG')]: {
            top: '376px',
        },
        [theme.breakpoints.down('sm')]: {
            top: '366px',
            right: 'calc((34vw - 140px)/2)',
        },
        [theme.breakpoints.down('mobileMD')]: {
            top: '376px',
            right: 'calc((34vw - 120px)/2)',
        },
        [theme.breakpoints.down('mobileSM')]: {
            display: 'none',
        },
        color: '#000',
    },
    [`& .swiper-slide-active`]: {
        height: '420px',
        [theme.breakpoints.down('lg')]: {
            height: '440px',
        },
        [theme.breakpoints.down('md')]: {
            height: '480px',
        },
        [theme.breakpoints.down('mobileXL')]: {
            height: '440px',
        },
        [theme.breakpoints.down('mobileLG')]: {
            height: '460px',
        },
        [theme.breakpoints.down('mobileMD')]: {
            height: '500px',
        },
        overflow: 'hidden',
    },
    [`& .${classes.sectionSecondWrapper}`]: {
        textAlign: 'center',
        color: '#d2e0e6',
        backgroundColor: '#1e252f',
        padding: '48px 0 0 0',
        maxWidth: '100%',
    },
    [`& .${classes.sectionSecondContent}`]: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: '83.33%',
        maxWidth: '83.33%',
        marginLeft: '7%',
        [theme.breakpoints.down('md')]: {
            flexBasis: '100%',
            maxWidth: '100%',
            marginLeft: '0',
            padding: '0',
        },
    },
    [`& .${classes.sectionSecondText}`]: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '24px',
        padding: '0px',
        [theme.breakpoints.down('mobileMD')]: {
            padding: '0 7.5px',
        },
    },
    [`& .${classes.textSecondHeading}`]: {
        fontSize: '1.35rem',
        fontWeight: 600,
        marginBottom: '8px',
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
    },
    [`& .${classes.swiperTwoCard}`]: {
        width: '38.96%',
        [theme.breakpoints.down('md')]: {
            width: '65.79%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        height: '260px',
        overflow: 'hidden',
    },
    [`& .${classes.swiperTwoCardText}`]: {
        textAlign: 'center',
        marginTop: '48px',
        lineHeight: '1.5',
        fontSize: '1rem',
        [theme.breakpoints.down('md')]: {
            marginRight: 'auto',
            marginLeft: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            width: '400px',
            marginTop: '36px',
        },
        [theme.breakpoints.down('mobileMD')]: {
            width: '310px',
        },
        [theme.breakpoints.down('mobileSM')]: {
            width: '300px',
        },
        [theme.breakpoints.down('mobileSM')]: {
            width: '280px',
        },
    },
    [`& .${classes.swiperSecondElementFirst}`]: {
        backgroundImage: "url('/swiperSecondPhoto-1.png')",
        backgroundPositionY: '25%',
    },
    [`& .${classes.swiperSecondElementSecond}`]: {
        backgroundImage: "url('/swiperSecondPhoto-2.png')",
        backgroundPositionY: '40%',
    },
    [`& .${classes.swiperSecondElementThird}`]: {
        backgroundImage: "url('/swiperSecondPhoto-3.png')",
        backgroundPositionY: '40%',
    },
    [`& .${classes.swiperSecondElementFourth}`]: {
        backgroundImage: "url('/swiperSecondPhoto-4.png')",
    },
    [`& .${classes.swiperSecondElementFifth}`]: {
        backgroundImage: "url('/swiperSecondPhoto-5.png')",
    },
    [`& .${classes.linkFont}`]: {
        fontFamily: 'LatoRegular',
    },
    [`& #swiperSecond .swiper-button-prev`]: {
        top: '340px',
        left: 'calc((61vw - 200px)/2)',
        [theme.breakpoints.down('md')]: {
            left: 'calc((34vw - 120px)/2)',
        },
        [theme.breakpoints.down('sm')]: {
            left: 'calc((34vw - 150px)/2)',
        },
        [theme.breakpoints.down('mobileMD')]: {
            left: 'calc((34vw - 120px)/2)',
        },
        [theme.breakpoints.down('mobileSM')]: {
            display: 'none',
        },
        color: '#fff',
    },
    [`& #swiperSecond .swiper-button-next`]: {
        top: '340px',
        right: 'calc((61vw - 200px)/2)',
        [theme.breakpoints.down('md')]: {
            right: 'calc((34vw - 120px)/2)',
        },
        [theme.breakpoints.down('sm')]: {
            right: 'calc((34vw - 150px)/2)',
        },
        [theme.breakpoints.down('mobileMD')]: {
            right: 'calc((34vw - 120px)/2)',
        },
        [theme.breakpoints.down('mobileSM')]: {
            display: 'none',
        },
        color: '#fff',
    },
    [`& #swiperSecondSlide.swiper-slide-active`]: {
        overflow: 'hidden',
        height: '580px',
        [theme.breakpoints.down('lg')]: {
            height: '600px',
        },
        [theme.breakpoints.down('md')]: {
            height: '550px',
        },
        [theme.breakpoints.down('mobileXL')]: {
            height: '600px',
        },
        [theme.breakpoints.down('mobileLG')]: {
            height: '640px',
        },
        [theme.breakpoints.down('mobileMD')]: {
            height: '760px',
        },
        [theme.breakpoints.down('mobileSM')]: {
            height: '780px',
        },
    },
    [`& .${classes.sectionThirdWrapper}`]: {
        textAlign: 'center',
        color: '#1e252f',
        backgroundImage: "url('/partThree-bg.jpg')",
        backgroundSize: '100%',
        backgroundPositionX: 'left',
        backgroundPositionY: 'top',
        backgroundRepeat: 'no-repeat',
        padding: '48px 0 36px 0',
        maxWidth: '100%',
    },
    [`& #swiperThird .swiper-button-prev`]: {
        top: '356px',
        left: 'calc((61vw - 200px)/2)',
        [theme.breakpoints.down('lg')]: {
            top: '366px',
        },
        [theme.breakpoints.down('mobileXL')]: {
            top: '356px',
            left: 'calc((34vw - 120px)/2)',
        },
        [theme.breakpoints.down('mobileLG')]: {
            top: '376px',
        },
        [theme.breakpoints.down('sm')]: {
            top: '366px',
            left: 'calc((34vw - 140px)/2)',
        },
        [theme.breakpoints.down('mobileMD')]: {
            top: '376px',
            left: 'calc((34vw - 120px)/2)',
        },
        [theme.breakpoints.down('mobileSM')]: {
            display: 'none',
        },
        color: '#000',
    },
    [`& #swiperThird .swiper-button-next`]: {
        top: '356px',
        right: 'calc((61vw - 200px)/2)',
        [theme.breakpoints.down('lg')]: {
            top: '366px',
        },
        [theme.breakpoints.down('mobileXL')]: {
            top: '356px',
            right: 'calc((34vw - 120px)/2)',
        },
        [theme.breakpoints.down('mobileLG')]: {
            top: '376px',
        },
        [theme.breakpoints.down('sm')]: {
            top: '366px',
            right: 'calc((34vw - 140px)/2)',
        },
        [theme.breakpoints.down('mobileMD')]: {
            top: '376px',
            right: 'calc((34vw - 120px)/2)',
        },
        [theme.breakpoints.down('mobileSM')]: {
            display: 'none',
        },
        color: '#000',
    },
}));

export const partFourNewClasses = classes;