import {Grid, styled} from "@mui/material";
import {prefixClasses} from "../common/Theme/utils";

const classes = {
    root: "root",
    firstPhoto: "firstPhoto",
    elementFirstPrevious: "elementFirstPrevious",
    elementSecondPrevious: "elementSecondPrevious",
    elementThirdPrevious: "elementThirdPrevious",
    elementFourthPrevious: "elementFourthPrevious",
    elementFirst: "elementFirst",
    elementSecond: "elementSecond",
    elementThird: "elementThird",
    elementFourth: "elementFourth",
    elementFifth: "elementFifth",
    elementSixth: "elementSixth",
    elementSeventh: "elementSeventh",
    elementSeventhNew: "elementSeventhNew",
    elementEighth: "elementEighth",
    elementNinth: "elementNinth",
    elementTenth: "elementTenth",
    elementEleventh: "elementEleventh",
    gridElementGradient: "gridElementGradient",
    gridElementText: "gridElementText",
    gridElementsAll: "gridElementsAll",
    sectionGrid: "sectionGrid",
    gridButton: "gridButton",
    content: "content",
    wrapper: "wrapper",
    heading: "heading",
    textBold: "textBold",
    textBoldSmaller: "textBoldSmaller",
    sectionText: "sectionText",
    sideLine: "sideLine",
};

prefixClasses("PartFive", classes);

export const StyledPartFive = styled(Grid)(({ theme }) => ({
    [`&.${classes.root}`]: {
        padding: '48px 0',
        width: '100%',
        backgroundImage: "url('/part5Background.png')",
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'right',
        backgroundPositionY: '30px',
        backgroundSize: '544px',

    },
    [`& .${classes.wrapper}`]: {
        display: 'flex',
        paddingRight: '1px',
        paddingLeft: '1px',
        marginRight: 'auto',
        marginLeft: 'auto',
        [theme.breakpoints.down('xl')]: {
            maxWidth: '1140px',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '960px',
        },
        [theme.breakpoints.down('mobileXL')]: {
            maxWidth: '720px',
        },
        [theme.breakpoints.down('mobileLG')]: {
            maxWidth: '540px',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '450px',
        },
        [theme.breakpoints.down('mobileMD')]: {
            maxWidth: '360px',
        },
        [theme.breakpoints.down('mobileSM')]: {
            maxWidth: '300px',
        },
    },
    [`& .${classes.content}`]: {
        flexWrap: 'wrap',
        marginLeft: '9%',
        padding: '0px',
        [theme.breakpoints.down('mobileXL')]: {
            flex: 'auto',
            maxWidth: '100%',
            marginLeft: '0',
        },
    },
    [`& .${classes.sideLine}`]: {
        height: '2px',
        position: 'relative',
        width: '100%',
        bottom: '18px',
        right: 'calc(100% + 60px)',
        backgroundColor: '#008bc8',
        [theme.breakpoints.down("mobileXL")]: {
            display: 'none',
        },
    },
    [`& .${classes.elementFirstPrevious}`]: {
        backgroundImage: "url('/part5Photo1-previous.jpg')",
    },
    [`& .${classes.elementSecondPrevious}`]: {
        backgroundImage: "url('/part5Photo2-previous.jpg')",
    },
    [`& .${classes.elementThirdPrevious}`]: {
        backgroundImage: "url('/part5Photo3-previous.jpg')",
    },
    [`& .${classes.elementFourthPrevious}`]: {
        backgroundImage: "url('/part5Photo4-previous.jpg')",
    },
    [`& .${classes.elementFirst}`]: {
        backgroundImage: "url('/part5Photo1.jpg')",
    },
    [`& .${classes.elementSecond}`]: {
        backgroundImage: "url('/part5Photo2.png')",
    },
    [`& .${classes.elementThird}`]: {
        backgroundImage: "url('/part5Photo3.jpg')",
    },
    [`& .${classes.elementFourth}`]: {
        backgroundImage: "url('/part5Photo4.jpg')",
    },
    [`& .${classes.elementFifth}`]: {
        backgroundImage: "url('/part5Photo5.jpg')",
    },
    [`& .${classes.elementSixth}`]: {
        backgroundImage: "url('/part5Photo6.png')",
    },
    [`& .${classes.elementSeventh}`]: {
        backgroundImage: "url('/part5Photo7.png')",
    },
    [`& .${classes.elementSeventhNew}`]: {
        backgroundImage: "url('/part5Photo7-new.png')",
    },
    [`& .${classes.elementEighth}`]: {
        backgroundImage: "url('/part5Photo8.png')",
    },
    [`& .${classes.elementNinth}`]: {
        backgroundImage: "url('/part5Photo9.png')",
    },
    [`& .${classes.elementTenth}`]: {
        backgroundImage: "url('/part5Photo10.png')",
    },
    [`& .${classes.elementEleventh}`]: {
        backgroundImage: "url('/part5Photo11.png')",
    },
    [`& .${classes.gridElementGradient}`]: {
        width: '100%',
        height: '100%',
        background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%, #1e252f 90%)',
        padding: '40px 15px 25px 25px',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column-reverse',
        "&:hover": {
            background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%, #008bc8 80%)',
        },
    },
    [`& .${classes.gridElementText}`]: {
        fontSize: '1.375rem',
        marginBottom: '16px',
        lineHeight: '1.2',
        fontWeight: '600',
    },
    [`& .${classes.gridElementsAll}`]: {
        minHeight: '275px',
        backgroundSize: 'cover',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
    },
    [`& .${classes.sectionGrid}`]: {
        gap: '15px',
        display: 'flex',
        flexWrap: 'wrap',
        margin: '0 auto',
        paddingBottom: '15px',
        [theme.breakpoints.down("sm")]: {
            display: 'grid',
            marginRight: '10%',
        },
        [theme.breakpoints.between("sm", "mobileXL")]: {
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            marginRight: '10%',
        },
    },
    [`& .${classes.gridButton}`]: {
        color: '#212529',
        backgroundColor: '#fff',
        whiteSpace: 'nowrap',
        height: '42px',
        textAlign: 'center',
        display: 'inline-block',
        fontFamily: 'LatoHeavy',
        verticalAlign: 'middle',
        textTransform: 'uppercase',
        borderRadius: '5px',
        padding: '14px 20px',
        lineHeight: '1',
        fontSize: '0.75rem',
        '&:hover': {
            backgroundColor: '#d2e0e6',
            borderColor: '#fff',
            boxShadow: 'none',
        },
    },
    [`& .${classes.heading}`]: {
        color: '#d2e0e6',
        fontSize: '1.875rem',
        textTransform: 'uppercase',
        marginBottom: '24px',
    },
    [`& .${classes.textBold}`]: {
        fontSize: '1.875rem',
        marginBottom: '24px',
        lineHeight: '1.2',
        [theme.breakpoints.down("sm")]: {
            marginRight: '10%',
        },
    },
    [`& .${classes.textBoldSmaller}`]: {
        fontSize: '1.4rem',
        margin: '16px 0px',
        [theme.breakpoints.down("sm")]: {
            marginRight: '10%',
        },
    },
    [`& .${classes.sectionText}`]: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

export const partFiveClasses = classes;