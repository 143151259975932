import { IDocument } from "./IDocument";
import { Link, Typography } from "@mui/material";
import img10 from "./img/document1-0.png";
import img11 from "./img/document1-1.png";
import img12 from "./img/document1-2.jpg";
import img13 from "./img/document1-3.jpg";
import img14 from "./img/document1-4.jpg";
import img15 from "./img/document1-5.jpg";
import img16 from "./img/document1-6.jpg";
import img17 from "./img/document1-7.jpg";
import img18 from "./img/document1-8.png";
import img19 from "./img/document1-9.png";
import img110 from "./img/document1-10.png";
import img111 from "./img/document1-11.png";
import img112 from "./img/document1-12.png";
import img113 from "./img/document1-13.png";


export const documentsOne: IDocument[] = [
  {
    id: "1",
    img: img12,
    imgAlt: "Woman getting fed with a spoon",
    title:
      "WHO recommendation on nutrition education on energy and protein intake during pregnancy",
    date: "12 March 2018",
    recommendation: {
      title: "Recommendation",
      content:
        "In undernourished populations, nutrition education on increasing daily energy and protein intake is recommended for pregnant women to reduce the risk of low birthweight neonates.",
    },
    recommendationItalic: "(Context-specific recommendation)",
    publicationHistory: {
      firstPublished: "November 2016",
      updated: "No update planned",
      assessedAsUpToDate: "November 2016",
    },
    remarks: {
      title: "Remarks",
      content: [
        "Undernourishment is usually defined by a low BMI (i.e. being underweight). For adults, a 20–39% prevalence of underweight women is considered a high prevalence of underweight and 40% or higher is considered a very high prevalence (1). Mid-upper arm circumference (MUAC) may also be useful to identify protein–energy malnutrition in individual pregnant women and to determine its prevalence in this population (2). However, the optimal cut-off points may need to be determined for individual countries based on context-specific cost–benefit analyses (2).",
        "Anthropometric characteristics of the general population are changing, and this needs to be taken into account by regularly reassessing the prevalence of undernutrition to ensure that the intervention remains relevant.",
        "The GDG noted that a strong training package is needed for practitioners, including standardized guidance on nutrition. This guidance should be evidence-based, sustainable, reproducible, accessible and adaptable to different cultural settings.",
        "Stakeholders might wish to consider alternative delivery platforms (e.g. peer counsellors, media reminders) and task shifting for delivery of this intervention.",
        "Areas that are highly food insecure or those with little access to a variety of foods may wish to consider additional complementary interventions, such as distribution of balanced protein and energy supplements (see WHO recommendation on energy and protein dietary supplements during pregnancy).",
      ],
    },
    background: {
      title: "Background",
      content: [
        "Pregnancy requires a healthy diet that includes an adequate intake of energy, protein, vitamins and minerals to meet maternal and fetal needs. However, for many pregnant women, dietary intake of vegetables, meat, dairy products and fruit is often insufficient to meet these needs, particularly in low and middle-income countries (LMICs) where multiple nutritional deficiencies often co-exist. In resource poor countries in sub-Saharan Africa, south-central and south-east Asia, maternal undernutrition is highly prevalent and is recognized as a key determinant of poor perinatal outcomes (3). However, obesity and overweight is also associated with poor pregnancy outcomes and many women in a variety of settings gain excessive weight during pregnancy. While obesity has historically been a condition associated with affluence, there is some evidence to suggest a shift in the burden of overweight and obesity from advantaged to disadvantaged populations (4).",
      ],
    },
    methods: {
      title: "Methods",
      content: [
        "The ANC recommendations are intended to inform the development of relevant health-care policies and clinical protocols. These recommendations were developed in accordance with the methods described in the WHO handbook for guideline development (5). In summary, the process included: identification of priority questions and outcomes, retrieval of evidence, assessment and synthesis of the evidence, formulation of recommendations, and planning for the implementation, dissemination, impact evaluation and updating of the guideline.",
        "The quality of the scientific evidence underpinning the recommendations was graded using the Grading of Recommendations Assessment, Development and Evaluation (GRADE) (6) and Confidence in the Evidence from Reviews of Qualitative research (GRADE-CERQual) (7) approaches, for quantitative and qualitative evidence, respectively. Up-to-date systematic reviews were used to prepare evidence profiles for priority questions. The DECIDE (Developing and Evaluating Communication Strategies to support Informed Decisions and Practice based on Evidence) (8) framework, an evidence-to-decision tool that includes intervention effects, values, resources, equity, acceptability and feasibility criteria, was used to guide the formulation and approval of recommendations by the Guideline Development Group (GDG) – an international group of experts assembled for the purpose of developing this guideline – at three Technical Consultations between October 2015 and March 2016.",
        "To ensure that each recommendation is correctly understood and applied in practice, the context of all context-specific recommendations is clearly stated within each recommendation, and the contributing experts provided additional remarks where needed.",
        "In accordance with WHO guideline development standards, these recommendations will be reviewed and updated following the identification of new evidence, with major reviews and updates at least every five years.",
        <Typography>
          {" "}
          Further information on procedures for developing this recommendation
          are available{" "}
          <Link
            href="https://web.archive.org/web/20200915144755/https://apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1"
            target="_blank"
          >
            here.
          </Link>
        </Typography>,
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following question:",
      content: [
        "For pregnant women in undernourished populations (P), does energy and protein dietary education (I) compared with no intervention (C) improve maternal and perinatal outcomes (O)?",
      ],
    },
    evidenceSummary: {
      title: "Evidence summary",
      content: [
        "Evidence on the effects of nutritional education was derived from a Cochrane review(9). Five trials conducted between 1975 and 2013 in Bangladesh, Greece and the USA, involving 1090 pregnant women, contributed data to this comparison. Nutritional education interventions were delivered one- to-one or in group classes and included education to improve the “quality” of diet, increase energy and protein intake, or improve knowledge of the nutritional value of different foods, including energy, protein, vitamins and iron. The Bangladesh study also involved cookery demonstrations.",
      ],
    },
    maternalOutcomes: {
      title: "Maternal outcomes",
      content:
        "Evidence on gestational weight gain was of very low certainty. There was no other evidence available on maternal outcomes in the review for this comparison.",
    },
    fetalAndNeonatalOutcomes: [
      {
        title: "Fetal and neonatal outcomes",
        content:
          "Low-certainty evidence shows that antenatal dietary education may reduce low-birth-weight neonates (300 women; RR: 0.04, 95% CI: 0.01–0.14), but may have little or no effect on small-for-gestational-age (SGA) neonates (2 trials, 449 women; RR: 0.46, 95% CI: 0.21–0.98), stillbirths (1 trial, 431 women; RR: 0.37, 95% CI: 0.07–1.90) or neonatal deaths (1 trial, 448 women; RR: 1.28, 95% CI: 0.35–4.72). Evidence on preterm birth was judged to be of very low certainty.",
      },
      {
        title: "Resources",
        content:
          "Resource costs are variable and mainly related to staffing and counselling time.",
      },
      {
        title: "Equity",
        content:
          "In many LMICs, pregnancy outcomes and ANC coverage are worse among women who are poor, least educated and residing in rural areas(10). Many low-income countries still struggle with widespread poverty and hunger, particularly among rural populations(11). Findings from a study of antenatal food supplementation and micronutrient supplements in rural Bangladesh suggest that food supplementation interventions might be associated with better ANC adherence among women with less education but not among those with more education(12). Therefore, providing antenatal food supplements could help to address inequalities by improving maternal nutritional status and increasing ANC coverage among disadvantaged women.",
      },
      {
        title: "Acceptability",
        content:
          "Qualitative evidence indicates that women in a variety of settings tend to view ANC as a source of knowledge and information and that they generally appreciate any advice (including dietary or nutritional) that may lead to a healthy baby anda positive pregnancy experience (high confidence in the evidence)(13). It also suggests that women may be less likely to engage with health services if advice is delivered in a hurried or didactic manner (high confidence in the evidence)(13). Therefore, these types of interventions are more likely to be acceptable if the interventions are delivered in an unhurried and supportive way, which may also facilitate better engagement with ANC services. Qualitative evidence on health-care providers’ views of ANC suggests that they may be keen to offer general health-care advice and specific pregnancy-related information (low confidence in the evidence) but they sometimes feel they do not have the appropriate training and lack the resources and time to deliver the service in the informative, supportive and caring manner that women want (high confidence in the evidence)(14).",
      },
      {
        title: "Feasibility",
        content:
          "In a number of LMIC settings, providers feel that a lack of resources may limit implementation of recommended interventions (high confidence in the evidence)(14).",
      },
      {
        title:
          "Further information and considerations related to this recommendation can be found in the WHO guidelines, available at:",
        content: (
          <Link href="http://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            http://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1
          </Link>
        ),
      },
    ],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "The successful introduction of evidence-based policies related to antenatal care into national programmes and health care services depends on well-planned and participatory consensus- driven processes of adaptation and implementation. These processes may include the development or revision of national guidelines or protocols based on this recommendation.",
        "The recommendation should be adapted into locally-appropriate documents and tools that are able to meet the specific needs of each country and health service. Modifications to the recommendation, where necessary, should be justified in an explicit and transparent manner.",
        "An enabling environment should be created for the use of this recommendation, including changes in the behaviour of health care practitioners to enable the use of evidence-based practices.",
        "Local professional societies may play important roles in this process and an all-inclusive and participatory process should be encouraged.",
        "Antenatal care models with a minimum of eight contacts are recommended to reduce perinatal mortality and improve women’s experience of care. Taking this as a foundation, the GDG reviewed how ANC should be delivered in terms of both the timing and content of each of the ANC contacts, and arrived at a new model – the 2016 WHO ANC model – which replaces the previous four-visit focused ANC (FANC) model. For the purpose of developing this new ANC model, the ANC recommendations were mapped to the eight contacts based on the evidence supporting each recommendation and the optimal timing of delivery of the recommended interventions to achieve maximal impact.",
      ],
    },
    additionalLists: [],
    researchImplications: {
      title: "Research implications",
      text: "The GDG identified these priority questions related to this recommendation",
      content: [
        "What are the effects, feasibility, acceptability and equity implications of healthy eating and exercise interventions in LMICs?",
        "Can an intervention package with standardized guidance on nutrition be developed that is evidence-based, sustainable, reproducible, accessible and adaptable to different cultural settings?",
        "Research is needed at country level to better understand the context-specific etiology of under- nutrition. Do alternatives to energy and protein supplements, such as cash or vouchers for pregnant women, or improved local and national food production and distribution, lead to improved maternal and perinatal outcomes?",
      ],
    },
    relatedLinks: {
      title: "Related links",
      text: (
        <Typography>
          WHO recommendations on antenatal care for a positive pregnancy
          experience (2016) -{" "}
          <Link href="https://web.archive.org/web/20200915144755/https://apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1">
            full document
          </Link>{" "}
          and{" "}
          <Link href="https://web.archive.org/web/20201219124651/https://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            evidence table
          </Link>
        </Typography>
      ),
      links: [
        {
          href: "https://web.archive.org/web/20201031220559/https://apps.who.int/iris/bitstream/handle/10665/255760/9789241565493-eng.pdf?sequence=1",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors",
        },
        {
          href: "https://web.archive.org/web/20201030093023/https://apps.who.int/iris/bitstream/handle/10665/249580/9789241549356-eng.pdf;jsessionid=7B8E18468A424E0301261B2CA6BCB324?sequence=1",
          text: "Pregnancy, Childbirth, Postpartum and Newborn Care: A guide for essential practice",
        },
        {
          href: "https://web.archive.org/web/20201103090439/https://www.who.int/reproductivehealth/en/",
          text: "WHO Programmes: Sexual and Reproductive health",
        },
        {
          href: "https://web.archive.org/web/20201018045702/https://www.who.int/nutrition/about_us/en/",
          text: "WHO Programmes: Department of Nutrition for Health and Development",
        },
        {
          href: "https://web.archive.org/web/20201111195601/https://www.who.int/health-topics/maternal-health",
          text: "Maternal Health",
        },
      ],
    },
    references: {
      title: "References",
      content: [
        "Physical status: the use and interpretation of anthropometry: report of a WHO Expert Committee. Technical Report Series No. 854. Geneva: World Health Organization; 1995 (http://whqlibdoc.who.int/trs/WHO_TRS_854. pdf, accessed 28 September 2016).",
        "Tang AM, Chung M, Dong K, Terrin N, Edmonds A, Assefa N et al. Determining a global midupper arm circumference cutoff to assess malnutrition in pregnant women. Washington (DC): FHI 360/Food and Nutrition Technical Assistance III Project (FANTA); 2016 (http://www.fantaproject.org/sites/default/files/resources/FANTA-MUAC-cutoffs- pregnantwomen-June2016.pdf, accessed 26 September 2016).",
        "Rasmussen KM, Yaktine AL, editors; Institute of Medicine and National Research Council. Weight gain during pregnancy: re-examining the guidelines. Washington (DC): The National Academies Press; 2009 (http://www.nationalacademies.org/hmd/WHO recommendations on antenatal care for a positive pregnancy experience 126 Reports/2009/Weight-Gain-During- PregnancyReexaminingthe-Guidelines.aspx, accessed 29September 2016).",
        "Popkin S, Slining MM. New dynamics in global obesity facing low- and middle-income countries. Obes Rev. 2013;14(2):11–20. doi:10.1111/obr.12102.",
        "WHO handbook for guideline development, 2nd edition. Geneva: World Health Organization; 2014 (http://www.who.int/kms/handbook_2nd_ ed.pdf, accessed 6 October 2016).",
        "GRADE [website]. The GRADE Working Group; 2016 (http://gradeworkinggroup.org/, accessed 27 October 2016).",
        "GRADE-CERQual [website]. The GRADECERQual Project Group; 2016 (https://cerqual. org/, accessed 27 October 2016).",
        "The DECIDE Project; 2016 (http://www.decide-collaboration.eu/, accessed 27 October 2016).",
        "Ota E, Hori H, Mori R, Tobe-Gai R, Farrar D. Antenatal dietary education and supplementation to increase energy and protein intake. Cochrane Database Syst Rev. 2015;(6):CD000032.",
        "State of inequality: reproductive, maternal, newborn and child health. Geneva: World Health Organization; 2015 (http://www.who.int/gho/health_equity/report_2015/en/, accessed 29 September 2016).",
        "Food Security Portal [website]. Washington (DC): International Food Policy Research Institute (IFPRI); 2012 (http://www.foodsecurityportal. org/, accessed 28 September 2016).",
        "Shaheen R, Streatfield PK, Naved RT, Lindholm L, Persson LÅ. Equity in adherence to and effect of prenatal food and micronutrient supplementation on child mortality: results from the MINIMat randomized trial, Bangladesh. BMC Public Health. 2014;14:5. doi:10.1186/1471-2458- 14-5.",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the use of routine antenatal services by pregnant women: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2016;(10):CD012392",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the provision of good quality routine antenatal care services by health staff: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2016",
      ],
    },
  },
  {
    id: "2",
    img: img13,
    imgAlt: "Woman and a nurse",
    title: "WHO recommendation on calcium supplementation during pregnancy",
    date: "07 November 2016",
    recommendation: {
      title: "Recommendation",
      content:
        "In populations with low dietary calcium intake, daily calcium supplementation (1.5–2.0 g oral elemental calcium) is recommended for pregnant women to reduce the risk of pre-eclampsia. (context-specific recommendation)",
    },
    recommendationItalic: "(Context-specific recommendation)",
    publicationHistory: {
      firstPublished: "October 2011",
      updated: "November 2016",
      assessedAsUpToDate: "November 2016",
    },
    remarks: {
      title: "Remarks",
      content: [
        "This recommendation is consistent with the 2011 WHO recommendations for prevention and treatment of pre-eclampsia and eclampsia (1) (strong recommendation, moderate-quality evidence) and supersedes the WHO recommendation found in the 2013 Guideline: calcium supplementation in pregnant women.(2)",
        "Dietary counselling of pregnant women should promote adequate calcium intake through locally available, calcium-rich foods.",
        "Dividing the dose of calcium may improve acceptability. The suggested scheme for calcium supplementation is 1.5–2 g daily, with the total dose divided into three doses, preferably taken at mealtimes.",
        "Negative interactions between iron and calcium supplements may occur. Therefore, the two nutrients should preferably be administered several hours apart rather than concomitantly.(2)",
        "As there is no clear evidence on the timing of initiation of calcium supplementation, stakeholders may wish to commence supplementation at the first ANC visit, given the possibility of compliance issues.",
        "To reach the most vulnerable populations and ensure a timely and continuous supply of supplements, stakeholders may wish to consider task shifting the provision of calcium supplementation in community settings with poor access to health-care professionals.",
        "The implementation and impact of this recommendation should be monitored at the health service, regional and country levels, based on clearly defined criteria and indicators associated with locally agreed targets. Successes and failures should be evaluated to inform integration of this recommendation into the ANC package.",
      ],
    },
    background: {
      title: "Background",
      content: [
        "Hypertensive disorders of pregnancy are an important cause of severe morbidity, long-term disability and death among both mothers and their babies. Worldwide, they account for approximately 14% of all maternal deaths, whereas in Latin America and the Caribbean, they contribute to approximately 22% of all maternal deaths. (3)",
        "Among the hypertensive disorders that complicate pregnancy, pre-eclampsia and eclampsia stand out as major causes of maternal and perinatal mortality and morbidity. The majority of deaths due to pre-eclampsia and eclampsia are avoidable through the provision of timely and effective care to the women presenting with these complications.",
        "There as an inverse relationship between calcium levels and risk of pre-eclampsia in pregnancy.(2) Increasing calcium intake in pregnancy (such as through oral supplementation) has been shown to reduce blood pressure directly.(4) This intervention has been trialled to assess potential benefits in reducing the risk of pre-eclampsia and related complications.",
      ],
    },
    methods: {
      title: "Methods",
      content: [
        "The recommendation was developed using standardized operating procedures in accordance with the process described in the “WHO handbook for guideline development”, guided by the GRADE approach.(5, 6) Outcomes used for this recommendation were aligned with the prioritized outcomes from the WHO recommendations on prevention and treatment of pre-eclampsia eclampsia (2011).(1)",
        "A Cochrane systematic review on  the effects of calcium on maternal and perinatal outcomes other than hypertension was conducted.(7) The first version of this recommendation was also informed by a separate Cochrane review on the effects of calcium  for preventing hypertensive disorders and related problems.(8)",
        "In these reviews, randomized controlled trials relevant to the key question were screened by review authors, and data on relevant outcomes and comparisons were extracted. Evidence profiles (in the form of GRADE tables) were prepared for comparisons of interest, including the assessment and judgments for each outcome, and the estimated risks. ",
        "WHO convened a Guideline Development Group (GDG) meeting on recommendations for prevention and treatment of pre-eclampsia or eclampsia in April 2011, where this recommendation was first developed. The recommendation was reviewed and updated at a GDG meeting on antenatal care recommendations in March 2016.",
        "Both GDGs were comprised of a group of independent experts, who used the evidence profiles to assess evidence on effects on the pre-specified outcomes. GDG members discussed the balance between desirable and undesirable effects, overall quality of supporting evidence, values and preferences of stakeholders, resource requirements, cost-effectiveness, acceptability, feasibility and equity, to formulate the recommendation. Remarks were added to clarify the recommendation, and aid implementation.",
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following questions:",
      content: [
        "In pregnant women, does calcium supplementation during pregnancy compared to placebo or no treatment, improve maternal and perinatal outcomes, particularly the onset of pre-eclampsia?",
        "In what populations/contexts is calcium supplementation most beneficial?",
      ],
    },
    evidenceSummary: {
      title: "Evidence summary",
      content: [
        "Evidence on the effects of calcium supplements on outcomes other than hypertension/pre-eclampsia was derived from a Cochrane systematic review.(7) The review included data from 23 trials involving 18 587 pregnant women. The aim of the review was to determine the effect of calcium on maternal and perinatal outcomes other than hypertension. There is a separate Cochrane review on the latter,(8) which has been referenced to support existing WHO recommendations on calcium supplementation to prevent pre-eclampsia in populations with low dietary calcium intake.(1, 2)\n" +
          "In 14 trials, daily calcium doses ranged from 1000 mg to 2000 mg, and in the remainder it was less than 1000 mg. Eleven trials started calcium supplementation at or after 20 weeks of gestation, five trials started before 20 weeks, and the rest did not specify when supplementation was initiated. The primary outcome of 16 of the trials was pregnancy-induced hypertension. For outcomes other than hypertension, few trials contributed to each outcome; this is the evidence presented in this section.",
      ],
    },
    maternalOutcomes: {
      title: "Maternal outcomes",
      content:
        "High-certainty evidence shows that calcium supplementation does not have important effects on maternal anaemia (1 trial, 1098 women; RR: 1.04, 95% CI: 0.90–1.22) or caesarean section rates (9 trials, 7440 women; RR: 0.99, 95% CI: 0.89–1.10). Moderate-certainty evidence indicates that calcium supplementation probably has little or no effect on maternal mortality (2 trials, 8974 women; RR: 0.29, 95% CI: 0.06–1.38) and probably makes little or no difference to the risk of urinary tract infections (3 trials, 1743 women; RR: 0.95, 95% CI: 0.69–1.30). Low-certainty evidence suggests that calcium supplementation may make little or no difference to maternal weight gain (3 trials; MD: –29.46 g per week, 95% CI: –119.80 to 60.89 g per week). Maternal satisfaction was not reported in any of the trials included in the Cochrane review.",
    },
    fetalAndNeonatalOutcomes: [
      {
        title: "Side-effects",
        content:
          "Calcium supplementation makes little or no difference to the risk of “any side-effect”, a composite outcome including headache, vomiting, backache, swelling, vaginal and urinary complaints, dyspepsia and abdominal pain (1 trial, 8312 women; RR: 1.02, 95% CI: 0.93–1.12), and probably makes little or no difference to the risk of urinary stones (3 trials, 13 419 women; RR: 1.11, 95% CI: 0.48–2.54), renal colic (1 trial, 8312 women; RR: 1.67, 95% CI: 0.40–6.99) and impaired renal function (1 trial, 4589 women; RR: 0.91, 95% CI: 0.51–1.64), all assessed as moderate-certainty evidence. Low-certainty evidence suggests that it may have little or no effect on the risk of gallstones (1 trial, 518 women; RR: 1.35, 95% CI: 0.48–3.85).",
      },
      {
        title: "Fetal and neonatal outcomes",
        content:
          "Calcium supplementation probably has little or no effect on low-birth-weight babies (< 2500 g), as indicated by evidence that was of moderate certainty due to inconsistency (6 trials, 14 162 women; RR: 0.93, 95% CI: 0.81–1.07). Low-certainty evidence suggests that it may have little or no effect on preterm birth before 37 weeks of gestation (13 trials, 16 139 women; RR: 0.86, 95% CI: 0.70–1.05). However, when trials are stratified by dose (< 1000 mg vs ≥ 1000 mg), moderate-certainty evidence shows that high-dose calcium supplementation probably reduces preterm birth (12 trials, 15 479women; RR: 0.81, 95% CI: 0.66–0.99).\n" +
          "Low-certainty evidence suggests that calcium supplementation may make little or no difference to perinatal mortality (8 trials, 15 785 women; RR: 0.87, 95% CI: 0.72–1.06), and moderate-certainty evidence shows that it probably has little or no effect on stillbirths or fetal deaths (6 trials, 15 269 women; RR: 0.91, 95% CI: 0.72–1.14).",
      },
      {
        title: "Additional considerations",
        content:
          "In the WHO recommendations for prevention and treatment of pre-eclampsia and eclampsia (2011), the recommendation on calcium states: “In areas where dietary calcium intake is low, calcium supplementation during pregnancy (at doses of 1.5–2.0 g elemental calcium/day) is recommended for the prevention of pre-eclampsia in all women, but especially in those at high risk of developing pre-eclampsia (strong recommendation)”.(1) This recommendation is based on moderate-quality evidence showing a 64% risk reduction (CI: 35–80%) in pre-eclampsia among women or populations with low baseline dietary calcium intake.(1)\n" +
          "In considering the evidence from the review of “non-hypertensive” effects, the GDG agreed that the effect of calcium on preterm birth is probably not distinct from the effect on preventing pre-eclampsia, as preterm birth is frequently a consequence of pre-eclampsia.",
      },
    ],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "The successful introduction of this recommendation into national programmes and health-care services  depends on well-planned and participatory consensus-driven processes of adaptation and implementation. The adaptation and implementation processes may include the development or revision of existing national guidelines or protocols based on this recommendation.",
        "The recommendation should be adapted into a locally appropriate document that can meet the specific needs of each country and health service. Any changes should be made in an explicit and transparent manner.",
        "A set of interventions should be established to ensure that an enabling environment is created for the use of the recommendations (including, for example, the availability of oral calcium supplements in antenatal care settings), and that the behaviour of the healthcare practitioner changes towards the use of this evidence-based practice.",
        "In this process, the role of local professional societies is important and an all-inclusive and participatory process should be encouraged.",
        "The WHO antenatal care guidelines outline the 2016 WHO ANC model, which includes timing, content and frequency of antenatal care contacts. In that model, the need for and compliance with calcium supplementation  should be considered at all antenatal care visits. The WHO antenatal care guidelines outline the 2016 WHO ANC model, which includes timing, content and frequency of antenatal care contacts. In that model, the need for and compliance with calcium supplementation  should be considered at all antenatal care visits.",
      ],
    },
    additionalLists: [],
    researchImplications: {
      title: "Research implications",
      text: "The 2011 GDG identified that further research on the following questions related to calcium supplementation is a priority:",
      content: [
        "It is unclear whether calcium supplementation corrects the pathological processes that underpin pre-eclampsia/eclampsia.",
        "Most calcium supplementation trials to date have used fairly high doses of daily calcium (1.5–2.0 g/day). While recommending those doses the guideline development group agreed that lower doses of calcium supplementation should be evaluated. This is important in view of the logistic and financial challenges of implementing large-scale calcium supplementation programmes.",
        "Calcium supplementation programme implementation should be monitored and evaluated carefully to assess their successes and failures in terms of integration of the programmes into the overall antenatal care package",
        "Evidence is weak on the effects of calcium supplementation in populations that are high risk of hypertensive disorders of pregnancy but have adequate intake of dietary calcium. It is unclear whether the observed effectiveness of calcium supplementation is the result of filling a dietary gap or whether calcium acts as a therapeutic agent.",
      ],
    },
    relatedLinks: {
      title: "Related links",
      text: "",
      links: [
        {
          href: "https://web.archive.org/web/20220305064019/https://apps.who.int/iris/bitstream/handle/10665/250796/9789241549912-eng.pdf;jsessionid=6FF3CE9DECCCE9981D9EF8AC62DBF759?sequence=1",
          text: "WHO recommendations on antenatal care for a positive pregnancy experience (2016)  full document and evidence tables (EB Table A3)",
        },
        {
          href: "https://web.archive.org/web/20201123211907/https://apps.who.int/iris/bitstream/handle/10665/44703/9789241548335_eng.pdf;jsessionid=E1349734B7443B7E4D5AD84731526315?sequence=1",
          text: "WHO recommendations on prevention and treatment of pre-eclampsia and eclampsia(2011) - full document and evidence tables (EB Table 6 and 7)",
        },
        {
          href: "https://web.archive.org/web/20201031220559/https://apps.who.int/iris/bitstream/handle/10665/255760/9789241565493-eng.pdf?sequence=1",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors (third edition)",
        },
        {
          href: "https://web.archive.org/web/20180206082954/http://onlinelibrary.wiley.com/doi/10.1002/14651858.CD007079.pub3/full",
          text: "Buppasiri P, Lumbiganon P, Thinkhamrop J, Ngamjarus C, Laopaiboon M, Medley N. Calcium supplementation (other than for preventing or treating hypertension) for improving pregnancy and infant outcomes. Cochrane Database Syst Rev. 2015(2):CD007079. Epub 2015/02/25.",
        },
      ],
    },
    references: {
      title: "References",
      content: [
        "WHO recommendations for prevention and treatment pre-eclampsia and eclampsia. Geneva: World Health Organization, 2011.",
        "WHO Guideline: Calcium supplementation in pregnant women. Geneva: 2013.",
        "Say L, Chou D, Gemmill A, Tunçalp Ö, Moller AB, Daniels J, et al. Global causes of maternal death: a WHO systematic analysis. Lancet Glob Health. 2014;2(6):e323-33. Epub 2014/05/05. doi: 10.1016/S2214-109X(14)70227-X. PubMed PMID: 25103301.",
        "Hofmeyr GJ, Mlokoti Z, Nikodem VC, Mangesi L, Ferreira S, Singata M, et al. Calcium supplementation during pregnancy for preventing hypertensive disorders is not associated with changes in platelet count, urate, and urinary protein: a randomized control trial. Hypertens Pregnancy. 2008;27(3):299-304. doi: 10.1080/10641950701826802. PubMed PMID: 18696358.",
        "WHO Handbook for Guideline Development (second edition). Geneva: World Health Organization; 2014.",
        "Schünemann H, Brożek J, Guyatt G, Oxman A. Handbook for grading the quality of evidence and the strength of recommendations using the GRADE approach. 2013.",
        "Buppasiri P, Lumbiganon P, Thinkhamrop J, Ngamjarus C, Laopaiboon M, Medley N. Calcium supplementation (other than for preventing or treating hypertension) for improving pregnancy and infant outcomes. Cochrane Database Syst Rev. 2015(2):CD007079. Epub 2015/02/25. doi: 10.1002/14651858.CD007079.pub3. PubMed PMID: 25922862.",
        "Hofmeyr GJ, Lawrie TA, Atallah AN, Duley L, Torloni MR. Calcium supplementation during pregnancy for preventing hypertensive disorders and related problems. Cochrane Database Syst Rev. 2014(6):CD001059. Epub 2014/06/24. doi: 10.1002/14651858.CD001059.pub4. PubMed PMID: 24960615.",
      ],
    },
  },
  {
    id: "3",
    img: img15,
    imgAlt: "a handful of pills",
    title: "WHO recommendation on zinc supplementation during pregnancy",
    date: "20 March 2018",
    recommendation: {
      title: "Recommendation",
      content:
        "Zinc supplementation for pregnant women is only recommended in the context of rigorous research. ",
    },
    recommendationItalic: "(Context-specific recommendation)",
    publicationHistory: {
      firstPublished: "November 2016",
      updated: "No date planned",
      assessedAsUpToDate: "November 2016",
    },
    remarks: {
      title: "Remarks",
      content: [
        "Many of the included studies were at risk of bias, which influenced the certainty of the review evidence on the effects of zinc supplementation.",
        "The low-certainty evidence that zinc supplementation may reduce preterm birth warrants further investigation, as do the other outcomes for which the evidence is very uncertain (e.g. perinatal mortality, neonatal sepsis), particularly in zinc-deficient populations with no food fortification strategy in place. Further research should aim to clarify to what extent zinc supplementation competes with iron and/or calcium antenatal supplements for absorption. The GDG considered that food fortification may be a more cost–effective strategy and that more evidence is needed on the cost–effectiveness of food fortification strategies.",
      ],
    },
    background: {
      title: "Background",
      content: [
        "Pregnancy requires a healthy diet that includes an adequate intake of energy, protein, vitamins and minerals to meet maternal and fetal needs. However, for many pregnant women, dietary intake of vegetables, meat, dairy products and fruit is often insufficient to meet these needs, particularly in low and middle-income countries (LMICs) where multiple nutritional deficiencies often co-exist. In resource poor countries in sub-Saharan Africa, south-central and south-east Asia, maternal undernutrition is highly prevalent and is recognized as a key determinant of poor perinatal outcomes (1). However, obesity and overweight is also associated with poor pregnancy outcomes and many women in a variety of settings gain excessive weight during pregnancy. While obesity has historically been a condition associated with affluence, there is some evidence to suggest a shift in the burden of overweight and obesity from advantaged to disadvantaged populations (2).",
        "Calcium deficiency is associated with an increased risk of pre-eclampsia (3), and deficiencies of other vitamins and minerals, such as vitamin E, C, B6 and zinc, have also been postulated to play a role in pre-eclampsia. Zinc deficiency is associated with impaired immunity (4). Vitamin C intake enhances iron absorption from the gut; however, zinc, iron and other mineral supplements may compete for absorption, and it is unclear whether such interactions have health consequences (4).",
      ],
    },
    methods: {
      title: "Methods",
      content: [
        "The ANC recommendations are intended to inform the development of relevant health-care policies and clinical protocols. These recommendations were developed in accordance with the methods described in the WHO handbook for guideline development (5). In summary, the process included: identification of priority questions and outcomes, retrieval of evidence, assessment and synthesis of the evidence, formulation of recommendations, and planning for the implementation, dissemination, impact evaluation and updating of the guideline.",
        "The quality of the scientific evidence underpinning the recommendations was graded using the Grading of Recommendations Assessment, Development and Evaluation (GRADE) (6) and Confidence in the Evidence from Reviews of Qualitative research (GRADE-CERQual) (7) approaches, for quantitative and qualitative evidence, respectively. Up-to-date systematic reviews were used to prepare evidence profiles for priority questions. The DECIDE (Developing and Evaluating Communication Strategies to support Informed Decisions and Practice based on Evidence) (8) framework, an evidence-to-decision tool that includes intervention effects, values, resources, equity, acceptability and feasibility criteria, was used to guide the formulation and approval of recommendations by the Guideline Development Group (GDG) – an international group of experts assembled for the purpose of developing this guideline – at three Technical Consultations between October 2015 and March 2016.",
        "To ensure that each recommendation is correctly understood and applied in practice, the context of all context-specific recommendations is clearly stated within each recommendation, and the contributing experts provided additional remarks where needed.",
        "In accordance with WHO guideline development standards, these recommendations will be reviewed and updated following the identification of new evidence, with major reviews and updates at least every five years.",
        <Typography>
          Further information on procedures for developing this recommendation
          are available{" "}
          <Link href="https://web.archive.org/web/20200918235703/https://apps.who.int/iris/bitstream/handle/10665/250796/9789241549912-eng.pdf?sequence=1">
            here.
          </Link>
        </Typography>,
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following question:",
      content: [
        "For pregnant women (P), does zinc supplementation (I) compared with no intervention or placebo (C) improve maternal and perinatal outcomes (O)?",
      ],
    },
    evidenceSummary: {
      title: "Evidence summary",
      content: [
        "The evidence was derived from a Cochrane review that included 21 trials involving more than 17 000 women (9). Most studies were conducted in LMICs, including Bangladesh, Chile, China, Egypt, Ghana, Indonesia, the Islamic Republic of Iran, Nepal, Pakistan, Peru and South Africa. Six trials were conducted in Denmark, the United Kingdom and the USA. Daily zinc supplementation was compared with no intervention or placebo. There was a wide variation among trials in terms of trial size (range: 56–4926 women), zinc dosage (range: 5–90 mg per day), nutritional and zinc status at trial entry, initiation and duration of supplementation (starting before conception in one trial, first or second trimester in the majority, or after 26 weeks of gestation in two trials, until delivery), and compliance with treatment.",
      ],
    },
    maternalOutcomes: {
      title: "Maternal outcomes",
      content:
        "Moderate-certainty evidence indicates that zinc supplementation probably makes little or no difference to the risk of any maternal infections (3 trials, 1185 women; RR: 1.06; 95% CI: 0.74–1.53). The evidence on caesarean section, pre-eclampsia and side-effects (maternal taste and smell dysfunction) is of very low certainty, and the review did not include anaemia, maternal mortality or maternal satisfaction as review outcomes.",
    },
    fetalAndNeonatalOutcomes: [
      {
        title: "Fetal and neonatal outcomes",
        content:
          "Moderate-certainty evidence indicates that zinc supplementation probably makes little or no difference to the risk of having SGA (8 trials, 4252 newborns; RR: 1.02; 95% CI: 0.94–1.11) or low-birthweight neonates (14 trials, 5643 neonates; RR: 0.93, 95% CI: 0.78–1.12). However, low-certainty evidence suggests that zinc supplementation may reduce preterm birth (16 trials, 7637 women; RR: 0.86, 95% CI: 0.76–0.97), particularly in women with presumed low zinc intake or poor nutrition (14 trials, 7099 women; RR: 0.87, 95% CI: 0.77–0.98). Low-certainty evidence suggests that zinc supplementation may have little or no effect on congenital anomalies (6 trials, 1240 newborns; RR: 0.67, 95% CI: 0.33–1.34) and macrosomia (defined in the review as “high birth weight”; 5 trials, 2837 neonates; RR: 1.00, 95% CI: 0.84–1.18). Evidence on perinatal mortality and neonatal sepsis is of very low certainty.",
      },
      {
        title: "Additional considerations",
        content:
          "The trials were clinically heterogeneous, therefore it is unclear what dose and timing of zinc supplementation, if any, might lead to a possible reduction in preterm birth. n There is little or no evidence on side-effects of zinc supplementation. In addition, it is unclear to what extent zinc might compete with iron and/or calcium for absorption. Maternal anaemia was not evaluated in the review.",
      },
      {
        title: "Resources",
        content:
          "Zinc costs approximately US$ 1.30 for 100 tablets of 20 mg (i.e. less than US$ 3.00 for a 6-month supply based on a daily dose of 20 mg) (10).",
      },
      {
        title: "Equity",
        content:
          "Effective interventions to improve maternal nutrition in disadvantaged populations could help to address health inequalities. A WHO report shows that inequalities in neonatal, infant and child mortality, as well as stunting prevalence, can be demonstrated according to economic status, education and place of residence in LMICs. The prevalence of stunting may be a good indicator of zinc deficiency in LMICs (11).",
      },
      {
        title: "Acceptability",
        content:
          "Qualitative evidence suggests that women in a variety of settings tend to view ANC as a source of knowledge and information and they generally appreciate any professional advice (including dietary or nutritional) that may lead to a healthy baby and a positive pregnancy experience (high confidence in the evidence) (12).",
      },
      {
        title: "Feasibility",
        content:
          "It may be more feasible to fortify food with zinc rather than to provide zinc as a single supplement, particularly in settings with a high prevalence of stunting in children.",
      },
      {
        title:
          "Further information and considerations related to this recommendation can be found in the WHO guidelines, available at:",
        content: (
          <Link href="http://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            http://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1
          </Link>
        ),
      },
    ],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "The successful introduction of evidence-based policies related to antenatal care into national programmes and health care services depends on well-planned and participatory consensus-driven processes of adaptation and implementation. These processes may include the development or revision of national guidelines or protocols based on this recommendation.",
        "The recommendation should be adapted into locally-appropriate documents and tools that are able to meet the specific needs of each country and health service. Modifications to the recommendation, where necessary, should be justified in an explicit and transparent manner.",
        "An enabling environment should be created for the use of this recommendation, including changes in the behaviour of health care practitioners to enable the use of evidence-based practices.",
        "Local professional societies may play important roles in this process and an all-inclusive and participatory process should be encouraged.",
        "Antenatal care models with a minimum of eight contacts are recommended to reduce perinatal mortality and improve women’s experience of care. Taking this as a foundation, the GDG reviewed how ANC should be delivered in terms of both the timing and content of each of the ANC contacts, and arrived at a new model – the 2016 WHO ANC model – which replaces the previous four-visit focused ANC (FANC) model. For the purpose of developing this new ANC model, the ANC recommendations were mapped to the eight contacts based on the evidence supporting each recommendation and the optimal timing of delivery of the recommended interventions to achieve maximal impact.",
      ],
    },
    additionalLists: [],
    researchImplications: {
      title: "Research implications",
      text: "The GDG identified these priority questions related to this recommendation",
      content: [
        "What is the effect of zinc supplementation on maternal outcomes (e.g. infections) and perinatal outcomes (e.g. preterm birth, SGA, neonatal infections, perinatal morbidity)?",
        "What is the optimal dose of zinc supplementation in pregnancy, particularly in zinc-deficient populations with no food fortification strategy in place?",
      ],
    },
    relatedLinks: {
      title: "Related links",
      text: (
        <Typography>
          WHO recommendations on antenatal care for a positive pregnancy
          experience (2016) -{" "}
          <Link href="https://web.archive.org/web/20200915144755/https://apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1">
            full document
          </Link>{" "}
          and{" "}
          <Link href="https://web.archive.org/web/20201219124651/https://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            evidence table
          </Link>
        </Typography>
      ),
      links: [
        {
          href: "https://web.archive.org/web/20201031220559/https://apps.who.int/iris/bitstream/handle/10665/255760/9789241565493-eng.pdf?sequence=1",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors",
        },
        {
          href: "https://web.archive.org/web/20201030093023/https://apps.who.int/iris/bitstream/handle/10665/249580/9789241549356-eng.pdf;jsessionid=7B8E18468A424E0301261B2CA6BCB324?sequence=1",
          text: "Pregnancy, Childbirth, Postpartum and Newborn Care: A guide for essential practice",
        },
        {
          href: "https://web.archive.org/web/20201103090439/https://www.who.int/reproductivehealth/en/",
          text: "WHO Programmes: Sexual and Reproductive health",
        },
        {
          href: "https://web.archive.org/web/20201018045702/https://www.who.int/nutrition/about_us/en/",
          text: "WHO Programmes: Department of Nutrition for Health and Development",
        },
        {
          href: "https://web.archive.org/web/20201111195601/https://www.who.int/health-topics/maternal-health",
          text: "Maternal Health",
        },
      ],
    },
    references: {
      title: "References",
      content: [
        "Tang AM, Chung M, Dong K, Terrin N, Edmonds A, Assefa N et al. Determining a global midupper arm circumference cutoff to assess malnutrition in pregnant women. Washington (DC): FHI 360/Food and Nutrition Technical Assistance III Project (FANTA); 2016 (http:// www.fantaproject.org/sites/default/files/ resources/FANTA-MUAC-cutoffs-pregnantwomen-June2016.pdf, accessed 26 September 2016).",
        "Popkin S, Slining MM. New dynamics in global obesity facing low- and middle-income countries. Obes Rev. 2013;14(2):11–20. doi:10.1111/obr.12102.",
        "Guideline: calcium supplementation in pregnant women. Geneva: World Health Organization; 2013 (http://apps.who.int/iris/ bitstream/10665/85120/1/9789241505376_ eng.pdf, accessed 28 September 2016).",
        "Roohani N, Hurrell R, Kelishadi R, Schulin R. Zinc and its importance for human health: an integrative review. J Res Med Sci. 2013;18(2):144–57.",
        "WHO handbook for guideline development, 2nd edition. Geneva: World Health Organization; 2014 (http://www.who.int/kms/handbook_2nd_ ed.pdf, accessed 6 October 2016).",
        "GRADE [website]. The GRADE Working Group; 2016 (http://gradeworkinggroup.org/, accessed 27 October 2016).",
        "GRADE-CERQual [website]. The GRADECERQual Project Group; 2016 (https://cerqual. org/, accessed 27 October 2016).",
        "The DECIDE Project; 2016 (http://www.decide-collaboration.eu/, accessed 27 October 2016).",
        "Ota E, Mori R, Middleton P, Tobe-Gai R, Mahomed K, Miyazaki C et al. Zinc supplementation for improving pregnancy and infant outcome. Cochrane Database Syst Rev. 2015;(2):CD000230.",
        "OneHealth Model: intervention treatment assumptions (draft 28 September). Geneva and Glastonbury (CT): United Nations InterAgency Working Group on Costing and the Futures Institute; 2013 (http://avenirhealth. org/Download/Spectrum/Manuals/ Intervention%20Assumptions%202013%20 9%2028.pdf, accessed 4 October 2016).",
        "Roohani N, Hurrell R, Kelishadi R, Schulin R. Zinc and its importance for human health: an integrative review. J Res Med Sci. 2013;18(2):144–57.",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the use of routine antenatal services by pregnant women: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2016;(10):CD012392",
      ],
    },
  },
  {
    id: "4",
    img: img16,
    imgAlt: "a woman and a doctor talking",
    title: "WHO recommendation on vitamin B6 supplementation during pregnancy",
    date: "20 March 2018",
    recommendation: {
      title: "Recommendation",
      content:
        "Vitamin B6 (pyridoxine) supplementation is not recommended for pregnant women to improve maternal and perinatal outcomes.",
    },
    recommendationItalic: "(Not recommended)",
    publicationHistory: {
      firstPublished: "November 2016",
      updated: "No date planned",
      assessedAsUpToDate: "November 2016",
    },
    remarks: {
      title: "Remarks",
      content: [
        "Pregnant women should be encouraged to receive adequate nutrition, which is best achieved through consumption of a healthy, balanced diet, and to refer to guidelines on healthy eating (1).",
        "The GDG agreed that there is insufficient evidence on the benefits and harms, if any, of routine vitamin B6 supplementation in pregnancy. However, research on the effects of routine vitamin B6 supplementation for pregnant women on maternal and perinatal outcomes is not considered a research priority.",
      ],
    },
    background: {
      title: "Background",
      content: [
        "Pregnancy requires a healthy diet that includes an adequate intake of energy, protein, vitamins and minerals to meet maternal and fetal needs. However, for many pregnant women, dietary intake of vegetables, meat, dairy products and fruit is often insufficient to meet these needs, particularly in low and middle-income countries (LMICs) where multiple nutritional deficiencies often co-exist. In resource poor countries in sub-Saharan Africa, south-central and south-east Asia, maternal undernutrition is highly prevalent and is recognized as a key determinant of poor perinatal outcomes (2). However, obesity and overweight is also associated with poor pregnancy outcomes and many women in a variety of settings gain excessive weight during pregnancy. While obesity has historically been a condition associated with affluence, there is some evidence to suggest a shift in the burden of overweight and obesity from advantaged to disadvantaged populations (3).",
        "Calcium deficiency is associated with an increased risk of pre-eclampsia (4), and deficiencies of other vitamins and minerals, such as vitamin E, C, B6 and zinc, have also been postulated to play a role in pre-eclampsia. Zinc deficiency is associated with impaired immunity (5). Vitamin C intake enhances iron absorption from the gut; however, zinc, iron and other mineral supplements may compete for absorption, and it is unclear whether such interactions have health consequences (5).",
      ],
    },
    methods: {
      title: "Methods",
      content: [
        "The ANC recommendations are intended to inform the development of relevant health-care policies and clinical protocols. These recommendations were developed in accordance with the methods described in the WHO handbook for guideline development (6). In summary, the process included: identification of priority questions and outcomes, retrieval of evidence, assessment and synthesis of the evidence, formulation of recommendations, and planning for the implementation, dissemination, impact evaluation and updating of the guideline.",
        "The quality of the scientific evidence underpinning the recommendations was graded using the Grading of Recommendations Assessment, Development and Evaluation (GRADE) (7) and Confidence in the Evidence from Reviews of Qualitative research (GRADE-CERQual) (8) approaches, for quantitative and qualitative evidence, respectively. Up-to-date systematic reviews were used to prepare evidence profiles for priority questions. The DECIDE (Developing and Evaluating Communication Strategies to support Informed Decisions and Practice based on Evidence) (9) framework, an evidence-to-decision tool that includes intervention effects, values, resources, equity, acceptability and feasibility criteria, was used to guide the formulation and approval of recommendations by the Guideline Development Group (GDG) – an international group of experts assembled for the purpose of developing this guideline – at three Technical Consultations between October 2015 and March 2016.",
        "To ensure that each recommendation is correctly understood and applied in practice, the context of all context-specific recommendations is clearly stated within each recommendation, and the contributing experts provided additional remarks where needed.",
        "In accordance with WHO guideline development standards, these recommendations will be reviewed and updated following the identification of new evidence, with major reviews and updates at least every five years.",
        <Typography>
          {" "}
          Further information on procedures for developing this recommendation
          are available{" "}
          <Link
            href="https://web.archive.org/web/20200918235703/https://apps.who.int/iris/bitstream/handle/10665/250796/9789241549912-eng.pdf?sequence=1"
            target="_blank"
          >
            here.
          </Link>
        </Typography>,
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following question:",
      content: [
        "For pregnant women (P), does Vitamin B6 (pyridoxine) supplementation (I) compared with no intervention or placebo (C) improve maternal and perinatal outcomes (O)?",
      ],
    },
    evidenceSummary: {
      title: "Evidence summary",
      content: [
        "The evidence was derived from a Cochrane review that included four trials involving approximately 1646 pregnant women (10). Studies were conducted in HICs between 1960 and 1984. Vitamin B6 (pyridoxine) given intramuscularly as a single dose (100 mg) or orally as capsules or lozenges (2.6 mg to 20 mg per day) was compared with placebo or no treatment. Only two out of four studies contributed data to this comparison.",
      ],
    },
    maternalOutcomes: {
      title: "Maternal outcomes",
      content:
        "Low-certainty evidence suggests that oral pyridoxine supplements may have little or no effect on preeclampsia (2 trials, 1197 women; RR: 1.71, 95% CI: 0.85–3.45). No other maternal outcomes relevant to the ANC guideline were reported in the review.",
    },
    fetalAndNeonatalOutcomes: [
      {
        title: "Fetal and neonatal outcomes",
        content:
          "Trials contributed no data on low birth weight, preterm birth or other ANC guideline outcomes. Mean birth weight was evaluated in one small trial but the evidence is very uncertain. There was no evidence on congenital anomalies.",
      },
      {
        title: "Additional considerations",
        content:
          "Moderate-certainty evidence shows that vitamin B6 probably provides some relief for nausea during pregnancy (See recommendation: Interventions for common physiological symptoms). Vitamin B6 deficiency alone is uncommon; it mostly occurs in combination with deficiencies of other B vitamins (11).",
      },
      {
        title: "Resources",
        content:
          "As a single supplement, vitamin B6 (pyridoxine hydrochloride tablets) can cost about US$ 2.50 for 90 × 10 mg tablets (12).",
      },
      {
        title: "Equity",
        content:
          "Effective interventions to improve maternal nutrition in disadvantaged populations could help to address health inequalities.",
      },
      {
        title: "Acceptability",
        content:
          "Qualitative evidence suggests that women in a variety of settings tend to view ANC as a source of knowledge and information and that they generally appreciate any professional advice (including dietary or nutritional) that may lead to a healthy baby and a positive pregnancy experience (high confidence in the evidence) (13).",
      },
      {
        title: "Feasibility",
        content:
          "Qualitative evidence shows that where there are additional costs associated with supplements (high confidence in the evidence) or where the recommended intervention is unavailable because of resource constraints (low confidence in the evidence), women may be less likely to engage with ANC services (14).",
      },
      {
        title:
          "Further information and considerations related to this recommendation can be found in the WHO guidelines, available at:",
        content: (
          <Link
            href="http://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1"
            target="_blank"
          >
            http://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1
          </Link>
        ),
      },
    ],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "The successful introduction of evidence-based policies related to antenatal care into national programmes and health care services depends on well-planned and participatory consensus-driven processes of adaptation and implementation. These processes may include the development or revision of national guidelines or protocols based on this recommendation.",
        "The recommendation should be adapted into locally-appropriate documents and tools that are able to meet the specific needs of each country and health service. Modifications to the recommendation, where necessary, should be justified in an explicit and transparent manner.",
        "An enabling environment should be created for the use of this recommendation, including changes in the behaviour of health care practitioners to enable the use of evidence-based practices.",
        "Local professional societies may play important roles in this process and an all-inclusive and participatory process should be encouraged.",
        "Antenatal care models with a minimum of eight contacts are recommended to reduce perinatal mortality and improve women’s experience of care. Taking this as a foundation, the GDG reviewed how ANC should be delivered in terms of both the timing and content of each of the ANC contacts, and arrived at a new model – the 2016 WHO ANC model – which replaces the previous four-visit focused ANC (FANC) model. For the purpose of developing this new ANC model, the ANC recommendations were mapped to the eight contacts based on the evidence supporting each recommendation and the optimal timing of delivery of the recommended interventions to achieve maximal impact.",
      ],
    },
    additionalLists: [],
    researchImplications: {
      title: "Research implications",
      text: "The GDG did not identify any priority question related to this recommendation",
      content: [],
    },
    relatedLinks: {
      title: "Related links",
      text: (
        <Typography>
          WHO recommendations on antenatal care for a positive pregnancy
          experience (2016) -{" "}
          <Link href="https://web.archive.org/web/20200915144755/https://apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1">
            full document
          </Link>{" "}
          and{" "}
          <Link href="https://web.archive.org/web/20201219124651/https://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            evidence table
          </Link>
        </Typography>
      ),
      links: [
        {
          href: "https://web.archive.org/web/20201031220559/https://apps.who.int/iris/bitstream/handle/10665/255760/9789241565493-eng.pdf?sequence=1",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors",
        },
        {
          href: "https://web.archive.org/web/20201030093023/https://apps.who.int/iris/bitstream/handle/10665/249580/9789241549356-eng.pdf;jsessionid=7B8E18468A424E0301261B2CA6BCB324?sequence=1",
          text: "Pregnancy, Childbirth, Postpartum and Newborn Care: A guide for essential practice",
        },
        {
          href: "https://web.archive.org/web/20201103090439/https://www.who.int/reproductivehealth/en/",
          text: "WHO Programmes: Sexual and Reproductive health",
        },
        {
          href: "https://web.archive.org/web/20201018045702/https://www.who.int/nutrition/about_us/en/",
          text: "WHO Programmes: Department of Nutrition for Health and Development",
        },
        {
          href: "https://web.archive.org/web/20201111195601/https://www.who.int/health-topics/maternal-health",
          text: "Maternal Health",
        },
      ],
    },
    references: {
      title: "References",
      content: [
        "Healthy diet. Fact sheet No. 394. Geneva: World Health Organization; 2015 (http://www.who.int/ mediacentre/factsheets/fs394/en/, accessed 1 November 2016).",
        "Tang AM, Chung M, Dong K, Terrin N, Edmonds A, Assefa N et al. Determining a global midupper arm circumference cutoff to assess malnutrition in pregnant women. Washington (DC): FHI 360/Food and Nutrition Technical Assistance III Project (FANTA); 2016 (http:// www.fantaproject.org/sites/default/files/ resources/FANTA-MUAC-cutoffs-pregnantwomen-June2016.pdf, accessed 26 September 2016).",
        "Popkin S, Slining MM. New dynamics in global obesity facing low- and middle-income countries. Obes Rev. 2013;14(2):11–20. doi:10.1111/obr.12102.",
        "Guideline: calcium supplementation in pregnant women. Geneva: World Health Organization; 2013 (http://apps.who.int/iris/ bitstream/10665/85120/1/9789241505376_ eng.pdf, accessed 28 September 2016).",
        "Roohani N, Hurrell R, Kelishadi R, Schulin R. Zinc and its importance for human health: an integrative review. J Res Med Sci. 2013;18(2):144–57.",
        "WHO handbook for guideline development, 2nd edition. Geneva: World Health Organization; 2014 (http://www.who.int/kms/handbook_2nd_ ed.pdf, accessed 6 October 2016).",
        "GRADE [website]. The GRADE Working Group; 2016 (http://gradeworkinggroup.org/, accessed 27 October 2016).",
        "GRADE-CERQual [website]. The GRADECERQual Project Group; 2016 (https://cerqual. org/, accessed 27 October 2016).",
        "The DECIDE Project; 2016 (http://www.decide-collaboration.eu/, accessed 27 October 2016).",
        "Salam RA, Zuberi NF, Bhutta ZA. Pyridoxine (vitamin B6) supplementation during pregnancy or labour for maternal and neonatal outcomes. Cochrane Database Syst Rev. 2015;(6):CD000179.",
        "Allen L, de Benoist B, Dary O, Hurrell R, editors. Guidelines on food fortification with micronutrients. Geneva: World Health Organization and Food and Agriculture Organization of the United Nations; 2006 (http://www.who.int/nutrition/publications/ guide_food_fortification_micronutrients.pdf, accessed 29 September 2016).",
        "Joint Formulary Committee. Vitamin deficiency. Chapter 9: Blood and Nutrition. In: British National Formulary (BNF) 72. London: BMJ Publishing Group Ltd and Royal Pharmaceutical Society; 2016.",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the use of routine antenatal services by pregnant women: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2016;(10):CD012392",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the provision of good quality routine antenatal care services by health staff: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2016",
      ],
    },
  },
  {
    id: "25",
    img: img18,
    imgAlt: "a pregnant woman",
    title: "WHO recommendation on high-protein supplements during pregnancy",
    date: "29 August 2021",
    recommendation: {
      title: "Recommendation",
      content:
        "In undernourished populations, high-protein supplementation is not recommended for pregnant women to improve maternal and perinatal outcomes.",
    },
    recommendationItalic: "(Not recommended)",
    publicationHistory: {
      firstPublished: "November 2016",
      updated: "No update planned",
      assessedAsUpToDate: "November 2016",
    },
    remarks: {
      title: "Remarks",
      content: [
        "The GDG noted that there is insufficient evidence on the benefits, if any, of high-protein supplementation.",
        "Further research on the effects of high-protein supplements in undernourished populations is not considered a research priority",
      ],
    },
    background: {
      title: "Background",
      content: [
        "Pregnancy requires a healthy diet that includes an adequate intake of energy, protein, vitamins and minerals to meet maternal and fetal needs. However, for many pregnant women, dietary intake of vegetables, meat, dairy products and fruit is often insufficient to meet these needs, particularly in low and middle-income countries (LMICs) where multiple nutritional deficiencies often co-exist. In resource poor countries in sub-Saharan Africa, south-central and south-east Asia, maternal undernutrition is highly prevalent and is recognized as a key determinant of poor perinatal outcomes (1). However, obesity and overweight is also associated with poor pregnancy outcomes and many women in a variety of settings gain excessive weight during pregnancy. While obesity has historically been a condition associated with affluence, there is some evidence to suggest a shift in the burden of overweight and obesity from advantaged to disadvantaged populations (2).",
      ],
    },
    methods: {
      title: "Methods",
      content: [
        "The ANC recommendations are intended to inform the development of relevant health-care policies and clinical protocols. These recommendations were developed in accordance with the methods described in the WHO handbook for guideline development (3). In summary, the process included: identification of priority questions and outcomes, retrieval of evidence, assessment and synthesis of the evidence, formulation of recommendations, and planning for the implementation, dissemination, impact evaluation and updating of the guideline.",
        "The quality of the scientific evidence underpinning the recommendations was graded using the Grading of Recommendations Assessment, Development and Evaluation (GRADE) (4) and Confidence in the Evidence from Reviews of Qualitative research (GRADE-CERQual) (5) approaches, for quantitative and qualitative evidence, respectively. Up-to-date systematic reviews were used to prepare evidence profiles for priority questions. The DECIDE (Developing and Evaluating Communication Strategies to support Informed Decisions and Practice based on Evidence) (6) framework, an evidence-to-decision tool that includes intervention effects, values, resources, equity, acceptability and feasibility criteria, was used to guide the formulation and approval of recommendations by the Guideline Development Group (GDG) – an international group of experts assembled for the purpose of developing this guideline – at three Technical Consultations between October 2015 and March 2016.",
        "To ensure that each recommendation is correctly understood and applied in practice, the context of all context-specific recommendations is clearly stated within each recommendation, and the contributing experts provided additional remarks where needed.",
        "In accordance with WHO guideline development standards, these recommendations will be reviewed and updated following the identification of new evidence, with major reviews and updates at least every five years.",
        <Typography>
          {" "}
          Further information on procedures for developing this recommendation
          are available{" "}
          <Link
            href="https://web.archive.org/web/20200915144755/https://apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1"
            target="_blank"
          >
            here.
          </Link>
        </Typography>,
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following question:",
      content: [
        "For pregnant women in undernourished populations (P), does high-protein supplementation (I) compared with no high protein supplementation (C) improve maternal and perinatal outcomes (O)?",
      ],
    },
    evidenceSummary: {
      title: "Evidence summary",
      content: [
        "Evidence on the effects of balanced energy and protein supplements compared with no supplementation or placebo was derived from a Cochrane review (7). The review included one trial of high-protein supplementation compared with a micronutrient supplement conducted in the 1970s, involving 1051 low-income, black women in the USA.",
      ],
    },
    maternalOutcomes: {
      title: "Maternal outcomes",
      content:
        "None of the outcomes prioritized for this guideline were reported for this comparison in the review.",
    },
    fetalAndNeonatalOutcomes: [
      {
        title: "Fetal and neonatal outcomes",
        content:
          "High-certainty evidence shows that high-protein supplementation increases SGA neonates (1 trial, 505 neonates; RR: 1.58, 95% CI: 1.03–2.41). Moderate-certainty evidence indicates that high-protein supplementation probably has little or no effect on preterm birth (1 study, 505 women; RR: 1.14, 95% CI: 0.83–1.56). Low-certainty evidence suggests that high-protein supplementation may have little or no effect on stillbirths (1 trial, 529 babies; RR: 0.81, 95% CI: 0.31–2.15; certainty of evidence downgraded due to imprecision) and neonatal deaths (1 trial, 529 neonates; RR: 2.78, 95% CI: 0.75–10.36).",
      },
      {
        title: "Additional considerations",
        content:
          "In the review, mean birth weight (in grams) was reported and the findings favoured the balanced energy and protein supplementation group (11 trials, 5385 neonates; mean difference [MD]: 40.96, 95% CI: 4.66–77.26). This evidence was graded as moderate-quality evidence in the review (9).",
      },
      {
        title: "Resources",
        content:
          "The cost of high-protein supplements is relatively high. There may also be cost implications with respect to transport, storage and training.",
      },
      {
        title: "Equity",
        content:
          "In many LMICs, pregnancy outcomes and ANC coverage are worse among women who are poor, least educated and residing in rural areas (8). Many low-income countries still struggle with widespread poverty and hunger, particularly among rural populations (9). Therefore, providing antenatal food supplements could help to address inequalities by improving maternal nutritional status and increasing ANC coverage among disadvantaged women.",
      },
      {
        title: "Acceptability",
        content:
          "Qualitative evidence indicates that women in a variety of settings tend to view ANC as a source of knowledge and information and that they generally appreciate any advice (including dietary or nutritional) that may lead to a healthy baby and a positive pregnancy experience (high confidence in the evidence) (10). It also suggests that women may be less likely to engage with health services if advice is delivered in a hurried or didactic manner (high confidence in the evidence) (10). Qualitative evidence on health-care providers’ views of ANC suggests that they may be keen to offer general health-care advice and specific pregnancy-related information (low confidence in the evidence) but they sometimes feel they do not have the appropriate training and lack the resources and time to deliver the service in the informative, supportive and caring manner that women want (high confidence in the evidence) (11).",
      },
      {
        title: "Feasibility",
        content:
          "Providing high-protein supplements may be associated with logistical issues, as supplements are bulky and will require adequate transport and storage facilities to ensure continual supplies. Qualitative evidence from LMIC settings indicates that providers feel that a lack of resources may limit implementation of recommended interventions (high confidence in the evidence) (11).",
      },
      {
        title:
          "Further information and considerations related to this recommendation can be found in the WHO guidelines, available at:",
        content: (
          <Link href="http://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            http://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1
          </Link>
        ),
      },
    ],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "The successful introduction of evidence-based policies related to antenatal care into national programmes and health care services depends on well-planned and participatory consensus-driven processes of adaptation and implementation. These processes may include the development or revision of national guidelines or protocols based on this recommendation.",
        "The recommendation should be adapted into locally-appropriate documents and tools that are able to meet the specific needs of each country and health service. Modifications to the recommendation, where necessary, should be justified in an explicit and transparent manner.",
        "An enabling environment should be created for the use of this recommendation, including changes in the behaviour of health care practitioners to enable the use of evidence-based practices.",
        "Local professional societies may play important roles in this process and an all-inclusive and participatory process should be encouraged.",
        "Antenatal care models with a minimum of eight contacts are recommended to reduce perinatal mortality and improve women’s experience of care. Taking this as a foundation, the GDG reviewed how ANC should be delivered in terms of both the timing and content of each of the ANC contacts, and arrived at a new model – the 2016 WHO ANC model – which replaces the previous four-visit focused ANC (FANC) model. For the purpose of developing this new ANC model, the ANC recommendations were mapped to the eight contacts based on the evidence supporting each recommendation and the optimal timing of delivery of the recommended interventions to achieve maximal impact.",
      ],
    },
    additionalLists: [],
    researchImplications: {
      title: "Research implications",
      text: "The GDG identified these priority questions related to this recommendation",
      content: [
        "What are the effects, feasibility, acceptability and equity implications of healthy eating and exercise interventions in LMICs?",
        "Can an intervention package with standardized guidance on nutrition be developed that is evidence-based, sustainable, reproducible, accessible and adaptable to different cultural settings?",
        "Research is needed at country level to better understand the context-specific etiology of under- nutrition. Do alternatives to energy and protein supplements, such as cash or vouchers for pregnant women, or improved local and national food production and distribution, lead to improved maternal and perinatal outcomes?",
      ],
    },
    relatedLinks: {
      title: "Related links",
      text: (
        <Typography>
          WHO recommendations on antenatal care for a positive pregnancy
          experience (2016) -{" "}
          <Link href="https://web.archive.org/web/20200915144755/https://apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1">
            full document
          </Link>{" "}
          and{" "}
          <Link href="https://web.archive.org/web/20201219124651/https://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            evidence table
          </Link>
        </Typography>
      ),
      links: [
        {
          href: "https://web.archive.org/web/20201031220559/https://apps.who.int/iris/bitstream/handle/10665/255760/9789241565493-eng.pdf?sequence=1",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors",
        },
        {
          href: "https://web.archive.org/web/20201030093023/https://apps.who.int/iris/bitstream/handle/10665/249580/9789241549356-eng.pdf;jsessionid=7B8E18468A424E0301261B2CA6BCB324?sequence=1",
          text: "Pregnancy, Childbirth, Postpartum and Newborn Care: A guide for essential practice",
        },
        {
          href: "https://web.archive.org/web/20201103090439/https://www.who.int/reproductivehealth/en/",
          text: "WHO Programmes: Sexual and Reproductive health",
        },
        {
          href: "https://web.archive.org/web/20201018045702/https://www.who.int/nutrition/about_us/en/",
          text: "WHO Programmes: Department of Nutrition for Health and Development",
        },
        {
          href: "https://web.archive.org/web/20201111195601/https://www.who.int/health-topics/maternal-health",
          text: "Maternal Health",
        },
      ],
    },
    references: {
      title: "References",
      content: [
        "Rasmussen KM, Yaktine AL, editors; Institute of Medicine and National Research Council. Weight gain during pregnancy: re-examining the guidelines. Washington (DC): The National Academies Press; 2009 (http://www.nationalacademies.org/hmd/ WHO recommendations on antenatal care for a positive pregnancy experience 126 Reports/2009/Weight-Gain-During-PregnancyReexaminingthe-Guidelines.aspx, accessed 29 September 2016).",
        "Popkin S, Slining MM. New dynamics in global obesity facing low- and middle-income countries. Obes Rev. 2013;14(2):11–20. doi:10.1111/obr.12102.",
        "WHO handbook for guideline development, 2nd edition. Geneva: World Health Organization; 2014 (http://www.who.int/kms/handbook_2nd_ ed.pdf, accessed 6 October 2016).",
        "GRADE [website]. The GRADE Working Group; 2016 (http://gradeworkinggroup.org/, accessed 27 October 2016).",
        "GRADE-CERQual [website]. The GRADECERQual Project Group; 2016 (https://cerqual. org/, accessed 27 October 2016).",
        "The DECIDE Project; 2016 (http://www.decide-collaboration.eu/, accessed 27 October 2016).",
        "Ota E, Hori H, Mori R, Tobe-Gai R, Farrar D. Antenatal dietary education and supplementation to increase energy and protein intake. Cochrane Database Syst Rev. 2015;(6):CD000032.",
        "State of inequality: reproductive, maternal, newborn and child health. Geneva: World Health Organization; 2015 (http://www.who.int/gho/health_equity/report_2015/en/, accessed 29 September 2016).",
        "Food Security Portal [website]. Washington (DC): International Food Policy Research Institute (IFPRI); 2012 (http://www.foodsecurityportal. org/, accessed 28 September 2016).",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the use of routine antenatal services by pregnant women: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2016;(10):CD012392",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the provision of good quality routine antenatal care services by health staff: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2016",
      ],
    },
  },
  {
    id: "26",
    img: img14,
    imgAlt: "",
    title:
      "WHO recommendation on daily oral iron and folic acid supplementation",
    date: "29 August 2021",
    recommendation: {
      title: "Recommendation",
      content:
        "Daily oral iron and folic acid supplementation with 30 mg to 60 mg of elemental iron and 400 g (0.4 mg) of folic acid is recommended for pregnant women to prevent maternal anaemia, puerperal sepsis, low birth weight, and preterm birth.",
    },
    recommendationItalic: "(Recommended)",
    publicationHistory: {
      firstPublished: "November 2016",
      updated: "No update planned",
      assessedAsUpToDate: "November 2016",
    },
    remarks: {
      title: "Remarks",
      content: [
        "This recommendation supersedes the previous WHO recommendation found in the 2012 Guideline: daily iron and folic acid supplementation in pregnant women (1). and should be considered alongside Recommendation on intermittent iron.",
        "The equivalent of 60 mg of elemental iron is 300 mg of ferrous sulfate hepahydrate, 180 mg of ferrous fumarate or 500 mg of ferrous gluconate.",
        "Folic acid should be commenced as early as possible (ideally before conception) to prevent neural tube defects.",
        "In settings where anaemia in pregnant women is a severe public health problem (i.e. where at least 40% of pregnant women have a blood haemoglobin [Hb] concentration < 110 g/L), a daily dose of 60 mg of elemental iron is preferred over a lower dose.",
        "In the first and third trimesters, the Hb threshold for diagnosing anaemia is 110 g/L; in the second trimester, the threshold is 105 g/L (2).",
        "If a woman is diagnosed with anaemia during pregnancy, her daily elemental iron should be increased to 120 mg until her Hb concentration rises to normal (Hb 110 g/L or higher) (3, 4). Thereafter, she can resume the standard daily antenatal iron dose to prevent recurrence of anaemia.",
        "Effective communication with pregnant women about diet and healthy eating – including providing information about food sources of vitamins and minerals, and dietary diversity – is an integral part of preventing anaemia and providing quality ANC.",
        "Effective communication strategies are vital for improving the acceptability of, and adherence to, supplementation schemes.",
        "Stakeholders may need to consider ways of reminding pregnant women to take their supplements and of assisting them to manage associated side-effects.",
        "In areas with endemic infections that may cause anaemia through blood loss, increased red cell destruction or decreased red cell production, such as malaria and hookworm, measures to prevent, diagnose and treat these infections should be implemented.",
        "Oral supplements are available as capsules or tablets (including soluble tablets, and dissolvable and modified-release tablets) (5). Establishment of a quality assurance process is important to guarantee that supplements are manufactured, packaged and stored in a controlled and uncontaminated environment (6).",
        "A better understanding of the etiology of anaemia (e.g. malaria endemnicity, haemoglobinopathies) and the prevalence of risk factors is needed at the country level, to inform context-specific adaptations of this recommendation.",
        "Standardized definitions of side-effects are needed to facilitate monitoring and evaluation.",
        "Development and improvement of integrated surveillance systems are needed to link the assessment of anaemia and iron status at the country level to national and global surveillance systems.",
        "To reach the most vulnerable populations and ensure a timely and continuous supply of supplements, stakeholders may wish to consider task shifting the provision of iron supplementation in community settings with poor access to health-care professionals (see Recommendation: Health systems interventions to improve the utilization and quality of ANC).",
      ],
    },
    background: {
      title: "Background",
      content: [
        "Anaemia is associated with iron, folate and vitamin A deficiencies. It is estimated to affect 38.2% of pregnant women globally, with the highest prevalence in the WHO regions of South-East Asia (48.7%) and Africa (46.3%), medium prevalence in the Eastern Mediterranean Region (38.9%) and the lowest prevalence in the WHO regions of the Western Pacific (24.3%), the Americas (24.9%) and Europe (25.8%) (7).",
        "Major contributory factors to anaemia include parasitic infections such as malaria, hookworm and schistosomiasis, in areas where these infections are endemic. In addition, chronic infections such as tuberculosis (TB) and HIV, and haemoglobinopathies such as sickle-cell disease, contribute to the prevalence of anaemia. It is estimated that 0.8 million pregnant women globally have severe anaemia (defined as a blood haemoglobin concentration < 70 g/L) (7). In pregnancy, severe anaemia is associated with an increased risk of maternal and infant mortality (8). It is estimated that about half of the anaemia found in pregnant women is amenable to iron supplementation (7); however, this may be quite variable and is likely to be much lower in malaria-endemic areas. In addition to causing anaemia, iron deficiency adversely affects the use of energy sources by muscles and, thus, physical capacity and work performance, and also adversely affects immune status and morbidity from infections (9). Folate (vitamin B9) deficiency, in addition to anaemia it is also linked to fetal neural tube defects (10). Vitamin A deficiency affects about 19 million pregnant women, mostly in Africa and South-East Asia, causing night blindness (11).",
      ],
    },
    methods: {
      title: "Methods",
      content: [
        "The ANC recommendations are intended to inform the development of relevant health-care policies and clinical protocols. These recommendations were developed in accordance with the methods described in the WHO handbook for guideline development (12). In summary, the process included: identification of priority questions and outcomes, retrieval of evidence, assessment and synthesis of the evidence, formulation of recommendations, and planning for the implementation, dissemination, impact evaluation and updating of the guideline.",
        "The quality of the scientific evidence underpinning the recommendations was graded using the Grading of Recommendations Assessment, Development and Evaluation (GRADE) (13) and Confidence in the Evidence from Reviews of Qualitative research (GRADE-CERQual) (14) approaches, for quantitative and qualitative evidence, respectively. Up-to-date systematic reviews were used to prepare evidence profiles for priority questions. The DECIDE (Developing and Evaluating Communication Strategies to support Informed Decisions and Practice based on Evidence) (15) framework, an evidence-to-decision tool that includes intervention effects, values, resources, equity, acceptability and feasibility criteria, was used to guide the formulation and approval of recommendations by the Guideline Development Group (GDG) – an international group of experts assembled for the purpose of developing this guideline – at three Technical Consultations between October 2015 and March 2016.",
        "To ensure that each recommendation is correctly understood and applied in practice, the context of all context-specific recommendations is clearly stated within each recommendation, and the contributing experts provided additional remarks where needed.",
        "In accordance with WHO guideline development standards, these recommendations will be reviewed and updated following the identification of new evidence, with major reviews and updates at least every five years.",
        <Typography>
          {" "}
          Further information on procedures for developing this recommendation
          are available{" "}
          <Link
            href="https://web.archive.org/web/20200915144755/https://apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1"
            target="_blank"
          >
            here.
          </Link>
        </Typography>,
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following questions:",
      content: [
        "For pregnant women (P), does daily iron supplementation (I) (with or without folic acid) compared with no iron supplementation or placebo (C), improve maternal and perinatal outcomes (O)?",
      ],
    },
    evidenceSummary: {
      title: "Evidence summary",
      content: [
        "The evidence on the effects of daily iron and/or folic acid was derived from a Cochrane review of 61 trials conducted in low-, middle- and high-income countries (16). Twenty-three trials were conducted in countries with some malaria risk, of which two reported malaria outcomes.  Overall, 44 trials involving 43274 women contributed data to the review’s meta-analyses. The trials compared daily oral iron supplementation, with or without folic acid or other vitamin and mineral supplements, with various control groups (folic acid only, placebo, no intervention, other vitamin and mineral supplements without iron or folic acid). Most of the evidence was derived from studies comparing iron supplementation with no iron supplementation. In most trials, women began taking supplements before 20 weeks of gestation and continued taking supplements until delivery. The most commonly used dose of elemental iron was 60 mg daily (range: 30–240 mg) and that of folic acid was 400 µg daily.",
      ],
    },
    maternalOutcomes: {
      title: "Maternal outcomes",
      content: [
        "Anaemia was reported in many different ways and at different time points during pregnancy and the puerperium. Low-certainty evidence shows that daily iron supplementation may reduce the risk of anaemia at term (defined as blood Hb concentration 130 g/L at 34 weeks of gestation or later) (8 trials, 2156 women; RR: 3.07, 95% CI: 1.18–8.02). Regarding maternal morbidity, moderate-certainty evidence shows that daily iron supplementation probably reduces the risk of maternal puerperal infections (4 trials, 4374 women; RR: 0.68, 95% CI: 0.5–0.92).",
        "Low-certainty evidence shows that daily iron supplementation may have little or no effect on pre-eclampsia (4 trials, 1704 women; RR: 1.63, 95% CI: 0.87–3.07) and antepartum haemorrhage (2 trials, 1157 women; RR: 1.48, 95% CI: 0.51–4.31), and moderate-certainty evidence shows that it probably has little or no effect on postpartum haemorrhage (4 trials, 1488 women; RR: 0.93, 95% CI: 0.59–1.49). Evidence on other morbidity outcomes, including placental abruption and blood transfusions, is of very low certainty.",
        "Low-certainty evidence shows that daily iron supplementation may have little or no effect on maternal mortality (2 trials, 12 560 women; RR: 0.33, 95% CI: 0.01–8.19). Women’s satisfaction was evaluated in one small trial (49 women), which found little difference between daily iron and control groups.",
        "Side-effects: Moderate-certainty evidence indicates that daily iron supplementation probably has little or no effect on the risk of experiencing any side-effect (11 trials, 2425 women; RR: 1.29, 95% CI: 0.83–2.02), and that it may have little or no effect on constipation (4 trials, 1495 women; RR: 0.95, 95% CI: 0.62–1.43), heartburn (3 trials, 1323 women; RR: 1.19, 95% CI: 0.86–1.66) and vomiting (4 trials, 1392 women; RR: 0.88, 95% CI: 0.59–1.30). Evidence that daily iron has little or no effect on nausea is of low certainty (4 trials, 1377 women; RR: 1.21, 95% CI: 0.72–2.03). High-certainty evidence shows that diarrhoea is less common with daily iron supplements (3 trials, 1088 women; RR: 0.55, 95% CI: 0.32–0.93).",
      ],
    },
    fetalAndNeonatalOutcomes: [
      {
        title: "Fetal and neonatal outcomes",
        content: [
          "Low-certainty evidence shows that daily iron may reduce the risk of low-birth-weight neonates (< 2500 g) (11 trials, 17 613 neonates; RR: 0.84, 95% CI: 0.69–1.03). High-certainty evidence shows that it does not reduce the risk of preterm birth before 37 weeks of gestation (13 trials, 19 286 women; RR: 0.93, 95% CI: 0.84–1.03), but it does reduce the risk of very preterm birth (i.e. less than 34 weeks of gestation) (5 trials, 3749 women; RR: 0.51, 95% CI: 0.29–0.91).",
          "Low-certainty evidence suggests that daily iron may have little or no effect on congenital anomalies (4 trials, 14 636 neonates; RR: 0.88, 95% CI: 0.58–1.33).",
          "Moderate-certainty evidence indicates that daily iron probably has little or no effect on neonatal deaths (4 trials, 16 603 neonates; RR: 0.91, 95% CI: 0.71–1.18).",
          "Neonatal infections and SGA were not reviewed as outcomes.",
        ],
      },
      {
        title: "Additional considerations",
        content:
          "Evidence from subgroups tended to be consistent with the overall findings for the main outcomes.",
      },
      {
        title: "Resources",
        content:
          "Daily iron and folic acid supplements are relatively low cost, at less than 1 United States dollar (US$ 1) per pregnant woman (17).",
      },
      {
        title: "Equity",
        content:
          "Iron deficiency and parasitic infections are more common in LMICs and disadvantaged populations. Poor, rural and least-educated populations also experience the highest maternal, infant and child mortality (18). Increasing coverage of effective nutritional interventions to prevent anaemia, particularly among disadvantaged populations, might help to address maternal and newborn health inequalities.",
      },
      {
        title: "Acceptability",
        content:
          "Qualitative evidence suggests that the availability of iron supplements may actively encourage women to engage with ANC providers (low confidence in the evidence) (19). However, where there are additional costs associated with supplementation or where the supplements may be unavailable (because of resource constraints) women are less likely to engage with ANC services (high confidence in the evidence). Lower doses of iron may be associated with fewer side-effects and therefore may be more acceptable to women than higher doses.",
      },
      {
        title: "Feasibility",
        content:
          "Qualitative evidence about the views of health-care providers suggests that resource constraints, both in terms of the availability of the supplements and the lack of suitably trained staff to deliver them, may limit implementation (high confidence in the evidence) (20).",
      },
      {
        title:
          "Further information and considerations related to this recommendation can be found in the WHO guidelines, available at:",
        content: (
          <Link href="http://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            http://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1
          </Link>
        ),
      },
    ],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "The successful introduction of evidence-based policies related to antenatal care into national programmes and health care services depends on well-planned and participatory consensus-driven processes of adaptation and implementation. These processes may include the development or revision of national guidelines or protocols based on this recommendation.",
        "The recommendation should be adapted into locally-appropriate documents and tools that are able to meet the specific needs of each country and health service. Modifications to the recommendation, where necessary, should be justified in an explicit and transparent manner.",
        "An enabling environment should be created for the use of this recommendation, including changes in the behaviour of health care practitioners to enable the use of evidence-based practices.",
        "Local professional societies may play important roles in this process and an all-inclusive and participatory process should be encouraged.",
        "Antenatal care models with a minimum of eight contacts are recommended to reduce perinatal mortality and improve women’s experience of care. Taking this as a foundation, the GDG reviewed how ANC should be delivered in terms of both the timing and content of each of the ANC contacts, and arrived at a new model – the 2016 WHO ANC model – which replaces the previous four-visit focused ANC (FANC) model. For the purpose of developing this new ANC model, the ANC recommendations were mapped to the eight contacts based on the evidence supporting each recommendation and the optimal timing of delivery of the recommended interventions to achieve maximal impact.",
      ],
    },
    additionalLists: [],
    researchImplications: {
      title: "Research implications",
      text: "The GDG identified these priority questions related to this recommendation",
      content: [
        "What is the most effective, acceptable and feasible regimen of recommended supplements (iron, calcium and folic acid)? Could micronutrients be combined into a single, or slow-release, formulation? To what extent do iron and calcium (or zinc) supplements compete for absorption?",
        "What is the most cost-effective iron compound and formulation (coated versus not) in terms of benefits and side effects?",
        "Can a rapid, portable, less invasive, and field-friendly test for iron deficiency anaemia be developed?",
        "Are there haemoconcentration risks associated with haemoglobin concentrations of more than 130 g/L in pregnancy?",
      ],
    },
    relatedLinks: {
      title: "Related links",
      text: (
        <Typography>
          WHO recommendations on antenatal care for a positive pregnancy
          experience (2016) -{" "}
          <Link href="https://web.archive.org/web/20200915144755/https://apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1">
            full document
          </Link>{" "}
          and{" "}
          <Link href="https://web.archive.org/web/20201219124651/https://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            evidence table
          </Link>
        </Typography>
      ),
      links: [
        {
          href: "https://web.archive.org/web/20201031220559/https://apps.who.int/iris/bitstream/handle/10665/255760/9789241565493-eng.pdf?sequence=1",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors",
        },
        {
          href: "https://web.archive.org/web/20201030093023/https://apps.who.int/iris/bitstream/handle/10665/249580/9789241549356-eng.pdf;jsessionid=7B8E18468A424E0301261B2CA6BCB324?sequence=1",
          text: "Pregnancy, Childbirth, Postpartum and Newborn Care: A guide for essential practice",
        },
        {
          href: "https://web.archive.org/web/20201103090439/https://www.who.int/reproductivehealth/en/",
          text: "WHO Programmes: Sexual and Reproductive health",
        },
        {
          href: "https://web.archive.org/web/20201018045702/https://www.who.int/nutrition/about_us/en/",
          text: "WHO Programmes: Department of Nutrition for Health and Development",
        },
        {
          href: "https://web.archive.org/web/20201111195601/https://www.who.int/health-topics/maternal-health",
          text: "Maternal Health",
        },
      ],
    },
    references: {
      title: "References",
      content: [
        "Guideline: daily iron and folic acid supplementation in pregnant women. Geneva: World Health Organization; 2012 (http://www. who.int/nutrition/publications/micronutrients/guidelines/daily_ifa_supp_pregnant_women/ en/, accessed 28 September 2016).",
        "The clinical use of blood in general medicine, obstetrics, paediatrics, surgery & anaesthesia, trauma & burns. Geneva: World Health Organization; 1998 (http://www.who.int/ bloodsafety/clinical_use/en/Manual_EN.pdf, accessed 29 September 2016).",
        "WHO; de Benoist B, McLean E, Egli I, Cogswell M, editors. Worldwide prevalence of anaemia 1993–2005. WHO global database on anaemia. Geneva: World Health Organization (WHO); 2008 (http://apps.who.int/iris/ bitstream/10665/43894/1/9789241596657_ eng.pdf, accessed 26 September 2016).",
        "Iron and folate supplementation: integrated management of pregnancy and childbirth (IMPAC). Standards for maternal and neonatal care 1.8. Geneva: Department of Making Pregnancy Safer, World Health Organization; 2006 (http://www.who.int/reproductivehealth/ publications/maternal_perinatal_health/ iron_folate_supplementation.pdf, accessed 29 September 2016).",
        "The international pharmacopoeia, 5th edition, volumes 1 and 2. Geneva: World Health Organization; 2015 (http://apps.who.int/phint/ en/p/about, accessed 4 October 2016).",
        "The global prevalence of anaemia in 2011. Geneva: World Health Organization; 2015 (http://apps.who.int/iris/bitstream/ 10665/177094/1/9789241564960_eng.pdf, accessed 29 September 2016).",
        "WHO; de Benoist B, McLean E, Egli I, Cogswell M, editors. Worldwide prevalence of anaemia 1993–2005. WHO global database on anaemia. Geneva: World Health Organization (WHO); 2008 (http://apps.who.int/iris/ bitstream/10665/43894/1/9789241596657_ eng.pdf, accessed 26 September 2016).",
        "The WHO Expert Committee on Specifications for Pharmaceutical Preparations. Quality assurance of pharmaceuticals: meeting a major public health challenge. Geneva: World Health Organization; 2007 (WHO/PSM/QSM/2007.5; http://www.who.int/medicines/publications/ brochure_pharma.pdf, accessed 4 October 2016).",
        "United Nations Children’s Fund, United Nations University, WHO. Iron deficiency anaemia: assessment, prevention, and control: a guide for programme managers. Geneva: World Health Organization (WHO); 2001 (WHO/NHD/01.3; http://www.who.int/nutrition/publications/ en/ida_assessment_prevention_control.pdf, accessed 29 September 2016).",
        "Guideline: daily iron and folic acid supplementation in pregnant women. Geneva: World Health Organization; 2012 (http://www. who.int/nutrition/publications/micronutrients/ guidelines/daily_ifa_supp_pregnant_women/ en/, accessed 28 September 2016).",
        "Global prevalence of vitamin A deficiency in populations at risk 1995–2005. WHO Global Database on vitamin A deficiency. Geneva: World Health Organization; 2009 (http://www. who.int/nutrition/publications/micronutrients/ vitamin_a_deficiency/9789241598019/en/, accessed 29 September 2016).",
        "WHO handbook for guideline development, 2nd edition. Geneva: World Health Organization; 2014 (http://www.who.int/kms/handbook_2nd_ ed.pdf, accessed 6 October 2016).",
        "GRADE [website]. The GRADE Working Group; 2016 (http://gradeworkinggroup.org/, accessed 27 October 2016).",
        "GRADE-CERQual [website]. The GRADECERQual Project Group; 2016 (https://cerqual. org/, accessed 27 October 2016).",
        "The DECIDE Project; 2016 (http://www.decide-collaboration.eu/, accessed 27 October 2016).",
        "Peña-Rosas JP, De-Regil LM, Garcia-Casal MN, Dowswell T. Daily oral iron supplementation during pregnancy. Cochrane Database Syst Rev. 2015;(7):CD004736.",
        "OneHealth Model: intervention treatment assumptions (draft 28 September). Geneva and Glastonbury (CT): United Nations InterAgency Working Group on Costing and the Futures Institute; 2013 (http://avenirhealth. org/Download/Spectrum/Manuals/ Intervention%20Assumptions%202013%20 9%2028.pdf, accessed 4 October 2016).",
        "State of inequality: reproductive, maternal, newborn and child health. Geneva: World Health Organization; 2015 (http://www.who.int/gho/ health_equity/report_2015/en/, accessed 29 September 2016).",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the use of routine antenatal services by pregnant women: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2016;(10):CD012392",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the provision of good quality routine antenatal care services by health staff: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2016",
      ],
    },
  },
  {
    id: "27",
    img: img19,
    imgAlt: "palm with pills",
    title:
      "WHO recommendation on intermittent oral iron and folic acid supplementation",
    date: "29 August 2021",
    recommendation: {
      title: "Recommendation",
      content:
        "Intermittent oral iron and folic acid supplementation with 120 mg of elemental irone and 2800 µg (2.8 mg) of folic acid once weekly is recommended for pregnant women to improve maternal and neonatal outcomes if daily iron is not acceptable due to side-effects, and in populations with anaemia prevalence among pregnant women of less than 20%.",
    },
    recommendationItalic: "(Context-specific recommendation)",
    publicationHistory: {
      firstPublished: "November 2016",
      updated: "No update planned",
      assessedAsUpToDate: "November 2016",
    },
    remarks: {
      title: "Remarks",
      content: [
        "This recommendation supersedes the previous WHO recommendation found in the 2012 Guideline: daily iron and folic acid supplementation in pregnant women (1) and should be considered alongside Recommendation on daily iron.",
        "In general, anaemia prevalence of less than 20% is classified as a mild public health problem (2).",
        "Before commencing intermittent iron supplementation, accurate measurement of maternal blood Hb concentrations is needed to confirm the absence of anaemia. Therefore, this recommendation may require a strong health system to facilitate accurate Hb measurement and to monitor anaemia status throughout pregnancy.",
        "If a woman is diagnosed with anaemia (Hb < 110 g/L) during ANC, she should be given 120 mg of elemental iron and 400 µg (0.4 mg) of folic acid daily until her Hb concentration rises to normal (Hb 110 g/L or higher) (3, 4). Thereafter, she can continue with the standard daily antenatal iron and folic acid dose (or the intermittent regimen if daily iron is not acceptable due to side-effects) to prevent recurrence of anaemia.",
        "Stakeholders may need to consider ways of reminding pregnant women to take their supplements on an intermittent basis and of assisting them to manage associated side-effects.",
        "The equivalent of 120 mg of elemental iron is 600 mg of ferrous sulfate hepahydrate, 360 mg of ferrous fumarate or 1000 mg of ferrous gluconate.",
      ],
    },
    background: {
      title: "Background",
      content: [
        "Anaemia is associated with iron, folate and vitamin A deficiencies. It is estimated to affect 38.2% of pregnant women globally, with the highest prevalence in the WHO regions of South-East Asia (48.7%) and Africa (46.3%), medium prevalence in the Eastern Mediterranean Region (38.9%) and the lowest prevalence in the WHO regions of the Western Pacific (24.3%), the Americas (24.9%) and Europe (25.8%) (2).",
        "Major contributory factors to anaemia include parasitic infections such as malaria, hookworm and schistosomiasis, in areas where these infections are endemic. In addition, chronic infections such as tuberculosis (TB) and HIV, and haemoglobinopathies such as sickle-cell disease, contribute to the prevalence of anaemia. It is estimated that 0.8 million pregnant women globally have severe anaemia (defined as a blood haemoglobin concentration < 70 g/L) (2). In pregnancy, severe anaemia is associated with an increased risk of maternal and infant mortality (3). It is estimated that about half of the anaemia found in pregnant women is amenable to iron supplementation (5); however, this may be quite variable and is likely to be much lower in malaria-endemic areas.",
        "In addition to causing anaemia, iron deficiency adversely affects the use of energy sources by muscles and, thus, physical capacity and work performance, and also adversely affects immune status and morbidity from infections (5). Folate (vitamin B9) deficiency, in addition to anaemia it is also linked to fetal neural tube defects (6). Vitamin A deficiency affects about 19 million pregnant women, mostly in Africa and South-East Asia, causing night blindness (7).",
      ],
    },
    methods: {
      title: "Methods",
      content: [
        "The ANC recommendations are intended to inform the development of relevant health-care policies and clinical protocols. These recommendations were developed in accordance with the methods described in the WHO handbook for guideline development (8). In summary, the process included: identification of priority questions and outcomes, retrieval of evidence, assessment and synthesis of the evidence, formulation of recommendations, and planning for the implementation, dissemination, impact evaluation and updating of the guideline.",
        "The quality of the scientific evidence underpinning the recommendations was graded using the Grading of Recommendations Assessment, Development and Evaluation (GRADE) (9) and Confidence in the Evidence from Reviews of Qualitative research (GRADE-CERQual) (10) approaches, for quantitative and qualitative evidence, respectively. Up-to-date systematic reviews were used to prepare evidence profiles for priority questions. The DECIDE (Developing and Evaluating Communication Strategies to support Informed Decisions and Practice based on Evidence) (11) framework, an evidence-to-decision tool that includes intervention effects, values, resources, equity, acceptability and feasibility criteria, was used to guide the formulation and approval of recommendations by the Guideline Development Group (GDG) – an international group of experts assembled for the purpose of developing this guideline – at three Technical Consultations between October 2015 and March 2016.",
        "To ensure that each recommendation is correctly understood and applied in practice, the context of all context-specific recommendations is clearly stated within each recommendation, and the contributing experts provided additional remarks where needed.",
        "In accordance with WHO guideline development standards, these recommendations will be reviewed and updated following the identification of new evidence, with major reviews and updates at least every five years.",
        <Typography>
          {" "}
          Further information on procedures for developing this recommendation
          are available{" "}
          <Link
            href="https://web.archive.org/web/20200915144755/https://apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1"
            target="_blank"
          >
            here.
          </Link>
        </Typography>,
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following questions:",
      content: [
        "For pregnant women (P), does intermittent iron supplementation (I) (with or without folic acid) compared with daily iron supplementation (with or without folic acid) (C), improve maternal and perinatal outcomes (O)?",
      ],
    },
    evidenceSummary: {
      title: "Evidence summary",
      content: [
        "The evidence on the effects of intermittent iron and folic acid was derived from a Cochrane review that included 27 trials from 15 countries; however, only 21 trials (involving 5490 women) contributed data to the review’s meta-analyses (12). All trials were conducted in LMICs with some degree of malaria risk (Argentina, Bangladesh, China, Guatemala, India, Indonesia, the Islamic Republic of Iran, Malawi, Malaysia, Mexico, Pakistan, Republic of Korea, Sri Lanka, Thailand and Viet Nam); however, only one trial specifically reported that it was conducted in a malaria-endemic area. Most of the intermittent iron regimens involved women taking weekly supplements, most commonly 120 mg elemental iron per week (range: 80–200 mg weekly), which was compared with daily regimens, most commonly 60 mg elemental iron daily (range: 40–120 mg daily). Where folic acid was also provided in the trials, it was administered weekly in the intermittent supplement groups (range: 400–3500 μg weekly) compared with the usual standard daily dose for control groups.",
      ],
    },
    maternalOutcomes: {
      title: "Maternal outcomes",
      content: [
        "Anaemia was reported in different ways across trials. Low-certainty evidence suggests there may be little or no difference between intermittent and daily iron supplementation in the effect on anaemia at term (4 trials, 676 women; RR: 1.22, 95% CI: 0.84–1.80). Moderate-certainty evidence shows that anaemia at or near term (defined as a Hb of < 110 g/L at 34 weeks of gestation or later) probably occurs more frequently with intermittent than daily iron supplementation (8 trials, 1385 women; RR: 1.66, 95% CI: 1.09–2.53), and that intermittent iron supplementation is probably less likely to be associated with a Hb concentration of more than 130 g/L than daily iron (15 trials, 2616 women; RR: 0.53, 95% CI: 0.38–0.74). No events of severe anaemia occurred in either group in six trials reporting this outcome (1240 women). The evidence on mean Hb concentrations at or near term and severe postpartum anaemia is of very low certainty. Limited evidence on maternal morbidity from one small trial (110 women) was assessed as very uncertain. Maternal infections and maternal satisfaction were not evaluated in the review.",
        "Side-effects: Moderate-certainty evidence shows that intermittent iron supplementation is probably less commonly associated with nausea than daily iron supplementation (7 trials, 1034 women; RR: 0.60, 95% CI: 0.37–0.97). However, the evidence on other specific side-effects (constipation, diarrhoea, heartburn or vomiting) or any side-effect is of very low certainty.",
      ],
    },
    fetalAndNeonatalOutcomes: [
      {
        title: "Fetal and neonatal outcomes",
        content: [
          "Low-certainty evidence suggests that intermittent iron supplementation may have a similar effect to daily iron supplementation on low birth weight (< 2500 g) (8 trials, 1898 neonates; RR: 0.82, 95% CI: 0.50–1.22). However, the evidence on preterm birth and very preterm birth was assessed as very uncertain. Evidence on the relative effects of intermittent versus daily iron supplementation on neonatal mortality is also very uncertain. Neonatal infections and SGA outcomes were not included in the review.",
        ],
      },
      {
        title: "Additional considerations",
        content: "",
      },
      {
        title: "Resources",
        content:
          "Intermittent iron and folic acid supplementation might cost a little less than daily iron and folic acid supplementation due to the lower total weekly dose of iron.",
      },
      {
        title: "Equity",
        content:
          "Intermittent iron and folic acid supplementation may have less impact on health inequalities than daily iron and folic acid supplementation, as anaemia is more common in disadvantaged populations.",
      },
      {
        title: "Acceptability",
        content:
          "Qualitative evidence suggests that the availability of iron supplements may actively encourage women to engage with ANC providers (low confidence in the evidence) (13). However, where there are additional costs associated with supplementation or where the supplements may be unavailable (because of resource constraints) women are less likely to engage with ANC services (high confidence in the evidence). Women may find intermittent iron supplementation more acceptable than daily iron supplementation, particularly if they experience side-effects with daily iron supplements.",
      },
      {
        title: "Feasibility",
        content:
          "Intermittent iron may be more feasible in some low-resource settings if it costs less than daily iron.",
      },
      {
        title:
          "Further information and considerations related to this recommendation can be found in the WHO guidelines, available at:",
        content: (
          <Link href="http://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            http://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1
          </Link>
        ),
      },
    ],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "The successful introduction of evidence-based policies related to antenatal care into national programmes and health care services depends on well-planned and participatory consensus-driven processes of adaptation and implementation. These processes may include the development or revision of national guidelines or protocols based on this recommendation.",
        "The recommendation should be adapted into locally-appropriate documents and tools that are able to meet the specific needs of each country and health service. Modifications to the recommendation, where necessary, should be justified in an explicit and transparent manner.",
        "An enabling environment should be created for the use of this recommendation, including changes in the behaviour of health care practitioners to enable the use of evidence-based practices.",
        "Local professional societies may play important roles in this process and an all-inclusive and participatory process should be encouraged.",
        "Antenatal care models with a minimum of eight contacts are recommended to reduce perinatal mortality and improve women’s experience of care. Taking this as a foundation, the GDG reviewed how ANC should be delivered in terms of both the timing and content of each of the ANC contacts, and arrived at a new model – the 2016 WHO ANC model – which replaces the previous four-visit focused ANC (FANC) model. For the purpose of developing this new ANC model, the ANC recommendations were mapped to the eight contacts based on the evidence supporting each recommendation and the optimal timing of delivery of the recommended interventions to achieve maximal impact.",
      ],
    },
    additionalLists: [],
    researchImplications: {
      title: "Research implications",
      text: "The GDG identified these priority questions related to this recommendation",
      content: [
        "What is the most effective, acceptable and feasible regimen of recommended supplements (iron, calcium and folic acid)? Could micronutrients be combined into a single, or slow-release, formulation? To what extent do iron and calcium (or zinc) supplements compete for absorption?",
        "What is the most cost-effective iron compound and formulation (coated versus not) in terms of benefits and side effects?",
        "Can a rapid, portable, less invasive, and field-friendly test for iron deficiency anaemia be developed?",
        "Are there haemoconcentration risks associated with haemoglobin concentrations of more than 130 g/L in pregnancy?",
      ],
    },
    relatedLinks: {
      title: "Related links",
      text: (
        <Typography>
          WHO recommendations on antenatal care for a positive pregnancy
          experience (2016) -{" "}
          <Link href="https://web.archive.org/web/20200915144755/https://apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1">
            full document
          </Link>{" "}
          and{" "}
          <Link href="https://web.archive.org/web/20201219124651/https://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            evidence table
          </Link>
        </Typography>
      ),
      links: [
        {
          href: "https://web.archive.org/web/20201031220559/https://apps.who.int/iris/bitstream/handle/10665/255760/9789241565493-eng.pdf?sequence=1",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors",
        },
        {
          href: "https://web.archive.org/web/20201030093023/https://apps.who.int/iris/bitstream/handle/10665/249580/9789241549356-eng.pdf;jsessionid=7B8E18468A424E0301261B2CA6BCB324?sequence=1",
          text: "Pregnancy, Childbirth, Postpartum and Newborn Care: A guide for essential practice",
        },
        {
          href: "https://web.archive.org/web/20201103090439/https://www.who.int/reproductivehealth/en/",
          text: "WHO Programmes: Sexual and Reproductive health",
        },
        {
          href: "https://web.archive.org/web/20201018045702/https://www.who.int/nutrition/about_us/en/",
          text: "WHO Programmes: Department of Nutrition for Health and Development",
        },
        {
          href: "https://web.archive.org/web/20201111195601/https://www.who.int/health-topics/maternal-health",
          text: "Maternal Health",
        },
      ],
    },
    references: {
      title: "References",
      content: [
        "Guideline: intermittent iron and folic acid supplementation in non-anaemic pregnant women. Geneva: World Health Organization; 2012 http://apps.who.int/iris/bitstream/10665/75335/1/9789241502016_eng.pdf, accessed 28 September 2016).",
        "The global prevalence of anaemia in 2011.Geneva: World Health Organization; (http://apps.who.int/iris/bitstream/10665/177094/1/9789241564960_eng.pdf,... 29 September 2016).",
        "WHO; de Benoist B, McLean E, Egli I, Cogswell M, editors. Worldwide prevalence of anaemia 1993–2005. WHO global database on anaemia. Geneva: World Health Organization (WHO); 2008 (http://apps.who.int/iris/bitstream/10665/43894/1/9789241596657_eng.pdf, accessed 26 September 2016).",
        "Iron and folate supplementation: integrated management of pregnancy and childbirth (IMPAC). Standards for maternal and neonatal care 1.8. Geneva: Department of Making Pregnancy Safer, World Health Organization; 2006 (http://www.who.int/reproductivehealth/publications/maternal_perinatal_he..., accessed 29 September 2016).",
        "United Nations Children’s Fund, United Nations University, WHO. Iron deficiency anaemia: assessment, prevention, and control: a guide for programme managers. Geneva: World Health Organization (WHO); 2001 (WHO/NHD/01.3; http://www.who.int/nutrition/publications/ en/ida_assessment_prevention_control.pdf, accessed 29 September 2016).",
        "Guideline: daily iron and folic acid supplementation in pregnant women. Geneva: World Health Organization; 2012 (http://www. who.int/nutrition/publications/micronutrients/ guidelines/daily_ifa_supp_pregnant_women/ en/, accessed 28 September 2016).",
        "Global prevalence of vitamin A deficiency in populations at risk 1995–2005. WHO Global Database on vitamin A deficiency. Geneva: World Health Organization; 2009 (http://www. who.int/nutrition/publications/micronutrients/ vitamin_a_deficiency/9789241598019/en/, accessed 29 September 2016).",
        "WHO handbook for guideline development, 2nd edition. Geneva: World Health Organization; 2014 (http://www.who.int/kms/handbook_2nd_ ed.pdf, accessed 6 October 2016).",
        "GRADE [website]. The GRADE Working Group; 2016 (http://gradeworkinggroup.org/, accessed 27 October 2016).",
        "GRADE-CERQual [website]. The GRADECERQual Project Group; 2016 (https://cerqual. org/, accessed 27 October 2016).",
        "The DECIDE Project; 2016 (http://www.decide-collaboration.eu/, accessed 27 October 2016).",
        "Peña-Rosas JP, De-Regil LM, Garcia-Casal MN, Dowswell T. Daily oral iron supplementation during pregnancy. Cochrane Database Syst Rev. 2015;(7):CD004736.",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the use of routine antenatal services by pregnant women: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2016;(10):CD012392",
      ],
    },
  },
  {
    id: "32",
    img: img17,
    imgAlt: "",
    title: "WHO recommendation on caffeine intake during pregnancy",
    date: "29 August 2021",
    recommendation: {
      title: "Recommendation",
      content: [
        "For pregnant women with high daily caffeine intake (more than 300 mg per day), lowering daily caffeine intake during pregnancy is recommended to reduce the risk of pregnancy loss and low-birth-weight neonates",
      ],
    },
    recommendationItalic: "(Context-specific recommendation)",
    publicationHistory: {
      firstPublished: "November 2016",
      updated: "No update planned",
      assessedAsUpToDate: "November 2016",
    },
    remarks: {
      title: "Remarks",
      content: [
        "Pregnant women should be informed that a high daily caffeine intake (> 300 mg per day) is probably associated with a higher risk of pregnancy loss and low birth weight.",
        "Caffeine is a stimulant found in tea, coffee, soft-drinks, chocolate, kola nuts and some over-the-counter medicines. Coffee is probably the most common source of high caffeine intake. A cup of instant coffee can contain about 60 mg of caffeine; however, some commercially brewed coffee brands contain more than 150 mg of caffeine per serving.",
        "Caffeine-containing teas (black tea and green tea) and soft drinks (colas and iced tea) usually contain less than 50 mg per 250 mL serving.",
        "Caffeine intake includes any product, beverage or food containing caffeine (i.e. brewed coffee, tea, cola-type soft drinks, caffeinated energy drinks, chocolate, caffeine tablets).",
      ],
    },
    background: {
      title: "Background",
      content: [
        "Pregnancy requires a healthy diet that includes an adequate intake of energy, protein, vitamins and minerals to meet maternal and fetal needs. However, for many pregnant women, dietary intake of vegetables, meat, dairy products and fruit is often insufficient to meet these needs, particularly in low and middle-income countries (LMICs) where multiple nutritional deficiencies often co-exist. In resource poor countries in sub-Saharan Africa, south-central and south-east Asia, maternal undernutrition is highly prevalent and is recognized as a key determinant of poor perinatal outcomes (1). However, obesity and overweight is also associated with poor pregnancy outcomes and many women in a variety of settings gain excessive weight during pregnancy. While obesity has historically been a condition associated with affluence, there is some evidence to suggest a shift in the burden of overweight and obesity from advantaged to disadvantaged populations (2).",
        "Caffeine is possibly the most widely used psychoactive substance in the world (3), and the GDG also evaluated evidence on the impact, if any, of caffeine restriction during pregnancy.",
      ],
    },
    methods: {
      title: "Methods",
      content: [
        "The ANC recommendations are intended to inform the development of relevant health-care policies and clinical protocols. These recommendations were developed in accordance with the methods described in the WHO handbook for guideline development (4). In summary, the process included: identification of priority questions and outcomes, retrieval of evidence, assessment and synthesis of the evidence, formulation of recommendations, and planning for the implementation, dissemination, impact evaluation and updating of the guideline.",
        "The quality of the scientific evidence underpinning the recommendations was graded using the Grading of Recommendations Assessment, Development and Evaluation (GRADE) (5) and Confidence in the Evidence from Reviews of Qualitative research (GRADE-CERQual) (6) approaches, for quantitative and qualitative evidence, respectively. Up-to-date systematic reviews were used to prepare evidence profiles for priority questions. The DECIDE (Developing and Evaluating Communication Strategies to support Informed Decisions and Practice based on Evidence) (7) framework, an evidence-to-decision tool that includes intervention effects, values, resources, equity, acceptability and feasibility criteria, was used to guide the formulation and approval of recommendations by the Guideline Development Group (GDG) – an international group of experts assembled for the purpose of developing this guideline – at three Technical Consultations between October 2015 and March 2016.",
        "To ensure that each recommendation is correctly understood and applied in practice, the context of all context-specific recommendations is clearly stated within each recommendation, and the contributing experts provided additional remarks where needed.",
        "In accordance with WHO guideline development standards, these recommendations will be reviewed and updated following the identification of new evidence, with major reviews and updates at least every five years.",
        <Typography>
          {" "}
          Further information on procedures for developing this recommendation
          are available{" "}
          <Link
            href="https://web.archive.org/web/20200915144755/https://apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1"
            target="_blank"
          >
            here.
          </Link>
        </Typography>,
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following questions:",
      content: [
        "For pregnant women (P), does restricting caffeine intake (I) compared with not restricting caffeine intake (C) improve maternal and perinatal outcomes (O)?",
      ],
    },
    evidenceSummary: {
      title: "Evidence summary",
      content: [
        "a) Effects of decaffeinated coffee versus caffeinated coffee (RCT evidence)",
        "Some evidence on the effects of caffeine intake was derived from a Cochrane review that included two RCTs (3). Only one of the trials, conducted in Denmark, contributed evidence. In this trial, 1207 pregnant women drinking more than three cups of coffee a day were randomized to receive instant decaffeinated coffee (intervention group) versus instant caffeinated coffee (control group) in order to assess the effect of caffeine reduction during pregnancy. In this trial, a cup of caffeinated coffee was estimated to contain 65 mg caffeine. Other sources of caffeine, such as cola, tea and chocolate were not restricted. Mean daily caffeine intake in the decaffeinated coffee group was 117 mg per day (interquartile range [IQR]: 56–228 mg) compared with 317 mg per day (IQR: 229–461 mg) in the caffeinated coffee group.",
      ],
    },
    maternalOutcomes: {
      title: "Maternal outcomes",
      content: [
        "None of the maternal outcomes addressed in the ANC guideline were reported in the review.",
      ],
    },
    fetalAndNeonatalOutcomes: [
      {
        title: "Fetal and neonatal outcomes",
        content: [
          "Low-certainty evidence from one trial shows that restricting caffeine intake (replacing caffeinated coffee with decaffeinated coffee) may have little or no effect on SGA (1150 neonates; RR: 0.97, 95% CI: 0.57–1.64), mean birth weight (1197 neonates; MD: 20.00, 95% CI: –48.68 to 88.68) and preterm birth (1153 neonates; RR: 0.81, 95% CI: 0.48–1.37). No data were available on congenital anomalies or perinatal mortality.",
        ],
      },
      {
        title: "",
        content: [
          "b) Effects of high caffeine intake versus moderate, low or no caffeine intake (nonrandomized study evidence)",
          "The GDG considered the evidence from RCTs to be insufficient to make a recommendation on caffeine restriction and additional evidence from reviews of non-randomized studies (NRSs) was thus evaluated. Two NRS reviews asked the question, “Is there an association between maternal caffeine intake and the risk of low birth weight?” (8, 9), and two reviews asked the question “Is there an association between maternal caffeine intake and the risk of pregnancy loss?” (10,11). In these reviews, low caffeine intake was defined as less than 150 mg caffeine per day, and high caffeine intake was defined as more than 300 mg or more than 350 mg per day. All four reviews adjusted data for smoking and other variables, and performed dose–response meta-analyses.",
        ],
      },
      {
        title: "Fetal and neonatal outcomes: low birth weight",
        content:
          "Moderate-certainty evidence from one review shows that high caffeine intake (more than 300 mg) is probably associated with a greater risk of low birth weight than low or no caffeine intake (12 studies; odds ratio [OR]: 1.38, 95% CI: 1.10–1.73) (9). Very low- to moderate-certainty evidence from the other review was stratified according to dose and shows that very low caffeine intake may be associated with fewer low-birth-weight neonates than low (5 studies; RR: 1.13, 95% CI: 1.06–1.21), moderate (7 studies; RR: 1.38, 95% CI: 1.18–1.62) or high caffeine intake (8 studies; RR: 1.60, 95% CI: 1.24–2.08) (8).",
      },
      {
        title: "Fetal and neonatal outcomes: stillbirths",
        content:
          "The reviews reported “pregnancy loss”, a composite outcome comprising stillbirths and miscarriages. Moderate-certainty evidence from one review (11) shows that any caffeine intake probably increases pregnancy loss compared with controls (no exposure) (18 studies; OR: 1.32, 95% CI: 1.24–1.40). However, pregnancy loss is probably more  common among pregnant women with moderate caffeine intake (18 studies; OR: 1.28, 95% CI: 1.16–1.42) and high caffeine intake (17 studies, OR: 1.60, 1.46–1.76), but not more common with low caffeine intake (13 studies; OR: 1.04, 95% CI: 0.94–1.15) compared with controls. This NRS evidence was upgraded to “moderate-certainty” due to the presence of a dose–response relationship. A dose–response relationship was also observed in the other review but the evidence was less certain (10).",
      },
      {
        title: "Resources",
        content:
          "Communicating with pregnant women about the probable risks of high caffeine intake during pregnancy is a relatively low-cost intervention.",
      },
      {
        title: "Equility",
        content:
          "Interventions to restrict coffee intake during pregnancy are unlikely to impact health inequalities as coffee consumption tends to be associated with affluence. However, it is unclear whether the consumption of caffeine through other sources might be a problem for pregnant women in disadvantaged populations.",
      },
      {
        title: "Acceptability",
        content:
          "Qualitative evidence indicates that women in a variety of settings generally appreciate any advice (including dietary or nutritional) that may lead to a healthy baby and a positive pregnancy experience (high confidence in the evidence) (12). Evidence on health-care providers’ views on ANC suggests that they may be keen to offer general health-careadvice and specific pregnancy-related information (low confidence in the evidence) but they sometimes feel they do not have the appropriate training and lack the resources and time to deliver the service in the informative, supportive and caring manner that women want (high confidence in the evidence) (13).",
      },
      {
        title: "Feasibility",
        content:
          "A lack of suitably trained staff to deliver health promotion interventions may limit implementation (high confidence in the evidence) (13).",
      },
      {
        title:
          "Further information and considerations related to this recommendation can be found in the WHO guidelines, available at:",
        content: (
          <Link href="http://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            http://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1
          </Link>
        ),
      },
    ],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "The successful introduction of evidence-based policies related to antenatal care into national programmes and health care services depends on well-planned and participatory consensus-driven processes of adaptation and implementation. These processes may include the development or revision of national guidelines or protocols based on this recommendation.",
        "The recommendation should be adapted into locally-appropriate documents and tools that are able to meet the specific needs of each country and health service. Modifications to the recommendation, where necessary, should be justified in an explicit and transparent manner.",
        "An enabling environment should be created for the use of this recommendation, including changes in the behaviour of health care practitioners to enable the use of evidence-based practices.",
        "Local professional societies may play important roles in this process and an all-inclusive and participatory process should be encouraged.",
        "Antenatal care models with a minimum of eight contacts are recommended to reduce perinatal mortality and improve women’s experience of care. Taking this as a foundation, the GDG reviewed how ANC should be delivered in terms of both the timing and content of each of the ANC contacts, and arrived at a new model – the 2016 WHO ANC model – which replaces the previous four-visit focused ANC (FANC) model. For the purpose of developing this new ANC model, the ANC recommendations were mapped to the eight contacts based on the evidence supporting each recommendation and the optimal timing of delivery of the recommended interventions to achieve maximal impact.",
      ],
    },
    additionalLists: [],
    researchImplications: {
      title: "Research implications",
      text: "The GDG did not identify any priority question related to this recommendation.",
      content: [],
    },
    relatedLinks: {
      title: "Related links",
      text: (
        <Typography>
          WHO recommendations on antenatal care for a positive pregnancy
          experience (2016) -{" "}
          <Link href="https://web.archive.org/web/20200915144755/https://apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1">
            full document
          </Link>{" "}
          and{" "}
          <Link href="https://web.archive.org/web/20201219124651/https://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            evidence table
          </Link>
        </Typography>
      ),
      links: [
        {
          href: "https://web.archive.org/web/20201031220559/https://apps.who.int/iris/bitstream/handle/10665/255760/9789241565493-eng.pdf?sequence=1",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors",
        },
        {
          href: "https://web.archive.org/web/20201030093023/https://apps.who.int/iris/bitstream/handle/10665/249580/9789241549356-eng.pdf;jsessionid=7B8E18468A424E0301261B2CA6BCB324?sequence=1",
          text: "Pregnancy, Childbirth, Postpartum and Newborn Care: A guide for essential practice",
        },
        {
          href: "https://web.archive.org/web/20201103090439/https://www.who.int/reproductivehealth/en/",
          text: "WHO Programmes: Sexual and Reproductive health",
        },
        {
          href: "http://web.archive.org/web/20221216214706/https://www.who.int/nutrition/about_us/en/",
          text: "WHO Programmes: Department of Nutrition for Health and Development",
        },
        {
          href: "https://web.archive.org/web/20201111195601/https://www.who.int/health-topics/maternal-health",
          text: "Maternal Health",
        },
      ],
    },
    references: {
      title: "References",
      content: [
        "Tang AM, Chung M, Dong K, Terrin N, Edmonds A, Assefa N et al. Determining a global midupper arm circumference cutoff to assess malnutrition in pregnant women. Washington (DC): FHI 360/Food and Nutrition Technical Assistance III Project (FANTA); 2016 (http:// www.fantaproject.org/sites/default/files/ resources/FANTA-MUAC-cutoffs-pregnantwomen-June2016.pdf, accessed 26 September 2016).",
        "Popkin S, Slining MM. New dynamics in global obesity facing low- and middle-income countries. Obes Rev. 2013;14(2):11–20. doi:10.1111/obr.12102.",
        "Jahanfar S, Jaafar SH. Effects of restricted caffeine intake by mother on fetal, neonatal andpregnancy outcomes. Cochrane Database Syst Rev.2015;(6):CD006965.",
        "WHO handbook for guideline development, 2nd edition. Geneva: World Health Organization; 2014 (http://www.who.int/kms/handbook_2nd_ ed.pdf, accessed 6 October 2016).",
        "GRADE [website]. The GRADE Working Group; 2016 (http://gradeworkinggroup.org/, accessed 27 October 2016).",
        "GRADE-CERQual [website]. The GRADECERQual Project Group; 2016 (https://cerqual. org/, accessed 27 October 2016).",
        "The DECIDE Project; 2016 (http://www.decide-collaboration.eu/, accessed 27 October 2016).",
        "Chen LW, Wu Y, Neelakantan N, Chong MF, Pan A, van Dam RM. Maternal caffeine intake during pregnancy is associated with risk of low birth weight: a systematic review and dose response meta-analysis. BMC Med. 2014;12:174.",
        "Rhee J, Kim R, Kim Y, Tam M, Lai Y, Keum N, Oldenburg CE. Maternal caffeine consumption during pregnancy and risk of low birth weight: a dose-response meta-analysis of observational studies. PLoS One. 2015;10(7):e0132334.doi:10.1371/journal.pone.0132334.",
        "Chen LW, Wu Y, Neelakantan N, Chong MF, Pan A, van Dam RM. Maternal caffeine intake during pregnancy and risk of pregnancy loss: a categorical and dose-response metaanalysis of prospective studies. Public Health Nutr. 2016:19(7):1233–44. doi:10.1017/S1368980015002463.",
        "Li J, Zhao H, Song JM, Zhang J, Tang YL, Xin CM. A meta-analysis of risk of pregnancy loss and caffeine and coffee consumption during pregnancy. Int J Gynaecol Obstet. 2015;130(2):116-22. doi:10.1016/j.ijgo.2015.03.033.",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the use of routine antenatal services by pregnant women: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2016;(10):CD012392",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the provision of good quality routine antenatal care services by health staff: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2016",
      ],
    },
  },
  {
    id: "42",
    img: img110,
    imgAlt: "palm with pills",
    template: "template-two",
    title:
      "WHO recommendation regarding Vitamin D supplementation during pregnancy",
    date: "29 August 2021",
    recommendation: {
      title: "Recommendation",
      content: [
        "Vitamin D supplementation is not recommended for pregnant women to improve maternal and perinatal outcomes.",
      ],
    },
    recommendationItalic: "",
    publicationHistory: {
      firstPublished: "October 2011",
      updated: "December 2016 & July 2020 ",
      assessedAsUpToDate: "July 2020",
    },
    remarks: {
      title: "Remarks",
      content: [
        "This recommendation updates and does not alter the respective WHO recommendation on vitamin D supplementation during pregnancy found in the WHO ANC guideline (1). ",
        "Pregnant women should be encouraged to receive adequate nutrition – which is best achieved through consumption of a healthy, balanced diet – and to refer to guidelines on healthy eating (2). ",
        "Pregnant women should be advised that sunlight is the most important source of vitamin D. The amount of time needed in the sun is not known and depends on many variables, such as the amount of skin exposed, the time of day, latitude and season, skin pigmentation (darker skin pigments synthesize less vitamin D than lighter pigments) and sunscreen use (3). ",
        "For pregnant women with suspected vitamin D deficiency, vitamin D supplements may be given at the current recommended nutrient intake of 200 IU (5 μg) per day (1,4). This may include women in populations where direct sun exposure is limited.",
      ],
    },
    background: {
      title: "Background",
      content: [
        "Pregnancy requires a healthy diet that includes an adequate intake of energy, protein, vitamins and minerals to meet increased maternal and fetal needs. Vitamin D is a fat-soluble vitamin that is mainly produced by the human body from exposure to sunlight. However, it can also be consumed from a few foods such as fish-liver oils, fatty fish, mushrooms, egg yolks and liver (5). Vitamin D is important for maintaining normal blood levels of calcium and phosphate, which are needed for general cell functioning in all cells of the body, but especially for bone health (3). Daily vitamin D intake is difficult to quantify because accurate food composition data for vitamin D are not available and because of the many variables that influence skin synthesis, which is reduced with dark skin pigmentation, insufficient exposure to sunlight, living in latitudes above 40 degrees, colder seasons, older age and sunscreen use (3). Fetuses acquire their vitamin D from their mothers, and this acquired store forms the main source of vitamin D for infants in the first few months of life, particularly among breastfed infants (6). ",
        "Deficiency of vitamin D is common worldwide, with a high prevalence occurring among pregnant women in Middle Eastern and Asian countries (7,8). In pregnancy, it has been implicated in the development of pre-eclampsia, gestational diabetes mellitus (GDM), preterm birth and low birthweight (9). ",
      ],
    },
    methods: {
      title: "Methods",
      content: [
        "In April 2019, following pre-established prioritization criteria, the Executive Guideline Steering Group prioritized updating of the recommendation on multiple micronutrient supplements (MMS). This resulting recommendation updates and supersedes the previous recommendation on antenatal MMS issued in the 2016 WHO ANC guideline (1). WHO convened a virtual Guideline Development Group (GDG)– an international group of experts assembled for the purpose of developing this guideline – meeting to review and update this recommendation on 4–5 December 2019, organized from Geneva, Switzerland. The recommendation was developed initially using the standardized operating procedures described in the WHO handbook and updated based on the WHO ‘living guideline’ approach for maternal and perinatal health recommendations (10,11).  ",
        "An updated Cochrane systematic review published by the Cochrane Pregnancy and Childbirth Group was the primary source of evidence on effectiveness of antenatal oral vitamin D supplements. Earlier versions of this review, in which evidence on effectiveness was derived from randomized controlled trial (RCT) data assessed and synthesized using standardized Cochrane methodology, supported the original ANC guideline recommendation. An additional Cochrane review was conducted to assess the effects and safety of different regimens of vitamin D supplementation alone or in combination with calcium or other vitamins, minerals or nutrients during pregnancy.  ",
        "Evidence profiles (in the form of Grading of Recommendations Assessment, Development and Evaluation [GRADE] tables, for quantitative) were prepared, including assessment of the certainty of the evidence, for comparisons of interest. Data from the Cochrane review were customized to reflect the key comparisons, GDG - specified subgroup analyses, and outcomes relevant to the ANC guideline. The DECIDE (Developing and Evaluating Communication Strategies to Support Informed Decisions and Practice Based on Evidence) framework – an evidence-to-decision tool that includes intervention effects, values, resources, equity, acceptability and feasibility criteria – was used to guide the formulation and approval of the recommendation. The latest versions of two qualitative systematic reviews commissioned by the WHO Steering Group for the 2016 guideline development process and systematic reviews of cost-effectiveness informed this framework (12,13). These reviews employed the Confidence in the Evidence from Reviews of Qualitative Research (GRADE-CERQual) approach for qualitative evidence. The GDG members reviewed, discussed and made judgements on the impact of the interventions for each of the EtD criteria. ",
        <Typography>
          {" "}
          Further information on procedures for developing this recommendation
          are available <Link></Link>
        </Typography>,
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following question:",
      content: [
        "for pregnant women (P), does vitamin D supplementation (I) compared with no vitamin D supplementation (C) improve maternal and perinatal health outcomes (O)?  ",
      ],
    },
    evidenceSummary: {
      title: "Evidence summery",
      content: [
        "This evidence was derived from an updated Cochrane systematic review that included 30 trials involving a total of 7033 women (7). Sample sizes ranged from 40 to 1298 women. Twenty-three trials compared the effects of vitamin D alone versus no supplementation or a placebo; and nine trials compared the effects of vitamin D plus calcium with no supplementation. The dose and regimen of vitamin D varied widely between the trials, as did the gestational age at enrolment. All included studies provided vitamin D supplements orally. ",
        "The updated review included data for:",
        "Comparison 1: Oral vitamin D supplement versus no vitamin D (placebo or no supplement); and ",
        "Comparison 2: Oral vitamin D + calcium supplement versus no vitamin D (placebo or no supplement) + calcium ",
      ],
    },
    comparison: [
      {
        title:
          "Comparison 1: Oral vitamin D supplement versus placebo or no vitamin D (placebo or no supplement)",
        content: [
          "Twenty-three trials involving a total of 5023 women contributed data to this comparison in the review. Twelve trials evaluated daily oral vitamin D with doses ranging from 200 IU to 2000 IU, with five trials using a dose of 1000 IU daily. In one trial the initial dose was 2000 IU daily, but this dose was increased to 4000 IU if the women remained deficient at 28 weeks. Two trials evaluated a single dose of 200 000 IU given at approximately 28 weeks of gestation; two trials evaluated 50 000 IU every two weeks; one trial evaluated 5000 IU weekly; one trial evaluated a single dose of 100 000 IU; two trials evaluated two doses of 60 000 IU during the third trimester; one trial evaluated a weekly dose of 35 000 IU during the third trimester; and one trial administered one to four vitamin D doses (60 000 IU to 480 000 IU in total) depending on the participant’s baseline serum 25-hydroxy vitamin D levels. The 5-arm trial randomized women to one of four different weekly doses of vitamin D, ranging from 4200 IU to 28 000 IU per week, or to placebo. ",
          "Maternal outcomes ",
          "Moderate-certainty evidence suggests that vitamin D supplementation probably makes little or no difference to the risk of caesarean section compared with placebo or no vitamin D (11 trials, 2402 women; risk ratio [RR]: 1.02, 95% confidence interval [CI]: 0.87 to 1.20). Low-certainty evidences suggests that vitamin D supplementation may reduce the risk of developing pre-eclampsia compared with placebo or no vitamin D (four trials, 499 women; RR: 0.48, 95% CI: 0.30 to 0.79). Low-certainty evidence suggests that vitamin D supplementation may reduce the risk of developing gestational diabetes mellitus (GDM) compared with placebo or no vitamin D (five trials, 1744 women; RR: 0.50, 95% CI: 0.28 to 0.88). The evidence on the effect of vitamin D on maternal mortality is of very low certainty.  ",
          "Fetal and neonatal outcomes ",
          "It is unclear whether or not vitamin D makes any difference to the risk of having a low birthweight neonate or to neonatal deaths compared with placebo or no vitamin D, as the certainty of the evidence is very low. Moderate-certainty evidence suggests that vitamin D supplementation makes little or no difference to the risk of preterm birth (< 37 weeks of gestation) (eight trials, 2938 women; RR: 0.78, 95% CI: 0.48 to 1.27) or to the risk of stillbirth (four trials, 1884 women; RR: 0.59, 95% CI: 0.28 to 1.22) compared with placebo or no Vitamin D. ",
        ],
      },
      {
        title:
          "Comparison 2: Oral vitamin D + calcium supplement versus no vitamin D + calcium (placebo or no supplement)  ",
        content: [
          "Nine trials involving 1916 women contributed data to this comparison. Vitamin D doses ranged from 200 IU to 1200 IU daily and calcium carbonate doses ranged from 375 mg to 1250 mg daily. ",
          "Maternal outcomes ",
          "Moderate-certainty evidence suggests that vitamin D plus calcium has little or no effect on caesarean section rates compared with placebo or no vitamin D plus calcium (two trials, 146 women; RR: 1.16, 95% CI: 0.87 to 1.54). Low-certainty evidence suggests that vitamin D plus calcium may reduce the risk of developing pre-eclampsia compared with placebo or no vitamin D plus calcium (four trials, 1174 women; RR: 0.50, 95% CI: 0.32 to 0.78). The evidence on the effect of vitamin D plus calcium on GDM is of very low certainty. Fetal and neonatal outcomes ",
          "Moderate-certainty evidence indicates that vitamin D plus calcium probably increases preterm birth (< 37 weeks of gestation) (3 trials, 798 women; RR: 1.57, 95% CI: 1.02–2.43). Low-certainty evidence suggests that vitamin D plus calcium has little or no effect on neonatal mortality (1 trial, 660 women; RR: 0.20, 95% CI: 0.01–4.14). ",
          "Fetal and neonatal outcomes ",
          "The evidence on the effect of vitamin D plus calcium on low birthweight and on neonatal deaths is of very low certainty. Low-certainty evidence suggests that vitamin D plus calcium may increase the risk of preterm birth (< 37 weeks of gestation) compared with placebo or no vitamin D plus calcium (five trials, 942 women; RR: 1.52, 95% CI: 1.01 to 2.28).  ",
        ],
      },
    ],
    maternalOutcomes: {
      title: "",
      content: "",
    },
    fetalAndNeonatalOutcomes: [],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "The successful introduction of this recommendation into national programmes and health-care services depends on well-planned and participatory consensus-driven processes of adaptation and implementation. The adaptation and implementation processes may include the development or revision of existing national guidelines or protocols based on this recommendation. ",
        "The recommendation should be adapted into a locally appropriate document that can meet the specific needs of each country and health service. Any changes should be made in an explicit and transparent manner. ",
        "A set of interventions should be established to ensure that an enabling environment is created for the use of the recommendations and that the behaviour of the healthcare practitioner changes towards the use of this evidence-based practice.",
        "In this process, the role of local professional societies is important and an all-inclusive and participatory process should be encouraged.",
        "The WHO antenatal care guidelines outline the 2016 WHO ANC model, which includes timing, content and frequency of antenatal care contacts.",
      ],
    },
    additionalLists: [
      {
        title: "Additional considerations ",
        content: [
          "With regard to calcium supplementation, the 2018 WHO recommendation on Calcium supplementation during pregnancy for the prevention of pre-eclampsia and its complications states the following: “In populations with low dietary calcium intake, daily calcium supplementation (1.5–2.0 g oral elemental calcium) is recommended for pregnant women to reduce the risk of pre-eclampsia” (14). ",
          "For pregnant women with documented low concentrations of 25-hydroxy vitamin D in nmol/L (a marker of vitamin D status), vitamin D supplements may be given at the current RNI of 200 IU (5 μg) per day, alone or as part of a multiple micronutrient supplement (1,4). ",
          "The Cochrane review (7) on which this evidence on effects is based also reported with moderate certainty that oral vitamin D supplementation probably reduces the risk of severe postpartum haemorrhage (PPH) compared with placebo or no vitamin D supplementation, based on the findings from one trial involving 1134 women (RR: 0.68, 95% CI: 0.51 to 0.91). The incidence of severe PPH in this trial was high (14%) and the definition of severe PPH was not provided in the report. ",
          "A further Cochrane review looked at the effect of different doses of vitamin D on pre-eclampsia, GDM, preterm birth and low birthweight, among other outcomes (15). Comparing a daily dose of more than 600 IU with a daily dose of 600 IU or less, the review found low-certainty evidence that the higher dose may reduce the risk of GDM more than the lower dose but that effects on the other three outcomes were similar. Comparing higher doses of 4000 IU daily or more with doses of less than 4000 IU daily did not reveal any clear differences, and most evidence was graded as being of low certainty by the reviewers. ",
          "The United Nations International Multiple Micronutrient Antenatal Preparation (UNIMMAP) comprises 15 micronutrients in its formulation, including 200 IU of vitamin D (but no calcium). The moderate-certainty evidence showing that adding vitamin D to calcium supplementation probably increases preterm birth is of concern and this potential harm needs further investigation. ",
        ],
      },
    ],
    researchImplications: {
      title: "Research implications",
      text: "During the recommendation development process, the GDG identified the following important knowledge gap that needs to be addressed through primary research: ",
      content: [
        "There are several ongoing RCTs on vitamin D supplementation in pregnancy (7,15). These should aim to provide clear evidence on: ",
        "Effectiveness ",
        "Adverse effects ",
        "Any additional benefits or harms of vitamin D when combined with other vitamins or minerals, particularly calcium ",
        "Optimal dose and timing (daily, intermittent, single-dose) ",
        "Optimal timing of initiation",
      ],
    },
    relatedLinks: {
      title: "Related links",
      text: (
        <Typography>
          <Link href="https://apps.who.int/iris/handle/10665/44703">
            WHO recommendations on prevention and treatment of pre-eclampsia and
            eclampsia (2011)
          </Link>{" "}
          and{" "}
          <Link href="http://apps.who.int/iris/bitstream/handle/10665/70731/WHO_RHR_11.25_eng.pdf%3Bjsessionid%3D80ED64C0D036D43C04B95829C7E6BA6B?sequence=1">
            evidence table
          </Link>
          {""}EB Table 51{""}
        </Typography>
      ),
      links: [
        {
          href: "http://web.archive.org/web/20230119130656/https://www.who.int/maternal_child_adolescent/documents/imca-essential-practice-guide/en/",
          text: "Pregnancy, Childbirth, Postpartum and Newborn Care: A guide for essential practice",
        },
        {
          href: "https://apps.who.int/iris/handle/10665/43972",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors",
        },
        {
          href: "http://web.archive.org/web/20230211013310/https://www.who.int/teams/maternal-newborn-child-adolescent-health-and-ageing",
          text: "WHO Programmes: Department of Maternal, Newborn, Child, Adolescent Health and Ageing ",
        },
        {
          href: "http://web.archive.org/web/20230301155724/https://www.who.int/nmh/about/nhd/en/",
          text: "WHO Programmes: Department of Nutrition and Food Safety ",
        },
        {
          href: "https://www.who.int/teams/sexual-and-reproductive-health-and-research-%28srh%29/overview",
          text: "WHO Programmes: Department of Sexual and Reproductive Health and Research",
        },
      ],
    },
    references: {
      title: "References",
      content: [
        "WHO recommendations on antenatal care for a positive pregnancy experience. Geneva: World Health Organization; 2016. ",
        "Healthy diet. Fact sheet No. 394. Geneva: World Health Organization; updated 30 August 2018 (https://www.who.int/nutrition/publications/nutrientrequirements/healthyd..., accessed 21 May 2020). ",
        "World Health Organization, Food and Agriculture Organization of the United Nations. Vitamin and mineral requirements in human nutrition. 2nd edition. Geneva: World Health Organization; 2004 (https://www.who.int/nutrition/publications/micronutrients/9241546123/en/, accessed 21 May 2020). ",
        "Guideline: Vitamin D supplementation in pregnant women. Geneva: World Health Organization; 2012 (https://apps.who.int/iris/bitstream/handle/10665/85313/9789241504935_eng..., accessed 21 May 2020). ",
        "Holick MF, Chen TC. Vitamin D deficiency: a worldwide problem with health consequences. Am J Clin Nutr. 2008;87(4):1080S–6S. doi:10.1093/ajcn/87.4.1080S. ",
        "Schoenmakers I, Pettifor JM, Peña-Rosas JP, Lamberg-Allardt C, Shaw N, Jones KS, et al. Prevention and consequences of vitamin D deficiency in pregnant and lactating women and children: a symposium to prioritise vitamin D on the global agenda. J Steroid Biochem Mol Biol. 2016;164:156–60. doi:10.1016/j.jsbmb.2015.11.004. ",
        "Palacios C, Kostiuk LK, Peña-Rosas JP. Vitamin D supplementation for women during pregnancy. Cochrane Database Syst Rev. 2019;7(7):CD008873. doi:10.1002/14651858.CD008873.pub4. ",
        "Bhowmik B, Siddique T, Majumder A, Mdala I, Hossain IA, Hassan Z, et al. Maternal BMI and nutritional status in early pregnancy and its impact on neonatal outcomes at birth in Bangladesh. BMC Pregnancy Childbirth. 2019;19(1):413. doi:10.1186/s12884-019-2571-5. ",
        "De-Regil LM, Palacios C, Lombardo LK, Peña-Rosas JP. Vitamin D supplementation for women during pregnancy. Cochrane Database Syst Rev. 2016;(1):CD008873. doi:10.1002/14651858.CD008873.pub3. ",
        "WHO handbook for guideline development. 2nd edition. Geneva: World Health Organization; 2014 (https://apps.who.int/iris/handle/10665/145714, accessed 21 May 2020). ",
        "Vogel JP, Dowswell T, Lewin S, Bonet M, Hampson L, Kellie F, et al. Developing and applying a ‘living guidelines’ approach to WHO recommendations on maternal and perinatal health. BMJ Glob Health. 2019;4(4):e001683. ",
        "Downe S, Finlayson K, Tunçalp Ö, Metin Gülmezoglu A. What matters to women: a scoping review to identify the processes and outcomes of antenatal care provision that are important to healthy pregnant women. BJOG. 2016;123(4):529–39. doi:10.1111/1471-0528.13819. ",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Provision and uptake of routine antenatal services: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2019;6:CD012392. doi:10.1002/14651858.CD012392. ",
        "WHO recommendation: Calcium supplementation during pregnancy for the prevention of pre-eclampsia and its complications. Geneva: World Health Organization; 2018 (https://apps.who.int/iris/bitstream/handle/10665/277235/9789241550451-en..., accessed 21 May 2020). ",
        "Palacios C, Trak-Fellermeier MA, Martinez RX, Lopez-Perez L, Lips P, Salisi JA, et al. Regimens of vitamin D supplementation for women during pregnancy. Cochrane Database Syst Rev. 2019;10(10):CD013446. doi:10.1002/14651858.CD013446. ",
      ],
    },
  },
  {
    id: "43",
    img: img111,
    imgAlt: "spoon with pills",
    template: "template-two",
    title:
      "WHO recommendation on multiple micronutrient supplementation during pregnancy",
    date: "29 August 2021",
    recommendation: {
      title: "Recommendation",
      content: [
        "Antenatal multiple micronutrient supplements that include iron and folic acid are recommended in the context of rigorous research*.",
        "* The GDG clarified that rigorous research includes implementation research using high-quality methods appropriate to the specific research questions.",
      ],
    },
    recommendationItalic: "(Context-specific recommendation - research) ",
    publicationHistory: {
      firstPublished: "November 2016",
      updated: "July 2020 ",
      assessedAsUpToDate: "July 2020",
    },
    remarks: {
      title: "Remarks",
      content: [
        "This recommendation updates and supersedes the WHO recommendation found in the WHO ANC guideline issued in 2016 (1). ",
        "The evidence is derived from trials using multiple micronutrient supplements (MMS) containing 13 to 15 micronutrients (including iron and folic acid [IFA]) and the widely available United Nations International Multiple Micronutrient Antenatal Preparation (UNIMMAP), which contains 15 micronutrients, including 30 mg of iron and 0.4 mg of folic acid. ",
        "As the evidence was mainly derived from low- and middle-income countries, its applicability to high-income countries or to populations not at risk of micronutrient deficiencies – for example, due to an adequate diet and food fortification programmes – is unclear.",
        "Research in this context therefore includes: (1)controlled clinical trials in which early pregnancy ultrasound is used to establish gestational age with certainty**, with assessment of critical maternal and perinatal outcomes, and follow-up of infants sustained into childhood; and  (2)where programmes of MMS are being considered, implementation research to establish the impact of switching from iron and folic acid supplements to MMS, including evaluation of acceptability, feasibility, sustainability, equity and cost-effectiveness.",
        "Many MMS contain 30 mg or less of elemental iron and WHO recommends antenatal iron and folic acid supplements containing 60 mg of elemental iron in populations where anaemia is a severe public health problem (a prevalence of 40% or higher) (2). Therefore, countries should consider their population magnitude and distribution of anaemia, its nutritional determinants (i.e. iron deficiency), as well as the magnitude and distribution of the complex low birthweight and its component parts (i.e. preterm, small for gestational age [SGA] or a combination of these) (3), when undertaking any research in the context of this recommendation. ",
        "Pregnant women should be supported and encouraged to receive adequate nutrition, which is best achieved through consumption of a healthy, balanced diet consistent with guidelines on healthy eating (4). ",
        "** Gestational age accurately assessed by ultrasound emerged as an important feature of future trials because of the conflicting and confusing differences in intervention effects found on low birthweight and its component parts (preterm birth, and SGA).",
      ],
    },
    background: {
      title: "Background",
      content: [
        "Pregnancy requires a healthy diet that includes an adequate intake of energy, protein, vitamins and minerals to meet increased maternal and fetal needs. However, for many pregnant women, dietary intake of fruit, vegetables, meat and dairy products is often insufficient to meet these needs, and may lead to micronutrient deficiencies. In resource-poor countries in sub-Saharan Africa, south-central Asia and south-east Asia, maternal undernutrition is highly prevalent and is recognized as a key determinant of poor perinatal outcomes (5). However, a clear understanding of the individual requirements and contributions of all essential vitamins and minerals to optimize maternal and fetal health during the antenatal period is limited (6). ",
      ],
    },
    methods: {
      title: "Methods",
      content: [
        "In April 2019, following pre-established prioritization criteria, the Executive Guideline Steering Group prioritized updating of the recommendation on multiple micronutrient supplements (MMS). This resulting recommendation updates and supersedes the previous recommendation on antenatal MMS issued in the 2016 WHO ANC guideline (1). WHO convened a virtual Guideline Development Group (GDG)– an international group of experts assembled for the purpose of developing this guideline – meeting to review and update this recommendation on 4–5 December 2019, organized from Geneva, Switzerland. The recommendation was developed initially using the standardized operating procedures described in the WHO handbook and updated based on the WHO ‘living guideline’ approach for maternal and perinatal health recommendations (7, 8).   ",
        "An updated Cochrane systematic review was the primary source of evidence on effectiveness of oral antenatal MMS. The scientific evidence supporting the recommendations was synthesized using the Grading of Recommendations Assessment, Development and Evaluation (GRADE) and Confidence in the Evidence from Reviews of Qualitative Research (GRADE-CERQual) approaches, for quantitative and qualitative evidence, respectively. Data from the Cochrane review were customized to reflect the key comparisons, GDG - specified subgroup analyses, and outcomes relevant to the ANC guideline. The DECIDE (Developing and Evaluating Communication Strategies to Support Informed Decisions and Practice Based on Evidence) framework – an evidence-to-decision tool that includes intervention effects, values, resources, equity, acceptability and feasibility criteria – was used to guide the formulation and approval of the recommendation. The latest versions of two qualitative systematic reviews commissioned by the WHO Steering Group for the 2016 guideline development process and systematic reviews of cost-effectiveness informed this framework (9,10). The GDG members reviewed, discussed and made judgements on the impact of the interventions for each of the EtD criteria. ",
        <Typography>
          {" "}
          Further information on procedures for developing this recommendation
          are available{" "}
          <Link href="https://www.who.int/publications/i/item/9789240007789">
            here
          </Link>
        </Typography>,
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following question:",
      content: [
        "For pregnant women (P), does antenatal MMS (I) that includes IFA compared with routine IFA supplementation (C) improve maternal and perinatal health outcomes (O)?  ",
      ],
    },
    evidenceSummary: {
      title: "Evidence summery",
      content: [
        "This evidence was derived from a Cochrane systematic review that included 20 trials involving 141,–849 women; however, only 16 trials contributed data to the updated WHO analysis (11). Of these 16 trials, six evaluated supplements with 13 or 14 micronutrients, including IFA; and 10 evaluated supplements with 15 micronutrients including vitamins A, D, E; niacin; folic acid; vitamins B1, B2, B6, B12, C; zinc, iron, iodine, selenium and copper, as per the UNIMMAP formulation (12). All the trials were conducted in LMICs. The GDG-specified WHO analyses were updated with these revised data to include: ",
        "Comparison 1: MMS with 13 to 15 micronutrients compared with IFA supplements. ",
        "Comparison 2: UNIMMAP supplements compared with IFA supplements. ",
        "The GDG also requested additional subgroup analyses according to the dose of iron in the control group because most trials in the review evaluated MMS containing 30 mg of elemental iron, and this was compared with IFA controls that employed either 30 mg or 60 mg of iron. Evidence from sensitivity analyses was not graded. ",
      ],
    },
    comparison: [
      {
        title:
          "Comparison 1: MMS with 13 to 15 micronutrients compared with IFA supplements ",
        content: [
          "Maternal outcomes ",
          "High-certainty evidence shows that MMS probably make little or no difference to maternal anaemia compared with IFA supplements (eight trials; risk ratio [RR]: 1.03, 95% confidence interval [CI]: 0.92 to 1.15). Compared to IFA supplements, low-certainty evidence indicates that MMN supplements probably make little or no difference to caesarean section rates (four trials; RR: 1.04, 95% CI: 0.76 to 1.43) and low-certainty evidence suggests that they may have little or no difference on maternal mortality (six trials; RR: 1.06, 95% CI: 0.72–1.54) compared to IFA supplements. Subgroup findings and sensitivity analyses were consistent with the overall findings for these outcomes. ",
          "Fetal and neonatal outcomes ",
          "High-certainty evidence suggest that MMS reduce the risk of having a low birth weight neonate (16 trials; RR: 0.88, 95% CI: 0.86 to 0.91) compared to IFA supplements but moderate-certainty evidence shows that MMS probably makes little to no difference to the risk of having a small for gestational age neonate compared to IFA supplements (15 trials; RR: 0.98, 95% CI: 0.96 to 1.00). Moderate-certainty evidence shows that MMS make little or no difference to preterm birth rates compared to IFA supplements (16 trials; RR: 0.94, 95% CI: 0.88 to 1.00).  ",
          "Subgroup findings for the effect on perinatal mortality differed according to the dose of iron (30 mg or 60 mg) in the IFA supplements and therefore subgroup data were not pooled. Moderate-certainty evidence for the 60 mg iron subgroup suggests there is probably little or no difference between MMS and IFA supplements (nine trials; RR: 1.15, 95% CI: 0.93 to 1.42); whereas moderate-certainty evidence for the 30 mg iron subgroup suggests that MMS are probably associated with lower perinatal mortality than IFA supplements (four trials; RR: 0.92, 95% CI: 0.86 to 0.98). Some subgroup evidence suggested that IFA supplements with 60 mg iron may be associated with lower neonatal mortality than MMS. Other subgroup evidence suggested that, when MMS were compared with IFA supplements containing the same dose of iron (30 mg), MMS may be associated with lower perinatal mortality than IFA supplements. However, these findings were uncertain.  ",
        ],
      },
      {
        title:
          "Comparison 2: UNIMMAP formulation compared with IFA supplements ",
        content: [
          "Maternal outcomes ",
          "The evidence on maternal outcomes was consistent with Comparison 1, and suggests little or no difference in the relative effects of UNIMMAP compared with IFA supplements (30 mg or 60 mg) on maternal anaemia, caesarean section and maternal mortality.  ",
          "Fetal and neonatal outcomes ",
          "Moderate-certainty evidence suggests that the UNIMMAP supplement probably reduces the risk of having an SGA neonate compared with IFA supplements. Consistent with Comparison 1, moderate-certainty suggests that the UNIMMAP supplement probably reduces the risk of having a low-birthweight neonate compared with IFA supplements.  ",
          "Consistent with Comparison 1, subgroup findings for perinatal and neonatal mortality differed according to the dose of iron in the IFA supplements. For perinatal mortality, moderate-certainty evidence for  the 60 mg iron subgroup suggests that IFA supplements were favoured (six trials; RR: 1.20, 95% CI: 0.95 to 1.51); while moderate-certainty evidence for the 30 mg iron subgroup, suggested that UNIMMAP was favoured (three trials; RR: 0.90, 95% CI: 0.80 to 1.01). However, neither of these effect estimates was statistically significant.  For neonatal mortality, moderate-certainty evidence for the 60 mg iron subgroup also suggested IFA supplements were favoured (six trials; RR: 1.25, 95% CI: 0.94 to 1.67) and UNIMMAP in the 30 mg iron subgroup (three trials; RR: 0.90, 95% CI: 0.78 to 1.05); however, both subgroup estimates included the possibility of no difference. In the sensitivity analysis restricted to studies using 0.4 mg of folic acid, there was a trend in favour of 60 mg IFA supplements that became statistically significant (five trials; RR: 1.38, 95% CI: 1.05 to 1.82).  ",
        ],
      },
    ],
    maternalOutcomes: {
      title: "",
      content: "",
    },
    fetalAndNeonatalOutcomes: [],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "The successful introduction of evidence-based policies related to antenatal care into national programmes and health care services depends on well-planned and participatory consensus-driven processes of adaptation and implementation. These processes may include the development or revision of national guidelines or protocols based on this recommendation.",
        "The recommendation should be adapted into locally-appropriate documents and tools that are able to meet the specific needs of each country and health service. Modifications to the recommendation, where necessary, should be justified in an explicit and transparent manner.",
        "An enabling environment should be created for the use of this recommendation, including changes in the behaviour of health care practitioners to enable the use of evidence-based practices.",
        "Local professional societies may play important roles in this process and an all-inclusive and participatory process should be encouraged.",
        "Antenatal care models with a minimum of eight contacts are recommended to reduce perinatal mortality and improve women’s experience of care. Taking this as a foundation, the GDG reviewed how ANC should be delivered in terms of both the timing and content of each of the ANC contacts, and arrived at a new model – the 2016 WHO ANC model – which replaces the previous four-visit focused ANC (FANC) model. For the purpose of developing this new ANC model, the ANC recommendations were mapped to the eight contacts based on the evidence supporting each recommendation and the optimal timing of delivery of the recommended interventions to achieve maximal impact.",
      ],
    },
    additionalLists: [
      {
        title: "Additional considerations ",
        content: [
          "In general, the research evidence suggests there may be some beneficial effects with MMS and that they may cause little harm compared with IFA supplements; however, this evidence was derived mostly from trials using MMS containing 30 mg of iron and 0.4 mg of folic acid, i.e. UNIMMAP. Many LMICs use IFA supplements with a higher dose of iron than 30 mg. Due to some uncertainty about the effects of switching from a higher dose of iron to a lower dose, more research is needed. ",
          "All evidence was derived from studies in LMICs; its applicability to other country settings is unclear. ",
          "WHO advises that 60 mg iron be taken daily by pregnant women and adolescent girls in settings with a high prevalence of anaemia (1). ",
          "A non–Cochrane review of MMS in LMIC countries (13) found that MMS reduced the risk of low birthweight by 14% (8–19%), preterm birth by 7% (2–13%) and SGA births by 6% (2–10%) on average compared with IFA supplements; the effects on low birthweight and SGA were greater among anaemic women than non-anaemic women. The review also found that, whilst there was no difference in neonatal mortality overall (RR: 0.99, 95% CI: 0.89 to 1.09), MMS were associated with lower neonatal mortality among female neonates by about 15% (4–25%). The review used individual patient data for 112 953 pregnant women from 17 RCTs comparing MMS with IFA supplements alone. In meta-analyses, data were pooled using a fixed effects model. Two trials, SUMMIT, 2008 (14) and West et al., 2014 (15), which used 30 mg and 27 mg of iron in the control arms, respectively, contributed more than two-thirds of the data. Trials among anaemic and/or malnourished pregnant women were also included in this review. These factors may explain differences in effect estimates between the Cochrane data used by WHO and the Smith et al. (2017) review (13). The latter also noted, however, that “some subgroups given multiple micronutrient supplements with low-dose iron (≤ 30 mg) had higher stillbirth and neonatal mortality than iron-folic acid alone with 60 mg iron”. ",
          "A meta-analysis of neonatal mortality data for the MMS versus 60 mg iron IFA comparison has also been the focus of a separate paper in which study methods are not reported in detail (16). This meta-analysis included data from the 60 mg study group of the MINIMat trial (17) that were not available in the 2019 Cochrane review (the latter only included data for the 30 mg IFA study group from this trial). Sudfeld and Smith (2019) also included data from one trial (18) that was excluded from the WHO analyses because its multiple MMS comprised fewer than 13 micronutrients. ",
          "Point estimates for RRs from these two additional trials favoured MMS and, overall, 11 trials included in their neonatal mortality analysis gave an RR of 1.05 (95% CI: 0.85 to 1.30), suggesting little or no difference in effect between MMS and IFA supplements. ",
          "A review of the effects of antenatal MMS compared with IFA supplements on health benefits for children used data from nine of the trials included in the 2015 Cochrane review (19), six of which assessed UNIMMAP (20). This review found no evidence of additional health benefits in the longer term with MMS, specifically for child mortality (nine trials), weight-for-age (four trials), height-for-age (six trials), head circumference (three trials) and cognitive function (four trials). ",
          "Resources",
          "Two economic analyses published in 2019 found MMS to be cost-effective compared with IFA supplements (21,22).",
          "Equity",
          "The WHO State of inequality report (2015) shows that women who are poor, least educated, and residing in rural areas have lower health intervention coverage and worse health outcomes than the more advantaged women in LMICs (23). ANC coverage of at least four visits differed according to the women’s education and income levels; inequalities in ANC coverage of at least one visit were also demonstrated, though to a lesser extent. In 50% of study countries, infant mortality was at least eight deaths per 1000 live births higher in rural than in urban areas and, in about a quarter of the study countries, neonatal mortality was at least 15 deaths per 1000 live births higher among the least educated. Stunting prevalence in children under 5 was also substantially unequal between the least and most educated mothers.",
          "Acceptability",
          "Qualitative evidence suggests that women in a variety of settings tend to view ANC as a source of knowledge and information and that they generally appreciate any advice (including dietary or nutritional) that may lead to a healthy baby and a positive pregnancy experience (high confidence in the evidence) (10). However, health-care providers have noted the lack of appropriate training, resources and time to deliver the service in the informative, supportive and caring manner that women want (high confidence in the evidence) (10).",
          "Feasibility",
          "Two economic analyses published in 2019 found MMS to be cost-effective compared with IFA supplements (21,22).",
          "Further information and considerations related to this recommendation can be found in the WHO guidelines, available in the Annex of this publication: https://www.who.int/publications/i/item/9789240007789",
        ],
      },
    ],
    researchImplications: {
      title: "Research implications",
      text: "During the recommendation development process, the GDG identified the following important knowledge gap that needs to be addressed through primary research:  ",
      content: [
        "What is the impact of switching from routine antenatal IFA supplements (either with 30 mg or 60 mg elemental iron) to MMS on important health outcomes (maternal, perinatal, child), equity, acceptability, feasibility, sustainability and health-care resources in different country settings? ",
      ],
    },
    relatedLinks: {
      title: "Related links",
      text: (
        <Typography>
          <Link href="https://apps.who.int/iris/handle/10665/44703">
            WHO recommendations on prevention and treatment of pre-eclampsia and
            eclampsia (2011)
          </Link>
          full document and{" "}
          <Link href="http://apps.who.int/iris/bitstream/handle/10665/70731/WHO_RHR_11.25_eng.pdf%3Bjsessionid%3D80ED64C0D036D43C04B95829C7E6BA6B?sequence=1">
            evidence table
          </Link>
          {""}EB Table 51{""}
        </Typography>
      ),
      links: [
        {
          href: "http://web.archive.org/web/20230211013310/https://www.who.int/teams/maternal-newborn-child-adolescent-health-and-ageing",
          text: "WHO Programmes: Department of Maternal, Newborn, Child, Adolescent Health and Ageing",
        },
        {
          href: "http://web.archive.org/web/20230113064826/https://www.who.int/maternal_child_adolescent/documents/managing-complications-pregnancy-childbirth/en/",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors",
        },
        {
          href: "http://web.archive.org/web/20230119130656/https://www.who.int/maternal_child_adolescent/documents/imca-essential-practice-guide/en/",
          text: "Pregnancy, Childbirth, Postpartum and Newborn Care: A guide for essential practice",
        },
        {
          href: "http://web.archive.org/web/20230301155724/https://www.who.int/nmh/about/nhd/en/",
          text: "WHO Programmes: Department of Nutrition and Food Safety ",
        },
        {
          href: "http://web.archive.org/web/20230207085811/http://www.who.int/reproductivehealth/en/",
          text: "WHO Programmes: Department of Sexual and Reproductive Health and Research",
        },
        {
          href: "http://web.archive.org/web/20230507050247/https://www.who.int/health-topics/maternal-health",
          text: "Maternal Health",
        },
        {
          href: "http://web.archive.org/web/20230205134858/https://gh.bmj.com/content/5/7/e003375",
          text: "WHO recommendations on antenatal nutrition: an update on multiple micronutrient supplements",
        },
      ],
    },
    references: {
      title: "References",
      content: [
        "WHO recommendations on antenatal care for a positive pregnancy experience. Geneva: World Health Organization; 2016. ",
        "Nutritional anaemias: tools for effective prevention and control. Geneva: World Health Organization; 2017 (https://apps.who.int/iris/bitstream/handle/10665/259425/9789241513067-en..., accessed 19 May 2020).",
        "United Nations Children’s Fund (UNICEF), World Health Organization (WHO). UNICEF-WHO low birthweight estimates: Levels and trends 2000–2015. Geneva: World Health Organization; 2019 (https://apps.who.int/iris/bitstream/handle/10665/324783/WHO-NMH-NHD-19.2..., accessed 19 May 2020). ",
        "Healthy diet. Fact sheet No. 394. Geneva: World Health Organization; updated 30 August 2018 (https://www.who.int/nutrition/publications/nutrientrequirements/healthyd..., accessed 19 May 2020). ",
        "Tang AM, Chung M, Dong K, Terrin N, Edmonds A, Assefa N, et al. Determining a global mid-upper arm circumference cutoff to assess malnutrition in pregnant women. Washington (DC): FHI 360/Food and Nutrition Technical Assistance III Project (FANTA); 2016 (http://www.fantaproject.org/sites/default/files/resources/FANTA-MUAC-cut..., accessed 19 May 2020). ",
        "World Health Organization, Food and Agriculture Organization of the United Nations. Vitamin and mineral requirements in human nutrition. 2nd edition. Geneva: World Health Organization; 2004 (https://www.who.int/nutrition/publications/micronutrients/9241546123/en/, accessed 19 May 2020). ",
        "WHO handbook for guideline development. 2nd edition. Geneva: World Health Organization; 2014 (https://apps.who.int/iris/handle/10665/145714, accessed 19 May 2020). ",
        "Vogel JP, Dowswell T, Lewin S, Bonet M, Hampson L, Kellie F, et al. Developing and applying a ‘living guidelines’ approach to WHO recommendations on maternal and perinatal health. BMJ Glob Health. 2019;4(4):e001683. ",
        "Downe S, Finlayson K, Tunçalp Ö, Metin Gülmezoglu A. What matters to women: a scoping review to identify the processes and outcomes of antenatal care provision that are important to healthy pregnant women. BJOG. 2016;123(4):529–39. doi:10.1111/1471-0528.13819.",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Provision and uptake of routine antenatal services: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2019;6:CD012392. doi:10.1002/14651858.CD012392. ",
        "Keats EC, Haider BA, Tam E, Bhutta ZA. Multiple-micronutrient supplementation for women during pregnancy. Cochrane Database Syst Rev. 2019;3:CD004905. doi:10.1002/14651858.CD004905.pub6. ",
        "World Health Organization, United Nations University, United Nations Children’s Fund. Composition of a multi-micronutrient supplement to be used in pilot programmes among pregnant women in developing countries: report of a United Nations Children’s Fund (UNICEF), World Health Organization (WHO) and United Nations University workshop. New York: UNICEF; 1999 (http://apps.who.int/iris/handle/10665/75358, accessed 19 May 2020). ",
        "Smith ER, Shankar AH, Lee S-F Wu, Aboud S, Adu-Afarwuah S, Ali H, et al (2017). Modifiers of the effect of maternal multiple micronutrient supplementation on stillbirth, birth outcomes, and infant mortality: a meta-analysis of individual patient data from 17 randomised trials in low-income and middle-income countries. Lancet Glob Health. 2017;5(11):e1090–100. doi:10.1016/S2214-109X(17)30371-6. ",
        "Supplementation with Multiple Micronutrients Intervention Trial (SUMMIT) Study Group, Shankar AH, Jahari AB, Sebayang SK, Aditiawarman, Apriatni M, et al. Effect of maternal multiple micronutrient supplementation on foetal loss and infant death in Indonesia: a double-blind cluster-randomised trial. Lancet. 2008;371(9608):215–27. doi:10.1016/S0140-6736(08)60133-6. ",
        "West KP Jr, Shamim AA, Mehra S, Labrique AB, Ali H, Shaikh S, et al. Effect of maternal multiple micronutrient vs iron–folic acid supplementation on infant mortality and adverse birth outcomes in rural Bangladesh: the JiVitA-3 randomized trial. JAMA. 2014;312(24):2649–58. doi:10.1001/jama.2014.16819. ",
        "Sudfeld CR, Smith ER. New evidence should inform WHO guidelines on multiple micronutrient supplementation in pregnancy. J Nutr. 2019;149(3):359–61. doi:10.1093/jn/nxy279. ",
        "Tofail F, Persson LA, Arifeen SE, Hamadani JD, Mehrin F, Ridout D, et al. Effects of prenatal food and micronutrient supplementation on infant development: a randomized trial from maternal and infant nutrition intervention, Matlab (MINIMat) study. Am J Clin Nutr. 2008;87(3):704–11. doi:10.1093/ajcn/87.3.704. ",
        "Fawzi WW, Msamanga GI, Urassa W, Hertzmark E, Petraro P, Willett WC, et al. Vitamins and perinatal outcomes among HIV-negative women in Tanzania. N Engl J Med. 2007;356(14):1423–31. doi:10.1056/NEJMoa064868. ",
        "Haider BA, Bhutta ZA. Multiple-micronutrient supplementation for women during pregnancy. Cochrane Database Syst Rev. 2015;11:CD004905. doi:10.1002/14651858.CD004905.pub4. ",
        "Devakumar D, Fall CHD, Sachdev HS, Margetts BM, Osmond C, Wells JCK, et al. Maternal antenatal multiple micronutrient supplementation for long-term health benefits in children: a systematic review and meta-analysis. BMC Med. 2016;14(1):90. doi:10.1186/s12916-016-0633-3. ",
        "Kashi B, Godin CM, Kurzawa ZA, Verney AMJ, Busch-Hallen JF, De-Regil, LM. Multiple micronutrient supplements are more cost-effective than iron and folic acid: modeling results from 3 high-burden Asian countries. J Nutr. 2019;149(7):1222–9. doi:10.1093/jn/nxz052. ",
        "Engle-Stone R, Kumordzie SM, Meinzen-Dick L, Vosti SA. Replacing iron-folic acid with multiple micronutrient supplements among pregnant women in Bangladesh and Burkina Faso: costs, impacts, and cost-effectiveness. Ann N Y Acad Sci. 2019;1444(1):35–51. doi:10.1111/nyas.14132. ",
        "State of inequality: reproductive, maternal, newborn and child health. Geneva: World Health Organization; 2015 (https://apps.who.int/iris/handle/10665/164590, accessed 19 May 2020). ",
      ],
    },
  },
  {
    id: "47",
    img: img112,
    imgAlt: "a woman and a doctor",
    template: "template-three",
    title: "WHO recommendation on balanced energy and protein supplementation during pregnancy",
    date: "29 August 2021",
    recommendationText:
      "Dietary advice is unlikely to yield any major benefits for either the infant or the mother. The best way of improving the dietary status of pregnant women may be to supplement their diets with energy-rich foods through community-based sustainable programmes. The best long-term solution is to raise the social and economic status of women.",
    evidence: [
      {
        title: "1. EVIDENCE SUMMARY",
        content:
          "Five interventions, previously reviewed separately, addressing energy and protein intake in pregnancy have been included in this Cochrane review.",
      },
      {
        title: "Nutritional advice:",
        content:
          "Advice to pregnant women to increase energy and especially protein intake seems to increase these intakes slightly, but no health benefits for infants or mothers have been documented, except in one study in which a reduction in preterm births was observed although this finding was not consistent with the lack of effect on mean gestational age or birth weight found in the same study.",
      },
      {
        title: "Balanced energy/protein supplementation:",
        content:
          "Compared with no energy supplementation, energy supplementation during pregnancy (300-850 kcal/day with less than 25% of that energy coming from protein) is associated with moderate increases in maternal weight gain and birth weight, and a substantial reduction in the risk of small for gestational age (SGA) infants (Relative risk [RR]: 0.68; 95% Confidence interval [CI]: 0.56–0.84). Three trials found important reductions in stillbirths (RR: 0.55; 95% CI: 0.31 0.97) and possibly neonatal deaths although this was not statistically significant (RR: 0.62; 95% CI: 0.37–1.05). In the few studies with follow-up in the postnatal period, neither any evidence of lasting weight improvement in mother or baby, nor of improved neurocognitive development at one or five years, was found.",
      },
      {
        title: "High protein supplementation:",
        content:
          "Two trials with 1076 women were included. There were no beneficial effects on any of the maternal or fetal outcomes and a statistically nonsignificant increase in neonatal death (RR: 2.78; 95% CI: 0.75 – 10.36) in one trial that provided data on this outcome.",
      },
      {
        title: "Isocaloric protein supplementation:",
        content:
          "Isocaloric protein supplementation, (in which less than 25% of the energy content of the supplement comes from protein), when compared to the same level of energy supplementation, was associated with a reduction in maternal weight gain as well as mean birth weight and increase in the risk of an SGA infant (one trial, RR: 1.35; 95% CI: 1.12–1.61). No change in gestational age distribution has been noted. Studies are not large enough to give reliable information on possible effects on perinatal mortality and do not give information on maternal health outcomes other than weight gain.",
      },
      {
        title:
          "Energy/protein restriction in overweight or with high weight gain women:",
        content: [
          "384 women participated in the three trials included. Energy/protein restriction reduced the weekly weight gain but there was inadequate evidence to assess any substantial beneficial or adverse effect on the mother and the baby.",
          "Overall, the methodology of the review seems to be rigorous and the trial characteristics are presented in detail.",
        ],
      },
      {
        title: "2. RELEVANCE TO UNDER-RESOURCED SETTINGS",
        content: [""],
      },
      {
        title: "2.1. Magnitude of the problem",
        content: [
          "Maternal under-nutrition and malnutrition are major problems in especially the poorest developing countries and are generally considered to be of importance for the high prevalence of low birth weight and fetal growth retardation. Limited access to high quality foods is the major reason for under-nutrition, but traditional food habits, food taboos and limited knowledge may also contribute to undernutrition.",
          "The important role of low birth weight and prematurity for perinatal mortality and morbidity in developing countries and its association with under-nutrition and malnutrition in the mothers, has motivated various attempts to improve pregnancy outcome through food supplementation.",
        ],
      },
      {
        title: "2.2. Applicability of the results",
        content: [
          "The most recent trial included in the review was published in 1997 (1). This trial was conducted in rural Gambia and the beneficial effects of high energy with balanced protein content supplementation would likely apply to similar settings where a substantial proportion of the population are undernourished. However, the applicability of findings from some of the studies included in the review could be questioned due to the time lag and large differences in demographic characteristics.",
        ],
      },
      {
        title: "2.3. Implementation of the intervention",
        content: [
          "Ideally, raising of the social and economic status of women in developing countries is the best long-term solution for improving the nutritional status of undernourished pregnant women. With regard to the specific interventions, the best way would be to supplement the diets of pregnant women with energy-rich foods through community-based sustainable programmes.",
          "The available information from the other trials does not suggest any additional benefit of providing nutritional advice and extra protein supplementation (high or isocaloric) but rather suggest the possibility of adverse effects hence, these should not be considered at all for implementation.",
        ],
      },
      {
        title: "3. RESEARCH",
        content: [
          "More basic research is needed on the aspects of maternal nutritional status most essential for fetal development and pregnancy outcome. Once this knowledge has been attained then interventions to change the composition of maternal diet based on this information can be undertaken",
          "Acknowledgements: None",
          "Sources of support: University of Uppsala, Sweden",
        ],
      },
      {
        title: "References",
        content:
          "Ceesay SN, Prentice AM, Cole TJ, Foord F, Weaver LT, Poskitt EME et al. Effects on birth weight and perinatal mortality of maternal dietary supplements in rural Gambia: 5 year randomised controlled trial. British Medical Journal 1997;315:786-790.",
      },
    ],
    abstract: {
      title: "",
      content: [
        "This document should be cited as: Lindmark G. Energy and protein intake in pregnancy: RHL commentary (last revised: 31 October 2003). The WHO Reproductive Health Library; Geneva: World Health Organization.",
      ],
    },
  },
  {
    id: "48",
    img: img11,
    imgAlt: "blister of pills and a hand",
    title:
      "WHO recommendation on Vitamin A supplementation in pregnancy",
    date: "20 September 2023",
    recommendation: {
      title: "Recommendation",
      content:
        "Vitamin A supplementation is only recommended for pregnant women in areas where vitamin A deficiency is a severe public health problem 1 , to prevent night blindness.",
    },
    recommendationItalic: "(Context-specific recommendation)",
    publicationHistory: {
      firstPublished: "November 2016",
      updated: "No update planned",
      assessedAsUpToDate: "November 2016",
    },
    remarks: {
      title: "Remarks",
      content: [
        "This recommendation supersedes the previous WHO recommendation found in the 2011 Guideline: vitamin A supplementation in pregnant women (1).",
        "Vitamin A is not recommended to improve maternal and perinatal outcomes.",
        "Vitamin A deficiency is a severe public health problem if 5% or more of women in a population have a history of night blindness in their most recent pregnancy in the previous 3–5 years that ended in a live birth, or if 20% or more of pregnant women have a serum retinol level below 0.70 μmol/L (2). Determination of vitamin A deficiency as a public health problem involves estimating the prevalence of deficiency in a population by using specific biochemical and clinical indicators of vitamin A status.",
        "Pregnant women should be encouraged to receive adequate nutrition, which is best achieved through consumption of a healthy, balanced diet, and to refer to WHO guidance on healthy eating (3).",
        "In areas where supplementation is indicated for vitamin A deficiency, it can be given daily or weekly. Existing WHO guidance suggests a dose of up to 10 000 IU vitamin A per day, or a weekly dose of up to 25 000 IU (1).",
        "A single dose of a vitamin A supplement greater than 25 000 IU is not recommended as its safety is uncertain. Furthermore, a single dose of a vitamin A supplement greater than 25 000 IU might be teratogenic if consumed between day 15 and day 60 from conception (1).",
        "There is no demonstrated benefit from taking vitamin A supplements in populations where habitual daily vitamin A intakes exceed 8000 IU or 2400 μg, and the potential risk of adverse events increases with higher intakes (above 10 000 IU) if supplements are routinely taken by people in these populations (4).",
      ],
    },
    background: {
      title: "Background",
      content: [
        "Pregnancy requires a healthy diet that includes an adequate intake of energy, protein, vitamins and minerals to meet increased maternal and fetal needs. However, for many pregnant women, dietary intake of fruit, vegetables, meat and dairy products is often insufficient to meet these needs, and may lead to micronutrient deficiencies. In resource-poor countries in sub-Saharan Africa, south-central Asia and south-east Asia, maternal undernutrition is highly prevalent and is recognized as a key determinant of poor perinatal outcomes (5). However, obesity and overweight is also associated with poor pregnancy outcomes and many women in a variety of settings gain excessive weight during pregnancy. While obesity has historically been a condition associated with affluence, there is some evidence to suggest a shift in the burden of overweight and obesity from advantaged to disadvantaged populations (6). Vitamin A deficiency affects about 19 million pregnant women, mostly in Africa and South-East Asia, causing night blindness (7)."
      ],
    },
    methods: {
      title: "Methods",
      content: [
        "The ANC recommendations are intended to inform the development of relevant health-care policies and clinical protocols. These recommendations were developed in accordance with the methods described in the WHO handbook for guideline development (8). In summary, the process included: identification of priority questions and outcomes, retrieval of evidence, assessment and synthesis of the evidence, formulation of recommendations, and planning for the implementation, dissemination, impact evaluation and updating of the guideline.",
        "The quality of the scientific evidence underpinning the recommendations was graded using the Grading of Recommendations Assessment, Development and Evaluation (GRADE) (9) and Confidence in the Evidence from Reviews of Qualitative research (GRADE-CERQual) (10) approaches, for quantitative and qualitative evidence, respectively. Up-to-date systematic reviews were used to prepare evidence profiles for priority questions. The DECIDE (Developing and Evaluating Communication Strategies to support Informed Decisions and Practice based on Evidence) (11) framework, an evidence-to-decision tool that includes intervention effects, values, resources, equity, acceptability and feasibility criteria, was used to guide the formulation and approval of recommendations by the Guideline Development Group (GDG) – an international group of experts assembled for the purpose of developing this guideline – at three Technical Consultations between October 2015 and March 2016.",
        "To ensure that each recommendation is correctly understood and applied in practice, the context of all context-specific recommendations is clearly stated within each recommendation, and the contributing experts provided additional remarks where needed.",
        "In accordance with WHO guideline development standards, these recommendations will be reviewed and updated following the identification of new evidence, with major reviews and updates at least every five years.",
        <Typography>
          {" "}
          Further information on procedures for developing this recommendation
          are available{" "}
          <Link
            href="https://iris.who.int/bitstream/handle/10665/250796/9789241549912-eng.pdf?sequence=1"
            target="_blank"
          >
            here.
          </Link>
        </Typography>,
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following question:",
      content: [
        "For pregnant women (P), does vitamin A supplementation (I) compared with no intervention or placebo (C) improve maternal and perinatal outcomes (O)?",
      ],
    },
    evidenceSummary: {
      title: "Evidence summary",
      content: [
        "Evidence was derived from a Cochrane systematic review of 19 trials of vitamin A (with or without other supplements) compared with no vitamin A (or placebo, or other supplements) involving over 310 000 women (12). All but one trial (conducted in the United Kingdom) were conducted in LMICs, including Bangladesh, China, Ghana, India, Indonesia, Malawi, Nepal, South Africa and the United Republic of Tanzania. Most trials were conducted in vitamin A deficient populations, with one study including only women living with HIV. Trials varied considerably in design, including in the dose and timing of the intervention. Ten trials contributed data to the comparison of vitamin A alone versus placebo or no treatment."
      ],
    },
    maternalOutcomes: {
      title: "Maternal outcomes",
      content:
        "Moderate-certainty evidence shows that vitamin A supplementation in vitamin A deficient populations during pregnancy probably reduces maternal anaemia (3 trials, 15 649 women; RR: 0.64, 95% CI: 0.43– 0.94), but that it probably has little or no effect on maternal mortality (4 trials, 101 574 women; RR: 0.88, 95% CI: 0.65–1.20). Low-certainty evidence on a composite outcome for maternal infection (including fever for more than one week at one week postnatally, puerperal fever greater than 38°C, subclinical mastitis and/or bacterial vaginosis) suggests that vitamin A supplementation may reduce maternal infection (5 trials, 17 313 women; average RR: 0.45, 95% CI: 0.2–0.99). Side-effects and other maternal ANC guideline outcomes were not reported in the trials."
    },
    fetalAndNeonatalOutcomes: [
      {
        title: "Fetal and neonatal outcomes",
        content:
          "High-certainly evidence shows that vitamin A supplementation makes little or no difference to perinatal mortality (76 176 women; RR: 1.01, 95% CI: 0.95–1.07), neonatal mortality (3 trials, 89 556 neonates; RR: 0.97, 95% CI: 0.90–1.05) or stillbirths (2 trials, 122 850 neonates; RR: 1.04, 95% CI: 0.98–1.10). Moderate-certainty evidence indicates that vitamin A supplementation probably has little or no effect on low birth weight (&lt; 2500 g) (4 trials, 14 599 neonates; RR: 0. 1.02, 95% CI: 0.89–1.16), and low- certainty evidence suggests that it may have little or no effect on preterm birth (5 trials, 40 137 women; RR: 0.98, 95% CI: 0.94–1.01). Neonatal infections and congenital anomalies were not reported in the trials."
      },
      {
        title: "Resources",
        content:
          "Vitamin A supplements are relatively inexpensive at approximately US$ 0.30 per woman per month (10 000 IU per day or 25 000 IU per week) (13). Vitamin A can be given as a daily or weekly supplement.",
      },
      {
        title: "Equity",
        content:
          "Effective nutritional interventions in disadvantaged populations could help to address health inequalities by improving nutritional status and promoting good maternal health.",
      },
      {
        title: "Acceptability",
        content:
          "Qualitative evidence suggests that women in a variety of settings tend to view ANC as a source of knowledge and information and that they generally appreciate any advice (including dietary or nutritional) that may lead to a healthy baby and a positive pregnancy experience (high confidence in the evidence) (14).",
      },
      {
        title: "Feasibility",
        content:
          "In a number of LMIC settings, providers feel that a lack of resources may limit implementation of recommended interventions (high confidence in the evidence)(14).",
      },
      {
        title:
          "Further information and considerations related to this recommendation can be found in the WHO guidelines, available in the Annex of this publication:",
        content: (
          <Link href="https://www.who.int/publications/i/item/9789241549912">
            https://www.who.int/publications/i/item/9789241549912
          </Link>
        ),
      },
    ],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "The successful introduction of evidence-based policies related to antenatal care into national programmes and health care services depends on well-planned and participatory consensus- driven processes of adaptation and implementation. These processes may include the development or revision of national guidelines or protocols based on this recommendation.",
        "The recommendation should be adapted into locally-appropriate documents and tools that are able to meet the specific needs of each country and health service. Modifications to the recommendation, where necessary, should be justified in an explicit and transparent manner.",
        "An enabling environment should be created for the use of this recommendation, including changes in the behaviour of health care practitioners to enable the use of evidence-based practices.",
        "Local professional societies may play important roles in this process and an all-inclusive and participatory process should be encouraged.",
        "Antenatal care models with a minimum of eight contacts are recommended to reduce perinatal mortality and improve women’s experience of care. Taking this as a foundation, the GDG reviewed how ANC should be delivered in terms of both the timing and content of each of the ANC contacts, and arrived at a new model – the 2016 WHO ANC model – which replaces the previous four-visit focused ANC (FANC) model. For the purpose of developing this new ANC model, the ANC recommendations were mapped to the eight contacts based on the evidence supporting each recommendation and the optimal timing of delivery of the recommended interventions to achieve maximal impact.",
      ],
    },
    additionalLists: [],
    relatedLinks: {
      title: "Related links",
      text: (
        <Typography>
          WHO recommendations on antenatal care for a positive pregnancy
          experience (2016) -{" "}
          <Link href="https://iris.who.int/bitstream/handle/10665/250796/9789241549912-eng.pdf?sequence=1">
            full document
          </Link>{" "}
          and{" "}
          <Link href="https://iris.who.int/bitstream/handle/10665/250796/9789241549912-websupplement-eng.pdf?amp;isAllowed=y&sequence=8">
            evidence table
          </Link>
        </Typography>
      ),
      links: [
        {
          href: "https://iris.who.int/handle/10665/255760",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors",
        },
        {
          href: "https://www.who.int/publications/i/item/9789241549356",
          text: "Pregnancy, Childbirth, Postpartum and Newborn Care: A guide for essential practice",
        },
        {
          href: "https://www.who.int/teams/maternal-newborn-child-adolescent-health-and-ageing/maternal-health/about/mca",
          text: "WHO Programmes: Department of Maternal, Newborn, Child, Adolescent Health and Ageing",
        },
        {
          href: "https://www.who.int/teams/maternal-newborn-child-adolescent-health-and-ageing/maternal-health/about/nutrition-and-food-safety",
          text: "WHO Programmes: Department of Nutrition and Food Safety",
        },
        {
          href: "https://www.who.int/teams/sexual-and-reproductive-health-and-research-(srh)/overview",
          text: "WHO Programmes: Department of Sexual and Reproductive Health and Research",
        },
        {
          href: "https://www.who.int/health-topics/maternal-health#tab=tab_1",
          text: "Maternal Health",
        },
      ],
    },
    references: {
      title: "References",
      content: [
        "Guideline: vitamin A supplementation in pregnant women. Geneva: World Health Organization; 2011 (http://apps.who.int/iris/bitstream/10665/44625/1/9789241501781_eng.pdf , accessed 29 September 2016).",
        "Sommer A, Davidson FR. Assessment and control of vitamin A deficiency: the Annecy Accords. J Nutr. 2002;132:2845S-50S.",
        "Healthy diet. Fact sheet No. 394. Geneva: World Health Organization; 2015 (http://www.who.int/mediacentre/factsheets/fs394/en/ , accessed 1 November 2016).",
        "Safe vitamin A dosage during pregnancy and lactation. Recommendations and report of a consultation. Micronutrient series. Geneva: World Health Organization; 1998 (WHO/NUT/98.4; http://apps.who.int/iris/bitstream/10665/63838/1/WHO_NUT_98.4_eng.pdf , accessed 29 September 2016).",
        "Tang AM, Chung M, Dong K, Terrin N, Edmonds A, Assefa N et al. Determining a global midupper arm circumference cutoff to assess malnutrition in pregnant women. Washington (DC): FHI 360/Food and Nutrition Technical Assistance III Project (FANTA); 2016 (http://www.fantaproject.org/sites/default/files/resources/FANTA-MUAC-cutoffs- pregnantwomen-June2016.pdf , accessed 26 September 2016).",
        "Popkin S, Slining MM. New dynamics in global obesity facing low- and middle-income countries. Obes Rev. 2013;14(2):11–20.doi:10.1111/obr.12102.",
        "Global prevalence of vitamin A deficiency in populations at risk 1995–2005. WHO Global Database on vitamin A deficiency. Geneva: World Health Organization; 2009 (http://www.who.int/nutrition/publications/micronutrients/vitamin_a_deficiency/9789241598 019/en/, accessed 29 September 2016).",
        "WHO handbook for guideline development, 2nd edition. Geneva: World Health Organization; 2014 (http://www.who.int/kms/handbook_2nd_ ed.pdf, accessed 6 October 2016).",
        "GRADE [website]. The GRADE Working Group; 2016 (http://gradeworkinggroup.org/, accessed 27 October 2016).",
        "GRADE-CERQual [website]. The GRADECERQual Project Group; 2016 (https://cerqual. org/, accessed 27 October 2016).",
        "The DECIDE Project; 2016 (http://www.decide-collaboration.eu/, accessed 27 October 2016).",
        "McCauley ME, van den Broek N, Dou L, Othman M. Vitamin A supplementation during pregnancy for maternal and newborn outcomes. Cochrane Database Syst Rev. 2015;(10):CD008666.",
        "OneHealth Model: intervention treatment assumptions (draft 28 September). Geneva and Glastonbury (CT): United Nations InterAgency Working Group on Costing and the Futures Institute; 2013 (http://avenirhealth.org/Download/Spectrum/Manuals/Intervention%20Assumptions%202013%209%2028.pdf , accessed 4 October 2016).",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the use of routine antenatal services by pregnant women: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2016;(10):CD012392.",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the provision of good‐quality routine antenatal services: a qualitative evidence synthesis of the views and experiences of maternity care providers. Cochrane Database Syst Rev. 2017 Aug 25;2017(8):CD012752. doi: 10.1002/14651858.CD012752. PMCID: PMC6483720.",
      ],
    },
  },
  {
    id: "49",
    img: img10,
    imgAlt: "a group of women",
    title:
      "WHO recommendation on counselling about healthy eating and keeping physically active during pregnancy",
    date: "20 September 2023",
    recommendation: {
      title: "Recommendation",
      content:
        "Counselling about healthy eating and keeping physically active during pregnancy is recommended for pregnant women to stay healthy and to prevent excessive weight gain during pregnancy.",
    },
    recommendationItalic: "(Recommended)",
    publicationHistory: {
      firstPublished: "November 2016",
      updated: "No update planned",
      assessedAsUpToDate: "November 2016",
    },
    remarks: {
      title: "Remarks",
      content: [
        "A healthy diet contains adequate energy, protein, vitamins and minerals, obtained through the consumption of a variety of foods, including green and orange vegetables, meat, fish, beans, nuts, whole grains and fruit (1).",
        "Stakeholders may wish to consider culturally appropriate healthy eating and exercise interventions to prevent excessive weight gain in pregnancy, particularly for populations with a high prevalence of overweight and obesity, depending on resources and women’s preferences. Interventions should be woman-centred and delivered in a non-judgemental manner, and developed to ensure appropriate weight gain (see further information in points below).",
        "A healthy lifestyle includes aerobic physical activity and strength-conditioning exercise aimed at maintaining a good level of fitness throughout pregnancy, without trying to reach peak fitness level or train for athletic competition. Women should choose activities with minimal risk of loss of balance and fetal trauma (2).",
        "Most normal gestational weight gain occurs after 20 weeks of gestation and the definition of “normal” is subject to regional variations, but should take into consideration pre-pregnant body mass index (BMI).",
        "According to the Institute of Medicine classification (3), women who are underweight at the start of pregnancy (i.e. BMI &lt; 18.5 kg/m2) should aim to gain 12.5–18 kg, women who are normal weight at the start of pregnancy (i.e. BMI 18.5–24.9 kg/m2) should aim to gain 11.5–16 kg, overweight women (i.e. BMI 25–29.9 kg/m2) should aim to gain 7–11.5 kg, and obese women(i.e. BMI &gt; 30 kg/m2) should aim to gain 5–9 kg.",
        "Most evidence on healthy eating and exercise interventions comes from high-income countries (HICs), and the GDG noted that that there are at least 40 ongoing trials in HICs in this field. The GDG noted that research is needed on the effects, feasibility and acceptability of healthy eating and exercise interventions in LMICs.",
        "Pregnancy may be an optimal time for behaviour change interventions among populations with a high prevalence of overweight and obesity, and the longer-term impact of these interventions on women, children and partners needs investigation.",
        "The GDG noted that a strong training package is needed for practitioners, including standardized guidance on nutrition. This guidance should be evidence-based, sustainable, reproducible, accessible and adaptable to different cultural settings.",
      ],
    },
    background: {
      title: "Background",
      content: [
        "Pregnancy requires a healthy diet that includes an adequate intake of energy, protein, vitamins and minerals to meet increased maternal and fetal needs. However, for many pregnant women, dietary intake of fruit, vegetables, meat and dairy products is often insufficient to meet these needs, and may lead to micronutrient deficiencies. In resource-poor countries in sub-Saharan Africa, south-central Asia and south-east Asia, maternal undernutrition is highly prevalent and is recognized as a key determinant of poor perinatal outcomes (4). However, obesity and overweight is also associated with poor pregnancy outcomes and many women in a variety of settings gain excessive weight during pregnancy. While obesity has historically been a condition associated with affluence, there is some evidence to suggest a shift in the burden of overweight and obesity from advantaged to disadvantaged populations (5)."
      ],
    },
    methods: {
      title: "Methods",
      content: [
        "The ANC recommendations are intended to inform the development of relevant health-care policies and clinical protocols. These recommendations were developed in accordance with the methods describedin the WHO handbook for guideline development (6). In summary, the process included: identification of priority questions and outcomes, retrieval of evidence, assessment and synthesis of the evidence, formulation of recommendations, and planning for the implementation, dissemination, impact evaluation and updating of the guideline.",
        "The quality of the scientific evidence underpinning the recommendations was graded using the Grading of Recommendations Assessment, Development and Evaluation (GRADE) (7) and Confidence in the Evidence from Reviews of Qualitative research (GRADE-CERQual) (8) approaches, for quantitative and qualitative evidence, respectively. Up-to-date systematic reviews were used to prepare evidence profiles for priority questions. The DECIDE (Developing and Evaluating Communication Strategies to support Informed Decisions and Practice based on Evidence) (9) framework, an evidence-to-decision tool that includes intervention effects, values, resources, equity, acceptability and feasibility criteria, was used to guide the formulation and approval of recommendations by the Guideline Development Group (GDG) – an international group of experts assembled for the purpose of developing this guideline – at three Technical Consultations between October 2015 and March 2016.",
        "To ensure that each recommendation is correctly understood and applied in practice, the context of all context-specific recommendations is clearly stated within each recommendation, and the contributing experts provided additional remarks where needed. In accordance with WHO guideline development standards, these recommendations will be reviewed and updated following the identification of new evidence, with major reviews and updates at least every five years.",
        <Typography>
          {" "}
          Further information on procedures for developing this recommendation
          are available{" "}
          <Link
            href="https://iris.who.int/bitstream/handle/10665/250796/9789241549912-eng.pdf?sequence=1"
            target="_blank"
          >
            here.
          </Link>
        </Typography>,
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following question:",
      content: [
        "For pregnant women (P), do diet and/or exercise interventions compared with standard ANC (C) improve maternal and perinatal health outcomes (O)?",
      ],
    },
    evidenceSummary: {
      title: "Evidence summary",
      content: [
        "Evidence on the effects of healthy eating and exercise interventions was derived from a Cochrane review that included 65 randomized controlled trials (RCTs), mostly conducted in HICs (10). Four trials recruited women from the general population (i.e. women of a wide range of BMIs at baseline), 24 trials recruited overweight and/or obese women and seven recruited women defined as being at high risk of gestational diabetes. In total, 49 RCTs involving 11 444 women contributed data to the review’s meta- analyses. Diet interventions were defined as a special selection of food or energy intake to which a participant was restricted, which were most commonly “healthy eating” types of diets. Exercise interventions were defined by reviewers as any activity requiring physical effort, carried out to sustain or improve health or fitness, and these were either prescribed/unsupervised (e.g. 30 minutes of daily walking), supervised (e.g. a weekly supervised group exercise class) or both. These interventions were usually compared with “standard ANC” and aimed to prevent excessive gestational weight gain (EGWG)."
      ],
    },
    maternalOutcomes: {
      title: "Maternal outcomes",
      content:[
        "High-certainty evidence shows that women receiving diet and/or exercise interventions as part of ANC to prevent EGWG are less likely to experience EGWG (24 trials, 7096 women; relative risk [RR]: 0.80, 95% confidence interval [CI]: 0.73–0.87; absolute effect of 91 fewer women with EGWG per 1000 on average). Subgroup analyses were consistent with these findings.",
        "High-certainty evidence shows that diet and/or exercise interventions have little or no effect on preeclampsia risk (15 trials, 5330 women; RR: 0.95, 95% CI: 0.77–1.16). However, moderate-certainty evidence indicates that diet and/or exercise interventions probably prevent hypertension in pregnancy (11 trials, 5162 women; RR: 0.70, 95% CI: 0.51–0.96).",
        "Low-certainty evidence suggests that diet and/or exercise interventions may have little or no effect on caesarean section (28 trials, 7534 women; RR: 0.95, 95% CI: 0.88–1.03); however, low-certainty evidence from the diet and exercise counselling subgroup of trials suggests that reductions in caesarean section rates may be possible with this intervention (9 trials, 3406 women; RR: 0.87, 95% CI: 0.75–1.01). Moderate-certainty evidence indicates that diet and/or exercise interventions probably make little or no difference to induction of labour (8 trials, 3832 women; RR: 1.06, 95% CI: 0.94–1.19).",
        "Low-certainty evidence suggests that diet and/or exercise interventions may reduce the risk of gestational diabetes mellitus (GDM) (19 trials, 7279 women; RR: 0.82, 95% CI: 0.67–1.01).",
      ]
    },
    fetalAndNeonatalOutcomes: [
      {
        title: "Fetal and neonatal outcomes",
        content:[
          "Moderate-certainty evidence suggests that diet and/or exercise interventions probably prevent neonatal macrosomia (27 trials, 8598 women; RR: 0.93, 95% CI: 0.86–1.02), particularly in overweight and obese women receiving diet and exercise counselling interventions (9 trials, 3252 neonates; RR: 0.85, 95% CI: 0.73–1.00). However, moderate certainty evidence indicates that diet and exercise interventions probably have little or no effect on neonatal hypoglycaemia (4 trials, 2601 neonates; RR: 0.95, 95% CI: 0.76–1.18) or shoulder dystocia (4 trials, 3253 neonates; RR: 1.02, 95% CI: 0.57–1.83). Low- certainty evidence suggests that neonatal respiratory morbidity may occur less frequently with diet and exercise counselling interventions than controls, particularly among overweight and obese women (2 studies, 2256 women; RR: 0.47, 95% CI: 0.26–0.85).",
          "Low-certainty evidence suggests that diet and/or exercise interventions may have little or no effect on preterm birth (16 trials, 5923 women; RR: 0.91, 95% CI: 0.68–1.22), and the evidence on low-birth-weight neonates is very uncertain. Perinatal mortality was not reported in the review.",
        ]
      },
      {
        title: "Additional considerations",
        content:[
          "High-certainty evidence from the review also shows that low gestational weight gain is more likely to occur with these interventions (11 trials, 4422 women; RR: 1.14, CI: 1.02–1.27); the clinical relevance of this finding is not known.",
          "The effects, acceptability and feasibility of diet and exercise interventions in LMICs has not been established.",
        ]
      },
      {
        title: "Resources",
        content:
          "Cost implications of diet and exercise interventions for health services are highly variable.",
      },
      {
        title: "Equity",
        content:
          "Most of the evidence came from trials conducted in HICs. Recent studies have reported a shift in the burden of overweight and obesity from advantaged to disadvantaged populations (11). Such a trend increases the risk of associated pregnancy complications, as well as cardiometabolic problems, among pregnant women from disadvantaged populations. These risks might be further exacerbated amongwomen in low-resource community settings, as these settings may not be equipped to deal withcomplications.",
      },
      {
        title: "Acceptability",
        content:
          "Qualitative evidence indicates that women in a variety of settings tend to view ANC as a source of knowledge and information and that they generally appreciate any advice (including dietary or nutritional) that may lead to a healthy baby and a positive pregnancy experience (high confidence in the evidence) (12). It also suggests that women may be less likely to engage with health services if advice is delivered in a hurried or didactic manner (high confidence in the evidence) (12). Therefore, these types of interventions are more likely to be acceptable if the interventions are delivered in an unhurried and supportive way, which may also facilitate better engagement with ANC services. Qualitative evidence on health-care providers’ views of ANC suggests that they may be keen to offer general health-care advice and specific pregnancy-related information (low confidence in the evidence) but they sometimes feel they do not have the appropriate training and lack the resources and time to deliver the service in the informative, supportive and caring manner that women want (high confidence in the evidence) (13)."
      },
      {
        title: "Feasibility",
        content:
          "In a number of LMIC settings, providers feel that a lack of resources may limit implementation of recommended interventions (high confidence in the evidence) (13).",
      },
      {
        title:
          "Further information and considerations related to this recommendation can be found in the WHO guidelines, available in the Annex of this publication:",
        content: (
          <Link href="https://www.who.int/publications/i/item/9789241549912">
            https://www.who.int/publications/i/item/9789241549912
          </Link>
        ),
      },
    ],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "The successful introduction of evidence-based policies related to antenatal care into national programmes and health care services depends on well-planned and participatory consensus- driven processes of adaptation and implementation. These processes may include the development or revision of national guidelines or protocols based on this recommendation.",
        "The recommendation should be adapted into locally-appropriate documents and tools that are able to meet the specific needs of each country and health service. Modifications to the recommendation, where necessary, should be justified in an explicit and transparent manner.",
        "An enabling environment should be created for the use of this recommendation, including changes in the behaviour of health care practitioners to enable the use of evidence-based practices.",
        "Local professional societies may play important roles in this process and an all-inclusive and participatory process should be encouraged.",
        "Antenatal care models with a minimum of eight contacts are recommended to reduce perinatal mortality and improve women’s experience of care. Taking this as a foundation, the GDG reviewed how ANC should be delivered in terms of both the timing and content of each of the ANC contacts, and arrived at a new model – the 2016 WHO ANC model – which replaces the previous four-visit focused ANC (FANC) model. For the purpose of developing this new ANC model, the ANC recommendations were mapped to the eight contacts based on the evidence supporting each recommendation and the optimal timing of delivery of the recommended interventions to achieve maximal impact.",
      ],
    },
    additionalLists: [],
    relatedLinks: {
      title: "Related links",
      text: (
        <Typography>
          WHO recommendations on antenatal care for a positive pregnancy experience (2016) -
          experience (2016) -{" "}
          <Link href="https://iris.who.int/bitstream/handle/10665/250796/9789241549912-eng.pdf?sequence=1">
            full document
          </Link>{" "}
          and{" "}
          <Link href="https://iris.who.int/bitstream/handle/10665/250796/9789241549912-websupplement-eng.pdf?amp;isAllowed=y&sequence=8">
            evidence table
          </Link>
        </Typography>
      ),
      links: [
        {
          href: "https://iris.who.int/handle/10665/255760",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors",
        },
        {
          href: "https://www.who.int/publications/i/item/9789241549356",
          text: "Pregnancy, Childbirth, Postpartum and Newborn Care: A guide for essential practice",
        },
        {
          href: "https://www.who.int/teams/maternal-newborn-child-adolescent-health-and-ageing/maternal-health/about/mca",
          text: "WHO Programmes: Department of Maternal, Newborn, Child, Adolescent Health and Ageing",
        },
        {
          href: "https://www.who.int/teams/maternal-newborn-child-adolescent-health-and-ageing/maternal-health/about/nutrition-and-food-safety",
          text: "WHO Programmes: Department of Nutrition and Food Safety",
        },
        {
          href: "https://www.who.int/teams/sexual-and-reproductive-health-and-research-(srh)/overview",
          text: "WHO Programmes: Department of Sexual and Reproductive Health and Research",
        },
        {
          href: "https://www.who.int/health-topics/maternal-health#tab=tab_1",
          text: "Maternal Health",
        },
      ],
    },
    references: {
      title: "References",
      content: [
        "Healthy diet. Fact sheet No. 394. Geneva: World Health Organization; 2015 (http://www.who.int/mediacentre/factsheets/fs394/en/, accessed 1 November 2016).",
        "Exercise in pregnancy. RCOG Statement No. 4. Royal College of Obstetricians and Gynaecologists. 2006:1–7 (https://www.rcog.org.uk/en/guidelines-research-services/ guidelines/exercise-in-pregnancy-statementno.4/, accessed 24 October 2016).",
        "Rasmussen KM, Yaktine AL, editors; Institute of Medicine and National Research Council. Weight gain during pregnancy: re-examining the guidelines. Washington (DC): The National Academies Press; 2009 (http://www.nationalacademies.org/hmd/Reports/2009/Weight-Gain-During- Pregnancy-Reexaminingthe-Guidelines.aspx, accessed 29 September 2016).",
        "Tang AM, Chung M, Dong K, Terrin N, Edmonds A, Assefa N et al. Determining a global midupper arm circumference cutoff to assess malnutrition in pregnant women. Washington (DC): FHI360/Food and Nutrition Technical Assistance III Project (FANTA); 2016(http://www.fantaproject.org/sites/default/files/resources/FANTA-MUAC-cutoffs- pregnantwomen-June2016.pdf, accessed 26 September 2016).",
        "Popkin S, Slining MM. New dynamics in global obesity facing low- and middle-income countries. Obes Rev. 2013;14(2):11–20.doi:10.1111/obr.12102.",
        "WHO handbook for guideline development, 2nd edition. Geneva: World Health Organization; 2014 (http://www.who.int/kms/handbook_2nd_ ed.pdf, accessed 6 October 2016).",
        "GRADE [website]. The GRADE Working Group; 2016 (http://gradeworkinggroup.org/, accessed 27 October 2016).",
        "GRADE-CERQual [website]. The GRADECERQual Project Group; 2016 (https://cerqual. org/, accessed 27 October 2016).",
        "The DECIDE Project; 2016 (http://www.decide-collaboration.eu/, accessed 27 October 2016).",
        "Muktabhant B, Lawrie TA, Lumbiganon P, Laopaiboon M. Diet or exercise, or both, for preventing excessive gestational weight gain in pregnancy. Cochrane Database Syst Rev. 2015;(6):CD007145.Ota E, Hori H, Mori R, Tobe-Gai R, Farrar D. Antenatal dietary education and supplementation to increase energy and protein intake. Cochrane Database Syst Rev. 2015;(6):CD000032.",
        "Popkin S, Slining MM. New dynamics in global obesity facing low- and middle-income countries. Obes Rev. 2013;14(2):11–20.",
        "doi:10.1111/obr.12102.Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the use of routine antenatal services by pregnant women: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2016;(10):CD012392.",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the provision of good‐quality routine antenatal services: a qualitative evidence synthesis of the views and experiences of maternity care providers. Cochrane Database Syst Rev. 2017 Aug 25;2017(8):CD012752. doi: 10.1002/14651858.CD012752. PMCID: PMC6483720.",
      ],
    },
  },
  {
    id: "50",
    img: img113,
    imgAlt: "a pregnant woman",
    title:
      "WHO recommendation on interventions for relief of heartburn during pregnancy",
    date: "20 September 2023",
    recommendation: {
      title: "Recommendation",
      content:
        "Advice on diet and lifestyle is recommended to prevent and relieve heartburn in pregnancy. Antacid preparations can be offered to women with troublesome symptoms that are not relieved by lifestyle modification.",
    },
    recommendationItalic: "(Recommended)",
    publicationHistory: {
      firstPublished: "November 2016",
      updated: "No update planned",
      assessedAsUpToDate: "November 2016",
    },
    remarks: {
      title: "Remarks",
      content: [
        "Lifestyle advice to prevent and relieve symptoms of heartburn includes avoidance of large, fatty meals and alcohol, cessation of smoking, and raising the head of the bed to sleep.",
        "The GDG agreed that antacids, such as magnesium carbonate and aluminium hydroxide preparations, are probably unlikely to cause harm in recommended dosages.",
        "There is no evidence that preparations containing more than one antacid are better than simpler preparations.",
        "Antacids may impair absorption of other drugs (1), and therefore should not be taken within two hours of iron and folic acid supplements.",
      ],
    },
    background: {
      title: "Background",
      content: [
        "Women’s bodies undergo substantial changes during pregnancy, which are brought about by both hormonal and mechanical effects. These changes lead to a variety of common symptoms – including nausea and vomiting, low back and pelvic pain, heartburn, varicose veins, constipation and leg cramps – that in some women cause severe discomfort and negatively affects their pregnancy experience. In general, symptoms associated with mechanical effects, e.g. pelvic pain, heartburn and varicose veins, often worsen as pregnancy progresses. Constipation can be very troublesome and may be complicated by haemorrhoids (2)."
      ],
    },
    methods: {
      title: "Methods",
      content: [
          "The ANC recommendations are intended to inform the development of relevant health-care policies and clinical protocols. These recommendations were developed in accordance with the methods described in the WHO handbook for guideline development (3). In summary, the process included: identification of priority questions and outcomes, retrieval of evidence, assessment and synthesis of the evidence, formulation of recommendations, and planning for the implementation, dissemination, impact evaluation and updating of the guideline.",
          "The quality of the scientific evidence underpinning the recommendations was graded using the Grading of Recommendations Assessment, Development and Evaluation (GRADE) (4) and Confidence in the Evidence from Reviews of Qualitative research (GRADE-CERQual) (5) approaches, for quantitative and qualitative evidence, respectively. Up-to-date systematic reviews were used to prepare evidence profiles for priority questions. The DECIDE (Developing and Evaluating Communication Strategies to support Informed Decisions and Practice based on Evidence) (6) framework, an evidence-to-decision tool that includes intervention effects, values, resources, equity, acceptability and feasibility criteria, was used to guide the formulation and approval of recommendations by the Guideline Development Group (GDG) – an international group of experts assembled for the purpose of developing this guideline – at three Technical Consultations between October 2015 and March 2016.",
          "To ensure that each recommendation is correctly understood and applied in practice, the context of all context-specific recommendations is clearly stated within each recommendation, and the contributing experts provided additional remarks where needed.",
          "In accordance with WHO guideline development standards, these recommendations will be reviewed and updated following the identification of new evidence, with major reviews and updates at least every five years.",
        <Typography>
          {" "}
          Further information on procedures for developing this recommendation
          are available{" "}
          <Link
            href="https://iris.who.int/bitstream/handle/10665/250796/9789241549912-eng.pdf?sequence=1"
            target="_blank"
          >
            here.
          </Link>
        </Typography>,
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following question:",
      content: [
        "For pregnant women (P), do diet and/or exercise interventions compared with standard ANC (C) improve maternal and perinatal health outcomes (O)?",
      ],
    },
    evidenceSummary: {
      title: "Evidence summary",
      content: [
        "The evidence on the effects of various interventions for heartburn in pregnancy comes from a Cochrane review that included nine trials involving 725 pregnant women with heartburn; however, only four trials (358 women) contributed data (7). One of these, from the 1960s, evaluated intramuscular prostigmine, which is no longer used, therefore these data were not considered for the guideline. The three remaining studies conducted in Brazil, Italy and the USA evaluated a magnesium hydroxide–aluminium hydroxide– simeticone complex versus placebo (156 women), sucrulfate (aluminium hydroxide and sulfated sucrose) versus advice on diet and lifestyle changes (66 women), and acupuncture versus no treatment (36 women). Evidence on symptom relief was generally assessed to be of low to very low certainty and no perinatal outcomes relevant to this guideline were reported. Evidence on side-effects for all comparisons was assessed as being of very low certainty.",
        "Pharmacological interventions versus placebo",
        "Low-certainty evidence suggests that complete relief from heartburn may occur more frequently with magnesium hydroxide–aluminium hydroxide– simethicone liquid and tablets than placebo (156 women; RR: 2.04, 95% CI: 1.44–2.89).",
        "Pharmacological interventions versus advice on diet and lifestyle changes",
        "Low-certainty evidence suggests that complete relief from heartburn may occur more frequently with sucralfate than with advice on diet and lifestyle changes (65 women; RR: 2.41, 95% CI: 1.42–4.07).",
        "Acupuncture versus no treatment",
        "Data on relief of heartburn was not available in the review for this comparison. Low-certainty evidence suggests that weekly acupuncture in pregnant women with heartburn may improve the ability to sleep (36 women; RR: 2.80, 95% CI: 1.14–6.86) and eat (36 women; RR: 2.40, 95% CI: 1.11–5.18), a proxy outcome for maternal satisfaction.",
      ],
    },
    maternalOutcomes: {
      title: "Maternal outcomes",
      content:[
        "High-certainty evidence shows that women receiving diet and/or exercise interventions as part of ANC to prevent EGWG are less likely to experience EGWG (24 trials, 7096 women; relative risk [RR]: 0.80, 95% confidence interval [CI]: 0.73–0.87; absolute effect of 91 fewer women with EGWG per 1000 on average). Subgroup analyses were consistent with these findings.",
        "High-certainty evidence shows that diet and/or exercise interventions have little or no effect on preeclampsia risk (15 trials, 5330 women; RR: 0.95, 95% CI: 0.77–1.16). However, moderate-certainty evidence indicates that diet and/or exercise interventions probably prevent hypertension in pregnancy (11 trials, 5162 women; RR: 0.70, 95% CI: 0.51–0.96).",
        "Low-certainty evidence suggests that diet and/or exercise interventions may have little or no effect on caesarean section (28 trials, 7534 women; RR: 0.95, 95% CI: 0.88–1.03); however, low-certainty evidence from the diet and exercise counselling subgroup of trials suggests that reductions in caesarean section rates may be possible with this intervention (9 trials, 3406 women; RR: 0.87, 95% CI: 0.75–1.01). Moderate-certainty evidence indicates that diet and/or exercise interventions probably make little or no difference to induction of labour (8 trials, 3832 women; RR: 1.06, 95% CI: 0.94–1.19).",
        "Low-certainty evidence suggests that diet and/or exercise interventions may reduce the risk of gestational diabetes mellitus (GDM) (19 trials, 7279 women; RR: 0.82, 95% CI: 0.67–1.01).",
      ]
    },
    fetalAndNeonatalOutcomes: [
      {
        title: "Fetal and neonatal outcomes",
        content:[
          "Moderate-certainty evidence suggests that diet and/or exercise interventions probably prevent neonatal macrosomia (27 trials, 8598 women; RR: 0.93, 95% CI: 0.86–1.02), particularly in overweight and obese women receiving diet and exercise counselling interventions (9 trials, 3252 neonates; RR: 0.85, 95% CI: 0.73–1.00). However, moderate certainty evidence indicates that diet and exercise interventions probably have little or no effect on neonatal hypoglycaemia (4 trials, 2601 neonates; RR: 0.95, 95% CI: 0.76–1.18) or shoulder dystocia (4 trials, 3253 neonates; RR: 1.02, 95% CI: 0.57–1.83). Low- certainty evidence suggests that neonatal respiratory morbidity may occur less frequently with diet and exercise counselling interventions than controls, particularly among overweight and obese women (2 studies, 2256 women; RR: 0.47, 95% CI: 0.26–0.85).",
          "Low-certainty evidence suggests that diet and/or exercise interventions may have little or no effect on preterm birth (16 trials, 5923 women; RR: 0.91, 95% CI: 0.68–1.22), and the evidence on low-birth-weight neonates is very uncertain. Perinatal mortality was not reported in the review.",
        ]
      },
      {
        title: "Additional considerations",
        content:[
          "Heartburn during pregnancy is a common problem that can be self-treated with over-the- counter products containing antacids such as magnesium carbonate, aluminium hydroxide or calcium carbonate.",
          "The Cochrane review found no evidence on prescription drugs for heartburn, such as omeprazole and ranitidine, which are not known to be harmful in pregnancy (7).",
        ]
      },
      {
        title: "Resources",
        content:
          "Costs of antacids vary widely, but generic products can be relatively low cost. Acupuncture requires professional training and skills and is likely to be associated with higher costs.",
      },
      {
        title: "Equity",
        content:
          "The prevalence of health-seeking behaviour and treatment for heartburn in pregnancy may be unequal among advantaged and disadvantaged women. However, it is not known whether interventions to relieve heartburn might impact inequalities.",
      },
      {
        title: "Acceptability",
        content:
          "Qualitative evidence from a range of LMICs suggests that women may be more likely to turn to traditional healers, herbal remedies or TBAs to treat these symptoms (moderate confidence in the evidence) (8). In addition, evidence from a diverse range of settings indicates that while women generally appreciate the interventions and information provided during antenatal visits, they are less likely to engage with services if their beliefs, traditions and socioeconomic circumstances are ignored or overlooked by health-care providers and/or policymakers (high confidence in the evidence). This may be particularly pertinent for an intervention like acupuncture, which may be culturally alien and/or poorly understood in certain contexts. Indirect evidence also indicates that women welcome the pregnancy- related advice and guidance given by health-care professionals during antenatal visits, so may respond to lifestyle suggestions favourably (moderate confidence in the evidence)."
      },
      {
        title: "Feasibility",
        content:
          "Qualitative evidence suggests that a lack of resources may limit the offer of treatment for this condition (high confidence in the evidence) (9).",
      },
      {
        title:
          "Further information and considerations related to this recommendation can be found in the WHOguidelines, available in the Annex of this publication:",
        content: (
          <Link href="https://www.who.int/publications/i/item/9789241549912">
            https://www.who.int/publications/i/item/9789241549912
          </Link>
        ),
      },
    ],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "The successful introduction of evidence-based policies related to antenatal care into national programmes and health care services depends on well-planned and participatory consensus- driven processes of adaptation and implementation. These processes may include the development or revision of national guidelines or protocols based on this recommendation.",
        "The recommendation should be adapted into locally-appropriate documents and tools that are able to meet the specific needs of each country and health service. Modifications to the recommendation, where necessary, should be justified in an explicit and transparent manner.",
        "An enabling environment should be created for the use of this recommendation, including changes in the behaviour of health care practitioners to enable the use of evidence-based practices.",
        "Local professional societies may play important roles in this process and an all-inclusive and participatory process should be encouraged.",
        "Antenatal care models with a minimum of eight contacts are recommended to reduce perinatal mortality and improve women’s experience of care. Taking this as a foundation, the GDG reviewed how ANC should be delivered in terms of both the timing and content of each of the ANC contacts, and arrived at a new model – the 2016 WHO ANC model – which replaces the previous four-visit focused ANC (FANC) model. For the purpose of developing this new ANC model, the ANC recommendations were mapped to the eight contacts based on the evidence supporting each recommendation and the optimal timing of delivery of the recommended interventions to achieve maximal impact.",
      ],
    },
    additionalLists: [],
    relatedLinks: {
      title: "Related links",
      text: (
        <Typography>
          WHO recommendations on antenatal care for a positive pregnancy experience (2016) -
          experience (2016) -{" "}
          <Link href="https://iris.who.int/bitstream/handle/10665/250796/9789241549912-eng.pdf?sequence=1">
            full document
          </Link>{" "}
          and{" "}
          <Link href="https://iris.who.int/bitstream/handle/10665/250796/9789241549912-websupplement-eng.pdf?amp;isAllowed=y&sequence=8">
            evidence table
          </Link>
        </Typography>
      ),
      links: [
        {
          href: "https://iris.who.int/handle/10665/255760",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors",
        },
        {
          href: "https://www.who.int/publications/i/item/9789241549356",
          text: "Pregnancy, Childbirth, Postpartum and Newborn Care: A guide for essential practice",
        },
        {
          href: "https://www.who.int/teams/maternal-newborn-child-adolescent-health-and-ageing/maternal-health/about/mca",
          text: "WHO Programmes: Department of Maternal, Newborn, Child, Adolescent Health and Ageing",
        },
        {
          href: "https://www.who.int/teams/maternal-newborn-child-adolescent-health-and-ageing/maternal-health/about/nutrition-and-food-safety",
          text: "WHO Programmes: Department of Nutrition and Food Safety",
        },
        {
          href: "https://www.who.int/teams/sexual-and-reproductive-health-and-research-(srh)/overview",
          text: "WHO Programmes: Department of Sexual and Reproductive Health and Research",
        },
        {
          href: "https://www.who.int/health-topics/maternal-health#tab=tab_1",
          text: "Maternal Health",
        },
      ],
    },
    references: {
      title: "References",
      content: [
        "Joint Formulary Committee. Disorders of gastric acid and ulceration. Chapter 1: Gastro-intestinal system. In: British National Formulary (BNF) 72. London: BMJ Publishing Group Ltd and Royal Pharmaceutical Society; 2016.",
        "Rungsiprakarn P, Laopaiboon M, Sangkomkamhang US, Lumbiganon P, Pratt JJ. Interventions for treating constipation in pregnancy. Cochrane Database Syst Rev. 2015;(9):CD011448.Healthy diet. Fact sheet No. 394. Geneva: World Health Organization; 2015 (http://www.who.int/mediacentre/factsheets/fs394/en/ , accessed 1 November 2016).",
        "WHO handbook for guideline development, 2nd edition. Geneva: World Health Organization; 2014 (http://www.who.int/kms/handbook_2nd_ ed.pdf, accessed 6 October 2016).",
        "GRADE [website]. The GRADE Working Group; 2016 (http://gradeworkinggroup.org/, accessed 27 October 2016).",
        "GRADE-CERQual [website]. The GRADECERQual Project Group; 2016 (https://cerqual. org/, accessed 27 October 2016).",
        "The DECIDE Project; 2016 (http://www.decide-collaboration.eu/, accessed 27 October 2016).",
        "Phupong V, Hanprasertpong T. Interventions for heartburn in pregnancy. Cochrane Database Syst Rev. 2015;(9):CD011379.",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the use of routine antenatal services by pregnant women: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2016;(10):CD012392.",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the provision of good‐quality routine antenatal services: a qualitative evidence synthesis of the views and experiences of maternity care providers. Cochrane Database Syst Rev. 2017 Aug 25;2017(8):CD012752. doi: 10.1002/14651858.CD012752. PMCID: PMC6483720.",
      ],
    },
  },
];
