import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
    interface BreakpointOverrides {
        xs: true;
        sm: true;
        md: true;
        lg: true;
        xl: true;
        mobileXS: true;
        mobileSM: true;
        mobileMD: true;
        mobileLG: true;
        mobileXL: true;
    }
}

export let theme = createTheme({});
theme = createTheme({
    typography: {
        h1: {
            fontFamily: 'LatoBlack',
        },
        h2: {
            fontFamily: 'LatoRegular',
        },
        body1: {
            fontFamily: 'LatoLight',
        },
        body2: {
            fontFamily: 'LatoBold',
        },
    },
    breakpoints: {
        values: {
            xs: 0, // after value is phone
            sm: 600, // after value is tablet
            md: 1280, // after value is low resolution laptop
            lg: 1400, // after value is high resolution laptop
            xl: 1920,
            mobileXS: 320,
            mobileSM: 360,
            mobileMD: 480,
            mobileLG: 768,
            mobileXL: 900,
        },
    },
});
