import React, {useState} from "react";
import { partOneClasses as classes, StyledPartOne } from "./StyledPartOne";
import { Container, Divider, Grid, Typography, Box, ThemeProvider } from "@mui/material";
import { Modals, modalsStructure } from "./PartOneModals";
import { theme } from "../common/Theme/theme";

const gridPhotos = [
    {
        class: classes.elementFirst,
        link: 'Read recommendations',
        text: 'Nutritional recommendations',
    },
    {
        class: classes.elementSecond,
        link: 'Read recommendations',
        text: 'Maternal and fetal assessments recommendations',
    },
    {
        class: classes.elementThird,
        link: 'Read recommendations',
        text: 'Preventive measures recommendations',
    },
    {
        class: classes.elementFourth,
        link: 'Read recommendations',
        text: 'Interventions for common physical symptoms recommendations',
    },
    {
        class: classes.elementFifth,
        link: 'Read recommendations',
        text: 'Health systems interventions recommendations',
    },
    {
        class: classes.elementSixth,
        link: 'Read full articles',
        text: 'Qualitative Evidence Syntheses',
    },
    {
        class: classes.elementSeventh,
        link: 'Read recommendations',
        text: 'Evidence for essential counselling and good clinical practices',
    },
]

export const PartOne = () => {
    const [hoverID, setHoverID] = useState(-1);
    const [open, setOpen] = useState<boolean[]>(new Array(modalsStructure.length).fill(false));
    const handleOpen = (id: number) => {
        let newValues = [...open];
        newValues[id] = true;
        setOpen(newValues);
    }
    const handleClose = (id: number) => {
        let newValues = [...open];
        newValues[id] = false;
        setOpen(newValues);
    }

    return <StyledPartOne container className={classes.root}>
        <ThemeProvider theme={theme}>
            <Modals open={open} handleOpen={(index: number) => {handleOpen(index);}} handleClose={(index: number) => {handleClose(index);}}/>
        </ThemeProvider>
        <Container id='Reviews' className={classes.sectionWrapper}>
            <Container className={classes.sectionContent}>
                <Grid container>
                    <Typography className={classes.sectionHeading} variant="h1">Part 1</Typography>
                    <Typography variant="h2" className={classes.sectionBoldText}>
                        Evidence base and systematic reviews underpinning WHO Antenatal Care recommendations
                        <Box className={classes.sideLine}/>
                    </Typography>
                </Grid>
                <Grid container className={classes.sectionGrid}>
                    {gridPhotos.map((gridPhoto, index) => (
                        <Grid key={gridPhoto.text} className={`${classes.gridElementsAll} ${gridPhoto.class}`} onClick={() => {handleOpen(index);}} item>
                            <Grid container className={classes.gridElementGradient} onMouseEnter={() => setHoverID(index)} onMouseLeave={() => setHoverID(-1)}>
                                {hoverID===index && (
                                    <Grid item className={classes.hoverPopUp}>
                                        <Divider className={classes.gridElementDivider}/>
                                        <Typography className={classes.gridElementRecommendationLink} variant="h2">{gridPhoto.link}</Typography>
                                    </Grid>
                                )}
                                <Typography className={classes.gridElementText} variant="h2">{gridPhoto.text}</Typography>
                            </Grid>
                        </Grid>
                    ))}
                    <Grid className={classes.elementEighth} item/>
                </Grid>
            </Container>
        </Container>
    </StyledPartOne>;
};
