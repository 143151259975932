import {Grid, styled} from "@mui/material";
import {prefixClasses} from "../common/Theme/utils";

const classes = {
    root: "",
    footer: "footer",
    footerWrapper: "footerWrapper",
    footerContent: "footerContent",
    footerContentLinks: "footerContentLinks",
    footerLinkGroup: "footerLinkGroup",
    firstLinks: "firstLinks",
    secondLinks: "secondLinks",
    footerLogo: "footerLogo",
    footerGridWrapper: "footerGridWrapper",
    footerCopyright: "footerCopyright",
    footerItem: "footerItem",
};

prefixClasses("Footer", classes);

export const StyledFooter = styled(Grid)(({ theme }) => ({
    [`&.${classes.root}`]: {
        width: '100%',
    },
    [`& .${classes.footer}`]: {
        backgroundColor: '#f5f5f5',
    },
    [`& .${classes.footerWrapper}`]: {
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        flexWrap: 'wrap',
        padding: '24px 48px 24px 7.5px',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xl')]: {
            maxWidth: '1140px',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '960px',
        },
        [theme.breakpoints.down('mobileXL')]: {
            maxWidth: '720px',
            padding: '24px 7.5px 24px 7.5px',
        },
        [theme.breakpoints.down('mobileLG')]: {
            maxWidth: '540px',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '450px',
        },
        [theme.breakpoints.down('mobileMD')]: {
            maxWidth: '360px',
        },
        [theme.breakpoints.down('mobileSM')]: {
            maxWidth: '300px',
        },
    },
    [`& .${classes.footerContent}`]: {
        padding: '0 0',
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: '91.66%',
        maxWidth: '91.66%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('mobileXL')]: {
            flex: 'auto',
            maxWidth: '100%',
            marginLeft: '0',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    [`& .${classes.footerContentLinks}`]: {
        padding: '0 0',
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: '91.66%',
        maxWidth: '91.66%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: "row",
        marginTop: "24px",
        [theme.breakpoints.down('mobileXL')]: {
            flex: 'auto',
            maxWidth: '100%',
            marginLeft: '0',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    [`& .${classes.footerLinkGroup}`]: {
        display: "flex",
        flexDirection: "column"
    },
    [`& .${classes.firstLinks}`]: {
        display: "flex",
        columnGap: "24px",
        marginTop: "6px",
        [theme.breakpoints.down('mobileXL')]: {
            justifyContent: 'center',
            marginBottom: '16px',
        },
    },
    [`& .${classes.secondLinks}`]: {
        display: "flex",
        columnGap: "32px",
        marginTop: "6px",
        justifyContent: "flex-end",
        [theme.breakpoints.down('mobileXL')]: {
            justifyContent: 'center',
        },
    },
    [`& .${classes.footerLogo}`]: {
        width: '170px'
    },
    [`& .${classes.footerGridWrapper}`]: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
        columnGap: '36px',
        [theme.breakpoints.down('mobileXL')]: {
            justifyContent: 'center',
            marginTop: '24px',
            rowGap: '24px',
        },
    },
    [`& .${classes.footerCopyright}`]: {
        margin: '0 auto',
        textAlign: 'center',
        padding: '16px 0',
    },
    [`& .${classes.footerItem}`]: {
        fontSize: '0.85rem',
    },
}));

export const footerClasses = classes;