import { prefixClasses } from "../common/Theme/utils";
import { Grid, styled } from "@mui/material";

const classes = {
    root: "root",
    rootHeight: "rootHeight",
    modalHeading: "modalHeading",
    modalSubsectionHeading: "modalSubsectionHeading",
    modalClose: "modalClose",
    modalContent: "modalContent",
    modalGrid: "modalGrid",
    modalElementPhoto: "modalElementPhoto",
    modalElementTextSection: "modalElementTextSection",
    modalElementText: "modalElementText",
    modalElementLink: "modalElementLink",
};

prefixClasses("PartTwoPNCModals", classes);

export const StyledPartTwoPNCModals = styled(Grid)(({ theme }) => ({
    [`&.${classes.root}`]: {
        position: 'absolute' as 'absolute',
        top: 'calc(50%)',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff',
        borderRadius: '4.8px',
        border: '1px solid rgba(0,0,0,0.2)',
        boxShadow: 24,
        p: 4,
        outline: 'none',
        display: 'flex',
        flexDirection: 'row',
        padding: '0px',
        maxWidth: '800px',
        [theme.breakpoints.down('mobileXL')]: {
            maxWidth: '500px',
            overflowY: 'scroll',
            maxHeight: 'calc(100% - 56px)',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '400px',
        },
        [theme.breakpoints.down('mobileMD')]: {
            maxWidth: '350px',
        },
        [theme.breakpoints.down('mobileSM')]: {
            maxWidth: '300px',
        },
    },
    [`&.${classes.rootHeight}`]: {
        position: 'absolute' as 'absolute',
        top: 'calc(50%)',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff',
        borderRadius: '4.8px',
        border: '1px solid rgba(0,0,0,0.2)',
        boxShadow: 24,
        p: 4,
        outline: 'none',
        display: 'flex',
        flexDirection: 'row',
        padding: '0px',
        maxWidth: '800px',
        [theme.breakpoints.down('mobileXL')]: {
            maxWidth: '500px',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '400px',
        },
        [theme.breakpoints.down('mobileMD')]: {
            maxWidth: '350px',
        },
        [theme.breakpoints.down('mobileSM')]: {
            maxWidth: '300px',
        },
        overflowY: 'scroll',
        maxHeight: 'calc(100% - 56px)',
    },
    [`& .${classes.modalHeading}`]: {
        padding: '16px',
        width: '100%',
        fontSize: '1.375rem',
        [theme.breakpoints.down('mobileMD')]: {
            fontSize: '1.175rem',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d2e0e6',
        marginBottom: '12px',
    },
    [`& .${classes.modalSubsectionHeading}`]: {
        padding: '8px 16px',
        fontSize: '1rem',
        [theme.breakpoints.down('mobileMD')]: {
            fontSize: '0.85rem',
        },
        borderBottom: '1px solid #d2e0e6'
    },
    [`& .${classes.modalClose}`]: {
        cursor: 'pointer',
    },
    [`& .${classes.modalContent}`]: {
        padding: '16px',
        rowGap: '40px'
    },
    [`& .${classes.modalGrid}`]: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('mobileXL')]: {
            width: '100%',
            maxWidth: '100%',
            flexBasis: 'auto',
        },
    },
    [`& .${classes.modalElementTextSection}`]: {
        padding: '0 16px',
    },
    [`& .${classes.modalElementText}`]: {
        fontWeight: '500',
        marginBottom: '5px',
        [theme.breakpoints.down('mobileMD')]: {
            fontSize: '0.85rem',
        },
    },
    [`& .${classes.modalElementLink}`]: {
        fontSize: '0.8rem',
        textTransform:'uppercase',
        fontFamily: 'LatoRegular',
        display: 'block',
    },
    [`& .${classes.modalElementPhoto}`]: {
        height: '125px',
        [theme.breakpoints.down('sm')]: {
            height: '110px',
        },
        [theme.breakpoints.down('mobileMD')]: {
            height: '95px',
        },
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        backgroundSize: 'cover',
        width: '100%',
        objectFit: 'cover',
        borderRadius: '4px',
    },
}));

export const partTwoPNCModalsClasses = classes;