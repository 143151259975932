import React from "react";
import { partFiveClasses as classes, StyledPartFive } from "./StyledPartFive";
import {Box, Button, Container, Typography, Grid} from "@mui/material";

const partFourGridPhotos = [
    {
        class: classes.elementFirstPrevious,
        text: 'From paper to digital pathway: WHO launches first \'SMART Guidelines\'',
        width: 6.8,
        site: 'https://www.who.int/news/item/18-02-2021-from-paper-to-digital-pathway-who-launches-first-smart-guidelines',
    },
    {
        class: classes.elementSecondPrevious,
        text: 'New tools to help ensure a positive pregnancy experience for woman',
        width: 5,
        site: 'https://www.who.int/news-room/detail/22-06-2020-new-tools-to-help-ensure-a-positive-pregnancy-experience-for-women',
    },
    {
        class: classes.elementThirdPrevious,
        text: 'Using qualitative research to strengthen guideline development',
        width: 4,
        site: 'https://www.who.int/news/item/08-08-2019-using-qualitative-research-to-strengthen-guideline-development',
    },
    {
        class: classes.elementFourthPrevious,
        text: 'Positive experience, key to antenatal care uptake and quality',
        width: 7.8,
        site: 'https://www.who.int/news/item/07-11-2016-new-guidelines-on-antenatal-care-for-a-positive-pregnancy-experience',
    },
    {
        class: classes.elementFirst,
        text: 'Launch of the WHO, UNICEF & JICA guide on strengthening implementation of home-based records for maternal, newborn and child health',
        width: 6.8,
        site: 'https://www.qualityofcarenetwork.org/updates/launch-who-unicef-jica-guide-strengthening-implementation-home-based-records-mnh',
    },
    {
        class: classes.elementSecond,
        text: 'New research identifies top priorities for WHO Labour Care Guide',
        width: 5,
        site: 'https://reproductive-health-journal.biomedcentral.com/articles/10.1186/s12978-023-01600-4',
    },
    {
        class: classes.elementThird,
        text: 'What matters to women during pregnancy: a different approach to antenatal care',
        width: 5.9,
        site: 'https://www.who.int/news/item/08-03-2016-different-approach-to-antenatal-care',
    },
    {
        class: classes.elementFourth,
        text: 'WHO urges quality care for women and newborns in critical first weeks after childbirth',
        width: 5.9,
        site: 'https://www.who.int/news/item/30-03-2022-who-urges-quality-care-for-women-and-newborns-in-critical-first-weeks-after-childbirth',
    },
    {
        class: classes.elementFifth,
        text: 'WHO maternal health website',
        width: 6.8,
        site: 'https://www.who.int/health-topics/maternal-health#tab=tab_1',
    },
    {
        class: classes.elementSixth,
        text: 'WHO antenatal care recommendations for a positive pregnancy experience: technical brief',
        width: 7.8,
        site: 'https://www.who.int/publications/i/item/WHO-RHR-18.02',
    },
    {
        class: classes.elementEighth,
        text: "Evidence brief: Implementing malaria in pregnancy programs in the context of WHO recommendations on ANC for a positive pregnancy experience",
        width: 4,
        site: 'https://www.who.int/publications/i/item/WHO-RHR-18.05',
    },
    {
        class: classes.elementSeventh,
        text: "Technical brief: Imaging ultrasound before 24 weeks of pregnancy: 2022 update to the WHO antenatal care recommendations for a positive pregnancy experience",
        width: 5.9,
        site: 'https://www.who.int/publications/i/item/9789240051461',
    },
    {
        class: classes.elementSeventhNew,
        text: "Evidence brief: Summary of WHO recommendations on ANC for a positive pregnancy experience",
        width: 5.9,
        site: 'https://www.who.int/publications/i/item/WHO-RHR-16.12',
    },
    {
        class: classes.elementNinth,
        text: "Essential Newborn Care Course",
        width: 6.9,
        site: 'https://www.who.int/tools/essential-newborn-care-training-course',
    },
    {
        class: classes.elementTenth,
        text: "AFRO ANC training package",
        width: 4.9,
        site: 'https://www.dropbox.com/scl/fo/t1tjxh412bnzgzo29pbzi/h?rlkey=f9jttt5mroz6zpxw4jwh4xi4b&amp;dl=0',
    },
    {
        class: classes.elementEleventh,
        text: 'WHO digital ANC module training package',
        width: 5.9,
        site: 'https://www.dropbox.com/scl/fo/4ztau5c1zs59fb9sep5fy/h?rlkey=js9djjn5jcp3czw6wyrbdipam&amp;dl=0',
    },
]

interface PhotoElement {
    class: string,
    text: string,
    width: number,
    site: string,
}

export const PartFive = () => {
    return <StyledPartFive className={classes.root}>
        <Container id='Resources' className={classes.wrapper}>
            <Container className={classes.content}>
                <Grid container className={classes.sectionText}>
                    <Typography className={classes.heading} variant="h1">
                        Part 5
                    </Typography>
                    <Typography className={classes.textBold} variant="h2">
                        ANC & PNC training and communications materials
                        <Box className={classes.sideLine}/>
                    </Typography>
                </Grid>
                <Typography className={classes.textBoldSmaller} variant="body2">News & updates</Typography>
                <Grid container xs={12} className={classes.sectionGrid}>
                    {partFourGridPhotos.slice(0,9).map((gridPhoto: PhotoElement, index) => (
                        <GridPhotoElement key={index} gridPhoto={gridPhoto} />
                    ))}
                </Grid>
                <Typography className={classes.textBoldSmaller} variant="body2">Policy briefs</Typography>
                <Grid container xs={12} className={classes.sectionGrid}>
                    {partFourGridPhotos.slice(9,13).map((gridPhoto: PhotoElement, index) => (
                        <GridPhotoElement key={index} gridPhoto={gridPhoto} />
                    ))}
                </Grid>
                <Typography className={classes.textBoldSmaller} variant="body2">Training material</Typography>
                <Grid container xs={12} className={classes.sectionGrid}>
                    {partFourGridPhotos.slice(13,17).map((gridPhoto: PhotoElement, index) => (
                        <GridPhotoElement key={index} gridPhoto={gridPhoto} />
                    ))}
                </Grid>
            </Container>
        </Container>
    </StyledPartFive>;
};

export const GridPhotoElement = ({ gridPhoto }: { gridPhoto: PhotoElement }) => {
    return <StyledPartFive item mobileXL={gridPhoto.width} className={`${classes.gridElementsAll} ${gridPhoto.class}`}>
        <Grid container className={classes.gridElementGradient}>
            <Grid item>
                <Button className={classes.gridButton} href={gridPhoto.site} target="_blank">Read more</Button>
            </Grid>
            <Typography className={classes.gridElementText} variant="body2">{gridPhoto.text}</Typography>
        </Grid>
    </StyledPartFive>
}
