import React from "react";
import { partTwoPNCModalsClasses as classes, StyledPartTwoPNCModals } from "./StyledPartTwoPNCModals";
import { Grid, Link, Typography, Modal} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { modalFirstContent, modalSecondContent, modalThirdContent, modalFourthContent, modalFifthContent, modalSixthContent, modalSeventhContent, modalEighthContent, modalNinthContent } from "./PartTwoPNCModalsData";

interface ModalsContentProps {
    open: boolean;
    handleOpen: () => void;
    handleClose: () => void;
    modalInfo: {
        heading: string,
        button: string,
        content: ModalsItemProps[],
        maternal: boolean,
        newborn: boolean,
        breastfeeding: boolean,
        other: boolean,
    }
}

interface ModalsItemProps {
    image: string,
    alt: string,
    credits: string,
    text: string,
    href: string,
    category?: string,
    otherHref?: string[],
}

interface ModalsProps {
    open: boolean[];
    handleOpen: (index: number) => void;
    handleClose: (index: number) => void;
}

const sections = [
    { category: "maternal", heading: "Maternal" },
    { category: "breastfeeding", heading: "Breastfeeding" },
    { category: "newborn", heading: "Newborn" },
    { category: "other", heading: "" }
];

export const modalsStructure = [
    {
        heading: "Nutrition & physical activity recommendations",
        button: "Read recommendations",
        content: modalFirstContent,
        maternal: true,
        newborn: true,
        breastfeeding: true,
        other: false,
    },
    {
        heading: "Infant growth & development recommendations",
        button: "Read recommendations",
        content: modalSecondContent,
        maternal: false,
        newborn: true,
        breastfeeding: false,
        other: false,
    },
    {
        heading: "Maternal and newborn assessments",
        button: "Read recommendations",
        content: modalThirdContent,
        maternal: true,
        newborn: true,
        breastfeeding: false,
        other: false,
    },
    {
        heading: "Preventive measures recommendations",
        button: "Read recommendations",
        content: modalFourthContent,
        maternal: true,
        newborn: true,
        breastfeeding: false,
        other: false,
    },
    {
        heading: "Interventions for common physiological signs and symptoms",
        button: "Read recommendations",
        content: modalFifthContent,
        maternal: true,
        newborn: false,
        breastfeeding: false,
        other: false,
    },
    {
        heading: "Mental health interventions",
        button: "Read recommendations",
        content: modalSixthContent,
        maternal: true,
        newborn: false,
        breastfeeding: false,
        other: false,
    },
    {
        heading: "Contraception recommendations",
        button: "Read recommendations",
        content: modalSeventhContent,
        maternal: true,
        newborn: false,
        breastfeeding: false,
        other: false,
    },
    {
        heading: "Health Systems & Health Promotion Interventions",
        button: "Read recommendations",
        content: modalEighthContent,
        maternal: false,
        newborn: false,
        breastfeeding: false,
        other: true,
    },
    {
        heading: "Qualitative Evidence Syntheses",
        button: "Read review",
        content: modalNinthContent,
        maternal: false,
        newborn: false,
        breastfeeding: false,
        other: true,
    },
]

export const Modals = ({open, handleOpen, handleClose}:ModalsProps) => {
    return (
        <>
            {modalsStructure.map((modal, index) => (
                <ModalNumber key={modal.heading} modalInfo={modal} open={open[index]} handleOpen={() => {handleOpen(index);}} handleClose={() => {handleClose(index);}}/>
            ))}
        </>
    )
};

export const ModalNumber = ({modalInfo, open, handleClose}:ModalsContentProps) => {
    return <Modal open={open} onClose={handleClose}>
        <StyledPartTwoPNCModals container className={classes.rootHeight}>
            <Typography className={classes.modalHeading} variant="h2">
                {modalInfo.heading}
                <CloseIcon className={classes.modalClose} onClick={handleClose}/>
            </Typography>
            {sections.map((section, index) => (
                modalInfo[section.category as keyof typeof modalInfo] && (
                    <Grid xs={12} key={index}>
                        {section.category!=="other" ? (
                            <Typography variant="body2" className={classes.modalSubsectionHeading}>
                                {section.heading}:
                            </Typography>
                        ) : null
                        }
                        <Grid container className={classes.modalContent}>
                            {modalInfo.content.map((element, index) =>
                                element.category === section.category ? (
                                    <ModalItem key={index} element={element} linkTitle={modalInfo.button} />
                                ) : null
                            )}
                        </Grid>
                    </Grid>
                )
            ))}
        </StyledPartTwoPNCModals>
    </Modal>
};

export const ModalItem = ({ element, linkTitle }: { element: ModalsItemProps, linkTitle: string }) => {
    return <StyledPartTwoPNCModals item className={classes.modalGrid} xs={6}>
        <Grid item xs={4}>
            <img src={element.image} title={element.credits} alt={element.alt} className={classes.modalElementPhoto}/>
        </Grid>
        <Grid item xs={8} className={classes.modalElementTextSection}>
            <Typography className={classes.modalElementText} variant="body2">{element.text}</Typography>
            <Link underline="none" href={element.href} target="_blank" className={classes.modalElementLink}>{linkTitle}</Link>
            {element.otherHref?.map((href: string) =>
                <Link underline="none" href={href} target="_blank" className={classes.modalElementLink} marginTop={"6px"}>Read recommendations</Link>
            )}
        </Grid>
    </StyledPartTwoPNCModals>
};
