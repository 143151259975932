import {Grid, styled} from "@mui/material";
import {prefixClasses} from "../common/Theme/utils";

const classes = {
    root: "root",
    image: "image",
    titleMarginSmall: "titleMarginSmall",
    titleMarginMedium: "titleMarginMedium",
    titleMarginLargeBoth: "titleMarginLargeBoth",
    titleMarginLargeTop: "titleMarginLargeTop",
    publicationHistory: "publicationHistory",
    bulletListWrapper: "bulletListWrapper",
    bulletList: "bulletList",
    bulletListItem: "bulletListItem",
    orderedListItem: "orderedListItem",
    widthWrapper: "widthWrapper",
    outcomes: "outcomes",
    outcomesItem: "outcomesItem",
};

prefixClasses("Document", classes);

export const StyledDocument = styled(Grid)(({ theme }) => ({
    [`&.${classes.root}`]: {
        fontFamily: "LatoLight",
        padding: '64px 0px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        lineHeight: 1.75,
        [theme.breakpoints.up('xl')]: {
            maxWidth: '1700px',
        },
        [theme.breakpoints.down('xl')]: {
            maxWidth: '1100px',
        },
        [theme.breakpoints.down('lg')]: {
            maxWidth: '1000px',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '800px',
        },
        [theme.breakpoints.down('mobileXL')]: {
            maxWidth: '620px',
        },
        [theme.breakpoints.down('mobileLG')]: {
            maxWidth: '520px',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '400px',
        },
        [theme.breakpoints.down('mobileMD')]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.down('mobileSM')]: {
            maxWidth: '260px',
        },
    },
    [`& .${classes.image}`]: {
        marginBottom: "24px"
    },
    [`& .${classes.titleMarginSmall}`]: {
        marginBottom: "8px"
    },
    [`& .${classes.titleMarginMedium}`]: {
        marginTop: "32px",
        marginBottom: "8px"
    },
    [`& .${classes.titleMarginLargeBoth}`]: {
        margin: "32px 0px"
    },
    [`& .${classes.titleMarginLargeTop}`]: {
        marginTop: "32px"
    },
    [`& .${classes.publicationHistory}`]: {
        marginBottom: "32px",
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%"
    },
    [`& .${classes.bulletListWrapper}`]: {
        display: "flex",
        flexDirection: "column"
    },
    [`& .${classes.bulletList}`]: {
        listStyleType: "disc",
        listStylePosition: "inside",
        fontWeight: "400",
        marginBottom: "32px"
    },
    [`& .${classes.bulletListItem}`]: {
        display: "list-item",
    },
    [`& .${classes.widthWrapper}`]: {
        maxWidth: "100%"
    },
    [`& .${classes.orderedListItem}`]: {
        marginLeft: "16px",
        marginBottom: "18px"
    },
    [`& .${classes.outcomes}`]: {
        marginTop: "24px",
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%"
    },
    [`& .${classes.outcomesItem}`]: {
        margin: "12px 0px 8px 0px"
    },
}));

export const documentClasses = classes;