import React from "react";
import { partFourNewClasses as classes, StyledPartFourNew } from "./StyledPartFourNew";
import { Container, Typography, Button, Grid, Link, Box } from "@mui/material";
import FileOpenIcon from '@mui/icons-material/FileOpen';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

const swiperSecondElements = [
    {
        class: classes.swiperSecondElementFirst,
        heading: "WHO SMART Guidelines",
        text: "WHO envisions a future where everyone in the world benefits fully and immediately from clinical, public health, and data-use recommendations. SMART Guidelines are a new approach to systematize and accelerate the consistent application of recommended, life-saving interventions in the digital age. Learn more here.",
        href: "https://www.who.int/teams/digital-health-and-innovation/smart-guidelines",
        buttonName: "Access page",
    },
    {
        class: classes.swiperSecondElementSecond,
        heading: "WHO SMART Guidelines vision",
        text: "This Lancet Digital Health publication explains the SMART – Standards-Based, Machine Readable, Adaptive, Requirements-based and Testable- Guideline approach and related products. It describes WHO’s longterm vision for this approach.",
        href: "https://www.thelancet.com/journals/landig/article/PIIS2589-7500(21)00038-8/fulltext",
        buttonName: "Read article",
    },
    {
        class: classes.swiperSecondElementThird,
        heading: "ANC Digital Adaptation Kit (Layer 2)",
        text: "This DAK includes data and health content consistent with WHO’s ANC recommendations, generically applicable to digital systems. They are software-neutral, operational and structured documentation based on WHO clinical, health system and data use recommendations to systematically and transparently inform the design of digital systems. Components include: (1) linked health interventions and recommendations; (2) personas; (3) user scenarios; (4) business processes and workflows; (5) core data elements mapped to standard terminology codes (e.g. ICD); (6) decision support; (7) programme indicators; and (8) functional and non-functional requirements.",
        href: "https://www.who.int/publications/i/item/9789240020306",
        buttonName: "Access document",
    },
    {
        class: classes.swiperSecondElementFourth,
        heading: "FHIR Implementation Guide (Layer 3)",
        text: "This third layer (L3) provides code necessary for software developers to incorporate standardized logic from WHO guidelines into digital systems. A standards-based technical implementation guide builds upon the DAKs by mapping its content to value sets and Health Level Seven International (HL7) Fast Healthcare Interoperability Resources (FHIR) standards.",
        href: "http://build.fhir.org/ig/WorldHealthOrganization/smart-anc/",
        buttonName: "Access guide",
    },
    {
        class: classes.swiperSecondElementFifth,
        heading: "WHO Digital ANC training module & training package (Layer 4)",
        text: "This module is a digital tool which incorporates decision support and person-centred tracking. It is customizable for content and cadre within the health system and meant to be integrated within the country digital ecosystem (Health Management Information Systems, logistical and local electronic medical records systems). This training package includes user and facilitator guides, as well as training presentations, learning assessment tools, and feedback forms.",
        href: "https://docs.google.com/forms/d/e/1FAIpQLSfV1DYaU1Cn1-AsotEqf4eTnaaKNq0CmTiKSdd1HHcU40y3TA/viewform",
        buttonName: "Access module",
    },
    {
        class: classes.swiperSecondElementFifth,
        heading: "PNC Digital Adaptation Kit (publication forthcoming)",
        text: "This DAK includes data and health content consistent with WHO’s PNC recommendations, generically applicable to digital health systems. The publication is forthcoming and will be shared on this platform as soon as available.",
        href: "",
        buttonName: "",
    },
]

const swiperThirdElements = [
    {
        class: classes.swiperCardImageTwo,
        heading: "Strengthening implementation of home-based records for maternal, newborn and child health: a guide for country programme-managers.",
        href: "https://apps.who.int/iris/handle/10665/365785",
        text: "The guide was developed in response to the implementation issues and seeks to provide decision-making tools and activities, links to existing resources and examples from countries that can be used to strengthen processes for planning, design, implementation and monitoring of home-based records. ",
    },
    {
        class: classes.swiperCardImageOne,
        heading: "WHO Guide for integration of perinatal mental health in maternal and child services.",
        href: "https://apps.who.int/iris/handle/10665/362880",
        text: "The guide outlines an evidence-informed approach describing how program managers, health service administrators and policy-makers responsible of planning and managing health services can develop and sustain high-quality, integrated mental health services for women during the perinatal period",
    },
    {
        class: classes.swiperCardImageThree,
        heading: "Improving the quality of care for maternal, newborn and child health: implementation guide for national, district and facility levels.",
        href: "https://iris.who.int/handle/10665/353738",
        text: "This implementation guide contains practical guidance for policy-makers, programme managers, health practitioners and other actors working to establish and implement quality of care (QoC) programmes for maternal, newborn and child health (MNCH) at national, district and facility levels.",
    },
    {
        class: classes.swiperCardImageFour,
        heading: "Integrating stakeholder and community engagement in quality of care initiatives for maternal, newborn and child health.",
        href: "https://apps.who.int/iris/handle/10665/333922",
        text: "This module aims to make stakeholder and community engagement an integral part of quality improvement (QI) initiatives and suggests approaches to make engagement comprehensive (throughout QI planning, implementation, monitoring and evaluation) and meaningful (supporting efficiency and partnership building).",
    },
]

export const PartFourNew = () => {
    return <StyledPartFourNew className={classes.root}>
        <Container id='Tools' className={classes.sectionWrapper}>
            <Container className={classes.sectionFirstContent}>
                <Container className={classes.sectionFirstText}>
                    <Typography className={classes.textHeading} variant="h1">Part 4</Typography>
                    <Typography variant="h2" className={classes.textBold}>
                        Implementation & Digital Health Tools
                        <Box className={classes.sideLine}/>
                    </Typography>
                </Container>
                <Typography className={classes.textSecondHeading} variant="h2">WHO ANC recommendations adaptation toolkit</Typography>
                <Typography variant="h2" className={classes.textSmall}>
                    Since the publication of the WHO ANC recommendations, WHO has developed tools to
                    support their adaptation and implementation. These include the “WHO ANC
                    recommendations adaptation toolkit” the “WHO ANC monitoring framework”. Please note
                    that the toolkit is being updated to include the WHO PNC recommendations, published in
                    2022, and the “WHO PNC monitoring framework”. Both tools will be shared on this platform
                    as soon as they are available.<br/>
                    Please scroll below for access to these tools and their components.
                </Typography>
            </Container>
        </Container>
        <Swiper id="swiperFirst" slidesPerView={"auto"} centeredSlides={true} spaceBetween={25} loop={true} navigation={true} modules={[Navigation]} className={classes.swiperOne}>
            <SwiperSlide className={classes.swiperOneCard}>
                <Grid container>
                    <Grid item className={`${classes.swiperCardImage} ${classes.swiperOneElementSecond}`}>
                        <Container className={classes.swiperCardGradient}>
                            <Typography className={classes.swiperCardHeading}>WHO ANC Adaptation Toolkit: Baseline Assessment Tool (BAT)</Typography>
                            <Grid container className={classes.swiperCardButtons}>
                                <Grid item>
                                    <Link className={classes.cardButtonDownload} underline="none" href="/Outils-d'évaluation-French_3abril20.xls" download>
                                        <Button className={classes.cardButton}>
                                            <FileDownloadIcon className={classes.cardButtonIcon} />
                                            BAT - FR
                                        </Button>
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link className={classes.cardButtonDownload} underline="none" href="/baseline-assessment-tool_anc-03_april_20.xls" download>
                                        <Button className={classes.cardButton}>
                                            <FileDownloadIcon className={classes.cardButtonIcon} />
                                            BAT - EN
                                        </Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                    <Grid item className={classes.swiperOneCardText}>
                        The BAT consists of a
                        Microsoft Excel file including sub-components for the situational analysis, for the
                        recommendations mapping, for the country-specific packages and SWOT analysis for
                        implementing ANC recommendations.
                    </Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide className={classes.swiperOneCard}>
                <Grid container>
                    <Grid item className={`${classes.swiperCardImage} ${classes.swiperOneElementThird}`}>
                        <Container className={classes.swiperCardGradient}>
                            <Typography className={classes.swiperCardHeading}>WHO ANC recommendations adaptation toolkit: Qualitative Evidence Syntheses (QES) Slidedoc ®</Typography>
                            <Grid container className={classes.swiperCardButtons}>
                                <Grid item>
                                    <Button className={classes.cardButton} href="/ANC QES slidedoc v2.3 -french.pdf" target="_blank">
                                        <FileOpenIcon className={classes.cardButtonIcon} />
                                        QES - FR
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button className={classes.cardButton} href="/ANC QES slidedoc v2.3.pdf" target="_blank">
                                        <FileOpenIcon className={classes.cardButtonIcon} />
                                        QES - EN
                                    </Button>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                    <Grid item className={classes.swiperOneCardText}>
                        This PowerPoint built using Duarte Slidedoc® format details how
                        the QES were employed, to inform the ANC recommendations
                        (regarding values and acceptability), and shape the entire guideline
                        development process, explaining the focus on a ‘positive pregnancy
                        experience’.
                    </Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide className={classes.swiperOneCard}>
                <Grid container>
                    <Grid item className={`${classes.swiperCardImage} ${classes.swiperOneElementFirst}`}>
                        <Container className={classes.swiperCardGradient}>
                            <Typography className={classes.swiperCardHeading}>WHO ANC recommendations adaptation toolkit: User Manual</Typography>
                            <Grid container className={classes.swiperCardButtons}>
                                <Grid item>
                                    <Button className={classes.cardButton} href="/WHO_ANC Instruction Manual_Final_fr.pdf" target="_blank">
                                        <FileOpenIcon className={classes.cardButtonIcon} />
                                        Manual - FR
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button className={classes.cardButton} href="/WHO_ANC Instruction Manual_Final.pdf" target="_blank">
                                        <FileOpenIcon className={classes.cardButtonIcon} />
                                        Manual - EN
                                    </Button>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                    <Grid item className={classes.swiperOneCardText}>
                        This user-friendly manual aims to guide
                        stakeholders through updating their national ANC policies using the toolkit. It contains
                        detailed instructions on the process to complete the different components of the Baseline
                        Assessment Tool (BAT), the use of the QES Slidedoc® and the necessary supplementary
                        materials.
                    </Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide className={classes.swiperOneCard}>
                <Grid container>
                    <Grid item className={`${classes.swiperCardImage} ${classes.swiperOneElementFourth}`}>
                        <Container className={classes.swiperCardGradient}>
                            <Typography className={classes.swiperCardHeading}>WHO ANC Monitoring Framework</Typography>
                            <Grid container className={classes.swiperCardButtons}>
                                <Grid item>
                                    <Button className={classes.cardButton} href="https://bmjopen.bmj.com/content/9/4/e024130" target="_blank">
                                        <FileOpenIcon className={classes.cardButtonIcon} />
                                        Scoping review
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button className={classes.cardButton} href="https://gh.bmj.com/content/5/6/e002605" target="_blank">
                                        <FileOpenIcon className={classes.cardButtonIcon} />
                                        Framework
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button className={classes.cardButton} href="https://apps.who.int/iris/handle/10665/340108" target="_blank">
                                        <FileOpenIcon className={classes.cardButtonIcon} />
                                        Lessons from South Africa
                                    </Button>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                    <Grid item className={classes.swiperOneCardText}>
                        The framework is based on the new ANC model to track
                        implementation and establish global standards. The framework was
                        created by a three-pronged approach. Results from this process are
                        published in a scoping review and lessons from the South African
                        implementation experience.
                    </Grid>
                </Grid>
            </SwiperSlide>
            <SwiperSlide className={classes.swiperOneCard}>
                <Grid container>
                    <Grid item className={`${classes.swiperCardImage} ${classes.swiperOneElementFifth}`}>
                        <Container className={classes.swiperCardGradient}>
                            <Typography className={classes.swiperCardHeading}>WHO PNC Monitoring Framework (publication forthcoming)</Typography>
                            <Grid container className={classes.swiperCardButtons}>
                                <Grid item>
                                    <Button className={classes.cardButton} href="https://journals.plos.org/globalpublichealth/article?id=10.1371/journal.pgph.0001384" target="_blank">
                                        Scoping review
                                    </Button>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                    <Grid item className={classes.swiperOneCardText}>
                        The PNC monitoring framework will track the implantation and establish global standards for monitoring PNC services.
                    </Grid>
                </Grid>
            </SwiperSlide>
        </Swiper>
        <Container className={classes.sectionSecondWrapper}>
            <Container className={classes.sectionWrapper}>
                <Container className={classes.sectionSecondContent}>
                    <Container className={classes.sectionSecondText}>
                        <Typography className={classes.textSecondHeading} variant="h2">Digital Health Tools</Typography>
                        WHO SMART Guidelines support countries to integrate WHO global data and health
                        recommendations into digital systems accurately and consistently. Digital Adaptation Kits (DAKs)
                        are part of the SMART guidelines initiative and include data and health content consistent with
                        WHO’s recommendations, generically applicable to digital systems. They are software-neutral,
                        operational, and structured documentation based on WHO clinical, health system and data use
                        recommendations to systematically and transparently inform the design of digital systems,
                        organized in different knowledge layers. The ANC DAK includes information consistent with
                        WHO’s antenatal care recommendations.
                    </Container>
                </Container>
            </Container>
            <Swiper id="swiperSecond" slidesPerView={"auto"} centeredSlides={true} spaceBetween={25} loop={true} navigation={true} modules={[Navigation]}>
                {swiperSecondElements.map( (element, i) => (
                    <SwiperSlide key={i} id="swiperSecondSlide" className={classes.swiperTwoCard}>
                        <Grid container>
                            <Grid item className={`${classes.swiperCardImage} ${element.class}`}>
                                <Container className={classes.swiperCardGradient}>
                                    <Typography className={classes.swiperCardHeading}>{element.heading}</Typography>
                                    <Grid container className={classes.swiperCardButtons}>
                                        <Grid item>
                                            {element.buttonName &&
                                                <Button className={classes.cardButton} href={element.href} target="_blank">
                                                    <FileOpenIcon className={classes.cardButtonIcon} />
                                                    {element.buttonName}
                                                </Button>
                                            }
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Grid>
                            <Grid item className={classes.swiperTwoCardText}>
                                {element.text}
                            </Grid>
                        </Grid>
                    </SwiperSlide>
                ))}
            </Swiper>
        </Container>
        <Container className={classes.sectionThirdWrapper}>
            <Container className={classes.sectionWrapper}>
                <Container className={classes.sectionSecondContent}>
                    <Container className={classes.sectionSecondText}>
                        <Typography className={classes.textSecondHeading} variant="h2">Other implementation tools</Typography>
                        Maternal and newborn health implementation tools and resources made available by WHO
                        are associated with a multimodal implementation approach that integrates best practices
                        within an improved safety and organizational culture. Please see below for a set of key
                        implementation tools and guidance published by WHO and relevant to ANC and PNC.
                    </Container>
                </Container>
            </Container>
            <Swiper id="swiperThird" slidesPerView={"auto"} centeredSlides={true} spaceBetween={25} loop={true} navigation={true} modules={[Navigation]}>
                {swiperThirdElements.map( (element, index) => (
                    <SwiperSlide key={index} className={classes.swiperOneCard}>
                        <Grid container>
                            <Grid item className={`${classes.swiperCardImage} ${element.class}`}>
                                <Container className={classes.swiperCardGradient}>
                                    <Typography className={classes.swiperCardHeading}>{element.heading}</Typography>
                                    <Grid container className={classes.swiperCardButtons}>
                                        <Grid item>
                                            <Button className={classes.cardButton} href={element.href} target="_blank">
                                                <FileOpenIcon className={classes.cardButtonIcon} />
                                                Access document
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Grid>
                            <Grid item className={classes.swiperTwoCardText}>
                                {element.text}
                            </Grid>
                        </Grid>
                    </SwiperSlide>
                ))}
            </Swiper>
        </Container>
    </StyledPartFourNew>;
};