import {Header} from "./Header/Header";
import {PartOne} from "./PartOne/PartOne";
import {PartTwoPNC} from "./PartTwoPNC/PartTwoPNC";
import {PartThree} from "./PartThree/PartThree";
import {PartFourNew} from "./PartFour/PartFourNew";
import {PartFive} from "./PartFive/PartFive";
import {Footer} from "./Footer/Footer";
import {Grid} from "@mui/material";
import React from "react";

export const Home = () => {
    return (
        <Grid container>
            <Header />
            <PartOne />
            <PartTwoPNC />
            <PartThree />
            <PartFourNew />
            <PartFive />
            <Footer />
        </Grid>
    )
}