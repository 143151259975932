import React from "react";
import { styled } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';

export const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        maxHeight: '216px',
        maxWidth: '100%',
        marginTop: '16px',
        borderRadius: '0px',
        boxShadow: 'none',
        color: '#000',
        marginLeft: '7.5px',
        [theme.breakpoints.down('mobileXL')]: {
            width: '720px',
        },
        [theme.breakpoints.down('mobileLG')]: {
            width: '540px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '450px',
        },
        [theme.breakpoints.down('mobileMD')]: {
            width: '320px',
        },
        [theme.breakpoints.down('mobileSM')]: {
            width: '300px',
        },
    },
}));
