import {Grid, styled} from "@mui/material";
import {prefixClasses} from "../common/Theme/utils";

const classes = {
    root: "root",
    sideLine: "sideLine",
    sectionWrapper: "sectionWrapper",
    sectionContent: "sectionContent",
    sectionText: "sectionText",
    textHeading: "textHeading",
    textBold: "textBold",
    textSmall: "textSmall",
    sectionSwiper: "sectionSwiper",
    swiperElementWrapper: "swiperElementWrapper",
    swiperElement: "swiperElement",
    elementCaption: "elementCaption",
    elementImage: "elementImage",
    elementImageWrapper: "elementImageWrapper"
};

prefixClasses("PartThree", classes);

export const StyledPartThree = styled(Grid)(({ theme }) => ({
    [`&.${classes.root}`]: {
        padding: '48px 0',
        background: "linear-gradient(108deg, rgba(30,37,47,1) 32%, rgba(255,255,255,0) 100%), url('/part-three-bg.jpg')",
        backgroundSize: 'cover',
        backgroundPositionX: 'right',
        backgroundPositionY: 'top',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'rgb(30, 37, 47)',
        [theme.breakpoints.down('mobileLG')]: {
            backgroundPositionX: '85%',
        },
    },
    [`& .${classes.sideLine}`]: {
        height: '2px',
        position: 'relative',
        width: '100%',
        bottom: '40px',
        right: 'calc(100% + 60px)',
        backgroundColor: '#d2e0e6',
        [theme.breakpoints.down('mobileXL')]: {
            display: 'none',
        },
    },
    [`& .${classes.sectionWrapper}`]: {
        padding: '0 0px',
        margin: '0 auto',
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.down('xl')]: {
            maxWidth: '1140px',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '960px',
        },
        [theme.breakpoints.down('mobileXL')]: {
            maxWidth: '720px',
        },
        [theme.breakpoints.down('mobileLG')]: {
            maxWidth: '540px',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '450px',
        },
        [theme.breakpoints.down('mobileMD')]: {
            maxWidth: '320px',
        },
        [theme.breakpoints.down('mobileSM')]: {
            maxWidth: '300px',
        },
    },
    [`& .${classes.sectionContent}`]: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: '83.33%',
        maxWidth: '83.33%',
        marginLeft: '8.33%',
        padding: '0 7.5px',
        [theme.breakpoints.down('mobileXL')]: {
            flex: 'auto',
            marginLeft: '0',
            padding: '0 0',
            maxWidth: '100%',
        },
    },
    [`& .${classes.sectionText}`]: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0px',
    },
    [`& .${classes.textHeading}`]: {
        color: '#d2e0e6',
        fontSize: '1.875rem',
        textTransform: 'uppercase',
        marginBottom: '1.5rem',
        fontWeight: '700',
    },
    [`& .${classes.textBold}`]: {
        color: '#d2e0e6',
        fontSize: '1.875rem',
        marginBottom: '48px',
        [theme.breakpoints.down('mobileMD')]: {
            fontSize: '1.5rem',
        },
    },
    [`& .${classes.textSmall}`]: {
        color: '#d2e0e6',
        fontSize: '1rem',
        marginBottom: '32px',
    },
    [`& .${classes.sectionSwiper}`]: {
        height: '460px'
    },
    [`& .${classes.swiperElementWrapper}`]: {
        maxHeight: '126px',
        [theme.breakpoints.down('xl')]: {
            maxWidth: '311px',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '261px',
        },
        [theme.breakpoints.down('mobileXL')]: {
            maxWidth: '360px',
        },
        [theme.breakpoints.down('mobileLG')]: {
            maxWidth: '540px',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '450px',
        },
        [theme.breakpoints.down('mobileMD')]: {
            maxWidth: '320px',
        },
        [theme.breakpoints.down('mobileSM')]: {
            maxWidth: '300px',
        },
    },
    [`& .${classes.swiperElement}`]: {
        height: '126px',
        padding: '13px',
        margin: '0px',
        width: '301.667px',
        [theme.breakpoints.down('xl')]: {
            width: '311px',
        },
        [theme.breakpoints.down('md')]: {
            width: '261px',
        },
        [theme.breakpoints.down('mobileXL')]: {
            width: '360px',
        },
        [theme.breakpoints.down('mobileLG')]: {
            width: '540px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '450px',
        },
        [theme.breakpoints.down('mobileMD')]: {
            width: '320px',
        },
        [theme.breakpoints.down('mobileSM')]: {
            width: '300px',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#d2e0e6',
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: '#fff',
            color: '#1e252f'
        },
    },
    [`& .${classes.elementCaption}`]: {
        marginLeft: '15px',
        paddingLeft: '5px',
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '66.66%',
        height: '100%',
        fontSize: '1rem',
        lineHeight: '1.5',
    },
    [`& .${classes.elementImage}`]: {
        height: '100px',
        borderRadius: '20px',
        maxWidth: '100%',
        [theme.breakpoints.down('mobileXL')]: {
            width: '66.66%',
        },
        [theme.breakpoints.down('mobileLG')]: {
            width: '40%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '50%',
        },
        [theme.breakpoints.down('mobileMD')]: {
            width: '70%',
        },
    },
    [`& .${classes.elementImageWrapper}`]: {
        width: '33%',
        padding: '0 7.5px',
        [theme.breakpoints.down('md')]: {
            padding: '0px',
        },
        [theme.breakpoints.down('mobileLG')]: {
            display: 'flex',
            justifyContent: 'center',
        },
    },
    [`& .swiper-pagination-bullet`]: {
        width: '15px',
        height: '15px',
        background: '#fff',
    },
    [`& .swiper-wrapper`]: {
        alignContent: 'flex-start',
    },
}));

export const partThreeClasses = classes;