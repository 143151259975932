import modalFirstPhoto1 from "../assets/PartTwoAssets/modalFirstPhoto1.jpeg"
import modalFirstPhoto2 from "../assets/PartTwoAssets/modalFirstPhoto2.jpeg"
import modalFirstPhoto3 from "../assets/PartTwoAssets/modalFirstPhoto3.jpeg"
import modalFirstPhoto4 from "../assets/PartTwoAssets/modalFirstPhoto4.jpg"
import modalFirstPhoto5 from "../assets/PartTwoAssets/modalFirstPhoto5.jpeg"
import modalFirstPhoto6 from "../assets/PartTwoAssets/modalFirstPhoto6.jpeg"
import modalFirstPhoto7 from "../assets/PartTwoAssets/modalFirstPhoto7.jpg"
import modalSecondPhoto1 from "../assets/PartTwoAssets/modalSecondPhoto1.jpeg"
import modalSecondPhoto2 from "../assets/PartTwoAssets/modalSecondPhoto2.jpeg"
import modalThirdPhoto1 from "../assets/PartTwoAssets/modalThirdPhoto1.jpeg"
import modalThirdPhoto2 from "../assets/PartTwoAssets/modalThirdPhoto2.jpeg"
import modalThirdPhoto3 from "../assets/PartTwoAssets/modalThirdPhoto3.jpeg"
import modalThirdPhoto4 from "../assets/PartTwoAssets/modalThirdPhoto4.jpeg"
import modalThirdPhoto5 from "../assets/PartTwoAssets/modalThirdPhoto5.jpeg"
import modalThirdPhoto6 from "../assets/PartTwoAssets/modalThirdPhoto6.jpeg"
import modalThirdPhoto7 from "../assets/PartTwoAssets/modalThirdPhoto7.jpg"
import modalFourthPhoto1 from "../assets/PartTwoAssets/modalFourthPhoto1.jpeg"
import modalFourthPhoto2 from "../assets/PartTwoAssets/modalFourthPhoto2.jpeg"
import modalFourthPhoto3 from "../assets/PartTwoAssets/modalFourthPhoto3.jpeg"
import modalFourthPhoto4 from "../assets/PartTwoAssets/modalFourthPhoto4.jpg"
import modalFourthPhoto5 from "../assets/PartTwoAssets/modalFourthPhoto5.jpeg"
import modalFourthPhoto6 from "../assets/PartTwoAssets/modalFourthPhoto6.jpeg"
import modalFourthPhoto7 from "../assets/PartTwoAssets/modalFourthPhoto7.jpeg"
import modalFourthPhoto8 from "../assets/PartTwoAssets/modalFourthPhoto8.jpeg"
import modalFourthPhoto9 from "../assets/PartTwoAssets/modalFourthPhoto9.jpeg"
import modalFourthPhoto10 from "../assets/PartTwoAssets/modalFourthPhoto10.jpg"
import modalFourthPhoto11 from "../assets/PartTwoAssets/modalFourthPhoto11.jpg"
import modalFourthPhoto12 from "../assets/PartTwoAssets/modalFourthPhoto12.jpg"
import modalFifthPhoto1 from "../assets/PartTwoAssets/modalFifthPhoto1.jpg"
import modalFifthPhoto2 from "../assets/PartTwoAssets/modalFifthPhoto2.jpeg"
import modalFifthPhoto3 from "../assets/PartTwoAssets/modalFifthPhoto3.jpeg"
import modalFifthPhoto4 from "../assets/PartTwoAssets/modalFifthPhoto4.jpeg"
import modalFifthPhoto5 from "../assets/PartTwoAssets/modalFifthPhoto5.jpg"
import modalFifthPhoto6 from "../assets/PartTwoAssets/modalFifthPhoto6.jpg"
import modalSixthPhoto1 from "../assets/PartTwoAssets/modalSixthPhoto1.jpeg"
import modalSixthPhoto2 from "../assets/PartTwoAssets/modalSixthPhoto2.jpeg"
import modalSeventhPhoto1 from "../assets/PartTwoAssets/modalSeventhPhoto1.jpeg"
import modalEighthPhoto1 from "../assets/PartTwoAssets/modalEighthPhoto1.jpeg"
import modalEighthPhoto2 from "../assets/PartTwoAssets/modalEighthPhoto2.jpeg"
import modalEighthPhoto3 from "../assets/PartTwoAssets/modalEighthPhoto3.jpeg"
import modalEighthPhoto4 from "../assets/PartTwoAssets/modalEighthPhoto4.jpeg"
import modalEighthPhoto5 from "../assets/PartTwoAssets/modalEighthPhoto5.jpeg"
import modalEighthPhoto6 from "../assets/PartTwoAssets/modalEighthPhoto6.jpeg"
import modalEighthPhoto7 from "../assets/PartTwoAssets/modalEighthPhoto7.jpeg"
import modalEighthPhoto8 from "../assets/PartTwoAssets/modalEighthPhoto8.jpeg"
import modalEighthPhoto9 from "../assets/PartTwoAssets/modalEighthPhoto9.jpeg"
import modalEighthPhoto10 from "../assets/PartTwoAssets/modalEighthPhoto10.jpg"
import modalEighthPhoto11 from "../assets/PartTwoAssets/modalEighthPhoto11.jpeg"
import modalEighthPhoto12 from "../assets/PartTwoAssets/modalEighthPhoto12.jpg"
import modalNinthPhoto1 from "../assets/PartTwoAssets/modalNinthPhoto1.png"
import modalNinthPhoto2 from "../assets/PartTwoAssets/modalNinthPhoto2.png"
import modalNinthPhoto3 from "../assets/PartTwoAssets/modalNinthPhoto3.png"
import modalNinthPhoto4 from "../assets/PartTwoAssets/modalNinthPhoto4.png"
import modalNinthPhoto5 from "../assets/PartTwoAssets/modalNinthPhoto5.png"

export const modalFirstContent = [
    {
        image: modalFirstPhoto1,
        category: "maternal",
        alt: "a bottle of medication",
        credits: "©UNICEF",
        text: "Postpartum oral iron and folate supplementation",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/jXx3kx",
    },
    {
        image: modalFirstPhoto2,
        category: "maternal",
        alt: "a woman with a stroller",
        credits: "Marcin Jozwiak/ Unsplash",
        text: "Physical activity and sedentary behaviour",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/EeB7Rz",
    },
    {
        image: modalFirstPhoto7,
        category: "maternal",
        alt: "a woman breastfeeding and a doctor",
        credits: "© WHO / Christopher Black, reference HQ11119",
        text: "Postpartum Vitamin A supplementation",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/nYvGNP",
    },
    {
        image: modalFirstPhoto3,
        category: "newborn",
        alt: "a baby",
        credits: "©UNICEF",
        text: "Neonatal vitamin A supplementation",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/jmYXDg",
    },
    {
        image: modalFirstPhoto4,
        category: "newborn",
        alt: "a woman breastfeeding",
        credits: "© WHO / Fernando G. Revilla, reference HQ10752 ",
        text: "Vitamin D supplementation for breastfed, term infants",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/nVNW24",
    },
    {
        image: modalFirstPhoto5,
        category: "breastfeeding",
        alt: "a woman breastfeeding",
        credits: "©UNICEF",
        text: "Exclusive breastfeeding",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/LG67rJ",
    },
    {
        image: modalFirstPhoto6,
        category: "breastfeeding",
        alt: "a woman breastfeeding and a doctor",
        credits: "© WHO / Christopher Black, reference HQ11119",
        text: "Protecting, promoting and supporting breastfeeding in facilities providing maternity and newborn services",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/E85e1q",
    },
]

export const modalSecondContent = [
    {
        image: modalSecondPhoto1,
        category: "newborn",
        alt: "а baby getting its diaper changed",
        credits: "CC by brapps",
        text: "Whole-body massage",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/L0zKOA",
    },
    {
        image: modalSecondPhoto2,
        category: "newborn",
        alt: "a woman and a baby",
        credits: "https://openverse.org/image/b1aed4ab-0ce9-48fa-b4b5-4d0372862af5?q=baby%20massage",
        text: "Early childhood development",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/j2oe9A",
        otherHref: [
            "https://app.magicapp.org/#/guideline/jxBJyn/rec/EPNGJ5",
            "https://app.magicapp.org/#/guideline/jxBJyn/rec/LA6rGb",
            "https://app.magicapp.org/#/guideline/jxBJyn/rec/LpRX6b",
        ],
    },
]

export const modalThirdContent = [
    {
        image: modalThirdPhoto1,
        category: "maternal",
        alt: "a woman and a doctor",
        credits: "© UNICEF Ethiopia/2021/Nahom Tesfaye",
        text: "Physiological assessment of the woman",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/nyq17V",
    },
    {
        image: modalThirdPhoto2,
        category: "maternal",
        alt: "getting a blood sample",
        credits: "©UNAIDS",
        text: "HIV testing",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/LwrXvp",
    },
    {
        image: modalThirdPhoto3,
        category: "maternal",
        alt: "scientific testing",
        credits: "© WHO / David Orr",
        text: "Tuberculosis testing",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/Lk6XJA",
    },
    {
        image: modalThirdPhoto4,
        category: "newborn",
        alt: "a woman with her baby and a doctor",
        credits: "© WHO",
        text: "Assessment of the newborn for danger signs",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/jOpRoG",
    },
    {
        image: modalThirdPhoto5,
        category: "newborn",
        alt: "a doctor examining a baby",
        credits: "©UNICEF & https://weshare.unicef.org/archive/-2AM40801SXGD.html",
        text: "Universal screening for abnormalities of the eye",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/jbobvZ",
    },
    {
        image: modalThirdPhoto6,
        category: "newborn",
        alt: "ear testing",
        credits: "",
        text: "Universal screening for hearing impairment",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/j96eav",
    },
    {
        image: modalThirdPhoto7,
        category: "newborn",
        alt: "a woman with her baby and a nurse",
        credits: "© UNICEF",
        text: "Universal screening for neonatal hyperbilirubinaemia",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/jxQXw4",
        otherHref: ["https://app.magicapp.org/#/guideline/jxBJyn/rec/j7AeJ4"],
    },
]

export const modalFourthContent = [
    {
        image: modalFourthPhoto1,
        category: "maternal",
        alt: "a woman breastfeeding",
        credits: "UNICEF/UN0206959/Alcock & https://weshare.unicef.org/archive/-2AMZIF2Q0AB9.html",
        text: "Non-pharmacological interventions to prevent postpartum mastitis",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/jN2GMG",
    },
    {
        image: modalFourthPhoto2,
        category: "maternal",
        alt: "a woman eating a soup",
        credits: "Henrique Felix Unsplash",
        text: "Prevention of postpartum constipation",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/LqlDVJ",
        otherHref: ["https://app.magicapp.org/#/guideline/jxBJyn/rec/EZ1Zw7"],
    },
    {
        image: modalFourthPhoto3,
        category: "maternal",
        alt: "a blister of pills",
        credits: "https://www.pexels.com/photo/pink-round-medication-pill-51929/",
        text: "Preventive anthelmintic treatment",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/jMpkD8",
    },
    {
        image: modalFourthPhoto4,
        category: "maternal",
        alt: "pills and a palm",
        credits: "Pexles picture, free to share ",
        text: "Preventive schistosomiasis treatment",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/jlRXXx",
    },
    {
        image: modalFourthPhoto5,
        category: "maternal",
        alt: "a woman with her baby and a doctor",
        credits: "© UNICEF",
        text: "Oral pre-exposure prophylaxis for HIV prevention",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/ERlGG2",
    },
    {
        image: modalFourthPhoto10,
        category: "maternal",
        alt: "a woman with her baby and a doctor",
        credits: "© WHO / Fanjan Combrink",
        text: "Prevention of maternal peripartum infection after uncomplicated vaginal birth",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/EQNKMw",
    },
    {
        image: modalFourthPhoto12,
        category: "maternal",
        alt: "a woman breastfeeding a baby",
        credits: "©UNICEF/Ethiopia",
        text: "Pharmacological interventions to prevent postpartum mastitis",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/EdrxQk",
    },
    {
        image: modalFourthPhoto6,
        category: "newborn",
        alt: "a baby",
        credits: "© UNICEF",
        text: "Timing of first bath to prevent hypothermia and its sequelae",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/jzogBd",
    },
    {
        image: modalFourthPhoto7,
        category: "newborn",
        alt: "a baby",
        credits: "",
        text: "Application of chlorhexidine to the umbilical cord stump for the prevention of neonatal infection",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/nJBJbR",
    },
    {
        image: modalFourthPhoto8,
        category: "newborn",
        alt: "a baby sleeping",
        credits: "Flickr - https://www.flickr.com/photos/nichd/48191422142/\n",
        text: "Sleeping position for the prevention of sudden infant death syndrome",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/L48W7d\n",
    },
    {
        image: modalFourthPhoto9,
        category: "newborn",
        alt: "a newborn baby",
        credits: "© UNICEF/UN0157417/Ayene & https://weshare.unicef.org/archive/-2AMZIF2OYL9X.html\n",
        text: "Immunization for the prevention of infections",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/Ea0O31",
    },
    {
        image: modalFourthPhoto11,
        category: "newborn",
        alt: "a newborn baby",
        credits: "©UNICEF Ethiopia/2021/Mulugeta Ayene ",
        text: "Use of emollients for prevention of skin conditions",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/noRX3k",
    },
]

export const modalFifthContent = [
    {
        image: modalFifthPhoto1,
        category: "maternal",
        alt: "two women",
        credits: "©UNICEF https://weshare.unicef.org/archive/-2AM40801SZ74.html",
        text: "Local cooling for perineal pain relief",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/n326rk",
    },
    {
        image: modalFifthPhoto2,
        category: "maternal",
        alt: "pills",
        credits: "free to share - Openverse",
        text: "Oral analgesia for perineal pain relief",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/jXx3gN",
    },
    {
        image: modalFifthPhoto3,
        category: "maternal",
        alt: "pills",
        credits: "free to share - Pexels",
        text: "Pharmacological relief of pain due to uterine cramping/involution",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/nYvGyy",
    },
    {
        image: modalFifthPhoto4,
        category: "maternal",
        alt: "a woman breastfeeding and a nurse",
        credits: "©UNICEF",
        text: "Non-pharmacological interventions to treat postpartum breast engorgement",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/L6mebr\n",
    },
    {
        image: modalFifthPhoto5,
        category: "maternal",
        alt: "a woman breastfeeding and a nurse",
        credits: "Russell Watkins/DFID FCDO",
        text: "Postnatal pelvic floor muscle training for pelvic floor strengthening",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/EeB7kl",
    },
    {
        image: modalFifthPhoto6,
        category: "maternal",
        alt: "a woman breastfeeding and a nurse",
        credits: "",
        text: "Pharmacological interventions to treat postpartum breast engorgement",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/EvwMY0",
    },
]

export const modalSixthContent = [
    {
        image: modalSixthPhoto1,
        category: "maternal",
        alt: "a woman at a therapist",
        credits: "free to share - Pexels",
        text: "Screening for postpartum depression and anxiety",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/Lk6XXW\n",
    },
    {
        image: modalSixthPhoto2,
        category: "maternal",
        alt: "three mothers",
        credits: "© WHO / Sergey Petkoglo",
        text: "Prevention of postpartum depression and anxiety",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/n326Vo\n",
    },
]

export const modalSeventhContent = [
    {
        image: modalSeventhPhoto1,
        category: "maternal",
        alt: "a doctor",
        credits: "©UNICEF",
        text: "Postpartum contraception",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/nBMB88",
    },
]

export const modalEighthContent = [
    {
        image: modalEighthPhoto1,
        category: "other",
        alt: "a woman and a nurse",
        credits: "© H6/Abbie Trayler-Smith",
        text: "Schedules for postnatal care contacts",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/jlRXDR",
    },
    {
        image: modalEighthPhoto2,
        category: "other",
        alt: "a woman and a newborn baby",
        credits: "Free to share - Openverse by Scott SM (licensed under CC BY-NC 2.0) & https://openverse.org/image/0a6eac57-0275-489c-99d8-62ba9f09151f?q=mother%20and%20hospital",
        text: "Length of stay in health facilities after birth",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/ERlG1W",
    },
    {
        image: modalEighthPhoto3,
        category: "other",
        alt: "a nurse examining a patient",
        credits: "© MSF",
        text: "Assessment prior to discharge from the health facility after birth",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/Lk6Xlk",
    },
    {
        image: modalEighthPhoto4,
        category: "other",
        alt: "a woman and a baby",
        credits: "© UNICEF",
        text: "Strengthening of preparation for discharge from the health facility to home after birth",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/n326A0",
    },
    {
        image: modalEighthPhoto5,
        category: "other",
        alt: "two women",
        credits: "© USAID",
        text: "Home visits for postnatal care contacts",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/jXx3O6",
    },
    {
        image: modalEighthPhoto6,
        category: "other",
        alt: "a woman and a newborn baby",
        credits: "©Shumon Ahmed / icddr,b & https://www.flickr.com/photos/125843585@N08/40833105742\n",
        text: "Midwifery continuity of care",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/nYvGbZ",
    },
    {
        image: modalEighthPhoto7,
        category: "other",
        alt: "three women",
        credits: "",
        text: "Task sharing components of postnatal care delivery",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/EeB72A",
    },
    {
        image: modalEighthPhoto8,
        category: "other",
        alt: "a sign",
        credits: "© WHO / Nyka Alexander",
        text: "Recruitment and retention of staff in rural and remote areas",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/L6me83",
    },
    {
        image: modalEighthPhoto9,
        category: "other",
        alt: "parents with a baby",
        credits: "© UNICEF",
        text: "Involvement of men in postnatal care and maternal and newborn health",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/jDJ5o3",
    },
    {
        image: modalEighthPhoto10,
        category: "other",
        alt: "women and a nurse",
        credits: "© WHO",
        text: "Home-based records",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/EvwMkY",
    },
    {
        image: modalEighthPhoto11,
        category: "other",
        alt: "a smartphone",
        credits: "© USAID",
        text: "Digital targeted client communication",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/jN2Gpv",
    },
    {
        image: modalEighthPhoto12,
        category: "other",
        alt: "two women looking at a laptop",
        credits: "Pexels (free to share)",
        text: "Digital birth notifications",
        href: "https://app.magicapp.org/#/guideline/jxBJyn/rec/Edrx0R",
    },
]

export const modalNinthContent = [
    {
        image: modalNinthPhoto1,
        category: "other",
        alt: "document header",
        credits: "",
        text: "What matters to women",
        href: "https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0231415",
    },
    {
        image: modalNinthPhoto2,
        category: "other",
        alt: "document header",
        credits: "",
        text: "Uptake of routine postnatal care",
        href: "https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0270264",
    },
    {
        image: modalNinthPhoto3,
        category: "other",
        alt: "document header",
        credits: "",
        text: "Provision of routine postnatal care",
        href: "https://www.cochrane.org/CD014790/EPOC_factors-influence-provision-postnatal-care-health-workers-qualitative-evidence-synthesis",
    },
    {
        image: modalNinthPhoto4,
        category: "other",
        alt: "document header",
        credits: "",
        text: "Discharge preparation and readiness after birth",
        href: "https://bmcpregnancychildbirth.biomedcentral.com/articles/10.1186/s12884-022-04577-3",
    },
    {
        image: modalNinthPhoto5,
        category: "other",
        alt: "document header",
        credits: "",
        text: "Factors that influence uptake of postnatal care from the perspective of fathers, partners and family members",
        href: "https://gh.bmj.com/content/8/Suppl_2/e011086.long",
    },
]