import { IDocument } from "./IDocument";
import { Grid, Link, List, ListItem, Typography } from "@mui/material";
import img41 from "./img/document4-1.png";
import img42 from "./img/document4-2.jpg";
import img43 from "./img/document4-3.jpg";
import img44 from "./img/document4-4.jpg";
import img45 from "./img/document4-5.jpg";

export const documentsFour: IDocument[] = [
  {
    id: "13",
    img: img42,
    imgAlt: "a woman and a nurse",
    title:
      "WHO recommendation on interventions for the relief of constipation during pregnancy",
    date: "28 March 2018",
    recommendation: {
      title: "Recommendation",
      content:
        "Wheat bran or other fibre supplements can be used to relieve constipation in pregnancy if the condition fails to respond to dietary modification, based on a woman’s preferences and available options.",
    },
    recommendationItalic: "(Recommended)",
    publicationHistory: {
      firstPublished: "November 2016",
      updated: "Np update planned",
      assessedAsUpToDate: "November 2016",
    },
    remarks: {
      title: "Remarks",
      content: [
        "Dietary advice to reduce constipation during pregnancy should include promoting adequate intake of water and dietary fibre (found in vegetables, nuts, fruit and whole grains).",
        "For women with troublesome constipation that is not relieved by dietary modification or fibre supplementation, stakeholders may wish to consider intermittent use of poorly absorbed laxatives.",
      ],
    },
    background: {
      title: "Background",
      content: [
        "Women’s bodies undergo substantial changes during pregnancy, which are brought about by both hormonal and mechanical effects. These changes lead to a variety of common symptoms – including nausea and vomiting, low back and pelvic pain, heartburn, varicose veins, constipation and leg cramps – that in some women cause severe discomfort and negatively affects their pregnancy experience. In general, symptoms associated with mechanical effects, e.g. pelvic pain, heartburn and varicose veins, often worsen as pregnancy progresses. Constipation can be very troublesome and may be complicated by haemorrhoids (1).",
      ],
    },
    methods: {
      title: "Methods",
      content: [
        "The ANC recommendations are intended to inform the development of relevant health-care policies and clinical protocols. These recommendations were developed in accordance with the methods described in the WHO handbook for guideline development (2). In summary, the process included: identification of priority questions and outcomes, retrieval of evidence, assessment and synthesis of the evidence, formulation of recommendations, and planning for the implementation, dissemination, impact evaluation and updating of the guideline.",
        "The quality of the scientific evidence underpinning the recommendations was graded using the Grading of Recommendations Assessment, Development and Evaluation (GRADE) (3) and Confidence in the Evidence from Reviews of Qualitative research (GRADE-CERQual) (4) approaches, for quantitative and qualitative evidence, respectively. Up-to-date systematic reviews were used to prepare evidence profiles for priority questions. The DECIDE (Developing and Evaluating Communication Strategies to support Informed Decisions and Practice based on Evidence) (5) framework, an evidence-to-decision tool that includes intervention effects, values, resources, equity, acceptability and feasibility criteria, was used to guide the formulation and approval of recommendations by the Guideline Development Group (GDG) – an international group of experts assembled for the purpose of developing this guideline – at three Technical Consultations between October 2015 and March 2016.",
        "To ensure that each recommendation is correctly understood and applied in practice, the context of all context-specific recommendations is clearly stated within each recommendation, and the contributing experts provided additional remarks where needed.",
        "In accordance with WHO guideline development standards, these recommendations will be reviewed and updated following the identification of new evidence, with major reviews and updates at least every five years.",
        <span>
          Further information on procedures for developing this recommendation
          are available{" "}
          <Link href="https://web.archive.org/web/20201105052216/http:/apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1">
            here.
          </Link>
        </span>,
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following question:",
      content: [
        "For pregnant women (P), what interventions (pharmacological or non-pharmacological) for constipation (I) compared with no interventions (C) reduce morbidity and improve outcomes (O)?",
      ],
    },
    evidenceSummary: {
      title: "Evidence summary",
      content: [
        <Grid>
          <Typography gutterBottom>
            The evidence on the effects of various interventions for
            constipation in pregnancy was derived from a Cochrane review to
            which only two small RCTs involving 180 women contributed data (1).
            Both studies were conducted in the United Kingdom among pregnant
            women with constipation. One compared fibre supplementation with no
            intervention (40 women), the other compared stimulant laxatives with
            bulk-forming laxatives (140 women). No perinatal outcomes relevant
            to this guideline were reported.
          </Typography>
          <Typography gutterBottom fontWeight={"bold"} fontStyle={"italic"}>
            Fibre supplementation versus no intervention
          </Typography>
          <Typography gutterBottom>
            Evidence from the small study evaluating fibre supplementation
            versus no intervention on constipation relief (reported as mean
            frequency of stools) was assessed as being very uncertain.
          </Typography>
          <Typography gutterBottom fontWeight={"bold"} fontStyle={"italic"}>
            Stimulant laxatives versus bulk-forming laxatives
          </Typography>
          <Typography gutterBottom>
            Two stimulant laxatives were used in this 1970s study, senna and
            Normax®. The latter (containing dantron) is potentially carcinogenic
            and now only used in terminally ill people; however, data on
            stimulant laxatives were not available separately for senna.
            Evidence on relative symptom relief, side-effects (abdominal
            discomfort, diarrhoea), and maternal satisfaction for stimulant
            laxatives versus bulkforming laxatives (sterculia with or without
            frangula) was assessed as being very uncertain.
          </Typography>
        </Grid>,
      ],
    },
    maternalOutcomes: {
      title: "",
      content: "",
    },
    fetalAndNeonatalOutcomes: [
      {
        title: "Additional considerations",
        content:
          "Various bulk-forming (wheat bran or oat bran fibre supplements, sterculia, methylcellulose, ispaghula husk), osmotic (lactulose) and stimulant laxatives (senna) are available as over-the-counter medications for constipation and are not known to be harmful in pregnancy (6).\n" +
          "The absorption of vitamins and mineral supplements could potentially be compromised by laxatives.\n",
      },
      {
        title: "Resources",
        content:
          "Costs will vary according to the intervention and region. Cereal fibre supplements can be relatively lowcost at around US$ 1.5 per 375 g bag of wheat bran.",
      },
      {
        title: "Equity",
        content:
          "It is not known whether interventions to relieve constipation might impact inequalities.",
      },
      {
        title: "Acceptability",
        content:
          "Qualitative evidence from a range of LMICs suggests that women may be more likely to turn to traditional healers, herbal remedies or TBAs to treat these symptoms (moderate confidence in the evidence) (7). Evidence from a diverse range of settings indicates that while women generally appreciate the interventions and information provided during antenatal visits, they are less likely to engage with services if their beliefs, traditions and socioeconomic circumstances are ignored or overlooked by healthcare providers and/or policy-makers (high confidence in the evidence).",
      },
      {
        title: "Feasibility",
        content:
          "Other qualitative evidence suggests that a lack of resources may limit the offer of treatment for constipation (high confidence in the evidence) (8).",
      },
      {
        title:
          "Further information and considerations related to this recommendation can be found in the WHO guidelines, available at:",
        content: (
          <Link href="http://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            http://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1
          </Link>
        ),
      },
    ],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "The successful introduction of evidence-based policies related to antenatal care into national programmes and health care services depends on well-planned and participatory consensus-driven processes of adaptation and implementation. These processes may include the development or revision of national guidelines or protocols based on this recommendation.",
        "The recommendation should be adapted into locally-appropriate documents and tools that are able to meet the specific needs of each country and health service. Modifications to the recommendation, where necessary, should be justified in an explicit and transparent manner.",
        "An enabling environment should be created for the use of this recommendation, including changes in the behaviour of health care practitioners to enable the use of evidence-based practices.",
        "Local professional societies may play important roles in this process and an all-inclusive and participatory process should be encouraged.",
        "Antenatal care models with a minimum of eight contacts are recommended to reduce perinatal mortality and improve women’s experience of care. Taking this as a foundation, the GDG reviewed how ANC should be delivered in terms of both the timing and content of each of the ANC contacts, and arrived at a new model – the 2016 WHO ANC model – which replaces the previous four-visit focused ANC (FANC) model. For the purpose of developing this new ANC model, the ANC recommendations were mapped to the eight contacts based on the evidence supporting each recommendation and the optimal timing of delivery of the recommended interventions to achieve maximal impact.",
      ],
    },
    additionalLists: [],
    researchImplications: {
      title: "Research implications",
      text: "The GDG identified this priority question related to this recommendation",
      content: [
        "What is the prevalence of common physiological symptoms among pregnant women in low-resource settings, and can the offer of treatment of these symptoms reduce health inequality, improve ANC coverage and improve women’s pregnancy experiences?",
      ],
    },
    relatedLinks: {
      title: "Related links",
      text: (
        <Typography>
          WHO recommendations on antenatal care for a positive pregnancy
          experience (2016) -{" "}
          <Link href="https://web.archive.org/web/20200915144755/https://apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1">
            full document
          </Link>{" "}
          and{" "}
          <Link href="https://web.archive.org/web/20201219124651/https://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            evidence table
          </Link>
        </Typography>
      ),
      links: [
        {
          href: "https://web.archive.org/web/20201031220559/https://apps.who.int/iris/bitstream/handle/10665/255760/9789241565493-eng.pdf?sequence=1",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors",
        },
        {
          href: "https://web.archive.org/web/20201030093023/https://apps.who.int/iris/bitstream/handle/10665/249580/9789241549356-eng.pdf;jsessionid=7B8E18468A424E0301261B2CA6BCB324?sequence=1",
          text: "Pregnancy, Childbirth, Postpartum and Newborn Care: A guide for essential practice",
        },
        {
          href: "https://web.archive.org/web/20201103090439/https://www.who.int/reproductivehealth/en/",
          text: "WHO Programmes: Sexual and Reproductive health",
        },
        {
          href: "https://web.archive.org/web/20201111195601/https://www.who.int/health-topics/maternal-health#tab=tab_1",
          text: "Maternal Health",
        },
      ],
    },
    references: {
      title: "References",
      content: [
        "Rungsiprakarn P, Laopaiboon M, Sangkomkamhang US, Lumbiganon P, Pratt JJ. Interventions for treating constipation in pregnancy. Cochrane Database Syst Rev. 2015;(9):CD011448.",
        "WHO handbook for guideline development, 2nd edition. Geneva: World Health Organization; 2014 (http://www.who.int/kms/handbook_2nd_ ed.pdf, accessed 6 October 2016).",
        "GRADE [website]. The GRADE Working Group; 2016 (http://gradeworkinggroup.org/, accessed 27 October 2016).",
        "GRADE-CERQual [website]. The GRADECERQual Project Group; 2016 (https://cerqual. org/, accessed 27 October 2016).",
        "The DECIDE Project; 2016 (http://www.decide-collaboration.eu/, accessed 27 October 2016).",
        "Joint Formulary Committee. Constipation and bowel cleansing. Chapter 1: Gastro-intestinal system. In: British National Formulary (BNF) 72. London: BMJ Publishing Group Ltd and Royal Pharmaceutical Society; 2016.",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the use of routine antenatal services by pregnant women: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2016;(10):CD012392",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the provision of good quality routine antenatal care services by health staff: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2016",
      ],
    },
  },
  {
    id: "14",
    img: img43,
    imgAlt: "a pregnant woman lying down",
    title:
      "WHO recommendation on interventions for the relief of low back and pelvic pain during pregnancy",
    date: "28 March 2018",
    recommendation: {
      title: "Recommendation",
      content:
        "Regular exercise throughout pregnancy is recommended to prevent low back and pelvic pain. There are a number of different treatment options that can be used, such as physiotherapy, support belts and acupuncture, based on a woman’s preferences and available options.",
    },
    recommendationItalic: "(Recommended)",
    publicationHistory: {
      firstPublished: "November 2016",
      updated: "No update planned",
      assessedAsUpToDate: "November 2016",
    },
    remarks: {
      title: "Remarks",
      content: [
        "Exercise to prevent low back and pelvic pain in pregnancy can take place on land or in water. While exercise may also be helpful to relieve low back pain, it could exacerbate pelvic pain associated with symphysis pubis dysfunction and is not recommended for this condition.",
        "Regular exercise is a key component of lifestyle interventions, which are recommended for pregnant women as part of ANC to prevent excessive weight gain in pregnancy.",
        "Pregnant women with low back and/or pelvic pain should be informed that symptoms usually improve in the months after birth.",
        "Women should be informed that it is unclear whether there are side-effects to alternative treatment options due to a paucity of data.",
        "Standardized reporting of outcomes is needed for future research on treatment for low back and/or pelvic pain in pregnancy.",
      ],
    },
    background: {
      title: "Background",
      content: [
        "Women’s bodies undergo substantial changes during pregnancy, which are brought about by both hormonal and mechanical effects. These changes lead to a variety of common symptoms – including nausea and vomiting, low back and pelvic pain, heartburn, varicose veins, constipation and leg cramps – that in some women cause severe discomfort and negatively affects their pregnancy experience. In general, symptoms associated with mechanical effects, e.g. pelvic pain, heartburn and varicose veins, often worsen as pregnancy progresses. Low back and pelvic pain is estimated to occur in half of pregnant women, 8% of whom experience severe disability (1).",
      ],
    },
    methods: {
      title: "Methods",
      content: [
        "The ANC recommendations are intended to inform the development of relevant health-care policies and clinical protocols. These recommendations were developed in accordance with the methods described in the WHO handbook for guideline development (2). In summary, the process included: identification of priority questions and outcomes, retrieval of evidence, assessment and synthesis of the evidence, formulation of recommendations, and planning for the implementation, dissemination, impact evaluation and updating of the guideline.",
        "The quality of the scientific evidence underpinning the recommendations was graded using the Grading of Recommendations Assessment, Development and Evaluation (GRADE) (3) and Confidence in the Evidence from Reviews of Qualitative research (GRADE-CERQual) (4) approaches, for quantitative and qualitative evidence, respectively. Up-to-date systematic reviews were used to prepare evidence profiles for priority questions. The DECIDE (Developing and Evaluating Communication Strategies to support Informed Decisions and Practice based on Evidence) (5) framework, an evidence-to-decision tool that includes intervention effects, values, resources, equity, acceptability and feasibility criteria, was used to guide the formulation and approval of recommendations by the Guideline Development Group (GDG) – an international group of experts assembled for the purpose of developing this guideline – at three Technical Consultations between October 2015 and March 2016.",
        "To ensure that each recommendation is correctly understood and applied in practice, the context of all context-specific recommendations is clearly stated within each recommendation, and the contributing experts provided additional remarks where needed.",
        "In accordance with WHO guideline development standards, these recommendations will be reviewed and updated following the identification of new evidence, with major reviews and updates at least every five years.",
        <span>
          Further information on procedures for developing this recommendation
          are available{" "}
          <Link href="https://web.archive.org/web/20201105052216/http:/apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1">
            here.
          </Link>
        </span>,
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following question:",
      content: [
        "For pregnant women (P), what interventions (pharmacological or non-pharmacological) for pelvic and back pain (I) compared with no interventions (C) reduce morbidity and improve outcomes (O)?",
      ],
    },
    evidenceSummary: {
      title: "Evidence summary",
      content: [
        <Grid>
          <Typography gutterBottom>
            The evidence on the effects of various interventions for low back
            and pelvic pain in pregnancy was derived from a Cochrane review that
            included 34 trials involving 5121 women (6).
          </Typography>
          <Typography gutterBottom>
            The definitions and terminology of low back and pelvic pain varied
            such that in 15 trials the interventions were aimed at reducing low
            back pain, in six trials interventions were for pelvic pain, and in
            13 trials the interventions were for low back and pelvic pain. Most
            trials evaluated treatment; however, six trials evaluated
            prevention. Few trials contributed data to analyses and several
            individual study findings were described only in narrative. Main
            outcomes were relief of symptoms and functional disability, and
            perinatal outcomes relevant to this guideline were not reported.
          </Typography>
          <Typography gutterBottom>Comparisons included: </Typography>
          <List>
            <ListItem>
              1. Any exercise (plus standard care) versus standard care
            </ListItem>
            <ListItem>
              2. Acupuncture (plus standard care) versus sham acupuncture (plus
              standard care)
            </ListItem>
            <ListItem>
              3. Acupuncture (plus standard care) versus individualized
              physiotherapy (plus standard care)
            </ListItem>
            <ListItem>
              4. Osteopathic manipulation (plus standard care) versus standard
              care
            </ListItem>
            <ListItem>
              5. One type of support belt versus another typee
            </ListItem>
            <ListItem>
              6. Multimodal interventions versus standard care.
            </ListItem>
          </List>
        </Grid>,
      ],
    },
    maternalOutcomes: {
      title: "",
      content: (
        <Grid>
          <Typography gutterBottom fontWeight={"bold"} fontStyle={"italic"}>
            Any exercise (plus standard care) versus standard care
          </Typography>
          <Typography gutterBottom>
            Seven trials (645 women) contributed data to this comparison for low
            back pain. Trials were conducted in Brazil, the Islamic Republic of
            Iran, Norway, South Africa and Thailand. Exercise interventions
            varied from individually supervised exercise to group exercise,
            including yoga and aqua-aerobics, and some included education via
            CDs and booklets. Interventions ran for 8–12 weeks and the presence
            or intensity of pain was assessed in most trials using visual
            analogue scales. However, the evidence on symptom relief from a
            meta-analysis of these seven studies is very uncertain.
          </Typography>
          <Typography gutterBottom>
            Low-certainty evidence suggests that functional disability scores
            are better with exercise interventions for low back pain (2 trials,
            146 women; standardized MD: 0.56 lower, 95% CI: 0.23–0.89 lower).
            Evidence on pain intensity (symptom scores) for low back pain was
            assessed as very uncertain. Low-certainty evidence suggests that an
            8- to 12-week exercise programme may reduce low back and pelvic pain
            compared with standard care (4 trials, 1176 women; RR: 0.66, 95% CI:
            0.45–0.97) and moderate-certainty evidence shows that healthy
            pregnant women taking part in an exercise programme are probably
            less likely to take sick leave related to low back and pelvic pain
            (2 trials, 1062 women; RR: 0.76; 95% CI: 0.62–0.94).
          </Typography>
          <Typography gutterBottom fontWeight={"bold"} fontStyle={"italic"}>
            Acupuncture (plus standard care) versus sham acupuncture (plus
            standard care)
          </Typography>
          <Typography gutterBottom>
            Four small studies conducted in Sweden and the USA evaluated the
            effects of acupuncture plus standard care versus sham acupuncture
            plus standard care. However, little data were extracted from these
            studies and data could not be pooled.
          </Typography>
          <Typography gutterBottom>
            Low-certainty evidence from one study suggests that acupuncture may
            relieve low back and pelvic pain (72 women; RR: 4.16, 95% CI:
            1.77–9.78). Evidence from other studies was variously reported and
            very uncertain.
          </Typography>
          <Typography gutterBottom fontWeight={"bold"} fontStyle={"italic"}>
            Acupuncture (plus standard care) versus individualized physiotherapy
            (plus standard care)
          </Typography>
          <Typography gutterBottom>
            One small study conducted in Sweden involving 46 women with low back
            and pelvic pain evaluated this comparison. Women’s satisfaction with
            treatment was the main outcome, but the evidence was assessed as
            very uncertain.
          </Typography>
          <Typography gutterBottom fontWeight={"bold"} fontStyle={"italic"}>
            Osteopathic manipulation therapy (OMT) (plus standard care) versus
            no osteopathic manipulation (standard care)
          </Typography>
          <Typography gutterBottom>
            Three studies evaluated OMT; however, data could not be pooled and
            the evidence from individual studies is inconsistent. The largest
            study involving 400 women compared OMT plus standard care with
            placebo ultrasound plus standard care, or standard care only.
            Limited data from this study suggests that OMT may relieve low back
            pain symptoms more than standard care, and may lead to lower
            functional disability scores, but may not be better than placebo
            ultrasound for these outcomes.
          </Typography>
          <Typography gutterBottom fontWeight={"bold"} fontStyle={"italic"}>
            One type of support belt versus another type
          </Typography>
          <Typography gutterBottom>
            One small study conducted in Australia compared two types of support
            belts in women with low back pain, the BellyBra® and Tubigrip® (N =
            94) and the evidence from this study was assessed as very
            low-certainty evidence.
          </Typography>
          <Typography gutterBottom fontWeight={"bold"} fontStyle={"italic"}>
            Multimodal interventions versus standard care
          </Typography>
          <Typography gutterBottom>
            One study in the USA reported the effect of a multimodal
            intervention that included weekly manual therapy by a chiropractic
            specialist, combined with daily exercise at home, and education
            versus standard care (rest, exercise, heat pads and analgesics) on
            low back and pelvic pain. Moderate-certainty evidence suggests that
            the multimodal intervention is probably associated with better pain
            scores (1 study, 169 women; MD: 2.70 lower, 95% CI: 1.86–3.54 lower)
            and better functional disability scores (MD: 1.40 lower; 95% CI:
            0.71–2.09 lower) compared with standard care.
          </Typography>
        </Grid>
      ),
    },
    fetalAndNeonatalOutcomes: [
      {
        title: "Additional considerations",
        content:
          "It is not clear whether the evidence on exercise interventions applies equally to low back pain and pelvic pain, or equally to prevention and treatment, as data from studies of prevention and treatment were pooled. Evidence from two studies on the effect of exercise plus education suggests that such interventions may have little or no effect on preventing pelvic pain (RR: 0.97; 95% CI: 0.77– 1.23).\n" +
          "Very low-certainty evidence on a number of other interventions, such as transcutaneous electrical nerve stimulation (TENS), progressive muscle relaxation with music, craniosacral therapy, and acetaminophen (paracetamol) – which were evaluated in single small trials with apparent relief of symptoms relative to standard care – was also presented in the review.\n" +
          "Standard care of low back and pelvic pain symptoms usually comprises rest, hot or cold compresses, and paracetamol analgesia. There is a paucity of evidence on potential side effects of alternative therapies, e.g. chiropractic and osteopathic manipulation, and further high-quality research is needed to establish whether these therapies are beneficial for low back and/or pelvic pain and safe during pregnancy. Exercise in pregnancy has been shown to have other benefits for pregnant women, including reducing excessive gestational weight gain.\n",
      },
      {
        title: "Resources",
        content:
          "Exercise can be administered in a group setting and individually at home; therefore, the cost of exercise interventions varies. Support belts are available commercially from under US$ 10 per item. Physiotherapy and acupuncture require specialist training and are therefore likely to be more resource intensive.",
      },
      {
        title: "Equity",
        content:
          "Improving access to low back and pelvic pain interventions may reduce inequalities by reducing functional disability and sick leave related to low back and pelvic pain among disadvantaged women.  ",
      },
      {
        title: "Acceptability",
        content:
          "Qualitative evidence from a diverse range of settings, indicates that while women generally appreciate the interventions and information provided during antenatal visits, they are less likely to engage with services if their beliefs, traditions and socioeconomic circumstances are ignored or overlooked by healthcare providers and/or policy-makers (high confidence in the evidence) (7). This may be particularly pertinent for an intervention like acupuncture, which may be culturally alien and/or poorly understood in certain contexts. In addition, where there are likely to be additional costs associated with treatment or where the treatment may be unavailable (because of resource constraints), women are less likely to engage with health services (high confidence in the evidence).",
      },
      {
        title: "Feasibility",
        content:
          "A lack of resources may limit the offer of treatment for this condition (high confidence in the evidence) (8).",
      },
      {
        title:
          "Further information and considerations related to this recommendation can be found in the WHO guidelines, available at:",
        content: (
          <Link href="http://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            http://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1
          </Link>
        ),
      },
    ],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "The successful introduction of evidence-based policies related to antenatal care into national programmes and health care services depends on well-planned and participatory consensus-driven processes of adaptation and implementation. These processes may include the development or revision of national guidelines or protocols based on this recommendation.",
        "The recommendation should be adapted into locally-appropriate documents and tools that are able to meet the specific needs of each country and health service. Modifications to the recommendation, where necessary, should be justified in an explicit and transparent manner.",
        "An enabling environment should be created for the use of this recommendation, including changes in the behaviour of health care practitioners to enable the use of evidence-based practices.",
        "Local professional societies may play important roles in this process and an all-inclusive and participatory process should be encouraged.",
        "Antenatal care models with a minimum of eight contacts are recommended to reduce perinatal mortality and improve women’s experience of care. Taking this as a foundation, the GDG reviewed how ANC should be delivered in terms of both the timing and content of each of the ANC contacts, and arrived at a new model – the 2016 WHO ANC model – which replaces the previous four-visit focused ANC (FANC) model. For the purpose of developing this new ANC model, the ANC recommendations were mapped to the eight contacts based on the evidence supporting each recommendation and the optimal timing of delivery of the recommended interventions to achieve maximal impact.",
      ],
    },
    additionalLists: [],
    researchImplications: {
      title: "Research implications",
      text: "The GDG identified this priority question related to this recommendation",
      content: [
        "What is the prevalence of common physiological symptoms among pregnant women in low-resource settings, and can the offer of treatment of these symptoms reduce health inequality, improve ANC coverage and improve women’s pregnancy experiences?",
      ],
    },
    relatedLinks: {
      title: "Related links",
      text: (
        <Typography>
          WHO recommendations on antenatal care for a positive pregnancy
          experience (2016) -{" "}
          <Link href="https://web.archive.org/web/20200915144755/https://apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1">
            full document
          </Link>{" "}
          and{" "}
          <Link href="https://web.archive.org/web/20201219124651/https://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            evidence table
          </Link>
        </Typography>
      ),
      links: [
        {
          href: "https://web.archive.org/web/20201031220559/https://apps.who.int/iris/bitstream/handle/10665/255760/9789241565493-eng.pdf?sequence=1",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors",
        },
        {
          href: "https://web.archive.org/web/20201030093023/https://apps.who.int/iris/bitstream/handle/10665/249580/9789241549356-eng.pdf;jsessionid=7B8E18468A424E0301261B2CA6BCB324?sequence=1",
          text: "Pregnancy, Childbirth, Postpartum and Newborn Care: A guide for essential practice",
        },
        {
          href: "https://web.archive.org/web/20201103090439/https://www.who.int/reproductivehealth/en/",
          text: "WHO Programmes: Sexual and Reproductive health",
        },
        {
          href: "https://web.archive.org/web/20201111195601/https://www.who.int/health-topics/maternal-health#tab=tab_1",
          text: "Maternal Health",
        },
      ],
    },
    references: {
      title: "References",
      content: [
        "Albert H, Godskesen M, Westergaard J. Prognosis in four syndromes of pregnancy-related pelvic pain. Acta Obstet Gynecol Scand. 2001;80:505–10.",
        "WHO handbook for guideline development, 2nd edition. Geneva: World Health Organization; 2014 (http://www.who.int/kms/handbook_2nd_ ed.pdf, accessed 6 October 2016).",
        "GRADE [website]. The GRADE Working Group; 2016 (http://gradeworkinggroup.org/, accessed 27 October 2016).",
        "GRADE-CERQual [website]. The GRADECERQual Project Group; 2016 (https://cerqual. org/, accessed 27 October 2016).",
        "The DECIDE Project; 2016 (http://www.decide-collaboration.eu/, accessed 27 October 2016).",
        "Liddle SD, Pennick V. Interventions for preventing and treating low-back and pelvic pain during pregnancy. Cochrane Database Syst Rev.2015;(9):CD001139.",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the use of routine antenatal services by pregnant women: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2016;(10):CD012392",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the provision of good quality routine antenatal care services by health staff: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2016",
      ],
    },
  },
  {
    id: "15",
    img: img44,
    imgAlt: "a woman and a doctor talking",
    title:
      "WHO recommendation on interventions for the relief of varicose veins and oedema during pregnancy",
    date: "28 March 2018",
    recommendation: {
      title: "Recommendation",
      content:
        "Non-pharmacological options, such as compression stockings, leg elevation and water immersion, can be used for the management of varicose veins and oedema in pregnancy, based on a woman’s preferences and available options.",
    },
    recommendationItalic: "(Recommended)",
    publicationHistory: {
      firstPublished: "November 2016",
      updated: "No update planned",
      assessedAsUpToDate: "November 2016",
    },
    remarks: {
      title: "Remarks",
      content: [
        "Women should be informed that symptoms associated with varicose veins may worsen as pregnancy progresses but that most women will experience some improvement within a few months of giving birth.",
        "Rest, leg elevation and water immersion are low-cost interventions that are unlikely to be harmful.",
      ],
    },
    background: {
      title: "Background",
      content: [
        "Women’s bodies undergo substantial changes during pregnancy, which are brought about by both hormonal and mechanical effects. These changes lead to a variety of common symptoms – including nausea and vomiting, low back and pelvic pain, heartburn, varicose veins, constipation and leg cramps – that in some women cause severe discomfort and negatively affects their pregnancy experience. In general, symptoms associated with mechanical effects, e.g. pelvic pain, heartburn and varicose veins, often worsen as pregnancy progresses. Varicose veins usually occur in the legs, but can also occur in the vulva and rectum, and may be associated with pain, night cramps, aching and heaviness, and worsen with long periods of standing (1).Women’s bodies undergo substantial changes during pregnancy, which are brought about by both hormonal and mechanical effects. These changes lead to a variety of common symptoms – including nausea and vomiting, low back and pelvic pain, heartburn, varicose veins, constipation and leg cramps – that in some women cause severe discomfort and negatively affects their pregnancy experience. In general, symptoms associated with mechanical effects, e.g. pelvic pain, heartburn and varicose veins, often worsen as pregnancy progresses. Varicose veins usually occur in the legs, but can also occur in the vulva and rectum, and may be associated with pain, night cramps, aching and heaviness, and worsen with long periods of standing (1).",
      ],
    },
    methods: {
      title: "Methods",
      content: [
        "The ANC recommendations are intended to inform the development of relevant health-care policies and clinical protocols. These recommendations were developed in accordance with the methods described in the WHO handbook for guideline development (2). In summary, the process included: identification of priority questions and outcomes, retrieval of evidence, assessment and synthesis of the evidence, formulation of recommendations, and planning for the implementation, dissemination, impact evaluation and updating of the guideline.",
        "The quality of the scientific evidence underpinning the recommendations was graded using the Grading of Recommendations Assessment, Development and Evaluation (GRADE) (3) and Confidence in the Evidence from Reviews of Qualitative research (GRADE-CERQual) (4) approaches, for quantitative and qualitative evidence, respectively. Up-to-date systematic reviews were used to prepare evidence profiles for priority questions. The DECIDE (Developing and Evaluating Communication Strategies to support Informed Decisions and Practice based on Evidence) (5) framework, an evidence-to-decision tool that includes intervention effects, values, resources, equity, acceptability and feasibility criteria, was used to guide the formulation and approval of recommendations by the Guideline Development Group (GDG) – an international group of experts assembled for the purpose of developing this guideline – at three Technical Consultations between October 2015 and March 2016.",
        "To ensure that each recommendation is correctly understood and applied in practice, the context of all context-specific recommendations is clearly stated within each recommendation, and the contributing experts provided additional remarks where needed.",
        "In accordance with WHO guideline development standards, these recommendations will be reviewed and updated following the identification of new evidence, with major reviews and updates at least every five years.",
        <span>
          Further information on procedures for developing this recommendation
          are available{" "}
          <Link href="https://web.archive.org/web/20201105052216/http:/apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1">
            here.
          </Link>
        </span>,
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following question:",
      content: [
        "For pregnant women (P), what interventions (pharmacological or non-pharmacological) for varicose veins and oedema (I) compared with no interventions (C) reduce morbidity and improve outcomes (O)?",
      ],
    },
    evidenceSummary: {
      title: "Evidence summary",
      content: [
        "The evidence on the effects of various interventions for varicose veins in pregnancy was derived from a Cochrane review that included seven small trials involving 326 women with varicose veins and/or oedema, and various types of interventions, including rutoside (a phlebotonic drug) versus placebo (two trials), foot massage by a professional masseur for five days versus no intervention (1 trial, 80 women), intermittent external pneumatic compression with a pump versus rest (1 trial, 35 women), standing in water at a temperature between 29°C and 33°C for 20 minutes (water immersion) versus leg elevation (1 trial, 32 women) and reflexology versus rest (1 trial, 55 women) (1). Another trial comparing compression stockings with rest in the left lateral position did not contribute any data. Fetal and neonatal outcomes relevant to the ANC guideline were not reported in these studies.",
      ],
    },
    maternalOutcomes: {
      title: "",
      content: (
        <Grid>
          <Typography gutterBottom fontWeight={"bold"} fontStyle={"italic"}>
            Pharmacological interventions versus placebo or no intervention
          </Typography>
          <Typography gutterBottom>
            Only one small trial conducted in 1975 (69 women) contributed data.
            Low-certainty evidence from this trial suggests that rutoside may
            reduce symptoms (nocturnal cramps, paraesthesia, tiredness)
            associated with varicose veins compared with placebo (69 women; RR:
            1.89, 95% CI: 1.11–3.22). However, no side-effect data were
            reported.
          </Typography>
          <Typography gutterBottom fontWeight={"bold"} fontStyle={"italic"}>
            Non-pharmacological interventions versus placebo or no intervention
          </Typography>
          <Typography gutterBottom>
            Low-certainty evidence suggests that reflexology may reduce oedema
            symptoms compared with rest only (55 women; RR: 9.09, 95% CI:
            1.41–58.54) and that water immersion may reduce oedema symptoms (leg
            volume) compared with leg elevation (32 women; RR: 0.43, 95% CI:
            0.22–0.83). Low-certainty evidence suggests that there may be little
            or no difference in oedema symptoms (measured as lower leg
            circumference in centimetres) between foot massage and no
            intervention (80 women; MD in cm: 0.11 less, 95% CI: 1.02 less to
            0.80 more) and between intermittent pneumatic compression and rest
            (measured as mean leg volume, unit of analysis unclear) (35 women;
            MD: 258.8 lower, 95% CI: 566.91 lower to 49.31 higher). Only one
            study (reflexology versus rest) evaluated women’s satisfaction, but
            the evidence is of very low certainty.
          </Typography>
        </Grid>
      ),
    },
    fetalAndNeonatalOutcomes: [
      {
        title: "Additional considerations",
        content:
          "Compression stockings combined with leg elevation is the most common non-surgical management for varicose veins and oedema; however, the Cochrane review found no evidence on this practice in pregnancy (1). Compression stockings are also widely used to prevent morbidity in non-pregnant people with varicose veins and the evidence for this practice in a related Cochrane review of compression stockings was generally very uncertain (6).",
      },
      {
        title: "Resources",
        content:
          "Postural interventions are low-cost interventions. The cost of compression stockings varies but they can cost more than US$ 15 per pair. Reflexology and professional massage require specialist training, and are, therefore, likely to be more costly.",
      },
      {
        title: "Equity",
        content:
          "It is not known whether interventions to relieve varicose veins and oedema might impact inequalities.",
      },
      {
        title: "Acceptability",
        content:
          "Qualitative evidence from a range of LMICs suggests that women may be more likely to turn to traditional healers, herbal remedies or TBAs to treat these symptoms (moderate confidence in the evidence) (7). In addition, evidence from a diverse range of settings indicates that while women generally appreciate the interventions and information provided during antenatal visits, they are less likely to engage with services if their beliefs, traditions and socioeconomic circumstances are ignored or overlooked by health-care providers and/or policymakers (high confidence in the evidence). This may be particularly pertinent for an intervention like reflexology, which may be culturally alien and/or poorly understood in certain contexts. Qualitative evidence shows that, where there are likely to be additional costs associated with treatment or where the treatment may be unavailable (because of resource constraints), women are less likely to engage with health services (high confidence in the evidence).",
      },
      {
        title: "Feasibility",
        content:
          "The evidence also suggests that a lack of resources may limit the offer of treatment for varicose veins and oedema (high confidence in the evidence) (8).",
      },
      {
        title:
          "Further information and considerations related to this recommendation can be found in the WHO guidelines, available at:\n",
        content: (
          <Link href="http://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            http://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1
          </Link>
        ),
      },
    ],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "The successful introduction of evidence-based policies related to antenatal care into national programmes and health care services depends on well-planned and participatory consensus-driven processes of adaptation and implementation. These processes may include the development or revision of national guidelines or protocols based on this recommendation.",
        "The recommendation should be adapted into locally-appropriate documents and tools that are able to meet the specific needs of each country and health service. Modifications to the recommendation, where necessary, should be justified in an explicit and transparent manner.",
        "An enabling environment should be created for the use of this recommendation, including changes in the behaviour of health care practitioners to enable the use of evidence-based practices.",
        "Local professional societies may play important roles in this process and an all-inclusive and participatory process should be encouraged.",
        "Antenatal care models with a minimum of eight contacts are recommended to reduce perinatal mortality and improve women’s experience of care. Taking this as a foundation, the GDG reviewed how ANC should be delivered in terms of both the timing and content of each of the ANC contacts, and arrived at a new model – the 2016 WHO ANC model – which replaces the previous four-visit focused ANC (FANC) model. For the purpose of developing this new ANC model, the ANC recommendations were mapped to the eight contacts based on the evidence supporting each recommendation and the optimal timing of delivery of the recommended interventions to achieve maximal impact.",
      ],
    },
    additionalLists: [],
    researchImplications: {
      title: "Research implications",
      text: "The GDG identified this priority question related to this recommendation",
      content: [
        "What is the prevalence of common physiological symptoms among pregnant women in low-resource settings, and can the offer of treatment of these symptoms reduce health inequality, improve ANC coverage and improve women’s pregnancy experiences?",
      ],
    },
    relatedLinks: {
      title: "Related links",
      text: (
        <Typography>
          WHO recommendations on antenatal care for a positive pregnancy
          experience (2016) -{" "}
          <Link href="https://web.archive.org/web/20200915144755/https://apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1">
            full document
          </Link>{" "}
          and{" "}
          <Link href="https://web.archive.org/web/20201219124651/https://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            evidence table
          </Link>
        </Typography>
      ),
      links: [
        {
          href: "https://web.archive.org/web/20201031220559/https://apps.who.int/iris/bitstream/handle/10665/255760/9789241565493-eng.pdf?sequence=1",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors",
        },
        {
          href: "https://web.archive.org/web/20201030093023/https://apps.who.int/iris/bitstream/handle/10665/249580/9789241549356-eng.pdf;jsessionid=7B8E18468A424E0301261B2CA6BCB324?sequence=1",
          text: "Pregnancy, Childbirth, Postpartum and Newborn Care: A guide for essential practice",
        },
        {
          href: "https://web.archive.org/web/20201103090439/https://www.who.int/reproductivehealth/en/",
          text: "WHO Programmes: Sexual and Reproductive health",
        },
        {
          href: "https://web.archive.org/web/20201111195601/https://www.who.int/health-topics/maternal-health#tab=tab_1",
          text: "Maternal Health",
        },
      ],
    },
    references: {
      title: "References",
      content: [
        "Smyth RMD, Aflaifel N, Bamigboye AA. Interventions for varicose veins and leg oedema in pregnancy. Cochrane Database Syst Rev. 2015;(10):CD001066.",
        "WHO handbook for guideline development, 2nd edition. Geneva: World Health Organization; 2014 (http://www.who.int/kms/handbook_2nd_ ed.pdf, accessed 6 October 2016).",
        "GRADE [website]. The GRADE Working Group; 2016 (http://gradeworkinggroup.org/, accessed 27 October 2016).",
        "GRADE-CERQual [website]. The GRADECERQual Project Group; 2016 (https://cerqual. org/, accessed 27 October 2016).",
        "The DECIDE Project; 2016 (http://www.decide-collaboration.eu/, accessed 27 October 2016).",
        "Shingler S, Robertson L, Boghossian S, Stewart M. Compression stockings for the initial treatment of varicose veins in patients without venous ulceration. Cochrane Database Syst Rev. 2013;(12):CD00881.",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the use of routine antenatal services by pregnant women: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2016;(10):CD012392",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the provision of good quality routine antenatal care services by health staff: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2016",
      ],
    },
  },
  {
    id: "38",
    img: img41,
    imgAlt: "a pregnant woman",
    title:
      "WHO recommendation on interventions for the relief of leg cramps during pregnancy",
    date: "29 August 2021",
    recommendation: {
      title: "Recommendation",
      content: [
        "Magnesium, calcium or non-pharmacological treatment options can be used for the relief of leg cramps in pregnancy, based on a woman’s preferences and available options.",
      ],
    },
    recommendationItalic: "(Recommended)",
    publicationHistory: {
      firstPublished: "November 2016",
      updated: "No update planned",
      assessedAsUpToDate: "November 2016",
    },
    remarks: {
      title: "Remarks",
      content: [
        "The review found no evidence on the effect of non-pharmacological therapies, such as muscle stretching, relaxation, heat therapy, dorsiflexion of the foot and massage.",
        "The evidence on magnesium and calcium is generally of low certainty. However, the GDG agreed that they are unlikely to be harmful in the dose schedules evaluated in included studies.",
        "Further research into the etiology and prevalence of leg cramps in pregnancy, and the role (if any) of magnesium and calcium in symptom relief, is needed.",
      ],
    },
    background: {
      title: "Background",
      content: [
        "Women’s bodies undergo substantial changes during pregnancy, which are brought about by both hormonal and mechanical effects. These changes lead to a variety of common symptoms – including nausea and vomiting, low back and pelvic pain, heartburn, varicose veins, constipation and leg cramps – that in some women cause severe discomfort and negatively affects their pregnancy experience. In general, symptoms associated with mechanical effects, e.g. pelvic pain, heartburn and varicose veins, often worsen as pregnancy progresses. Leg cramps often occur at night and can be very painful, affecting sleep and daily activities (1)",
      ],
    },
    methods: {
      title: "Methods",
      content: [
        "The ANC recommendations are intended to inform the development of relevant health-care policies and clinical protocols. These recommendations were developed in accordance with the methods described in the WHO handbook for guideline development (5). In summary, the process included: identification of priority questions and outcomes, retrieval of evidence, assessment and synthesis of the evidence, formulation of recommendations, and planning for the implementation, dissemination, impact evaluation and updating of the guideline.",
        "The quality of the scientific evidence underpinning the recommendations was graded using the Grading of Recommendations Assessment, Development and Evaluation (GRADE) (6) and Confidence in the Evidence from Reviews of Qualitative research (GRADE-CERQual)  (7) approaches, for quantitative and qualitative evidence, respectively. Up-to-date systematic reviews were used to prepare evidence profiles for priority questions. The DECIDE (Developing and Evaluating Communication Strategies to support Informed Decisions and Practice based on Evidence) (8) framework, an evidence-to-decision tool that includes intervention effects, values, resources, equity, acceptability and feasibility criteria, was used to guide the formulation and approval of recommendations by the Guideline Development Group (GDG) – an international group of experts assembled for the purpose of developing this guideline – at three Technical Consultations between October 2015 and March 2016.",
        "To ensure that each recommendation is correctly understood and applied in practice, the context of all context-specific recommendations is clearly stated within each recommendation, and the contributing experts provided additional remarks where needed.",
        "In accordance with WHO guideline development standards, these recommendations will be reviewed and updated following the identification of new evidence, with major reviews and updates at least every five years.",
        <Typography>
          {" "}
          Further information on procedures for developing this recommendation
          are available{" "}
          <Link
            href="http://web.archive.org/web/20230207083135/http://apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1"
            target="_blank"
          >
            here.
          </Link>
        </Typography>,
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following question:",
      content: [
        "For pregnant women (P), what interventions (pharmacological or non-pharmacological) for leg cramps (I) compared with no interventions (C) reduce morbidity and improve outcomes (O)?",
      ],
    },
    evidenceSummary: {
      title: "Evidence summary",
      content: [
        "The evidence on the effects of various interventions for leg cramps in pregnancy is derived from a Cochrane review that included six small trials involving 390 pregnant women with leg cramps (1). Three studies from Norway (42 women), Sweden (69 women) and Thailand (86 women) contributed data on oral magnesium compared with placebo. One study from Sweden (43 women) compared oral calcium with no treatment; a study conducted in the Islamic Republic of Iran (42 women) compared oral vitamins B6 and B1 with no treatment; and another conducted in Sweden compared oral calcium with vitamin C (30 women). Symptom relief, measured in different ways, was the primary outcome in these studies, and other maternal and perinatal outcomes relevant to this guideline were not reported.",
        "Oral magnesium versus placebo",
        "In three small studies, women in the intervention group were given 300–360 mg magnesium per day in two or three divided doses. Studies measured persistence or occurrence of leg cramps in different ways, so results could not be pooled. Moderate-certainty evidence from the Thai study suggests that women receiving magnesium are more likely to experience a 50% reduction in the number of leg cramps (1 trial, 86 women; RR: 1.42, 95% CI: 1.09–1.86). The same direction of effect was found in the Swedish study, which reported the outcome “no leg cramps” after treatment, but the evidence was of low certainty (1 trial, 69 women; RR: 5.66, 95% CI: 1.35–23.68). Low-certainty evidence suggests that oral magnesium has little or no effect on the occurrence of potential side-effects, including nausea, diarrhoea, flatulence and bloating. Evidence from the third study was judged to be very uncertain.",
        "Oral calcium versus no treatment",
        "Calcium, 1 g twice daily for two weeks, was compared with no treatment in one small study. Low-certainty evidence suggests that women receiving calcium treatment are more likely to experience no leg cramps after treatment (43 women; RR: 8.59, 95% CI: 1.19–62.07). Oral calcium versus vitamin C Low-certainty evidence suggests that there may be little or no difference between calcium and vitamin C in the effect (if any) on complete symptom relief from leg cramps (RR: 1.33, 95% CI: 0.53–3.38).",
        "Oral vitamin B1 and B6 versus no treatment",
        "One study evaluated this comparison, with 21 women receiving vitamin B1 (100 mg) plus B6 (40 mg) once daily for two weeks and 21 women receiving no treatment; however, the low-certainty findings are contradictory and difficult to interpret.",
      ],
    },
    maternalOutcomes: { title: "", content: "" },
    fetalAndNeonatalOutcomes: [
      {
        title: "Additional considerations",
        content:
          "The review found no evidence on nonpharmacological therapies, such as muscle stretching, massage, relaxation, heat therapy and dorsiflexion of the foot.",
      },
      {
        title: "Resources",
        content:
          "Magnesium and calcium supplements are relatively low-cost interventions, particularly when administered for limited periods of two to four weeks.",
      },
      {
        title: "Equity",
        content:
          "The potential etiology of leg cramps being related to a nutritional deficiency (magnesium) suggests that the prevalence of leg cramps might be higher in disadvantaged populations. In theory, therefore, nutritional interventions may have equity implications, but evidence is needed.",
      },
      {
        title: "Acceptability",
        content:
          "Qualitative evidence from a diverse range of settings suggests that women generally appreciate the pregnancy-related advice given by healthcare professionals during ANC, so may respond to supplement suggestions favourably (moderate confidence in the evidence) (6). Evidence from some LMICs suggests that women hold the belief that pregnancy is a healthy condition and may turn to traditional healers and/or herbal remedies to treat these kinds of associated symptoms (high confidence in the evidence).",
      },
      {
        title: "Feasibility",
        content:
          "Qualitative evidence suggests that a lack of resources may limit the offer of treatment for this condition (high confidence in the evidence) (7). In addition, where there are additional costs for pregnant women associated with treatment, women are less likely to use it.",
      },
      {
        title:
          "Further information and considerations related to this recommendation can be found in the WHO guidelines, available at: ",
        content: (
          <Link href="http://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            http://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1
          </Link>
        ),
      },
    ],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "The successful introduction of evidence-based policies related to antenatal care into national programmes and health care services depends on well-planned and participatory consensus-driven processes of adaptation and implementation. These processes may include the development or revision of national guidelines or protocols based on this recommendation.",
        "The recommendation should be adapted into locally-appropriate documents and tools that are able to meet the specific needs of each country and health service. Modifications to the recommendation, where necessary, should be justified in an explicit and transparent manner.",
        "An enabling environment should be created for the use of this recommendation, including changes in the behaviour of health care practitioners to enable the use of evidence-based practices.",
        "Local professional societies may play important roles in this process and an all-inclusive and participatory process should be encouraged.",
        "Antenatal care models with a minimum of eight contacts are recommended to reduce perinatal mortality and improve women’s experience of care. Taking this as a foundation, the GDG reviewed how ANC should be delivered in terms of both the timing and content of each of the ANC contacts, and arrived at a new model – the 2016 WHO ANC model – which replaces the previous four-visit focused ANC (FANC) model. For the purpose of developing this new ANC model, the ANC recommendations were mapped to the eight contacts based on the evidence supporting each recommendation and the optimal timing of delivery of the recommended interventions to achieve maximal impact.",
      ],
    },
    additionalLists: [],
    researchImplications: {
      title: "Research implications",
      text: "The GDG identified these priority questions related to this recommendation",
      content: [
        "What is the prevalence of common physiological symptoms among pregnant women in low-resource settings, and can the offer of treatment of these symptoms reduce health inequality, improve ANC coverage and improve women’s pregnancy experiences?",
        "What is the etiology of leg cramps in pregnancy, and does treatment with magnesium and/or calcium relieve symptoms?",
      ],
    },
    relatedLinks: {
      title: "Related links",
      text: (
        <Typography>
          WHO recommendations on antenatal care for a positive pregnancy
          experience (2016) -{" "}
          <Link href="https://web.archive.org/web/20200915144755/https://apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1">
            full document
          </Link>{" "}
          and{" "}
          <Link href="https://web.archive.org/web/20201219124651/https://apps.who.int/iris/bitstream/10665/250796/8/9789241549912-websupplement-eng.pdf?ua=1">
            evidence table
          </Link>
        </Typography>
      ),
      links: [
        {
          href: "https://web.archive.org/web/20201031220559/https://apps.who.int/iris/bitstream/handle/10665/255760/9789241565493-eng.pdf?sequence=1",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors",
        },
        {
          href: "https://web.archive.org/web/20201030093023/https://apps.who.int/iris/bitstream/handle/10665/249580/9789241549356-eng.pdf;jsessionid=7B8E18468A424E0301261B2CA6BCB324?sequence=1",
          text: "Pregnancy, Childbirth, Postpartum and Newborn Care: A guide for essential practice",
        },
        {
          href: "https://web.archive.org/web/20201103090439/https://www.who.int/reproductivehealth/en/",
          text: "WHO Programmes: Sexual and Reproductive health",
        },
        {
          href: "http://web.archive.org/web/20230507050247/https://www.who.int/health-topics/maternal-health",
          text: "Maternal Health",
        },
      ],
    },
    references: {
      title: "References",
      content: [
        "Zhou K, West HM, Zhang J, Xu L, Li W. Interventions for leg cramps in pregnancy. Cochrane Database Syst Rev. 2015;(8):CD010655.",
        "WHO handbook for guideline development, 2nd edition. Geneva: World Health Organization; 2014 (http://www.who.int/kms/handbook_2nd_ ed.pdf, accessed 6 October 2016).",
        "GRADE [website]. The GRADE Working Group; 2016 (http://gradeworkinggroup.org/, accessed 27 October 2016).",
        "GRADE-CERQual [website]. The GRADECERQual Project Group; 2016 (https://cerqual. org/, accessed 27 October 2016).",
        "The DECIDE Project; 2016 (http://www.decide-collaboration.eu/, accessed 27 October 2016).",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the use of routine antenatal services by pregnant women: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2016;(10):CD012392",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the provision of good quality routine antenatal care services by health staff: a qualitative evidence synthesis. Cochrane Database Syst Rev. 2016",
      ],
    },
  },
  {
    id: "53",
    img: img45,
    imgAlt: "a group of women",
    title: "WHO recommendation on interventions for relief of nausea in early pregnancy",
    date: "20 September 2023",
    recommendation: {
      title: "Recommendation",
      content:
          "Ginger, chamomile, vitamin B6 and/or acupuncture are recommended for the relief of nausea in early\n" +
          "pregnancy, based on a woman’s preferences and available options.",
    },
    recommendationItalic: "(Recommended)",
    publicationHistory: {
      firstPublished: "November 2016",
      updated: "No update planned",
      assessedAsUpToDate: "November 2016",
    },
    remarks: {
      title: "Remarks",
      content: [
        "In the absence of stronger evidence, the GDG agreed that these non-pharmacological options\n" +
        "are unlikely to have harmful effects on mother and baby.",
        "Women should be informed that symptoms of nausea and vomiting usually resolve in the\n" +
        "second half of pregnancy.",
        "Pharmacological treatments for nausea and vomiting, such as doxylamine and metoclopramide,\n" +
        "should be reserved for those pregnant women experiencing distressing symptoms that are not\n" +
        "relieved by nonpharmacological options, under the supervision of a medical doctor.",
       ],
    },
    background: {
      title: "Background",
      content: [
        "Women’s bodies undergo substantial changes during pregnancy, which are brought about by both\n" +
        "hormonal and mechanical effects. These changes lead to a variety of common symptoms – including\n" +
        "nausea and vomiting, low back and pelvic pain, heartburn, varicose veins, constipation and leg cramps –\n" +
        "that in some women cause severe discomfort and negatively affects their pregnancy experience. In\n" +
        "general, symptoms associated with mechanical effects, e.g. pelvic pain, heartburn and varicose veins,\n" +
        "often worsen as pregnancy progresses. Constipation can be very troublesome and may be complicated\n" +
        "by haemorrhoids (1).",
      ],
    },
    methods: {
      title: "Methods",
      content: [
        "The ANC recommendations are intended to inform the development of relevant health-care policies and\n" +
        "clinical protocols. These recommendations were developed in accordance with the methods described\n" +
        "in the WHO handbook for guideline development (2). In summary, the process included: identification of\n" +
        "priority questions and outcomes, retrieval of evidence, assessment and synthesis of the evidence,\n" +
        "formulation of recommendations, and planning for the implementation, dissemination, impact\n" +
        "evaluation and updating of the guideline.",
        "The quality of the scientific evidence underpinning the recommendations was graded using the Grading\n" +
        "of Recommendations Assessment, Development and Evaluation (GRADE) (3) and Confidence in the\n" +
        "Evidence from Reviews of Qualitative research (GRADE-CERQual) (4) approaches, for quantitative and\n" +
        "qualitative evidence, respectively. Up-to-date systematic reviews were used to prepare evidence\n" +
        "profiles for priority questions. The DECIDE (Developing and Evaluating Communication Strategies to\n" +
        "support Informed Decisions and Practice based on Evidence) (5) framework, an evidence-to-decision\n" +
        "tool that includes intervention effects, values, resources, equity, acceptability and feasibility criteria, was\n" +
        "used to guide the formulation and approval of recommendations by the Guideline Development Group\n" +
        "(GDG) – an international group of experts assembled for the purpose of developing this guideline – at\n" +
        "three Technical Consultations between October 2015 and March 2016.",
        "To ensure that each recommendation is correctly understood and applied in practice, the context of all\n" +
        "context-specific recommendations is clearly stated within each recommendation, and the contributing\n" +
        "experts provided additional remarks where needed.",
        "In accordance with WHO guideline development standards, these recommendations will be reviewed\n" +
        "and updated following the identification of new evidence, with major reviews and updates at least every\n" +
        "five years.",
        <span>Further information on procedures for developing this recommendation are available <a href={"https://iris.who.int/bitstream/handle/10665/250796/9789241549912-eng.pdf?sequence=1"}>here</a>.</span>,
      ],
    },
    recommendationQuestion: {
      title: "Recommendation question",
      text: "For this recommendation, we aimed to answer the following questions:",
      content: [
        "For pregnant women (P), what interventions (pharmacological or non-pharmacological) for\n" +
        "nausea and vomiting (I) compared with no interventions (C) reduce morbidity and improve\n" +
        "outcomes (O)?",
      ],
    },
    evidenceSummary: {
      title: "Evidence summary",
      content: [
        "The evidence on the effects of various interventions for nausea and vomiting in pregnancy was derived\n" +
        "from a Cochrane systematic review (6). The review included 41 trials involving 5449 women in whom a\n" +
        "wide variety of pharmacological and nonpharmacological interventions were evaluated. Trials were\n" +
        "conducted in a variety of HICs and LMICs, and most included pregnant women at less than 16 weeks of\n" +
        "gestation with mild to moderate nausea and vomiting. Alternative therapies and non-pharmacological\n" +
        "agents evaluated included acupuncture, acupressure, vitamin B6, ginger, chamomile, mint oil and lemon\n" +
        "oil. Pharmacological agents included antihistamines, phenothiazines, dopamine-receptor antagonists\n" +
        "and serotonin 5-HT3 receptor antagonists. Due to heterogeneity among the types of interventions and\n" +
        "reporting of outcomes, reviewers were seldom able to pool data. The primary outcome of all\n" +
        "interventions was maternal relief from symptoms (usually measured using the Rhodes Index), and\n" +
        "perinatal outcomes relevant to this guideline were rarely reported.", <br/>,
        <i>Non-pharmacological agents versus placebo or no treatment</i>,
        "Ten trials evaluated non-pharmacological interventions including ginger (prepared as syrup, capsules or\n" +
        "powder within biscuits) (7 trials from the Islamic Republic of Iran, Pakistan, Thailand and the USA\n" +
        "involving 578 participants), lemon oil (one Iranian study, 100 participants), mint oil (one Iranian study,\n" +
        "60 participants), chamomile (one Iranian study, 105 participants), and vitamin B6 interventions (two\n" +
        "studies in Thailand and the USA; 416 participants) compared with no treatment or placebo.",
        <span>
          <i>Ginger: {" "}</i>
          Low-certainty evidence from several small individual studies suggests that ginger may relieve
          symptoms of nausea and vomiting. A study from Pakistan found that ginger reduced nausea symptom
          scores (68 women; MD: 1.38 lower on day 3, 95% CI: 0.03–2.73 lower), and vomiting symptom scores
          (64 women; MD: 1.14 lower, 95% CI: 0.37–1.91 lower), and an Iranian study showed improvements in
          nausea and vomiting symptom scores on day 7 in women taking ginger supplements compared with
          placebo (95 women; MD: 4.19 lower, 95% CI: 1.73–6.65 lower). Data from the studies in Thailand and
          the USA showed a similar direction of effect on nausea symptoms in favour of ginger.
        </span>,
        <span>
          <i>Lemon oil: {" "}</i>
          Low-certainty evidence from one small Iranian study suggests that lemon oil may make little
          or no difference to nausea and vomiting symptom scores (100 women; MD: 0.46 lower on day 3, 95% CI:
          1.27 lower to 0.35 higher), or to maternal satisfaction (the number of women satisfied with treatment)
          (1 trial, 100 women; RR: 1.47, 95% CI: 0.91–2.37).
        </span>,
        <span>
          <i>Mint oil: {" "}</i>
          The evidence on mint oil’s ability to relieve symptoms of nausea and vomiting is of very low
          certainty.
        </span>,
        <span>
          <i>Chamomile: {" "}</i>
          Low-certainty evidence from one small study suggests that chamomile may reduce nausea
and vomiting symptoms scores (70 women; MD: 5.74 lower, 95% CI: 3.17–8.31 lower).
        </span>,
        <span>
          <i>Vitamin B6 (pyridoxine): {" "}</i>
          Moderate-certainty evidence from two trials (one used 25 mg oral vitamin B6 8-
          hourly for 3 days, the other used 10 mg oral vitamin B6 8-hourly for 5 days) shows that vitamin B6
          probably reduces nausea symptoms scores (388 women, trials measured the change in nausea scores
          from baseline to day 3; MD: 0.92 higher score change, 95% 0.4–1.44 higher), but low-certainty evidence
          suggests that it may have little or no effect on vomiting (2 trials, 392 women; RR: 0.76, 95% CI:
          0.35–1.66).
        </span>, <br/>,
        <i>Acupuncture and acupressure versus placebo or no treatment</i>,
        "Five studies (601 participants) evaluated P6 (inner forearm) acupressure versus placebo, one Thai study\n" +
        "(91 participants) evaluated auricular acupressure (round magnetic balls used as ear pellets) versus no\n" +
        "treatment, one study in the USA (230 participants) evaluated P6 acustimulation therapy (nerve\n" +
        "stimulation at the P6 acupuncture point) versus placebo, and a four-arm Australian study (593 women)\n" +
        "evaluated traditional Chinese acupuncture or P6 acupuncture versus P6 placebo acupuncture or no\n" +
        "intervention.",
        "Low-certainty evidence suggests that P6 acupressure may reduce nausea symptom scores (100 women;\n" +
        "MD: 1.7 lower, 95% CI: 0.99–2.41 lower) and reduce the number of vomiting episodes (MD: 0.9 lower,\n" +
        "95% CI: 0.74–1.06 lower). Low-certainty evidence suggests that auricular acupressure may also reduce\n" +
        "nausea symptom scores (91 women; MD: 3.6 lower, 95% CI: 0.58–6.62 lower), as may traditional\n" +
        "Chinese acupuncture (296 women; MD: 0.7 lower, 95% CI: 0.04–1.36 lower). Low-certainty evidence\n" +
        "suggests that P6 acupuncture may make little or no difference to mean nausea scores compared with P6\n" +
        "placebo acupuncture (296 women; MD: 0.3 lower, 95% CI: 1.0 lower to 0.4 higher).", <br/>,
        <i>Pharmacological agents versus placebo</i>,
        "One study evaluated an antihistamine (doxylamine) and another evaluated a dopamine-receptor\n" +
        "antagonist (metoclopramide). Certain other drugs evaluated in the review (hydroxyzine,\n" +
        "thiethylperazine and fluphenazine) are from old studies and these drugs are no longer used in pregnant\n" +
        "women due to safety concerns.",
        "Moderate-certainty evidence suggests that doxylamine plus vitamin B6 probably reduces nausea and\n" +
        "vomiting symptom scores compared with placebo (1 study, 256 women; MD: 0.9 lower on day 15, 95%\n" +
        "CI: 0.25–1.55 lower). Low-certainty evidence from this study suggests that there may be little or no\n" +
        "difference in headache (256 women; RR: 0.81, 95% CI: 0.45–1.48) or drowsiness (256 women; RR: 1.21,\n" +
        "95% CI: 0.64–2.27) between doxylamine plus vitamin B6 and placebo.",
        "Low-certainty evidence on metoclopramide (10 mg) suggests that this agent may reduce nausea\n" +
        "symptom scores (1 trial, 68 women; MD: 2.94 lower on day 3, 95% CI: 1.33–4.55 lower). There was no\n" +
        "side-effect data on metoclopramide in the review.",
        "No studies compared ondansetron (a 5HT3 receptor antagonist) with placebo. Two small studies\n" +
        "compared ondansetron with metoclopramide and doxylamine, respectively, but evidence on relative\n" +
        "effects was uncertain.",
      ],
    },
    maternalOutcomes: {
      title: "Additional considerations",
      content:
          <ul>
            <li>
              Low-certainty evidence from single studies comparing different non-pharmacological
              interventions with each other – namely acupuncture plus vitamin B6 versus P6 acupuncture plus
              placebo (66 participants), traditional acupuncture and P6 acupuncture (296 participants), ginger
              versus chamomile (70 participants), P6 acupuncture versus ginger (98 participants), and ginger
              versus vitamin B6 (123 participants) – suggests there may be little or no difference in effects on
              relief of nausea symptoms.
            </li>
            <li>
              Low-certainty evidence suggests that there may be little or no difference between ginger and
              metoclopramide on nausea symptom scores (1 trial, 68 women; MD: 1.56 higher, 95% 0.22
              lower to 3.34 higher) or vomiting symptom scores (68 women; MD: 0.33 higher, 95% CI: 0.69
              lower to 1.35 higher) on day 3 after the intervention.
            </li>
            <li>
              Side-effects and safety of pharmacological agents were poorly reported in the included studies.
              However, drowsiness is a common side-effect of various antihistamines used to treat nausea
              and vomiting.
            </li>
            <li>
              Metoclopramide is generally not recommended in the first trimester of pregnancy, but is widely
              used (7). A study of over 81 700 singleton births in Israel reported that they found no
              statistically significant differences in the risk of major congenital malformations, low birth
              weight, preterm birth or perinatal death between neonates exposed (3458 neonates) and not
              exposed to metoclopramide in the first trimester of gestation.
            </li>
          </ul>,
    },
    fetalAndNeonatalOutcomes: [
      {
        title: "Resources",
        content:
            "Costs associated with non-pharmacological remedies vary. Acupuncture requires professional training\n" +
            "and skills and is probably associated with higher costs. Vitamin B6 (pyridoxine hydrochloride tablets)\n" +
            "could cost about US$ 2.50 for 90 × 10 mg tablets (8).",
      },
      {
        title: "Equity",
        content:
            "The impact on equity is not known.",
      },
      {
        title: "Acceptability",
        content:
            "Qualitative evidence from a range of LMICs suggests that women may be more likely to turn to\n" +
            "traditional healers, herbal remedies or traditional birth attendants (TBAs) to treat these symptoms\n" +
            "(moderate confidence in the evidence) (9). In addition, evidence from a diverse range of settings\n" +
            "indicates that while women generally appreciate the interventions and information provided during\n" +
            "antenatal visits, they are less likely to engage with services if their beliefs, traditions and socioeconomic\n" +
            "circumstances are ignored or overlooked by healthcare providers and/or policy-makers (high confidence\n" +
            "in the evidence). This may be particularly pertinent for acupuncture or acupressure, which may be\n" +
            "culturally alien and/or poorly understood in certain contexts.",
      },
      {
        title: "Feasibility",
        content:
            "A lack of suitably trained staff may limit feasibility of certain interventions (high confidence in the\n" +
            "evidence) (10)."
      },
      {
        title: "Further information and considerations related to this recommendation can be found in the WHO\n" +
            "guidelines, available in the Annex of this publication:",
        content:
            <a href={"https://www.who.int/publications/i/item/9789241549912"}>https://www.who.int/publications/i/item/9789241549912</a>
      },
    ],
    implementationConsiderations: {
      title: "Implementation considerations",
      content: [
        "The successful introduction of evidence-based policies related to antenatal care into national\n" +
        "programmes and health care services depends on well-planned and participatory consensus-\n" +
        "driven processes of adaptation and implementation. These processes may include the\n" +
        "development or revision of national guidelines or protocols based on this recommendation.",
        "The recommendation should be adapted into locally-appropriate documents and tools that are\n" +
        "able to meet the specific needs of each country and health service. Modifications to the\n" +
        "recommendation, where necessary, should be justified in an explicit and transparent manner.",
        "An enabling environment should be created for the use of this recommendation, including\n" +
        "changes in the behaviour of health care practitioners to enable the use of evidence-based\n" +
        "practices.",
        "Local professional societies may play important roles in this process and an all-inclusive and\n" +
        "participatory process should be encouraged.",
        "Antenatal care models with a minimum of eight contacts are recommended to reduce perinatal\n" +
        "mortality and improve women’s experience of care. Taking this as a foundation, the GDG\n" +
        "reviewed how ANC should be delivered in terms of both the timing and content of each of the\n" +
        "ANC contacts, and arrived at a new model – the 2016 WHO ANC model – which replaces the\n" +
        "previous four-visit focused ANC (FANC) model. For the purpose of developing this new ANC\n" +
        "model, the ANC recommendations were mapped to the eight contacts based on the evidence\n" +
        "supporting each recommendation and the optimal timing of delivery of the recommended\n" +
        "interventions to achieve maximal impact.",
      ],
    },
    additionalLists: [],
    relatedLinks: {
      title: "Related links",
      text: (
          <Typography>
            WHO recommendations on antenatal care for a positive pregnancy
            experience (2016) -{" "}
            <Link href="https://web.archive.org/web/20200915144755/https://apps.who.int/iris/bitstream/10665/250796/1/9789241549912-eng.pdf?ua=1">
              full document
            </Link>{" "}
            and{" "}
            <Link href="https://iris.who.int/bitstream/handle/10665/250796/9789241549912-websupplement-eng.pdf?amp;isAllowed=y&sequence=8">
              evidence table
            </Link>
          </Typography>
      ),
      links: [
        {
          href: "https://web.archive.org/web/20201031220559/https://apps.who.int/iris/bitstream/handle/10665/255760/9789241565493-eng.pdf?sequence=1",
          text: "Managing Complications in Pregnancy and Childbirth: A guide for midwives and doctors",
        },
        {
          href: "https://web.archive.org/web/20201030093023/https://apps.who.int/iris/bitstream/handle/10665/249580/9789241549356-eng.pdf;jsessionid=7B8E18468A424E0301261B2CA6BCB324?sequence=1",
          text: "Pregnancy, Childbirth, Postpartum and Newborn Care: A guide for essential practice",
        },
        {
          href: "https://www.who.int/teams/maternal-newborn-child-adolescent-health-and-ageing/maternal-health/about/mca",
          text: "WHO Programmes: Department of Maternal, Newborn, Child, Adolescent Health and Ageing",
        },
        {
          href: "https://www.who.int/teams/maternal-newborn-child-adolescent-health-and-ageing/maternal-health/about/nutrition-and-food-safety",
          text: "WHO Programmes: Department of Nutrition and Food Safety",
        },
        {
          href: "https://www.who.int/teams/sexual-and-reproductive-health-and-research-(srh)/overview",
          text: "WHO Programmes: Department of Sexual and Reproductive Health and Research",
        },
        {
          href: "https://www.who.int/health-topics/maternal-health#tab=tab_1",
          text: "Maternal Health",
        },
      ]
    },
    references: {
      title: "References",
      content: [
        "Rungsiprakarn P, Laopaiboon M, Sangkomkamhang US, Lumbiganon P, Pratt JJ. Interventions for\n" +
        "treating constipation in pregnancy. Cochrane Database Syst Rev. 2015;(9):CD011448.Healthy\n" +
        "diet. Fact sheet No. 394. Geneva: World Health Organization; 2015\n" +
        "(http://www.who.int/mediacentre/factsheets/fs394/en/ , accessed 1 November 2016).",
        "WHO handbook for guideline development, 2nd edition. Geneva: World Health Organization;\n" +
        "2014 (http://www.who.int/kms/handbook_2nd_ ed.pdf, accessed 6 October 2016).",
        "GRADE [website]. The GRADE Working Group; 2016 (http://gradeworkinggroup.org/, accessed\n" +
        "27 October 2016).",
        "GRADE-CERQual [website]. The GRADECERQual Project Group; 2016 (https://cerqual. org/,\n" +
        "accessed 27 October 2016).",
        "The DECIDE Project; 2016 (http://www.decide-collaboration.eu/, accessed 27 October 2016).",
        "Matthews A, Haas DM, O’Mathúna DP, Dowswell T. Interventions for nausea and vomiting in\n" +
        "early pregnancy. Cochrane Database Syst Rev. 2015;(9):CD007575.",
        "Matok I, Gorodischer R, Koren G, Sheiner E, Wiznitzer A, Levy A. The safety of metoclopramide\n" +
        "use in first trimester of pregnancy. N Engl J Med. 2009;360:2528–35.",
        "Joint Formulary Committee. Vitamin deficiency. Chapter 9: Blood and Nutrition. In: British\n" +
        "National Formulary (BNF) 72. London: BMJ Publishing Group Ltd and Royal Pharmaceutical\n" +
        "Society; 2016.",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the use of routine\n" +
        "antenatal services by pregnant women: a qualitative evidence synthesis. Cochrane Database\n" +
        "Syst Rev. 2016;(10):CD012392.",
        "Downe S, Finlayson K, Tunçalp Ö, Gülmezoglu AM. Factors that influence the provision of\n" +
        "good‐quality routine antenatal services: a qualitative evidence synthesis of the views and\n" +
        "experiences of maternity care providers. Cochrane Database Syst Rev. 2017 Aug\n" +
        "25;2017(8):CD012752. doi: 10.1002/14651858.CD012752. PMCID: PMC6483720."
      ],
    },
  },
];
