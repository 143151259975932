import { IDocument } from "./IDocument";
import { documentsOne } from "./document-modal-one-data";
import { documentsTwo } from "./document-modal-two-data";
import { documentsThree } from "./document-modal-three-data";
import { documentsFour } from "./document-modal-four-data";
import { documentsFive } from "./document-modal-five-data";
import { documentsSeven } from "./document-modal-seven-data";

export const documents: IDocument[] = [
  ...documentsOne,
  ...documentsTwo,
  ...documentsThree,
  ...documentsFour,
  ...documentsFive,
  ...documentsSeven,
];
