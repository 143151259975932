import { IDocument } from "../IDocument";
import { documentClasses as classes, StyledDocument } from "../StyledDocuments";
import {
  Typography,
  Grid,
  List,
  ListItem,
  Link,
  CardMedia,
} from "@mui/material";

const TemplateTwo = ({ document }: { document: IDocument }) => {
  return (
    <StyledDocument className={classes.root}>
      <CardMedia
        component="img"
        alt={document.imgAlt}
        image={document.img}
        className={classes.image}
      />
      <Grid>
        <Typography fontWeight={"bold"} className={classes.titleMarginSmall}>
          {document.title}
        </Typography>
        <Typography fontWeight={"bold"}>{document.date}</Typography>
      </Grid>
      <Grid className={classes.titleMarginLargeBoth}>
        <Typography fontWeight={"bold"} className={classes.titleMarginSmall}>
          {document.recommendation?.title}
        </Typography>
        <Typography>{document.recommendation?.content}</Typography>
        <Typography fontStyle={"italic"}>
          {document.recommendationItalic}
        </Typography>
      </Grid>
      <Grid container className={classes.publicationHistory}>
        <Typography fontWeight={"bold"}>Publication history</Typography>
        <Typography>
          <b>First published: </b>
          <span>{document.publicationHistory?.firstPublished}</span>
        </Typography>
        <Typography>
          <b>Updated: </b>
          {document.publicationHistory?.updated}
        </Typography>
        <Typography>
          <b>Assessed as up-to-date: </b>
          {document.publicationHistory?.assessedAsUpToDate}
        </Typography>
      </Grid>
      <Grid>
        <Typography fontWeight={"bold"}>{document.remarks?.title}</Typography>
        <List className={classes.bulletList}>
          {document.remarks?.content.map((remark) => (
            <ListItem className={classes.bulletListItem}>
              <span>{remark}</span>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid>
        <Typography fontWeight={"bold"} className={classes.titleMarginSmall}>
          {document.background?.title}
        </Typography>
        {document.background?.content.map((paragraph) => (
          <Typography className={classes.titleMarginSmall}>
            {paragraph}
          </Typography>
        ))}
        <Typography fontWeight={"bold"} className={classes.titleMarginMedium}>
          {document.methods?.title}
        </Typography>
        {document.methods?.content.map((method) => (
          <Typography className={classes.titleMarginSmall}>{method}</Typography>
        ))}
      </Grid>
      <Grid className={classes.bulletListWrapper}>
        <Typography fontWeight={"bold"} className={classes.titleMarginMedium}>
          {document.recommendationQuestion?.title}
        </Typography>
        <Typography>{document.recommendationQuestion?.text}</Typography>
        <List className={classes.bulletList}>
          {document.recommendationQuestion?.content.map((question) => (
            <ListItem className={classes.bulletListItem}>
              <span>{question}</span>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid>
        <Typography fontWeight={"bold"} className={classes.titleMarginSmall}>
          {document.evidenceSummary?.title}
        </Typography>
        {document.evidenceSummary?.content.map((paragraph: any) => (
          <Typography className={classes.titleMarginSmall}>
            {paragraph}
          </Typography>
        ))}
      </Grid>
      <Grid>
        {document.comparison?.map((list) => (
          <Grid>
            <Typography fontWeight={"bold"}>{list.title}</Typography>
            <List>
              {list.content.map((point) => (
                <ListItem>
                  <span>{point}</span>
                </ListItem>
              ))}
            </List>
          </Grid>
        ))}
      </Grid>
      <Grid>
        <Typography fontWeight={"bold"} className={classes.titleMarginLargeTop}>
          {document.implementationConsiderations?.title}
        </Typography>
        <List className={classes.bulletList}>
          {document.implementationConsiderations?.content.map((remark) => (
            <ListItem className={classes.bulletListItem}>
              <span>{remark}</span>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid>
        {document.additionalLists?.map((list) => (
          <Grid>
            <Typography fontWeight={"bold"} fontStyle={"italic"}>
              {list.title}
            </Typography>
            <List className={classes.bulletList}>
              {list.content.map((point) => (
                <ListItem className={classes.bulletListItem}>
                  <span>{point}</span>
                </ListItem>
              ))}
            </List>
          </Grid>
        ))}
      </Grid>
      <Grid>
        <Typography fontWeight={"bold"} className={classes.titleMarginSmall}>
          {document.relatedLinks?.title}
        </Typography>
        {document.relatedLinks?.text}
        {document.relatedLinks?.links.map((link) => (
          <Grid className={classes.outcomesItem}>
            <Link href={link.href}>{link.text}</Link>
            <br />
          </Grid>
        ))}
      </Grid>
      {document.additionalLinks}
      <Grid className={classes.widthWrapper}>
        <Typography fontWeight={"bold"} className={classes.titleMarginMedium}>
          {document.references?.title}
        </Typography>
        {document.references?.content.map((element, index) => (
          <Typography className={classes.orderedListItem}>
            {index + 1}. {element}
          </Typography>
        ))}
      </Grid>
    </StyledDocument>
  );
};

export default TemplateTwo;
