import React from "react";
import { partOneModalsClasses as classes, StyledPartOneModals } from "./StyledPartOneModals";
import { Grid, Link, Typography, Modal} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { modalFirstContent, modalSecondContent, modalThirdContent, modalFourthContent, modalFifthContent, modalSixthContent, modalSeventhContent } from "./PartOneModalsData";

interface Props {
    open: boolean;
    handleOpen: () => void;
    handleClose: () => void;
    modalInfo: {
        class: string,
        heading: string,
        button: string,
        content: any[],
        linkTitle: string
    }
}

interface ModalsProps {
    open: boolean[];
    handleOpen: (index: number) => void;
    handleClose: (index: number) => void;
}

export const modalsStructure = [
    {
        class: classes.rootHeight,
        heading: "Nutritional recommendations",
        button: "Read recommendations",
        content: modalFirstContent,
        linkTitle: "Read recommendations",
    },
    {
        class: classes.rootHeight,
        heading: "Maternal and fetal assessments recommendations",
        button: "Read recommendations",
        content: modalSecondContent,
        linkTitle: "Read recommendations",
    },
    {
        class: classes.root,
        heading: "Preventive measures recommendations",
        button: "Read recommendations",
        content: modalThirdContent,
        linkTitle: "Read recommendations",
    },
    {
        class: classes.root,
        heading: "Interventions for common physical symptoms recommendations",
        button: "Read recommendations",
        content: modalFourthContent,
        linkTitle: "Read recommendations",
    },
    {
        class: classes.rootHeight,
        heading: "Health systems interventions recommendations",
        button: "Read recommendations",
        content: modalFifthContent,
        linkTitle: "Read recommendations",
    },
    {
        class: classes.root,
        heading: "Qualitative Evidence Syntheses",
        button: "Read full articles",
        content: modalSixthContent,
        linkTitle: "Read review",
    },
    {
        class: classes.rootHeight,
        heading: "Evidence for essential counselling and good clinical practices",
        button: "Read recommendations",
        content: modalSeventhContent,
        linkTitle: "Read recommendations",
    },
]

export const Modals = ({open, handleOpen, handleClose}:ModalsProps) => {
    return (
        <>
            {modalsStructure.map((modal, index) => (
                <ModalNumber key={modal.heading} modalInfo={modal} open={open[index]} handleOpen={() => {handleOpen(index);}} handleClose={() => {handleClose(index);}}/>
            ))}
        </>
    )};
    
export const ModalNumber = ({modalInfo, open, handleClose}:Props) => {    
    return <Modal open={open} onClose={handleClose}>
        <StyledPartOneModals container className={modalInfo.class}>
            <Typography className={classes.modalHeading} variant="h2">
                {modalInfo.heading}
                <CloseIcon className={classes.modalClose} onClick={handleClose}/>
            </Typography>
            <Grid container className={classes.modalContent}>
                {modalInfo.content.map((element) => (
                    <Grid key={element.text} className={classes.modalGrid} item xs={6}>
                        <Grid item xs={4}>
                            <img src={element.image} alt={element.alt} className={classes.modalElementPhoto}/>
                        </Grid>
                        <Grid item xs={8} className={classes.modalElementTextSection}>
                            <Typography className={classes.modalElementText} variant="body2">{element.text}</Typography>
                            <Link underline="none" href={element.href} target="_blank" className={classes.modalElementLink}>{modalInfo.linkTitle}</Link>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </StyledPartOneModals>
    </Modal>
};
