import React from 'react';
import './App.css';
import { ThemeProvider } from "@mui/material";
import { Home } from "./Home"
import { Document } from "./Documents/Document"
import { theme } from "./common/Theme/theme";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";
ReactGA.initialize("G-2QJGXKHB31");

function App() {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />}></Route>
                    <Route path="/document/:documentId" element={<Document />}></Route>
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
