import {Grid, styled} from "@mui/material";
import {prefixClasses} from "../common/Theme/utils";

const classes = {
    root: "root",
    transparentLayer: "transparentLayer",
    navWrapper: "navWrapper",
    navGridWrapper: "navGridWrapper",
    navGridItem: "navGridItem",
    menu: "menu",
    headerContentWrapper: "headerContentWrapper",
    headerContent: "headerContent",
    bothSides: "bothSides",
    oneSide: "oneSide",
    sidePhoto: "sidePhoto",
    sidePhotoLink: "sidePhotoLink",
    sidePhotoGradient: "sidePhotoGradient",
    buttonGuideline: "buttonGuideline",
    contentTitle: "contentTitle",
    subTitle: "subTitle",
    gridPhotosWrapper: "gridPhotosWrapper",
    gridPhotos: "gridPhotos",
    gridPhotoGradient: "gridPhotoGradient",
    gridPhotosAll: "gridPhotosAll",
    gridPhotos1: "gridPhotos1",
    gridPhotos2: "gridPhotos2",
    gridPhotos3: "gridPhotos3",
    gridPhotos4: "gridPhotos4",
    gridPhotos5: "gridPhotos5",
    gridPhotosLink: "gridPhotosLink",
};

prefixClasses("Dashboard", classes);

export const StyledHeader = styled(Grid)(({ theme }) => ({
    [`&.${classes.root}`]: {
        [theme.breakpoints.up('mobileXL')]: {
            backgroundImage: "url('/bg-header-11.jpg')",
            backgroundSize: 'cover',
            backgroundPositionX: 'bottom',
            backgroundPositionY: 'left',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'rgb(30, 37, 47)'
        },
    },
    [`& .${classes.navWrapper}`]: {
        padding: '16px 0px',
        display: 'flex',
        justifyContent: 'space-evenly',
        [theme.breakpoints.down('mobileXL')]: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '16px 48px',
        },
        flexDirection: 'row',
        background: '#fff',
        width: '100%',
        maxWidth: '100%',
    },
    [`& .${classes.navGridWrapper}`]: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '30px',
        [theme.breakpoints.down('mobileXL')]: {
            display: 'none',
        },
    },
    [`& .${classes.navGridItem}`]: {
        padding: '8px',
    },
    [`& .${classes.menu}`]: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '0px',
        [theme.breakpoints.up('mobileXL')]: {
            display: 'none',
        },
    },
    [`& .${classes.transparentLayer}`]: {
        backgroundColor: 'rgba(255,255,255,0.2)',
        padding: '174px 24px 48px 24px',
        [theme.breakpoints.down('mobileXL')]: {
            padding: '56px 24px',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '40px 16px',
        },
    },
    [`& .${classes.headerContentWrapper}`]: {
        margin: '0px',
        maxWidth: '100%',
        padding: '0px',
        [theme.breakpoints.down('mobileXL')]: {
            backgroundImage: "url('/bg-header-11.jpg')",
            backgroundSize: 'cover',
            backgroundPositionX: 'bottom',
            backgroundPositionY: 'left',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'rgb(30, 37, 47)'
        },
    },
    [`& .${classes.headerContent}`]: {
        padding: '0 7.5px',
        marginBottom: '48px',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down('xl')]: {
            maxWidth: '1140px',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '960px',
        },
        [theme.breakpoints.down('mobileXL')]: {
            maxWidth: '720px',
        },
        [theme.breakpoints.down('mobileLG')]: {
            maxWidth: '580px',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '450px',
        },
        [theme.breakpoints.down('mobileMD')]: {
            maxWidth: '320px',
        },
        [theme.breakpoints.down('mobileSM')]: {
            maxWidth: '300px',
        },
    },
    [`& .${classes.bothSides}`]: {
        display: "flex",
        width: "80%",
        justifyContent: "space-evenly",
        alignItems: "center",
        backgroundColor: 'rgba(255,255,255,0.7)',
        padding: "32px 48px",
        columnGap: "56px",
        borderRadius: '16px',
        marginLeft: "100px",
        [theme.breakpoints.down('md')]: {
            marginLeft: "80px",
        },
        [theme.breakpoints.down('mobileXL')]: {
            marginLeft: "0px",
        },
        [theme.breakpoints.down('mobileLG')]: {
            width: "95%",
            flexDirection: "column",
            rowGap: "48px",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: "24px 36px",
        },
        [theme.breakpoints.down('mobileMD')]: {
            padding: "16px 24px",
        },
    },
    [`& .${classes.oneSide}`]: {
        display: "flex",
        flexDirection: "column",
        rowGap: "16px",
        width: "calc(50% - 28px)",
        padding: "0px",
        [theme.breakpoints.down('mobileLG')]: {
            width: "100%",
        },
    },
    [`& .${classes.sidePhoto}`]: {
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        backgroundSize: 'cover',
        borderRadius: '6px',
        overflow: 'hidden',
        paddingTop: '0px',
        height: "170px",
        [theme.breakpoints.down('md')]: {
            height: '160px',
        },
        [theme.breakpoints.down('mobileXL')]: {
            height: '120px',
        },
        [theme.breakpoints.down('mobileLG')]: {
            height: '160px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '120px',
        },
    },
    [`& .${classes.sidePhotoLink}`]: {
        color: 'white',
        fontWeight: '600',
        fontSize: '1.1rem',
        lineHeight: '1.2',
        [theme.breakpoints.down('mobileXL')]: {
            fontSize: "1rem",
        },
        [theme.breakpoints.down('mobileLG')]: {
            fontSize: '0.9rem',
        },
    },
    [`& .${classes.sidePhotoGradient}`]: {
        width: '100%',
        height: '100%',
        background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%, #1e252f 90%)',
        padding: '16px 20px',
        display: 'flex',
        flexDirection: 'column-reverse',
        "&:hover": {
            background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%, #008bc8 80%)',
        },
    },
    [`& .${classes.buttonGuideline}`]: {
        padding: '16px 10px',
        [theme.breakpoints.between('mobileXL','md')]: {
            padding: '10px 14px',
            fontSize: '0.58rem',
        },
        width: '100%',
        fontFamily: 'LatoHeavy',
        lineHeight: 1,
        borderRadius: '16px',
        color: '#fff',
        backgroundColor: '#008bc8',
        "&:hover": {
            backgroundColor: 'rgba(0,139,200,0.75)',
        },
    },
    [`& .${classes.contentTitle}`]: {
        fontSize: '3rem',
        color: "rgb(0, 139, 200)",
        [theme.breakpoints.down('md')]: {
            fontSize: '2rem',
        },
        [theme.breakpoints.down('mobileXL')]: {
            fontSize: '1.6rem',
        },
        [theme.breakpoints.down('mobileLG')]: {
            fontSize: '2.4rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
        },
        [theme.breakpoints.down('mobileMD')]: {
            fontSize: '1.75rem',
        },
    },
    [`& .${classes.subTitle}`]: {
        fontFamily: 'LatoHeavy',
        fontSize: "1.2rem",
        [theme.breakpoints.down('sm')]: {
            fontSize: "1.1rem",
        },
    },
    [`& .${classes.gridPhotosWrapper}`]: {
        rowGap: '24px',
        columnGap: '24px',
        backgroundColor: 'rgba(255,255,255,0.7)',
        padding: "32px 48px",
        borderRadius: "16px",
        display: "flex",
        width: "90%",
        flexDirection: "column",
        justifyContent: "center",
        margin: "68px 0px 0px 100px",
        [theme.breakpoints.down('md')]: {
            marginLeft: "80px",
        },
        [theme.breakpoints.down('mobileXL')]: {
            margin: "68px 0px 0px 0px",
        },
        [theme.breakpoints.down('mobileLG')]: {
            width: "95%",
            padding: "24px 36px",
        },
        [theme.breakpoints.down('mobileMD')]: {
            width: "100%",
            padding: "16px 24px",
        },
    },
    [`& .${classes.gridPhotos}`]: {
        rowGap: '24px',
        columnGap: '24px',
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down('mobileLG')]: {
            width: "100%",
            rowGap: '18px',
            columnGap: '18px',
            flexDirection: "column",
        },
    },
    [`& .${classes.gridPhotosAll}`]: {
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        backgroundSize: 'cover',
        height: '180px',
        width: "calc((100% - 48px)/3)",
        borderRadius: '5px',
        overflow: 'hidden',
        paddingTop: '0px',
        [theme.breakpoints.down('md')]: {
            height: '160px',
        },
        [theme.breakpoints.down('mobileXL')]: {
            width: "100%",
            height: '130px',
        },
        [theme.breakpoints.down('mobileLG')]: {
            height: '150px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '110px',
        },
    },
    [`& .${classes.gridPhotoGradient}`]: {
        width: '100%',
        height: '100%',
        background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%, #1e252f 90%)',
        padding: '14px 18px',
        display: 'flex',
        flexDirection: 'column-reverse',
        "&:hover": {
            background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%, #008bc8 80%)',
        },
    },
    [`& .${classes.gridPhotos1}`]: {
        backgroundImage: "url('/header-grid-1.png')",
        backgroundPositionY: '60%',
    },
    [`& .${classes.gridPhotos2}`]: {
        backgroundImage: "url('/header-grid-2.png')",
        backgroundPositionY: '70%',
    },
    [`& .${classes.gridPhotos3}`]: {
        backgroundImage: "url('/header-grid-3.png')",
    },
    [`& .${classes.gridPhotos4}`]: {
        backgroundImage: "url('/header-grid-4.jpg')",
    },
    [`& .${classes.gridPhotos5}`]: {
        backgroundImage: "url('/header-grid-5.png')",
        backgroundPositionY: '70%',
    },
    [`& .${classes.gridPhotosLink}`]: {
        color: 'white',
        fontWeight: '600',
        fontSize: '1.05rem',
        lineHeight: '1.2',
    },
}));

export const headerClasses = classes;
