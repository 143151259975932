import React from "react";
import { headerClasses as classes, StyledHeader } from "./StyledHeader";
import { Container, Grid, Button, Typography, Link } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import DehazeIcon from '@mui/icons-material/Dehaze';

import logo from "../img/logo.png";
import { StyledMenu } from "./StyledMenuHeader";
import { useGA } from "../common/Theme/utils";

export const Header = () => {
    useGA("Home");
    const options = [
        {value: 'REVIEWS', pageId: '#Reviews' },
        {value: 'GUIDELINES', pageId: '#Guidelines' },
        {value: 'TOOLS', pageId: '#Tools' },
        {value: 'RESOURCES', pageId: '#Resources' },
        {value: 'CONTACT', pageId: 'https://www.who.int/teams/sexual-and-reproductive-health-and-research-(srh)/about-us'},
    ];
    const gridElements = [
        {class: classes.gridPhotos2, title: 'Other relevant WHO Guidelines', pageId: '#Guidelines' },
        {class: classes.gridPhotos3, title: 'Implementation & Digital Health Tools', pageId: '#Tools' },
        {class: classes.gridPhotos4, title: 'Training & Communications materials', pageId: '#Resources' },
    ];
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return <StyledHeader className={classes.root} container>
        <Grid container className={classes.navWrapper}>
            <img src={logo} alt="logo"/>
            <Grid item className={classes.navGridWrapper}>
                {options.map((option) => (
                    <Grid key={option.pageId} item className={classes.navGridItem}>
                        <Link key={option.pageId} underline="none" href={option.pageId} color="#000">{option.value}</Link>
                    </Grid>
                ))}
            </Grid>
            <Grid item className={classes.menu}>
                <IconButton onClick={handleClick}>
                    <DehazeIcon />
                </IconButton>
                <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
                     {options.map((option) => (
                         <Link key={option.pageId} underline="none" href={option.pageId} color="#000" >
                             <MenuItem key={option.value} onClick={handleClose}>{option.value}</MenuItem>
                         </Link>
                     ))}
                </StyledMenu>
            </Grid>
        </Grid>
        <Container className={classes.headerContentWrapper}>
            <Grid className={classes.transparentLayer}>
                <Container className={classes.headerContent}>
                    <Grid container className={classes.bothSides}>
                        <Grid item className={classes.oneSide}>
                            <Grid item display={"flex"} justifyContent={"center"}>
                                <Typography variant="body2" className={classes.contentTitle} component="strong">Antenatal Care</Typography>
                            </Grid>
                            <Grid item className={`${classes.sidePhoto} ${classes.gridPhotos1}`}>
                                <Container fixed className={classes.sidePhotoGradient}>
                                    <Link href={'#Reviews'} underline="none" className={classes.sidePhotoLink}>Evidence base for the Antenatal Care Guideline</Link>
                                </Container>
                            </Grid>
                            <Grid>
                                <Button className={classes.buttonGuideline} href="https://www.who.int/publications/i/item/9789241549912" target="_blank">Access Guideline</Button>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.oneSide}>
                            <Grid item display={"flex"} justifyContent={"center"}>
                                <Typography variant="body2" className={classes.contentTitle} component="strong">Postnatal Care</Typography>
                            </Grid>
                            <Grid item className={`${classes.sidePhoto} ${classes.gridPhotos5}`}>
                                <Container fixed className={classes.sidePhotoGradient}>
                                    <Link href={'#Reviews2'} underline="none" className={classes.sidePhotoLink}>Evidence base for the Postnatal Care Guideline</Link>
                                </Container>
                            </Grid>
                            <Grid>
                                <Button className={classes.buttonGuideline} href="https://www.who.int/publications/i/item/9789240045989" target="_blank">Access Guideline</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.gridPhotosWrapper}>
                        <Grid item display="flex" gap={"24px"} className={classes.gridPhotos}>
                            {gridElements.map((element) => (
                                <Grid key={element.pageId} item className={`${classes.gridPhotosAll} ${element.class}`}>
                                    <Container fixed className={classes.gridPhotoGradient}>
                                        <Link key={element.pageId} href={element.pageId} underline="none" className={classes.gridPhotosLink}>{element.title}</Link>
                                    </Container>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Container>
    </StyledHeader>;
};
