import modalFirstPhoto1 from "../assets/PartOneAssets/modalFirstPhoto1.jpg";
import modalFirstPhoto2 from "../assets/PartOneAssets/modalFirstPhoto2.jpg";
import modalFirstPhoto3 from "../assets/PartOneAssets/modalFirstPhoto3.jpg";
import modalFirstPhoto4 from "../assets/PartOneAssets/modalFirstPhoto4.jpg";
import modalFirstPhoto5 from "../assets/PartOneAssets/modalFirstPhoto5.jpg";
import modalFirstPhoto6 from "../assets/PartOneAssets/modalFirstPhoto6.jpg";
import modalFirstPhoto7 from "../assets/PartOneAssets/modalFirstPhoto7.jpg";
import modalFirstPhoto8 from "../assets/PartOneAssets/modalFirstPhoto8.jpg";
import modalFirstPhoto9 from "../assets/PartOneAssets/modalFirstPhoto9.jpg";
import modalFirstPhoto10 from "../assets/PartOneAssets/modalFirstPhoto10.jpg";
import modalFirstPhoto11 from "../assets/PartOneAssets/modalFirstPhoto11.jpg";
import modalFirstPhoto12 from "../assets/PartOneAssets/modalFirstPhoto12.jpg";
import modalFirstPhoto13 from "../assets/PartOneAssets/modalFirstPhoto13.jpg";
import modalFirstPhoto14 from "../assets/PartOneAssets/modalFirstPhoto14.jpg";
import modalSecondPhoto1 from "../assets/PartOneAssets/modalSecondPhoto1.jpg";
import modalSecondPhoto2 from "../assets/PartOneAssets/modalSecondPhoto2.jpg";
import modalSecondPhoto3 from "../assets/PartOneAssets/modalSecondPhoto3.jpg";
import modalSecondPhoto4 from "../assets/PartOneAssets/modalSecondPhoto4.jpg";
import modalSecondPhoto5 from "../assets/PartOneAssets/modalSecondPhoto5.jpg";
import modalSecondPhoto6 from "../assets/PartOneAssets/modalSecondPhoto6.jpg";
import modalSecondPhoto7 from "../assets/PartOneAssets/modalSecondPhoto7.jpg";
import modalSecondPhoto8 from "../assets/PartOneAssets/modalSecondPhoto8.jpg";
import modalSecondPhoto9 from "../assets/PartOneAssets/modalSecondPhoto9.jpg";
import modalSecondPhoto10 from "../assets/PartOneAssets/modalSecondPhoto10.jpg";
import modalSecondPhoto11 from "../assets/PartOneAssets/modalSecondPhoto11.jpg";
import modalSecondPhoto12 from "../assets/PartOneAssets/modalSecondPhoto12.jpg";
import modalSecondPhoto13 from "../assets/PartOneAssets/modalSecondPhoto13.jpg";
import modalThirdPhoto1 from "../assets/PartOneAssets/modalThirdPhoto1.jpg";
import modalThirdPhoto2 from "../assets/PartOneAssets/modalThirdPhoto2.jpg";
import modalThirdPhoto3 from "../assets/PartOneAssets/modalThirdPhoto3.jpg";
import modalThirdPhoto4 from "../assets/PartOneAssets/modalThirdPhoto4.jpg";
import modalThirdPhoto5 from "../assets/PartOneAssets/modalThirdPhoto5.jpg";
import modalFourthPhoto1 from "../assets/PartOneAssets/modalFourthPhoto1.jpg";
import modalFourthPhoto2 from "../assets/PartOneAssets/modalFourthPhoto2.jpg";
import modalFourthPhoto3 from "../assets/PartOneAssets/modalFourthPhoto3.jpg";
import modalFourthPhoto4 from "../assets/PartOneAssets/modalFourthPhoto4.jpg";
import modalFourthPhoto5 from "../assets/PartOneAssets/modalFourthPhoto5.jpg";
import modalFourthPhoto6 from "../assets/PartOneAssets/modalFourthPhoto6.jpg"
import modalFifthPhoto1 from "../assets/PartOneAssets/modalFifthPhoto1.jpg";
import modalFifthPhoto2 from "../assets/PartOneAssets/modalFifthPhoto2.png";
import modalFifthPhoto3 from "../assets/PartOneAssets/modalFifthPhoto3.jpg";
import modalFifthPhoto4 from "../assets/PartOneAssets/modalFifthPhoto4.jpg";
import modalFifthPhoto5 from "../assets/PartOneAssets/modalFifthPhoto5.jpg";
import modalFifthPhoto6 from "../assets/PartOneAssets/modalFifthPhoto6.jpg";
import modalFifthPhoto7 from "../assets/PartOneAssets/modalFifthPhoto7.jpg";
import modalFifthPhoto8 from "../assets/PartOneAssets/modalFifthPhoto8.jpg";
import modalSixthPhoto1 from "../assets/PartOneAssets/modalSixthPhoto1.jpg";
import modalSixthPhoto2 from "../assets/PartOneAssets/modalSixthPhoto2.jpg";
import modalSeventhPhoto1 from "../assets/PartOneAssets/modalSeventhPhoto1.jpg";
import modalSeventhPhoto2 from "../assets/PartOneAssets/modalSeventhPhoto2.jpg";
import modalSeventhPhoto3 from "../assets/PartOneAssets/modalSeventhPhoto3.jpg";
import modalSeventhPhoto4 from "../assets/PartOneAssets/modalSeventhPhoto4.jpg";
import modalSeventhPhoto5 from "../assets/PartOneAssets/modalSeventhPhoto5.jpg";
import modalSeventhPhoto6 from "../assets/PartOneAssets/modalSeventhPhoto6.jpg";
import modalSeventhPhoto7 from "../assets/PartOneAssets/modalSeventhPhoto7.jpg";

export const modalFirstContent = [
  {
    image: modalFirstPhoto1,
    alt: "a group of women",
    text: "Counselling about healthy eating and keeping physically active during pregnancy",
    href: "/document/49",
  },
  {
    image: modalFirstPhoto2,
    alt: "women at a doctor's office",
    text: "Increasing daily energy and protein intake",
    href: "/document/1",
  },
  {
    image: modalFirstPhoto3,
    alt: "a woman and a doctor",
    text: "Balanced energy and protein dietary supplementation",
    href: "/document/47",
  },
  {
    image: modalFirstPhoto4,
    alt: "piece of paper and a pregnant woman",
    text: "High protein supplements",
    href: "/document/25",
  },
  {
    image: modalFirstPhoto5,
    alt: "red pills",
    text: "Daily oral iron and folic acid supplementation",
    href: "/document/26",
  },
  {
    image: modalFirstPhoto6,
    alt: "pills in a hand",
    text: "Intermittent oral iron and folic acid supplementation",
    href: "/document/27",
  },
  {
    image: modalFirstPhoto7,
    alt: "a woman and a doctor",
    text: "Calcium supplementation",
    href: "/document/2",
  },
  {
    image: modalFirstPhoto8,
    alt: "pills in a blister",
    text: "Vitamin A supplementation",
    href: "/document/48",
  },
  {
    image: modalFirstPhoto9,
    alt: "pills in a hand and in blisters",
    text: "Zinc supplementation",
    href: "/document/3",
  },
  {
    image: modalFirstPhoto10,
    alt: "pills on a spoon",
    text: "Multiple micronutrient supplementation",
    href: "/document/43",
  },
  {
    image: modalFirstPhoto11,
    alt: "a woman and a doctor",
    text: "Vitamin B6 supplementation",
    href: "/document/4",
  },
  {
    image: modalFirstPhoto12,
    alt: "pills in a hand",
    text: "Vitamin D supplementation",
    href: "/document/42",
  },
  {
    image: modalFirstPhoto13,
    alt: "a pregnant woman",
    text: "Restricting caffeine intake",
    href: "/document/32",
  },
  {
    image: modalFirstPhoto14,
    alt: "a pregnant woman",
    text: "Vitamin E and C supplements",
    href: "https://www.who.int/tools/elena/interventions/vitaminsec-pregnancy",
  },
];

export const modalSecondContent = [
  {
    image: modalSecondPhoto1,
    alt: "a lying woman and a doctor",
    text: "Method for diagnosing anaemia",
    href: "/document/33",
  },
  {
    image: modalSecondPhoto2,
    alt: "a pregnant woman and a doctor",
    text: "Methods for diagnosing asymptomatic bacteriuria",
    href: "/document/34",
  },
  {
    image: modalSecondPhoto3,
    alt: "pregnant woman and man with a bike",
    text: "Clinical diagnosis of intimate partner violence",
    href: "/document/45",
  },
  {
    image: modalSecondPhoto4,
    alt: "a device taking blood samples",
    text: "Diagnosis of gestational diabetes mellitus (GDM)",
    href: "/document/35",
  },
  {
    image: modalSecondPhoto5,
    alt: "a pregnant woman",
    text: "Tobacco use",
    href: "/document/5",
  },
  {
    image: modalSecondPhoto6,
    alt: "a pregnant woman",
    text: "Substance use",
    href: "/document/6",
  },
  {
    image: modalSecondPhoto7,
    alt: "a blood sample",
    text: "HIV and syphilis testing",
    href: "/document/36",
  },
  {
    image: modalSecondPhoto8,
    alt: "a group of pregnant women",
    text: "Tuberculosis testing",
    href: "/document/37",
  },
  {
    image: modalSecondPhoto9,
    alt: "a woman and a doctor",
    text: "Daily fetal movement counting",
    href: "/document/7",
  },
  {
    image: modalSecondPhoto10,
    alt: "a piece of paper and a pregnant woman",
    text: "Symphysial fundal height measurement",
    href: "/document/52",
  },
  {
    image: modalSecondPhoto11,
    alt: "a woman next to a device",
    text: "Antenatal cardiotocography",
    href: "/document/8",
  },
  {
    image: modalSecondPhoto12,
    alt: "a pregnant woman taking an ultrasound",
    text: "Ultrasound scan",
    href: "/document/51",
  },
  {
    image: modalSecondPhoto13,
    alt: "a pregnant woman taking an ultrasound",
    text: "Doppler ultrasound of fetal blood vessels",
    href: "/document/9",
  },
];

export const modalThirdContent = [
  {
    image: modalThirdPhoto1,
    alt: "colorful pills",
    text: "Antibiotics for asymptomatic bacteriuria",
    href: "/document/28",
  },
  {
    image: modalThirdPhoto2,
    alt: "white pills",
    text: "Antibiotic prophylaxis for recurrent urinary tract infections",
    href: "/document/54",
  },
  {
    image: modalThirdPhoto3,
    alt: "a syringe",
    text: "Antenatal anti-D immunoglobulin administration",
    href: "/document/10",
  },
  {
    image: modalThirdPhoto4,
    alt: "a pregnant woman and a doctor",
    text: "Preventive anthelminthic treatment",
    href: "/document/11",
  },
  {
    image: modalThirdPhoto5,
    alt: "a group of women",
    text: "Tetanus toxoid-containing vaccination",
    href: "https://www.who.int/publications/m/item/who-unicef-guidance-note---ensuring-sustained-protection-against-diphtheria-replacing-tt-with-td-vaccine",
  },
];

export const modalFourthContent = [
  {
    image: modalFourthPhoto1,
    alt: "a woman and a doctor",
    text: "Constipation",
    href: "/document/13",
  },
  {
    image: modalFourthPhoto2,
    alt: "a pregnant woman lying",
    text: "Lower back and pelvic pain",
    href: "/document/14",
  },
  {
    image: modalFourthPhoto3,
    alt: "a woman and a doctor",
    text: "Varicose veins and oedema",
    href: "/document/15",
  },
  {
    image: modalFourthPhoto4,
    alt: "a pregnant woman",
    text: "Heartburn",
    href: "/document/50",
  },
  {
    image: modalFourthPhoto5,
    alt: "a pregnant woman standing",
    text: "Leg cramps",
    href: "/document/38",
  },
  {
    image: modalFourthPhoto6,
    alt: "a group of women",
    text: "Nausea and vomiting",
    href: "/document/53",
  },
];

export const modalFifthContent = [
  {
    image: modalFifthPhoto1,
    alt: "a woman and a doctor",
    text: "Woman-held case notes",
    href: "/document/12",
  },
  {
    image: modalFifthPhoto2,
    alt: "a doctor checking a pregnant patient",
    text: "Midwife-led continuity of care",
    href: "/document/29",
  },
  {
    image: modalFifthPhoto3,
    alt: "a group of women",
    text: "Group ANC",
    href: "/document/30",
  },
  {
    image: modalFifthPhoto4,
    alt: "two women getting taught",
    text: "Participatory learning and action (PLA) cycles",
    href: "/document/31",
  },
  {
    image: modalFifthPhoto5,
    alt: "a group of women sitting",
    text: "Community and home visits",
    href: "/document/16",
  },
  {
    image: modalFifthPhoto6,
    alt: "a doctor examining a pregnant patient",
    text: "Task shifting components of ANC",
    href: "/document/39",
  },
  {
    image: modalFifthPhoto7,
    alt: "a group of doctors around a table",
    text: "Recruitment and retention of rural staff",
    href: "/document/18",
  },
  {
    image: modalFifthPhoto8,
    alt: "a nurse attending a pregnant patient",
    text: "ANC contact schedule",
    href: "/document/40",
  },
];

export const modalSixthContent = [
  {
    image: modalSixthPhoto1,
    alt: "a group of women",
    text: "What matters to women",
    href: "https://obgyn.onlinelibrary.wiley.com/doi/full/10.1111/1471-0528.13819",
  },
  {
    image: modalSixthPhoto2,
    alt: "a pregnant woman and a nurse",
    text: "Provision and uptake of routine antenatal service",
    href: "https://www.cochranelibrary.com/cdsr/doi/10.1002/14651858.CD012392.pub2/full",
  },
];

export const modalSeventhContent = [
  {
    image: modalSeventhPhoto1,
    alt: "a pregnant woman doing chores",
    text: "Birth preparedness and complication readiness",
    href: "/document/19",
  },
  {
    image: modalSeventhPhoto2,
    alt: "a woman and a man in a pool",
    text: "Male involvement interventions",
    href: "/document/20",
  },
  {
    image: modalSeventhPhoto3,
    alt: "a woman speaking to a group of other women",
    text: "Interventions to promote awareness of human, sexual and reproductive rights and the right to access quality skilled care",
    href: "/document/41",
  },
  {
    image: modalSeventhPhoto4,
    alt: "a doctor examining a pregnant woman",
    text: "Partnership with Traditional Birth Attendants (TBAs)",
    href: "/document/21",
  },
  {
    image: modalSeventhPhoto5,
    alt: "a woman speaking to a group of mothers with children",
    text: "Providing culturally appropriate skilled maternity care",
    href: "/document/22",
  },
  {
    image: modalSeventhPhoto6,
    alt: "a man and a woman doing a podcast",
    text: "Community participation in quality-improvement processes",
    href: "/document/23",
  },
  {
    image: modalSeventhPhoto7,
    alt: "a group of women protesting",
    text: "Community participation in programme planning and implementation",
    href: "/document/24",
  },
];
